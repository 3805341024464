import { FC } from 'react';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import log from 'loglevel';

// App
import AuthButton from 'containers/Auth/components/Modal';
import { buttonPrimary, buttonDanger, buttonSuccess, buttonMuted } from 'styles/classnames';
import useRequestResponseContext from 'hooks/useRequestResponse';
import { UserAuthType } from 'types';
import { useSelector, useApp } from 'hooks';
import { comment as commentApi, userAuth as userAuthApi } from 'store/index';

interface SubmitButtonProps {
  onSubmit: () => void;
  disabled: boolean;
  state: any;
  setState: (state: any) => void;
}

const SubmitButton: FC<SubmitButtonProps> = ({ onSubmit, disabled, state, setState }) => {
  const userAuth: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const commentList = useSelector((state: any) => state[commentApi.APP_NAME].list);
  const {
    canRequestCall,
    requestedUser,
    hasInSufficientFundsForMeeting,
    hasInSufficientFundsForMessage,
  } = useRequestResponseContext();
  const business = requestedUser?.business;
  const { app } = useApp();

  // There is currently a bug where the user store doesn't get populated fast enough
  // for is sufficient amount check to be called
  const authOnSuccess = (func: VoidFunction) => {
    log.debug(func());
  };

  if (app === 'feedback') {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <AuthButton
            title="Create account to Submit"
            onSuccess={() => authOnSuccess(onSubmit)}
            className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            variant="outlined"
            className={classnames(buttonSuccess, 'tw-w-full tw-py-2 tw-text-lg')}
            onClick={() => ''}
          >
            Book Meeting
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (!canRequestCall) {
    if (userAuth?.id) {
      return (
        <Grid item xs={12} sm={12}>
          <Button
            variant="outlined"
            className={classnames(
              disabled ? buttonMuted : buttonPrimary,
              'tw-w-full tw-py-2 tw-text-lg',
            )}
            onClick={onSubmit}
            disabled={disabled}
            id="feedback-submit-button"
          >
            {commentList.length > 0 ? 'Submit' : 'Ask Question'}
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={12}>
          <AuthButton
            title="Create account to Submit"
            onSuccess={() => authOnSuccess(onSubmit)}
            className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
            disabled={false}
          />
        </Grid>
      );
    }
  }

  if (userAuth?.id) {
    if (state.isVideoCall) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              className={classnames(buttonDanger, 'tw-w-full tw-py-2 tw-text-lg')}
              onClick={() => setState({ isVideoCall: false })}
              id="feedback-submit-button"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              className={classnames(buttonSuccess, 'tw-w-full tw-py-2 tw-text-lg')}
              onClick={onSubmit}
              id="feedback-submit-button"
            >
              {hasInSufficientFundsForMeeting ? 'Continue to Payment' : 'Send Meeting Request'}
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              className={classnames(
                disabled ? buttonMuted : buttonPrimary,
                'tw-w-full tw-py-2 tw-text-lg',
              )}
              onClick={onSubmit}
              disabled={disabled}
              id="feedback-submit-button"
            >
              {hasInSufficientFundsForMessage ? 'Continue to Payment' : 'Ask Question'}
            </Button>
          </Grid>

          {/* If there is no business id set or the business feature config is true then show request video call  */}
          {!business?.id || business?.feature_config?.allow_video_call ? (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                variant="outlined"
                className={classnames(buttonSuccess, 'tw-w-full tw-py-2 tw-text-lg')}
                onClick={() => setState({ isVideoCall: true, meetingType: 'video' })}
                id="feedback-submit-button"
              >
                Request Video Call
              </Button>
            </Grid>
          ) : null}
        </Grid>
      );
    }
  } else {
    // Not authenticated
    if (state.isVideoCall) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              className={classnames(buttonDanger, 'tw-w-full tw-py-2 tw-text-lg')}
              onClick={() => setState({ isVideoCall: false })}
              id="feedback-cancel-button"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AuthButton
              title="Create account to Send Request"
              onSuccess={() => authOnSuccess(onSubmit)}
              className={classnames(buttonSuccess, 'tw-w-full tw-py-2 tw-text-lg')}
              disabled={false}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AuthButton
              title="Create account to Submit"
              onSuccess={() => authOnSuccess(onSubmit)}
              className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
              disabled={false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* If there is no business id set or the business feature config is true then show request video call  */}
            {canRequestCall &&
            requestedUser &&
            (!business?.id || business?.feature_config?.allow_video_call) ? (
              <Button
                variant="outlined"
                className={classnames(buttonSuccess, 'tw-w-full tw-py-2 tw-text-lg')}
                onClick={() => setState({ isVideoCall: true, meetingType: 'video' })}
              >
                Request Video Call
              </Button>
            ) : null}
          </Grid>
        </Grid>
      );
    }
  }
};

export default SubmitButton;
