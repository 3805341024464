import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import classnames from 'classnames';

// App
import { userPublic as userPublicApi, businessMember as businessMemberApi } from 'store/index';
import { buttonPrimary } from 'styles/classnames';
import { ROUTE_MAP } from 'utils/constants';
import { useDispatch } from 'hooks';

import MemberTable from './MemberTable';

export default function Dashboard(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(businessMemberApi.reset());
    dispatch(userPublicApi.reset());
  }, []);

  return (
    <Container maxWidth="xl">
      <Link to={`${ROUTE_MAP.business.member}/add`}>
        <Button
          variant="contained"
          className={classnames(buttonPrimary, 'tw-mb-4 tw-w-full tw-py-2 tw-text-base md:tw-w-36')}
        >
          Add Member
        </Button>
      </Link>
      <MemberTable />
    </Container>
  );
}
