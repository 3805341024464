import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { FileWithPath } from 'file-selector';

// Material ui
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

// App
import { board as boardApi } from 'store/index';
import { uploadVideo } from 'store/firebase';
import DropzoneComponent from 'components/Upload/DropzoneLarge';
import { buttonPrimary, buttonMuted } from 'styles/classnames';
import { FileType } from 'types';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
    paddingLeft: '15%',
    paddingRight: '15%',
    minHeight: '60vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      paddingLeft: '5%',
      paddingRight: '5%',
      minHeight: '50vh',
    },
  },

  content: {
    flex: 1,
    alignSelf: 'center',
  },
}));

function RenderUpload({ subtitle }: { subtitle?: string }): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(boardApi.reset());
    dispatch(boardApi.getDetailRequest('current_board'));
  }, []);

  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const board = useSelector((state: any) => state[boardApi.APP_NAME].detail);
  const firebaseStorage = useSelector((state: any) => state.firebaseStorage);
  const disabled = fileList.length === 0;

  const UPLOAD_TEXT = {
    A: {
      title: 'Upload a file to begin getting or giving feedback.',
    },
    B: {
      title: 'Upload file',
      subtitle: 'Upload and share the file you want to give feedback on.',
    },
  };
  return (
    <Paper elevation={0} variant="outlined" style={{ marginTop: 24, padding: 16 }}>
      <Grid container spacing={3} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <DropzoneComponent
            title={UPLOAD_TEXT.B.title}
            subtitle={subtitle || UPLOAD_TEXT.B.subtitle}
            fileList={fileList}
            setFileList={(fileList: FileWithPath[]) => setFileList(fileList)}
            board={board}
            progress={firebaseStorage.payload}
            uploadStatus={firebaseStorage.status}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            className={classnames(
              'submit-upload-button',
              disabled ? buttonMuted : buttonPrimary,
              'tw-w-full tw-text-lg',
            )}
            disabled={disabled}
            onClick={() => {
              const file = fileList[0];
              const data: any = {
                title: file.name,
                metadata: file,
                upload_type: 'PRIMARY',
                recorded_timestamp: file.lastModified,
                board_id: board.id,
              };

              dispatch(
                uploadVideo(data, file, (videoObj?: FileType) => {
                  dispatch(boardApi.getDetailRequest(board.id)); // Sets the current board context
                  if (location.pathname === window.location.hash.split('#')[1].split('?')[0]) {
                    if (videoObj?.id) {
                      navigate(`/manage-file/${videoObj.id}/${location.search || ''}`);
                    }
                  }
                }),
              );
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

interface UploadType {
  subtitle?: string;
}

const Upload: FC<UploadType> = ({ subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <RenderUpload subtitle={subtitle} />
      </div>
    </div>
  );
};

export default Upload;
