import { FC } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import classnames from 'classnames';

// MUI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// App
import { UserAvatar } from 'components/Common/Avatar';
import { userAuth as userAuthApi } from 'store/index';
import { buttonPrimary, card } from 'styles/classnames';
import { UserAuthType } from 'types';
import { useSelector, useBusiness } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: 'auto',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
      marginBottom: 20,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  editContainer: {
    marginTop: 7,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

const ProfileSummary: FC = () => {
  const classes = useStyles();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { business } = useBusiness();

  return (
    <div className={classnames(classes.root, card)}>
      <Grid
        container
        style={{ paddingTop: 20, paddingBottom: 20 }}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4} lg={3} xl={2} className={classes.avatarContainer}>
          <UserAvatar user={user} size={200} />
        </Grid>

        <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
          <Typography variant="h5" display="block">
            {user.full_name || `@${user.username}`}
          </Typography>

          {user.full_name ? (
            <Typography variant="body1" display="block" className="tw-mt-2">
              @{user.username}
            </Typography>
          ) : null}

          {business?.financial_config?.external_billing ||
          business?.financial_config?.disable_billing ? null : (
            <Typography variant="body1" display="block" className="tw-mt-2" id="rate-per-Balance">
              Current balance: {numeral(user?.balance?.available_amount || 0).format('$ 0.00')}
            </Typography>
          )}

          <div className={classnames(classes.editContainer, 'tw-mt-5')}>
            <Link to="/account">
              <Button
                variant="outlined"
                className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-sm')}
                id="edit-profile"
              >
                Edit
              </Button>
            </Link>

            {business?.financial_config?.external_billing ||
            business?.financial_config?.disable_billing ? null : (
              <Link to="/top-up">
                <Button
                  variant="outlined"
                  className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-sm')}
                  id="top-up"
                >
                  Top up
                </Button>
              </Link>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileSummary;
