import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import { useQuery } from '@apollo/client';

// App
import { userPublic as userPublicApi, businessMember as businessMemberApi } from 'store/index';
import { getStaffAnalyticsQuery } from 'store/graphql/query';
import { BusinessMemberType, UserBaseType } from 'types';
import ProfileSummary from 'containers/Business/Member/components/ProfileSummary';
import TotalCard from 'components/Analytics/TotalCard';
import MonthlyBreakdown from 'components/Analytics/MonthlyBreakdown';
import { useBusiness, useDispatch, useSelector } from 'hooks';

interface GraphData {
  month: string;
  value: number;
}

interface MemberAnalyticsType {
  totalClients?: number;
  totalRequests?: number;
  totalRevenue?: number;
  loading?: boolean;
  monthlyRequests: GraphData[];
  monthlyRevenue: GraphData[];
}
function MemberAnalytics({
  totalClients,
  totalRequests,
  totalRevenue,
  monthlyRequests,
  monthlyRevenue,
  loading,
}: MemberAnalyticsType): JSX.Element {
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  if (!user.id) {
    return <div />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <TotalCard value={totalClients} loading={loading} title="Total Clients" />
      </Grid>

      <Grid item xs={12} md={4}>
        <TotalCard value={totalRequests} loading={loading} title="Total Requests" />
      </Grid>

      <Grid item xs={12} md={4}>
        <TotalCard value={totalRevenue} loading={loading} title="Total Revenue" isCurrency />
      </Grid>

      <Grid item xs={12}>
        <MonthlyBreakdown title="Requests" data={monthlyRequests} loading={loading} />
      </Grid>

      <Grid item xs={12}>
        <MonthlyBreakdown title="Revenue" data={monthlyRevenue} loading={loading} isCurrency />
      </Grid>
    </Grid>
  );
}
export default function BusinessMemberView(): JSX.Element {
  const dispatch = useDispatch();
  const params = useParams();
  const member: BusinessMemberType = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].detail,
  );
  const { business } = useBusiness();

  useEffect(() => {
    dispatch(businessMemberApi.reset());
    if (params.id) dispatch(businessMemberApi.getDetailRequest(params.id));
  }, []);

  useEffect(() => {
    dispatch(userPublicApi.reset());
    if (member?.member?.username) {
      dispatch(userPublicApi.getDetailRequest(member?.member?.username));
    }
  }, [member?.member?.username]);

  const { loading, data: analyticsData } = useQuery(getStaffAnalyticsQuery, {
    fetchPolicy: 'network-only',
    variables: { memberUid: params.id, businessUid: business?.uid },
    skip: !params.id || !business?.uid,
  });

  return (
    <Container maxWidth="xl">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/business/member">members</Link>
        <Typography color="text.primary">{member?.member?.username || member?.email}</Typography>
      </Breadcrumbs>

      <ProfileSummary />

      <MemberAnalytics
        totalClients={analyticsData?.getStaffAnalytics?.totalClients}
        totalRequests={analyticsData?.getStaffAnalytics?.totalRequests}
        totalRevenue={analyticsData?.getStaffAnalytics?.totalRevenue}
        monthlyRevenue={analyticsData?.getStaffAnalytics?.monthlyRevenue}
        monthlyRequests={analyticsData?.getStaffAnalytics?.monthlyRequests}
        loading={loading}
      />
    </Container>
  );
}
