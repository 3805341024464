import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import classnames from 'classnames';

// App
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  loginLink: {
    textDecoration: 'none',
    color: 'white',
  },
  navLink: {
    color: 'inherit',
    position: 'relative',
    padding: '0.9375rem',
    fontWeight: '600',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '""',
        display: 'block',
        height: '1px',
        marginLeft: '15px',
        backgroundColor: '#e5e5e5',
      },
    },
  },
}));

interface IInternalExternalLink {
  children: ReactNode;
  path: string;
  className?: string;
  external: boolean;
  onClick?: () => void;
}

interface INavLink {
  path: string;
  title: string;
  external: boolean;
  handleDrawerClose: () => void;
}

export const InternalExternalLink: FC<IInternalExternalLink> = ({
  children,
  path,
  className,
  external,
  ...rest
}) => {
  if (external) {
    return (
      <a
        href={path}
        className={className}
        style={{ textDecoration: 'none', color: 'inherit' }}
        {...rest}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={path}
        className={className}
        style={{ textDecoration: 'none', color: 'inherit' }}
        {...rest}
      >
        {children}
      </Link>
    );
  }
};

export const NavLink: FC<INavLink> = ({ path, title, handleDrawerClose, external }) => {
  const classes = useStyles();

  return (
    <InternalExternalLink path={path} className={classes.loginLink} external={external}>
      <ListItem className={classes.listItem}>
        <Button
          className={classnames(classes.navLink, 'tw-text-base tw-capitalize tw-text-blue-700')}
          onClick={() => {
            handleDrawerClose && handleDrawerClose();
            segmentAnalytics.track(SEGMENT_CONSTANTS.NAV_CLICKED, {
              path,
              title,
            });
          }}
        >
          {title}
        </Button>
      </ListItem>
    </InternalExternalLink>
  );
};
