/* eslint-disable no-console */
// logging stuff

const ENABLE_LOG = false;

export default {
  info: (...args) => {
    ENABLE_LOG && console.log(...args);
  },
  error: (...args) => {
    ENABLE_LOG && console.log(...args);
  },
  actionError: (error, params) => {
    ENABLE_LOG && console.log('actionError: ', params);
    try {
      // analytics.logEventError(params);
      // Sentry.captureException(error, { extra: params });
    } catch (err) {}
  },
  warn: (...args) => {
    ENABLE_LOG && console.log(...args);
  },
  debug: (...args) => {
    ENABLE_LOG && console.log(...args);
  },

  compression: params => {
    ENABLE_LOG && console.log(params);
    // Sentry.captureMessage('Compression Statistics', { extra: params });
  },

  exception(error, params = {}) {
    ENABLE_LOG && console.log(error, params);
    // try { Sentry.captureException(error, { extra: params }); } catch (err) {};
  },
};
