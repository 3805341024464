import { Link } from 'react-router-dom';
import { Img } from 'react-image';

import logo from 'images/fs/logo-light.svg';
import { Container } from './Container';
// import { FacebookIcon, YoutubeIcon, TwitterIcon, InstagramIcon, TiktokIcon } from './SocialIcons';
import { APP_HUMAN_NAME } from 'utils/constants';

const navigation = {
  company: [
    { name: 'About us', href: '/about' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms of use', href: '/tos' },
    { name: 'Contact us', href: '/contact' },
  ],
  // solutions: [
  //   { name: 'Fitness instructors', href: '/solutions#fitness' },
  //   { name: 'Tutors', href: '/solutions#tutors' },
  //   { name: 'Therapists', href: '/solutions#therapists' },
  //   { name: 'Dance teachers', href: '/solutions#dance-teachers' },
  // ],
  integrations: [
    { name: 'Eventbrite', href: '/integration/eventbrite' },
    //   { name: 'Zoom', href: '#' },
    //   { name: 'Stripe', href: '#' },
    //   { name: 'Google calendar', href: '#' },
  ],
  resources: [
    { name: 'FAQ', href: '/#faq' },
    { name: 'Pricing', href: '/pricing' },
    // { name: 'Help center', href: `https://helpdesk.${BASE_DOMAIN}/` },
  ],
};

// function SocialLink({ icon: Icon, href, ...props }: { icon: any; href: string }) {
//   return (
//     <Link className="tw-group" to={href} {...props}>
//       <Icon className="tw-h-5 tw-w-5 tw-text-slate-100/90 tw-duration-150 group-hover:tw-text-slate-50" />
//     </Link>
//   );
// }

export function Footer() {
  return (
    <section className="tw-overflow-hidden tw-bg-slate-700 tw-pb-12 tw-pt-20">
      <Container>
        <div className="lg:tw-grid lg:tw-grid-cols-12 lg:tw-gap-12">
          <div className="tw-max-w-xl lg:tw-col-span-7 lg:tw-max-w-none">
            <Link to="/" className="tw-flex tw-flex-shrink-0" aria-label="Home">
              <Img src={logo} className="tw-h-7 tw-w-auto sm:tw-h-8 lg:tw-h-9" alt="Logo" />
            </Link>
            <p className="tw-mt-10 tw-text-md tw-leading-relaxed tw-text-slate-50 tw-w-96">
              Faster learning through feedback helps engage and retain clients by providing them
              with insights after each session.
            </p>
            {/* <div className="tw-mt-8 tw-flex tw-items-center tw-gap-4">
              <SocialLink
                href="https://facebook.com"
                aria-label="Follow on Facebook"
                icon={FacebookIcon}
              />
              <SocialLink
                href="https://youtube.com"
                aria-label="Follow on Youtube"
                icon={YoutubeIcon}
              />
              <SocialLink
                href="https://twitter.com"
                aria-label="Follow on Twitter"
                icon={TwitterIcon}
              />
              <SocialLink
                href="https://instagram.com"
                aria-label="Follow on Instagram"
                icon={InstagramIcon}
              />
              <SocialLink
                href="https://tiktok.com"
                aria-label="Follow on Tiktok"
                icon={TiktokIcon}
              />
            </div> */}
          </div>
          <div className="tw-mt-12 tw-grid tw-grid-cols-12 tw-gap-8 lg:tw-col-span-5 lg:tw-mt-0">
            <div className="tw-col-span-6 md:tw-col-span-3">
              <p className="tw-text-md tw-font-semibold tw-text-white">Company</p>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-3">
                {navigation.company.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="tw-text-md tw-text-slate-100 tw-duration-150 hover:tw-text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* <div className="tw-col-span-6 md:tw-col-span-3">
              <p className="tw-text-md tw-font-semibold tw-text-white">Solutions</p>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-3">
                {navigation.solutions.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="tw-text-md tw-text-slate-100 tw-duration-150 hover:tw-text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div> */}
            <div className="tw-col-span-6 md:tw-col-span-3">
              <p className="tw-text-md tw-font-semibold tw-text-white">Integrations</p>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-3">
                {navigation.integrations.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="tw-text-md tw-text-slate-100 tw-duration-150 hover:tw-text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="tw-col-span-6 md:tw-col-span-3">
              <p className="tw-text-md tw-font-semibold tw-text-white">Resources</p>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-3">
                {navigation.resources.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="tw-text-md tw-text-slate-100 tw-duration-150 hover:tw-text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr className="tw-mt-16 tw-border-gray-secondary-400/60" />
        <div className="tw-flex tw-w-full tw-flex-col tw-justify-between tw-pt-8 sm:tw-flex-row">
          <p className="tw-text-md tw-text-slate-200">
            © {new Date().getFullYear()} {APP_HUMAN_NAME}, Inc. All rights reserved.
          </p>
          {/* <div className="tw-mt-5 tw-flex tw-gap-3 sm:tw-mt-0">
            <Link
              to="#"
              className="tw-text-md tw-text-slate-100 tw-underline tw-duration-150 hover:tw-text-white"
            >
              Terms
            </Link>
            <Link
              to="#"
              className="tw-text-md tw-text-slate-100 tw-underline tw-duration-150 hover:tw-text-white"
            >
              Privacy
            </Link>
            <Link
              to="#"
              className="tw-text-md tw-text-slate-100 tw-underline tw-duration-150 hover:tw-text-white"
            >
              Cookies
            </Link>
          </div> */}
        </div>
      </Container>
    </section>
  );
}
