import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

// Material UI
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import green from '@mui/material/colors/green';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

// App
import ConfirmDelete from 'components/Common/ConfirmDelete';
import { VideoTitleLoading } from 'components/Loading/index';
import PlayerComponent from 'containers/File/components/Player';
import FileUnavailableComponent from 'containers/File/components/Unavailable';
import FileDescription from 'components/File/Description';
import { video as videoApi } from 'store/index';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import { notification } from 'store/notification';
import UsernameLink from 'components/User/UsernameLink';
import { buttonPrimary, buttonSuccess, buttonMuted } from 'styles/classnames';
import { FileType } from 'types';
import { useDispatch, useSelector, useBusiness } from 'hooks';
import { ContentCard } from 'layouts/dashboard/Content';

const useStyles = makeStyles(() => ({
  spanCopied: {
    backgroundColor: green[300],
  },
}));

interface EditingViewProps {
  video: FileType;
  onClose: VoidFunction;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditingView({ video, onClose }: EditingViewProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(video);
  const [copied, setCopied] = useState(false);
  const { members } = useBusiness();
  const usernames =
    members
      ?.filter(x => x?.member && x?.member_type === 'CLIENT')
      ?.map(x => x?.member?.username || '') || [];
  const sharedWith = () =>
    members
      ?.filter(
        x => x?.member && video?.shared_with && video?.shared_with?.indexOf(x?.member.id) !== -1,
      )
      ?.map(x => x?.member?.username || '') || [];
  const [clients, setClients] = useState<string[]>(sharedWith());
  const classes = useStyles();

  useEffect(() => {
    setState(video);
    setClients(sharedWith());
  }, [video.id]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setClients(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      <ContentCard>
        <Typography variant="subtitle1" gutterBottom className="tw-mb-4 tw-text-xl">
          Edit Details
        </Typography>

        <TextField
          id="outlined-basic"
          label="Name (required)"
          variant="outlined"
          onChange={ev => setState({ ...state, title: ev.target.value })}
          value={state.title}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          onChange={ev => setState({ ...state, description: ev.target.value })}
          value={state.description}
          rows={4}
          fullWidth
          multiline
          style={{ marginTop: 10 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Divider className="tw-my-5" />

        <Typography variant="subtitle1" gutterBottom className="tw-mb-2 tw-text-xl">
          Share with Clients
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="tw-flex tw-items-center">
            <Typography variant="body1" gutterBottom className="tw-text-lg tw-font-normal">
              Share with all clients
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="tw-flex tw-items-center">
            <Switch
              checked={!!usernames?.length && usernames?.length === clients?.length}
              onChange={(ev: any) => setClients(ev.target.checked ? [...usernames] : [])}
              className="tw-float-right"
            />
          </Grid>

          <Grid item xs={12} md={6} className="tw-flex tw-items-center">
            <Typography variant="body1" gutterBottom className="tw-text-lg tw-font-normal">
              Select individual clients
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label">Select Clients</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={clients}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
                fullWidth
              >
                {usernames?.map((name: string) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={clients.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider className="tw-my-5" />

        <Typography variant="subtitle1" gutterBottom className="= tw-mt-10 tw-text-xl">
          Make Public
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom className="tw-text-lg tw-font-normal">
              Click to share with non clients{' '}
              <Chip
                variant="outlined"
                color="primary"
                icon={video.dynamic_link ? <PublicIcon /> : <LockIcon />}
                label={video.dynamic_link ? 'Published' : 'Private'}
              />
            </Typography>

            {video.dynamic_link && (
              <>
                <Typography variant="body1" gutterBottom display="block">
                  <a
                    target="_blank"
                    href={video.dynamic_link}
                    style={{ marginRight: 5 }}
                    className={classnames({
                      [classes.spanCopied]: copied,
                    })}
                  >
                    {video.dynamic_link}
                  </a>

                  <CopyToClipboard text={video.dynamic_link} onCopy={() => setCopied(true)}>
                    <Button
                      variant="outlined"
                      className={classnames(buttonPrimary, 'tw-text-sm')}
                      style={{
                        width: copied ? 50 : 40,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {copied ? 'copied' : 'copy'}
                    </Button>
                  </CopyToClipboard>
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  display="block"
                  style={{ display: 'flex' }}
                >
                  <span>(share this link with your audience)</span>
                </Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              id="button-share"
              variant="outlined"
              className={classnames(
                video.dynamic_link ? buttonMuted : buttonSuccess,
                'tw-w-36 tw-text-base',
              )}
              onClick={() => {
                if (video.dynamic_link) {
                  dispatch(videoApi.patchRequest({}, `${video.id}/make_private`));
                } else {
                  dispatch(videoApi.patchRequest({}, `${video.id}/dynamic_web_link`));
                  segmentAnalytics.track(SEGMENT_CONSTANTS.DL_GENERATED, {
                    video_id: video.id,
                  });
                }
              }}
            >
              {video.dynamic_link ? 'Unpublish' : 'Publish'}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="tw-mt-10">
          <Grid item sm={6} md={3}>
            <Button
              className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
              onClick={() => {
                const data = {
                  title: state.title,
                  description: state.description,
                  shared_with:
                    members
                      ?.filter(x => clients.indexOf(x?.member?.username || '') !== -1)
                      ?.map(x => x?.member?.id) || [],
                };
                const options = {
                  notify: { success: `Video ${state.title} was updated.` },
                };
                // @ts-ignore
                dispatch(videoApi.patchRequest(data, video.id, options)).then(() => onClose());
              }}
            >
              Save
            </Button>
          </Grid>

          <Grid item sm={6} md={3}>
            <span className="tw-w-full">
              <ConfirmDelete
                onDelete={() => {
                  const options = {
                    notify: { success: `Video ${state.title} was deleted.` },
                  };
                  // @ts-ignore
                  dispatch(videoApi.deleteRequest(video.id, options)).then(resp => {
                    if (resp.success) {
                      segmentAnalytics.track(SEGMENT_CONSTANTS.VIDEO_DELETE, {
                        comment_id: video.id,
                        status: 'success',
                      });
                      navigate('/manage-file');
                    } else {
                      dispatch(notification('Something went wrong, unable to delete', 'error'));
                      segmentAnalytics.track(SEGMENT_CONSTANTS.VIDEO_DELETE, {
                        comment_id: video.id,
                        status: 'error',
                      });
                    }
                  });
                }}
                content={`Do you want to delete ${video?.title}?`}
              />
            </span>
          </Grid>
        </Grid>
      </ContentCard>
    </>
  );
}

function RenderTitleContent(): JSX.Element {
  const video = useSelector((state: any) => state[videoApi.APP_NAME].detail);
  const initialized = useSelector((state: any) => state[videoApi.APP_NAME].initialized);
  const username = video.created_by ? video.created_by.username : '';

  if (!initialized) {
    return <VideoTitleLoading />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10}>
        <Typography variant="h6" gutterBottom>
          {video.title}
        </Typography>

        <Typography variant="body1" gutterBottom display="flex">
          <UsernameLink username={username} /> - {video.created_at_humanize}
        </Typography>

        <FileDescription video={video} />

        {video.parent && video.parent.id && (
          <Typography variant="body1" gutterBottom display="block">
            Shared by <UsernameLink username={video.parent.created_by.username} />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default function ManageDetailContainer(): JSX.Element {
  const params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  const initialized = useSelector((state: any) => state[videoApi.APP_NAME].initialized);
  const errorDetail = useSelector((state: any) => state[videoApi.APP_NAME].errorDetail);
  const videoDetail = useSelector((state: any) => state[videoApi.APP_NAME].detail);
  const { memberInitialized } = useBusiness();

  useEffect(() => {
    if (params.id) dispatch(videoApi.getDetailRequest(params.id));
    dispatch(videoApi.getRequest());
  }, [params.id]);

  if (initialized && errorDetail) {
    return <FileUnavailableComponent />;
  }

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PlayerComponent videoDetail={videoDetail} />
        </Grid>
      </Grid>

      <RenderTitleContent />

      {memberInitialized ? (
        <EditingView video={videoDetail} onClose={() => setState({ ...state, isEditing: false })} />
      ) : null}
    </div>
  );
}
