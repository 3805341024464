import { useState, useEffect, useRef } from 'react';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';

import { GOOGLE_MAPS_API_KEY } from 'utils/constants';

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const PlaceAutocomplete = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: [
        'name',
        'formatted_address',
        'place_id',
        'utc_offset_minutes',
        'address_components',
        'geometry.location',
      ],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input
        ref={inputRef}
        className="tw-w-full tw-py-2 tw-h-12 tw-px-2 tw-text-base tw-border-slate-300 focus:tw-border-slate-600 tw-border"
      />
    </div>
  );
};

interface Props {
  selectedPlace: google.maps.places.PlaceResult | null;
  setSelectedPlace: (value: google.maps.places.PlaceResult | null) => void;
}

export default function Autocomplete({ setSelectedPlace }: Props): JSX.Element {
  return (
    <APIProvider
      apiKey={GOOGLE_MAPS_API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <div className="autocomplete-control">
        <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
      </div>
    </APIProvider>
  );
}
