import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// material
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

import { FileType } from 'types/index';
import FileDialog from 'components/File/Dialog';

function LightboxModalStyles() {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const backgroundIcon = (_iconName: string) => ({
    backgroundSize: '24px 24px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: alpha(theme.palette.grey[900], 0.8),
    // backgroundImage: `url("/static/icons/controls/${iconName}.svg")`,
    width: '40px',
    height: '40px',
  });

  return (
    <GlobalStyles
      styles={{
        '& .ReactModalPortal': {
          '& .ril__outer': {
            backdropFilter: 'blur(8px)',
            backgroundColor: alpha(theme.palette.grey[900], 0.88),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& .ril__inner': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },

          // Toolbar
          '& .ril__toolbar': {
            height: 'auto !important',
            padding: theme.spacing(2, 3),
            backgroundColor: 'transparent',
            position: 'absolute',
            top: 0,
            right: 0,
          },
          '& .ril__toolbarLeftSide': { display: 'none' },
          '& .ril__toolbarRightSide': {
            height: 'auto !important',
            padding: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            '& li': {
              display: 'flex',
              alignItems: 'center',
            },
            '& li:first-of-type': {
              flexGrow: 1,
            },
            '& li:not(:first-of-type)': {
              width: theme.spacing(5),
              height: theme.spacing(5),
              justifyContent: 'center',
              marginLeft: theme.spacing(1),
              borderRadius: theme.shape.borderRadius,
            },
          },

          // Button
          '& button:focus': { outline: 'none' },
          '& .ril__toolbarRightSide button': {
            width: '100%',
            height: '100%',
            '&.ril__zoomInButton': backgroundIcon('maximize-outline'),
            '&.ril__zoomOutButton': backgroundIcon('minimize-outline'),
            '&.ril__closeButton': backgroundIcon('close'),
          },
          '& .ril__navButtons': {
            padding: theme.spacing(3),
            borderRadius: theme.shape.borderRadiusSm,
            '&.ril__navButtonPrev': {
              left: theme.spacing(2),
              right: 'auto',
              ...backgroundIcon(isRTL ? 'arrow-ios-forward' : 'arrow-ios-back'),
            },
            '&.ril__navButtonNext': {
              right: theme.spacing(2),
              left: 'auto',
              ...backgroundIcon(isRTL ? 'arrow-ios-back' : 'arrow-ios-forward'),
            },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

interface ModalLighboxProps {
  file: FileType | null;
  images?: string[];
  photoIndex?: number;
  setPhotoIndex?: (index: number) => void;
  isOpen: boolean;
  onClose: VoidFunction;
}

function LightboxModal({
  // images,
  // photoIndex,
  // setPhotoIndex,
  file,
  isOpen,
  onClose,
  ...other
}: ModalLighboxProps): JSX.Element {
  // const showIndex = (
  //   <Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>
  // );

  // const toolbarButtons = [showIndex];
  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
  };

  if (file?.media_type === 'IMAGE') {
    return (
      <>
        <LightboxModalStyles />

        {isOpen && (
          <Lightbox
            mainSrc={file?.video || file?.thumbnail || ''}
            // mainSrcThumbnail={file?.thumbnail || ''}
            onCloseRequest={onClose}
            reactModalStyle={customStyles}
            clickOutsideToClose={false}
            {...other}
          />
        )}

        {/* {isOpen && (
        <Lightbox
          animationDuration={120}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          toolbarButtons={toolbarButtons}
          reactModalStyle={customStyles}
          {...other}
        />
      )} */}
      </>
    );
  }

  if (file && isOpen) return <FileDialog handleClose={onClose} file={file} open={isOpen} />;

  return <span />;
}

export default LightboxModal;
