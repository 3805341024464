import React, { useEffect } from 'react';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { capitalCase } from 'change-case';

// Material UI
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// App
import { deposit as api } from 'store/index';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: '70vh',
  },
}));

const TableCellStyled = styled(TableCell)(() => ({
  padding: 6,
}));

export default function Withdraw() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactions = useSelector(state => state[api.APP_NAME].list);
  const initialized = useSelector(state => state[api.APP_NAME].initialized);

  useEffect(() => {
    dispatch(api.getRequest());
  }, []);

  let renderContent = null;

  if (initialized && transactions.length === 0) {
    renderContent = (
      <Typography variant="h6" gutterBottom align="center" style={{ marginTop: 100 }}>
        No history yet, as have not given or recieved feedback.
      </Typography>
    );
  } else
    renderContent = (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellStyled align="center">Type</TableCellStyled>
              <TableCellStyled align="center">Amount</TableCellStyled>
              <TableCellStyled align="center">Date</TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(row => (
              <TableRow key={row.id}>
                <TableCellStyled align="center">{capitalCase(row.deposit_type)}</TableCellStyled>
                <TableCellStyled align="center">
                  {numeral(row.amount || 0).format('$ 0,0.00')}
                </TableCellStyled>
                <TableCellStyled align="center">
                  {row.created_at_humanize || dayjs(row.created_at).fromNow()}
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Deposit History
        </Typography>

        {renderContent}
      </Grid>
    </Grid>
  );
}
