import React, { createContext } from 'react';
import log from 'loglevel';

const WebSocketUserContext = createContext(null);

export { WebSocketUserContext };

export default ({ children }) => {
  let socket;
  let ws;

  if (!socket) {
    try {
      socket = new WebSocket(
        `${location.protocol === 'http:' ? 'ws' : 'wss'}://${window.location.host}/ws/user`, // eslint-disable-line no-restricted-globals
      );

      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        log.debug(data);
      };

      socket.onclose = () => {
        log.info("WebSocket closed let's reopen");
        socket && socket.connect && socket.connect();
      };

      ws = {
        socket: socket,
      };
    } catch (error) {}
  }

  return <WebSocketUserContext.Provider value={ws}>{children}</WebSocketUserContext.Provider>;
};
