import { useEffect, useState, useRef } from 'react';
import sortBy from 'lodash.sortby';

import Scrollbar from 'components/Minimals/Scrollbar';
import LightboxModal from 'components/Minimals/LightboxModal';
import { CommentType, UserAuthType, FileType } from 'types/index';
import { VideoCommentListLoading } from 'components/Loading/index';
import { userAuth as userAuthApi, comment as commentApi } from 'store/index';
import { useSelector } from 'hooks';
import ChatMessageItem from './ChatMessageItem';

export default function ChatMessageList(): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);

  const comments: CommentType[] = useSelector((state: any) => state[commentApi.APP_NAME].list);
  const commentInitialized = useSelector((state: any) => state[commentApi.APP_NAME].initialized);
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [comments.length]);

  const handleOpenLightbox = (file: FileType) => {
    setOpenLightbox(true);
    setSelectedFile(file);
  };

  if (!commentInitialized) {
    return (
      <div
        style={{ position: 'absolute', top: 60, left: 0, right: 0, bottom: 57, overflow: 'scroll' }}
      >
        <VideoCommentListLoading />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 60,
          left: 0,
          right: 0,
          bottom: 57,
          overflow: 'scroll',
          marginTop: 14,
        }}
      >
        <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
          {sortBy(comments, x => x.created_at).map(message => (
            <ChatMessageItem
              key={message.id}
              message={message}
              onOpenLightbox={handleOpenLightbox}
              user={user}
            />
          ))}
        </Scrollbar>
      </div>

      <LightboxModal
        file={selectedFile}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </>
  );
}
