import { ReactNode } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App
import { card } from 'styles/classnames';

interface Props {
  globalError?: string;
  fullWidth?: boolean;
  title: string;
  children: ReactNode;
  isCenter?: boolean;
}

export function FormContainer({
  children,
  globalError,
  title,
  fullWidth,
  isCenter,
}: Props): JSX.Element {
  let renderErrorAll;
  if (globalError) {
    renderErrorAll = (
      <Grid item xs={12} sm={12} md={12}>
        <Typography id="error-text" variant="body2" gutterBottom color="error">
          {globalError}
        </Typography>
      </Grid>
    );
  }

  return (
    <div className={clsx(card, 'tw-w-full', fullWidth && 'md:tw-w-3/4')}>
      <div className="tw-mb-16 tw-w-full tw-p-4 tw-px-4 md:tw-p-8">
        <h2 className="tw-my-2 tw-text-2xl tw-font-semibold tw-text-gray-600">{title}</h2>

        <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-gap-y-2 tw-text-sm md:tw-grid-cols-5">
          <Grid
            container
            spacing={4}
            className={clsx(
              'tw-my-2 md:tw-col-span-5',
              isCenter && 'tw-flex tw-items-center tw-justify-center',
            )}
          >
            {renderErrorAll}

            {children}
          </Grid>
        </div>
      </div>
    </div>
  );
}
