import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classnames from 'classnames';
import Container from '@mui/material/Container';

import { card } from 'styles/classnames';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    // minHeight: 500,
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
  },
}));

type Props = {
  children?: React.ReactNode;
};

export const ContentCard: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <div className={classnames(card)}>
        <div className={classnames(classes.container)}>{children}</div>
      </div>
    </Container>
  );
};

export const ContentPlain: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <div className={classnames(classes.container)}>{children}</div>
    </Container>
  );
};
