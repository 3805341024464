import Typography from '@mui/material/Typography';

import { RequestType } from 'types/api';

function getStatusClassName(status?: string) {
  let className = 'tw-text-base tw-px-2 tw-rounded-md ';
  switch (status) {
    case 'PENDING': {
      className += 'tw-bg-blue-600 tw-text-white';
      break;
    }
    case 'ACCEPTED': {
      className += 'tw-text-blue-600 tw-font-medium';
      break;
    }
    case 'CANCELLED': {
      className += 'tw-text-red-600 tw-font-medium';
      break;
    }
    case 'REJECTED': {
      className += 'tw-text-red-600 tw-font-medium';
      break;
    }
    case 'COMPLETED': {
      className += 'tw-text-green-600 tw-font-medium';
      break;
    }
  }
  return className;
}

interface IStatusRequest {
  item?: RequestType;
}

export const StatusRequest = ({ item }: IStatusRequest) => {
  const className = getStatusClassName(item?.status);
  return (
    <Typography display="inline" className={`${className} tw-lowercase`}>
      {item?.status || ''}
    </Typography>
  );
};
