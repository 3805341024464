import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

// MAterial ui
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EventIcon from '@mui/icons-material/Event';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// App
import { UserAvatar } from 'components/Common/Avatar';
import Axios from 'utils/axios';
import { userAuth as userAuthApi } from 'store/index';
import { InternalExternalLink } from './common';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import { API_URL, APP_URL, ROUTE_MAP } from 'utils/constants';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles(() => ({
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
}));

interface INavItem {
  title: string;
  path: string;
  handleDrawerToggle?: () => void;
  isMobile?: boolean;
  callback?: () => void;
  external: boolean;
  icon?: ReactNode | string;
}

const NavItem: FC<INavItem> = ({
  title,
  icon,
  path,
  handleDrawerToggle,
  isMobile,
  callback,
  external,
}) => {
  return (
    <InternalExternalLink
      path={path}
      external={external}
      onClick={() => {
        isMobile && handleDrawerToggle && handleDrawerToggle();
        callback && callback();
        segmentAnalytics.track(SEGMENT_CONSTANTS.NAV_CLICKED, {
          path,
          title,
        });
      }}
    >
      <ListItem button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </InternalExternalLink>
  );
};

interface INavContainer {
  handleDrawerToggle?: () => void;
  isMobile?: boolean;
  open: boolean;
}

const NavContainer: FC<INavContainer> = ({ isMobile, open, handleDrawerToggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appConfig = useSelector((state: any) => state.app.config) || {};
  const external = appConfig.app !== 'main';

  return (
    <>
      <ListItem button onClick={handleDrawerToggle}>
        <ListItemIcon>{!open ? <MenuIcon /> : <ChevronLeftIcon />}</ListItemIcon>
        <ListItemText
          primary={!open ? null : 'Close'}
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
        />
      </ListItem>
      <Divider />
      <List>
        {!open && (
          <>
            <ListItem button onClick={handleDrawerToggle}>
              <ListItemIcon>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  className={classnames(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon /> <ChevronRightIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>

            <Divider />
          </>
        )}
        <NavItem
          title="Profile"
          path="/"
          icon={<DashboardIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />

        <NavItem
          title="Requests"
          path="/request"
          icon={<AddCommentIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />

        <NavItem
          title="Search"
          path="/u/#/"
          icon={<SearchIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external
        />

        <NavItem
          title="Video Calls"
          path={`/call`}
          icon={<VideoCallIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemText primary={<span className="tw-text-lg tw-font-medium">Manage</span>} />
        </ListItem>

        <NavItem
          title="Account"
          path={`/account`}
          icon={<AccountCircleIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />

        <NavItem
          title="Rate"
          path={`${ROUTE_MAP.manage.rate}`}
          icon={<MonetizationOnIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />

        <NavItem
          title="Availability"
          path={`${ROUTE_MAP.manage.availability}`}
          icon={<EventIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
        />

        <NavItem
          title="Logout"
          path={`/logout`}
          icon={<ExitToAppIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={external}
          callback={() => {
            Axios({
              url: `${API_URL}/api/v1/rest-auth/logout/`,
              method: 'POST',
            })
              .then(() => {
                dispatch(userAuthApi.resetDetail());
                navigate('/');
              })
              .catch(() => {
                alert("Something went wrong, can't logout");
              });
          }}
        />
      </List>
    </>
  );
};

export default NavContainer;

export const UserDrawerContents: FC<INavContainer> = ({ isMobile, open, handleDrawerToggle }) => {
  const classes = useStyles();
  const userAuthDetail = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const appConfig = useSelector((state: any) => state.app.config) || {};

  return (
    <>
      <ListItem button onClick={handleDrawerToggle}>
        <ListItemIcon>{!open ? <MenuIcon /> : <ChevronLeftIcon />}</ListItemIcon>
        <ListItemText
          primary={!open ? null : 'Close'}
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
        />
      </ListItem>

      <ListItem className="tw-align-center tw-flex tw-justify-start">
        <UserAvatar user={userAuthDetail} size={40} />
        <Typography
          variant="body1"
          style={{ color: 'black', paddingTop: 8, marginRight: 8, marginLeft: 20 }}
          display="inline"
        >
          Hi, {userAuthDetail.username}
        </Typography>
      </ListItem>

      <Divider />
      <List>
        {!open && (
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className={classnames(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
        <NavItem
          title="Dashboard"
          path={APP_URL}
          icon={<DashboardIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={appConfig.app !== 'main'}
        />

        <NavItem
          title="Search"
          path="/u/#/"
          icon={<SearchIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={appConfig.app !== 'user'}
        />
      </List>
    </>
  );
};

export const AnonymousDrawerContents: FC<INavContainer> = ({
  isMobile,
  open,
  handleDrawerToggle,
}) => {
  const classes = useStyles();
  const appConfig = useSelector((state: any) => state.app.config) || {};

  return (
    <>
      <ListItem id="drawer-close" button onClick={handleDrawerToggle}>
        <ListItemIcon>{!open ? <MenuIcon /> : <ChevronLeftIcon />}</ListItemIcon>
        <ListItemText
          primary={!open ? null : 'Close'}
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
            },
          }}
        />
      </ListItem>
      <Divider />
      <List>
        {!open && (
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className={classnames(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
        <NavItem
          title="Home"
          path="/"
          icon={<HomeIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external
        />
        <NavItem
          title="Search"
          path={appConfig.app === 'user' ? '/' : '/u/#/'}
          icon={<SearchIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={appConfig.app !== 'user'}
        />

        <NavItem
          title="Register"
          path="/register"
          icon={<PersonAddIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={appConfig.app === 'profile'}
        />

        <NavItem
          title="Login"
          path="/login"
          icon={<LaunchIcon />}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
          external={appConfig.app === 'profile'}
        />
      </List>
    </>
  );
};
