import { useContext } from 'react';
import { BusinessContext, BusinessContextProps } from 'context/BusinessContext';
// ----------------------------------------------------------------------

export default function useAuthContext(): BusinessContextProps {
  const context = useContext(BusinessContext);

  if (!context) throw new Error('Business context must be use inside BusinessProvider');

  return context;
}
