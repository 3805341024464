import { Routes, Route } from 'react-router-dom';

import EmbedPublicList from 'containers/Embed/public-list';
import EmbedPublicProfile from 'containers/Embed/public-profile';
import EmbedSuccess from 'containers/Embed/success';

export function EmbedRoutes() {
  return (
    <Routes>
      <Route path=":businessId" element={<EmbedPublicList />} />
      <Route path=":businessId/:username" element={<EmbedPublicProfile />} />
      <Route path=":businessId/:username/success/:uid" element={<EmbedSuccess />} />
    </Routes>
  );
}
