import { FC, ReactNode } from 'react';
import { FileWithPath } from 'file-selector';

// App
import { uploadVideo } from 'store/firebase';
import {
  comment as commentApi,
  userAuth as userAuthApi,
  feedbackRequest as feedbackRequestApi,
} from 'store/index';
import { notification } from 'store/notification';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import FeedbackEntry from 'components/Feedback/Entry';
import { UserBaseType, RequestType, FileType, SubmitVideoType } from 'types/index';
import { log } from 'utils/index';
import { useRequestResponse, useDispatch, useSelector, useBusiness } from 'hooks';

interface FeedbackProps {
  handleClose?: VoidFunction;
  requestId?: number | string; // This is only if from an existing request
  request?: RequestType;
  primaryText?: string | ReactNode;
  hideAvatarSection?: boolean;
  videoDetail: FileType;
}

interface ISubmitVideoData {
  title: string;
  metadata: any;
  upload_type: string;
  request_id?: number | string;
  recorded_timestamp: number;
  is_comment: boolean;
  video_comment_video_id?: number;
  media_type?: string;
}

interface ISubmitRequest {
  requested_id?: number;
  instructor_id?: number;
  business_id?: number;
  rate?: number | string;
}

const Feedback: FC<FeedbackProps> = ({ handleClose, requestId, request, videoDetail: video }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { business } = useBusiness();
  const { fileList, recordedFile, state } = useRequestResponse();

  const shouldClose = () => {
    handleClose?.();
  };

  const onSuccess = () => {
    dispatch(userAuthApi.getDetailRequest('user')); // Need to update the available balance
    dispatch(commentApi.getRequest());
    shouldClose();
  };

  let request_user: UserBaseType | undefined = undefined;

  if (video && video.created_by) {
    request_user = video.created_by;
  } else if (request && request.requested) {
    request_user = request.requested;
  }

  const instructor = request_user?.instructor;

  let insufficientFunds = false;

  if (
    !business?.financial_config?.external_billing &&
    !business?.financial_config?.disable_billing
  ) {
    insufficientFunds =
      instructor &&
      user &&
      user.balance &&
      request_user?.id !== user.id && // make sure the owner is not the one commenting
      !request?.id && // make sure this is not in response to a request i.e. must be a new request
      instructor.rate > user.balance.available_amount;
  }

  const submitVideo = async (
    file: FileWithPath | null,
    recording: Blob | null,
    vidObj?: FileType,
    request_id?: number | string,
  ) => {
    if (!file && !recording) onSuccess();

    if (file) {
      const data: SubmitVideoType = {
        title: file.name,
        metadata: file,
        upload_type: 'COMMENT',
        recorded_timestamp: file.lastModified,
        is_comment: true,
        video_comment_video_id: vidObj?.id,
        request_id: request_id,
      };

      dispatch(
        uploadVideo(data, file, () => {
          onSuccess();
        }),
      );
    }

    if (recording) {
      let media_type = '';
      if (recording.type.includes('audio')) {
        media_type = 'AUDIO';
      } else if (recording.type.includes('video')) {
        media_type = 'VIDEO';
      }

      const data: ISubmitVideoData = {
        title: '',
        metadata: {
          size: recording.size,
          type: recording.type,
        },
        upload_type: 'COMMENT',
        recorded_timestamp: 0,
        is_comment: true,
        video_comment_video_id: vidObj?.id,
        request_id,
        media_type: media_type || undefined,
      };

      dispatch(
        uploadVideo(data, recording, () => {
          onSuccess();
        }),
      );
    }
  };

  // This get's feedback on a particular file.
  const getFeedback = async (
    vidObj: FileType,
    comment: string,
    file: FileWithPath | null,
    recording: Blob | null,
  ) => {
    let request_id = requestId;

    if (!request_id) {
      try {
        const data: ISubmitRequest = {
          rate: request_user?.instructor?.rate,
          requested_id: request_user?.id,
          instructor_id: request_user?.instructor?.id,
        };

        if (business?.id) {
          data.business_id = business?.id;
          if (business?.financial_config?.business_set_rate) {
            data.rate = business?.financial_config?.rate;
          }
        }

        const resp: any = await dispatch(feedbackRequestApi.postRequest(data));
        request_id = resp.id;
        if (!request_id) {
          dispatch(notification(resp?.data?.error || 'Something went wrong', 'error'));
          return;
        }
      } catch (err) {
        // SHOW MODAL TO TRY AGAIN
        log.exception(err);
      }
    }

    if (comment) {
      dispatch(
        commentApi.postRequest({
          comment,
          video_id: vidObj?.id,
          request_id,
        }),
      );
    }
    submitVideo(file, recording, vidObj, request_id);
  };

  const onSubmit = async () => {
    const comment: string = state.comment;
    const file: FileWithPath | null = fileList?.[0] || null;
    const recording: Blob | null = recordedFile || null;

    if (instructor?.rate && insufficientFunds) {
      dispatch(
        notification(
          "You don't have enough money to complete this request, please top up",
          'error',
        ),
      );
      return;
    }

    getFeedback(video, comment, file, recording);
    segmentAnalytics.track(SEGMENT_CONSTANTS.GET_FEEDBACK_SUBMIT, {
      video_id: video && video.id,
    });
  };

  return <FeedbackEntry onSubmit={onSubmit} />;
};

export default Feedback;
