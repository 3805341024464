import { useEffect, useRef, useState } from 'react';
import {
  Player,
  ControlBar,
  BigPlayButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  // @ts-ignore
} from 'video-react';
import AudioPlayer from 'react-h5-audio-player';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Document, Page, pdfjs } from 'react-pdf';

// App
import { GridContainer } from 'components/Common/index';
import { FileType } from 'types';
import './styles.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const _getPlayerWidth = (width: number) => {
  if (width < 720) return 375;
  return 600;
};

function RenderPDF({ fileUrl }: { fileUrl: string }) {
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} options={options}>
        {Array.from(new Array(numPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
}

export default function PlayerComponent({ videoDetail }: { videoDetail: FileType }): JSX.Element {
  const playerRef = useRef(null);
  const [playerWidth, setPlayerWidth] = useState(_getPlayerWidth(window.innerWidth));

  const _calcInnerWidth = () => {
    setPlayerWidth(_getPlayerWidth(window.innerWidth));
  };

  useEffect(() => {
    if (playerRef.current) {
      window.addEventListener('resize', _calcInnerWidth);
    }
    return () => {
      window.removeEventListener('resize', _calcInnerWidth);
    };
  }, [playerRef]);

  if (videoDetail) {
    if (videoDetail.media_type === 'IMAGE') {
      return (
        <div style={{ width: '100%', backgroundColor: 'black' }}>
          <img
            src={videoDetail.video}
            style={{ objectFit: 'contain', width: '100%', maxHeight: 700, padding: 10 }}
          />
        </div>
      );
    } else if (videoDetail.media_type === 'AUDIO') {
      return (
        <GridContainer>
          <AudioPlayer src={videoDetail.video} />
        </GridContainer>
      );
    } else if (videoDetail.media_type === 'PDF') {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={12} className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <Typography variant="h6">
              <a
                href={videoDetail.video}
                target="_blank"
                className="tw-text-decoration-line tw-text-blue-600 tw-decoration-blue-600"
              >
                Click to open in new window
              </a>
            </Typography>
            <RenderPDF fileUrl={videoDetail.video || ''} />
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Player
        ref={playerRef}
        src={videoDetail?.video}
        height={playerWidth}
        width="auto"
        fluid={false}
      >
        <BigPlayButton position="center" />
        <ControlBar autoHide={false}>
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
        </ControlBar>
      </Player>
    </div>
  );
}
