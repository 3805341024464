import { gql } from '@apollo/client';

export const businessMemberAcceptInviteMutation = gql`
  mutation businessMemberAcceptInvite($uid: ID!) {
    businessMemberAcceptInvite(uid: $uid) {
      business {
        uid
        name
      }
      member {
        uid
        status
      }
    }
  }
`;
