import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import BaseOptionChart from './BaseOptionChart';

interface GraphData {
  month: string;
  value: number;
}

interface Props {
  title: string;
  data?: GraphData[];
  loading?: boolean;
  isCurrency?: boolean;
}
export default function BankingBalanceStatistics({ title, data, isCurrency }: Props) {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data ? data.map(x => x.month) : [],
    },
    tooltip: {
      y: {
        formatter: (val: number) => (isCurrency ? `$${val}` : val),
      },
    },
  });
  const values = data ? data.map(x => x.value) : [];
  return (
    <Card>
      <CardHeader title={title} />

      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={[{ name: title, data: values }]}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
