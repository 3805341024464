// material
import { Grid, Container, Typography, Card, CardHeader } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import classnames from 'classnames';

import { useBusiness } from 'hooks';
import { buttonPrimary } from 'styles/classnames';

function IntegrationCard({
  title,
  description,
  logoUrl,
  connectUrl,
}: {
  title: string;
  description: string;
  logoUrl: string;
  connectUrl?: string;
}) {
  return (
    <Card>
      <CardHeader title={title} className="tw-text-center" />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 100,
        }}
      >
        <Avatar alt={title} src={logoUrl} sx={{ height: '70px', width: '70px' }} />
        <Typography className="tw-my-8" textAlign="center">
          {description}
        </Typography>
        <Button
          variant="outlined"
          className={classnames(buttonPrimary, 'tw-text-lg')}
          href={connectUrl || ''}
          target="_blank"
        >
          Connect
        </Button>
      </CardContent>
    </Card>
  );
}

export default function Integrations(): JSX.Element {
  const { business } = useBusiness();

  const integrationList = [
    {
      title: 'Mind Body',
      description:
        'Connect your mindbody account in order to send your client an email for feedback once their session is complete.',
      logoUrl: 'https://avatars.githubusercontent.com/u/1529724?s=200&v=4',
      connectUrl: business?.integration_config?.MINDBODY?.connect_url,
    },
    {
      title: 'Jane app',
      description:
        'Connect your Jane App account in order to send your client an email for feedback once their session is complete.',
      logoUrl: 'https://jane.app/assets/poster-182277b166a0c4f170a613aa3b928a65.png',
      connectUrl: '',
    },
    {
      title: 'Google Drive',
      description:
        'Connect your Google Drive account to backup client information in your account.',
      logoUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4Qw4izG59ENBQITZpnFJBmbw42HLF3iH2l6q1IEFl44Bw87tJessAoiv9mabXllOZOMs&usqp=CAU',
      connectUrl: '',
    },
    {
      title: 'Zapier',
      description: 'Automate your feedback across your various applications',
      logoUrl: 'https://cdn.zapier.com/zapier/images/logos/social.png',
      connectUrl: '',
    },
  ];

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Integrations
        </Typography>

        <Grid container spacing={3}>
          {integrationList.map(item => (
            <Grid key={item.title} item xs={12} sm={6} md={4} lg={4}>
              <IntegrationCard {...item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
