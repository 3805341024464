import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { Alert, CircularProgress, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom';

import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';
import AuthLayout from './layout';
import { useAuth } from 'hooks';
import Axios, { getHeaders } from 'utils/axios';
import { API_URL } from 'utils/constants';

enum KeyValidEnum {
  PENDING = 'PENDING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

function capitalize(value: string) {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
}

export default function PasswordResetPage() {
  const { error, setError, setCredentials } = useAuth();
  const headers = getHeaders();
  const [isKeyValid, setIsKeyValid] = useState<KeyValidEnum>(KeyValidEnum.PENDING);
  const [password, setPassword] = useState('');
  const [resetError, setResetError] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const params = useParams();

  const passwordResetVerifyKeyApi = async () => {
    try {
      setError({});
      const resp = await Axios({
        url: `${API_URL}/api/accounts/browser/v1/auth/password/reset`,
        method: 'GET',
        headers: {
          ...headers,
          'X-Password-Reset-Key': params.key,
        },
      });

      if (resp?.data?.data?.user?.email || resp?.data?.data?.user?.id) {
        setIsKeyValid(KeyValidEnum.VALID);
      } else {
        setIsKeyValid(KeyValidEnum.INVALID);
      }
    } catch (err) {
      setIsKeyValid(KeyValidEnum.INVALID);
    }
  };

  useEffect(() => {
    passwordResetVerifyKeyApi();
  }, []);

  const setPasswordApi = async () => {
    try {
      setError({});
      const resp = await Axios({
        url: `${API_URL}/api/accounts/browser/v1/auth/password/reset`,
        method: 'POST',
        data: { password: password, key: params.key },
        headers,
      });
      if (resp?.data?.data?.user?.email || resp?.data?.data?.user?.id) {
        setIsComplete(true);
      }
    } catch (err) {
      // It seems allauth returns 401 even when the password has been changed but the user isn't authenticated.
      if (
        err?.response?.data?.meta ||
        err?.response?.status === 401 ||
        err?.response?.data?.status === 401
      ) {
        setIsComplete(true);
        return;
      }
      if (err?.response?.data?.errors) {
        const e = err?.response?.data?.errors[0];
        let msg = '';
        if (e?.param) {
          msg = `${capitalize(e?.param)} - `;
        }
        setResetError(`${msg}${e?.message}`);
      } else {
        setResetError('Something went wrong with resetting your password');
      }
    }
  };

  useEffect(() => {
    setError({});
    setCredentials({});
  }, []);

  if (isKeyValid === KeyValidEnum.PENDING) {
    // Create material ui loading circle spinner that's centered on page
    return (
      <AuthLayout>
        <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
          <CircularProgress />
        </div>
      </AuthLayout>
    );
  }

  if (isKeyValid === KeyValidEnum.INVALID) {
    return (
      <AuthLayout>
        <h1 className="tw-text-center tw-text-4xl tw-font-semibold tw-text-red-600">
          Invalid Link
        </h1>

        <h2 className="tw-mx-auto tw-mt-4 tw-max-w-md tw-text-center tw-text-2xl tw-text-red-400 tw-font-semibold">
          The link you provided is invalid. Please check the link and try again.
        </h2>
      </AuthLayout>
    );
  }

  let renderErrorAll;
  if (resetError) {
    renderErrorAll = (
      <Typography
        id="error-text"
        variant="body2"
        gutterBottom
        color="error"
        className="tw-mb-4 tw-text-md"
      >
        {resetError}
      </Typography>
    );
  }

  let renderComplete;
  if (isComplete) {
    renderComplete = (
      <Alert
        icon={<CheckIcon fontSize="inherit" className="tw-text-white" />}
        severity="success"
        className="tw-mb-4 tw-bg-green-600 tw-text-white"
      >
        Password reset was successful, please log into your account.
      </Alert>
    );
  }

  return (
    <AuthLayout>
      <h1 className="tw-text-center tw-text-4xl tw-font-semibold tw-text-slate-900">
        Reset your password
      </h1>
      <p className="tw-mx-auto tw-mt-4 tw-max-w-md tw-text-center tw-leading-relaxed tw-text-slate-600">
        Enter your email below and we will send you an email with instruction to reset your
        password.
      </p>

      <div className="tw-mt-10 tw-w-full">
        {renderErrorAll}

        {renderComplete}
        <form action="#" method="POST">
          <div>
            <label
              htmlFor="email"
              className="tw-block tw-text-base tw-font-medium tw-text-slate-700"
            >
              Enter new password
            </label>
            <div className="tw-relative tw-mt-1.5">
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="Password"
                startAdornment={
                  <LockClosedIcon className="tw-absolute tw-left-4 tw-top-1/2 tw-h-5 tw-w-5 tw--translate-y-1/2 tw-text-slate-400" />
                }
                inputProps={{
                  type: 'password',
                  onChange: (ev: any) => {
                    setPassword(ev.target.value);
                  },
                  value: password,
                  variant: 'outlined',
                }}
                errorText={error.emailReset}
              />
            </div>
          </div>

          <Button className="tw-mt-6 tw-w-full" onClick={setPasswordApi}>
            Save password
          </Button>
        </form>
        <p className="tw-mt-4 tw-text-center tw-leading-relaxed tw-text-slate-600">
          Remembered your password?
          <Link
            to="/signin"
            className="tw-ml-1.5 tw-font-medium tw-text-slate-800 tw-underline tw-duration-150 hover:tw-text-slate-900"
          >
            Sign in
          </Link>
          .
        </p>
      </div>
    </AuthLayout>
  );
}
