import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';

// APp
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { MHidden } from 'components/@material-extend';

const DRAWER_WIDTH = 220;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: grey[100],
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps): JSX.Element {
  const { isCollapse } = useCollapseDrawer();

  return (
    <MHidden width="lgUp">
      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
          }),
          paddingTop: 1,
        }}
      >
        <ToolbarStyle>
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              mr: 1,
              color: 'text.primary',
              backgroundColor: 'white',
              fontSize: 35,
              borderRadius: 2,
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </ToolbarStyle>
      </RootStyle>
    </MHidden>
  );
}
