import { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import log from 'loglevel';
import { enqueueSnackbar, BaseVariant, SnackbarMessage } from 'notistack';

// styles
import { buttonDanger, buttonPrimary } from 'styles/classnames';
import Axios, { getHeaders } from 'utils/axios';
import { APP_URL, API_URL, STRIPE_KEY, ROUTE_MAP } from 'utils/constants';
import { useRequestResponse, useDispatch } from 'hooks';
import { userAuth as userAuthApi, deposit as depositApi } from 'store/index';

const stripePromise = loadStripe(STRIPE_KEY || '');
const showMessage = (variant: BaseVariant, message: SnackbarMessage) =>
  enqueueSnackbar(message, { variant });

const CheckoutForm = ({
  clientSecret,
  intentId,
}: {
  clientSecret: string;
  intentId: string;
}): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { setShowPayment, getFeedback } = useRequestResponse();
  const skipStripe = true;

  const handleSuccess = async () => {
    const resp: any = await dispatch(
      depositApi.postRequest(
        { intent_id: intentId },
        { url: `${API_URL}/api/v1/transaction/deposit/confirm_stripe/` },
      ),
    );

    if (!resp.id) {
      showMessage('error', 'Something went wrong');
      return;
    }

    await dispatch(userAuthApi.getDetailRequest('user'));
    getFeedback();
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (skipStripe) {
      getFeedback();
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${APP_URL}/${ROUTE_MAP.intent}/${intentId}/complete`,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      log.error(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent?.status) {
      case 'succeeded':
        showMessage('success', 'Payment succeeded!');
        handleSuccess();
        break;
      case 'processing':
        showMessage('warning', 'Your payment is processing.');
        break;
      case 'requires_payment_method':
        showMessage('error', 'Your payment was not successful, please try again.');
        break;
      default:
        showMessage('error', 'Something went wrong.');
        break;
    }
  };

  return (
    <form className="tw-mt-6 tw-w-full">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" display="block" gutterBottom>
            Payment
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" display="block" gutterBottom className="tw-mb-4">
            All transactions are secure and encrypted using{' '}
            <a
              href="https://stripe.com/"
              target="_blank"
              className="tw-text-blue-600 hover:tw-underline dark:tw-text-blue-500"
            >
              Stripe
            </a>{' '}
            .
          </Typography>
        </Grid>

        <Grid item xs={12} className="tw-my-4">
          <PaymentElement />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            variant="outlined"
            className={classnames(buttonDanger, 'tw-w-full tw-py-2 tw-text-lg')}
            onClick={() => setShowPayment(false)}
            id="feedback-submit-button"
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            variant="outlined"
            className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
            id="top-up"
            disabled={!stripe}
            onClick={handleSubmit}
          >
            Submit and Complete
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default function StripeWrapper(): JSX.Element {
  const [clientSecret, setClientSecret] = useState('');
  const [intentId, setIntentId] = useState('');
  const { requestValue: amount, setShowPayment } = useRequestResponse();
  const headers = getHeaders();

  useEffect(() => {
    if (amount === undefined) {
      showMessage('error', 'The amount was not set.');
      setShowPayment(false);
      return;
    }
    Axios.post(`${API_URL}/api/v1/transaction/deposit/connect_stripe/`, { amount }, { headers })
      .then((resp: any) => {
        setClientSecret(resp?.data?.client_secret);
        setIntentId(resp?.data?.intent_id);
      })
      .catch(err => {
        setShowPayment(false);
        showMessage('error', err?.response?.data?.detail || 'Something went wrong');
      });
  }, [amount]);

  if (!clientSecret) {
    return <></>;
  }
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm clientSecret={clientSecret} intentId={intentId} />
    </Elements>
  );
}
