import { FC, useState } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// MUI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';

// App
import { UserAvatar } from 'components/Common/Avatar';
// import { card } from 'styles/classnames';
import { RequestType } from 'types';
import { StatusRequest } from 'components/Feedback/StatusItem';
import UsernameLink from 'components/User/UsernameLink';
import { GridContainer } from 'components/Common/index';
import { VideoTitleLoading } from 'components/Loading/index';
import { buttonSuccess } from 'styles/classnames';
import dayjs from 'utils/dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginBottom: 20,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

interface HeaderProps {
  initialized?: boolean;
  request: RequestType;
  action: string;
}

const ChatRequestHeader: FC<HeaderProps> = ({ action, request }) => {
  let username = '';

  const video = request?.video;
  let prefix = 'Request';
  if (action === 'SENT') {
    prefix += ' to ';
    username = request?.requested?.username || '';
  } else if (action === 'RECEIVED') {
    prefix += ' from ';
    username = request?.created_by?.username || '';
  }

  let renderInstructorRate;
  if (request?.rate) {
    renderInstructorRate = ` for ${numeral(request.rate).format('$ 0.00')}`;
  }

  return (
    <List disablePadding>
      <ListItem alignItems="flex-start" disableGutters>
        <ListItemText
          primary={
            <div style={{ marginLeft: 30 }}>
              <Typography variant="h6">{video?.title}</Typography>

              <Typography variant="subtitle1">
                {prefix} <UsernameLink username={username} /> {renderInstructorRate}
              </Typography>

              <Typography variant="body1" gutterBottom>
                {video?.created_at_humanize}
              </Typography>
            </div>
          }
          secondary={
            <div style={{ marginLeft: 25 }}>
              <StatusRequest item={request} />
            </div>
          }
        />
      </ListItem>
    </List>
  );
};

const CallRequestHeader: FC<HeaderProps> = ({ request, action }) => {
  const [copied, setCopied] = useState(false);
  const { date, time, timezone, duration } = request.details;

  let prefix = 'Request';
  let username = '';
  if (action === 'SENT') {
    prefix += ' to ';
    username = request?.requested?.username || '';
  } else if (action === 'RECEIVED') {
    prefix += ' from ';
    username = request?.created_by?.username || '';
  }

  let renderInstructorRate;
  if (request?.rate) {
    renderInstructorRate = ` for ${numeral(request.rate).format('$ 0.00')}`;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className="tw-mb-1">
        <Typography
          variant="h5"
          className="tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-flex-row md:tw-justify-start"
        >
          Video Call {prefix}{' '}
          <span className="tw-mx-1">
            <UsernameLink username={username} />
          </span>{' '}
          {renderInstructorRate}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="tw-mb-1">
        <Typography
          variant="body1"
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-base tw-font-medium md:tw-flex-row md:tw-justify-start"
        >
          {dayjs(date).format('DD-MMM-YYYY')} at {time} for {duration} minutes. ({timezone})
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="tw-mb-1">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-flex-row md:tw-justify-start">
          <a
            href={request.call_url}
            target="_blank"
            style={{ padding: 3, backgroundColor: copied ? '#c4e5d6' : 'initial' }}
            className="tw-break-all tw-text-base"
          >
            {request.call_url}
          </a>
          <CopyToClipboard
            text={request.call_url || ''}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 2000);
            }}
          >
            <Button
              color="primary"
              className="tw-ml-2 tw-text-base tw-text-blue-700"
              startIcon={<FileCopyIcon fontSize="medium" />}
            >
              {copied ? 'Copied' : 'copy'}
            </Button>
          </CopyToClipboard>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="tw-mb-1">
        <Button
          variant="outlined"
          className={classnames(buttonSuccess, 'tw-px-3 tw-py-2 tw-text-base')}
          href={request.call_url}
        >
          Join meeting
        </Button>
      </Grid>
    </Grid>
  );
};

const RequestHeader: FC<HeaderProps> = ({ initialized, action, request }) => {
  const classes = useStyles();
  const user = request?.created_by;

  if (!initialized || !request.id) {
    return <VideoTitleLoading />;
  }

  return (
    <GridContainer>
      <Grid
        container
        style={{ paddingTop: 20, paddingBottom: 20 }}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4} lg={2} xl={2} className={classes.avatarContainer}>
          <UserAvatar user={user} size={110} />
        </Grid>

        <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
          {request.request_type === 'CALL' ? (
            <CallRequestHeader action={action} request={request} />
          ) : (
            <ChatRequestHeader action={action} request={request} />
          )}
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default RequestHeader;
