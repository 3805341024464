import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useReactMediaRecorder } from 'react-media-recorder';

import { Icon } from '@iconify/react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import micFill from '@iconify/icons-eva/mic-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import stopFill from '@iconify/icons-eva/stop-circle-fill';

const useStyles = makeStyles(() => ({
  uploadIcon: {
    padding: 4,
  },
}));

interface RecordProps {
  setFile: (blob: Blob) => void;
  setRecording: (data: any) => void;
  type: 'video' | 'audio';
}

export default function Record({ type, setRecording, setFile }: RecordProps): JSX.Element {
  const classes = useStyles();
  const [isRecording, setIsRecording] = useState(false);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    previewAudioStream,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: type === 'video',
    onStop: (_blobUrl: string, blob: Blob) => {
      setFile(blob);
    },
  });

  useEffect(() => {
    setRecording({
      status,
      mediaBlobUrl,
      type,
      stream: type === 'video' ? previewStream : previewAudioStream,
      clearBlobUrl,
    });
  }, [status, mediaBlobUrl]);

  return (
    <span className={classnames('dropzone dz-clickable')}>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        className={classnames(
          classes.uploadIcon,
          isRecording ? 'tw-animate-pulse tw-text-red-700' : 'tw-text-blue-600',
        )}
        id={`feedback-row-record-${type}-button`}
        onClick={() => {
          if (!isRecording) {
            clearBlobUrl();
            startRecording();
            setIsRecording(true);
          } else {
            stopRecording();
            setIsRecording(false);
          }
        }}
      >
        <Icon
          icon={isRecording ? stopFill : type === 'video' ? videoFill : micFill}
          width={30}
          height={30}
        />
      </IconButton>
    </span>
  );
}
