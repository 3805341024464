import React, { createContext } from 'react';
import log from 'loglevel';

// App
import { comment as commentApi } from 'store/index';
import { useDispatch } from 'hooks';

const WebSocketRequestContext = createContext(null);

export { WebSocketRequestContext };

export default ({ request_id, children }) => {
  const dispatch = useDispatch();
  let socket;
  let ws;

  if (!socket) {
    try {
      socket = new WebSocket(
        `${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${
          window.location.host
        }/ws/request/${request_id}/`, // eslint-disable-line no-restricted-globals
      );

      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        if (data && data.comment_id) {
          dispatch(commentApi.getDetailRequest(data.comment_id, { skip_detail: true }));
        }
      };

      socket.onclose = () => {
        log.debug("WebSocket closed let's reopen");
        socket && socket.connect && socket.connect();
      };

      ws = {
        socket: socket,
      };
    } catch (error) {}
  }

  return <WebSocketRequestContext.Provider value={ws}>{children}</WebSocketRequestContext.Provider>;
};
