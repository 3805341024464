import { FC, useState, useEffect } from 'react';

// Material UI
import Typography from '@mui/material/Typography';

// App
import { GridContainer } from 'components/Common/index';
import { VideoCommentListLoading } from 'components/Loading/index';
import FileDialog from 'components/File/Dialog';
import CommentItemComponent from 'components/Comment/Item';
import { userAuth as userAuthApi, comment as commentApi } from 'store/index';
import { CommentType } from 'types/index';
import { useDispatch, useSelector } from 'hooks';

interface Props {
  commentParams: any;
}

const CommentSection: FC<Props> = ({ commentParams = {} }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [selectedComment, setSelectedComment] = useState<CommentType | null>(null);
  useEffect(() => {
    dispatch(commentApi.reset());
    dispatch(commentApi.setGetParams(commentParams));
    // @ts-ignore
    dispatch(commentApi.getRequest());
  }, []);

  const comments = useSelector((state: any) => state[commentApi.APP_NAME].list);
  const commentInitialized = useSelector((state: any) => state[commentApi.APP_NAME].initialized);

  if (!commentInitialized) {
    return <VideoCommentListLoading />;
  }

  let renderComments;
  if (commentInitialized && (!comments || comments.length === 0)) {
    renderComments = (
      <GridContainer>
        <Typography variant="h6" gutterBottom>
          No Comments Found
        </Typography>
      </GridContainer>
    );
  } else {
    renderComments = (comments || []).map((item: CommentType) => (
      <CommentItemComponent
        key={item.id}
        comment={item}
        setSelectedComment={setSelectedComment}
        user={user}
      />
    ));
  }

  return (
    <>
      {renderComments}
      {selectedComment?.video_comment && (
        <FileDialog
          handleClose={() => setSelectedComment(null)}
          file={selectedComment.video_comment}
          comment={selectedComment}
          open
        />
      )}
    </>
  );
};

export default CommentSection;
