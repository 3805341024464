import { useState, FC } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CustomTextField } from 'components/Input/index';
import InputAdornment from '@mui/material/InputAdornment';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PublicIcon from '@mui/icons-material/Public';

// App
import { userAuth as userAuthApi } from 'store/index';
import { useDispatch, useSelector } from 'hooks';
import { API_URL } from 'utils/constants';
import { SaveButton } from 'components/Common/Button';

const MediaLinks: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { profile = {} } = user;
  const { links = {} } = profile;

  const [state, setState] = useState(links);

  const textFieldProps = {
    state,
    error: {},
    setState,
  };

  const media_list = [
    {
      field: 'instagram',
      placeholder: 'Instagram username',
      icon: <InstagramIcon />,
    },

    // {
    //   field: 'medium',
    //   placeholder: 'Medium Link',
    //   icon: <SvgIcon style={{ marginTop: 7 }}>{MediumSvg}</SvgIcon>,
    // },

    // {
    //   field: 'tiktok',
    //   placeholder: 'TikTok Username',
    //   icon: <SvgIcon style={{ marginTop: 7 }}>{TikTokSvg}</SvgIcon>,
    // },

    {
      field: 'twitter',
      placeholder: 'Twitter Username',
      icon: <TwitterIcon />,
    },

    // {
    //   field: 'youtube',
    //   placeholder: 'YouTube Profile',
    //   icon: <YouTubeIcon />,
    // },

    // {
    //   field: 'facebook',
    //   placeholder: 'Facebook Profile',
    //   icon: <FacebookIcon />,
    // },

    {
      field: 'linkedin',
      placeholder: 'LinkedIn Link',
      icon: <LinkedInIcon />,
    },

    {
      field: 'website',
      placeholder: 'Website Link',
      icon: <PublicIcon />,
    },

    // {
    //   field: 'snapchat',
    //   placeholder: 'Snapchat Profile',
    //   icon: <SvgIcon style={{ marginTop: 7 }}>{SnapchatSVG}</SvgIcon>,
    // },
  ];

  return (
    <Grid id="profile-social-link" container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" gutterBottom align="left">
          Where can you be found?
        </Typography>
      </Grid>

      {media_list.map(m => {
        return (
          <CustomTextField
            key={m.field}
            field={m.field}
            placeholder={m.placeholder}
            lg={12}
            md={12}
            textProps={{
              InputProps: {
                startAdornment: <InputAdornment position="start">{m.icon}</InputAdornment>,
              },
            }}
            {...textFieldProps}
            renderBottom={null}
          />
        );
      })}

      <Grid item xs={12}>
        <SaveButton
          onClick={() => {
            const options = {
              url: `${API_URL}/api/v1/user/user/${user.id}/update_profile/`,
              notify: {
                success: 'The link have been updated',
              },
            };
            dispatch(userAuthApi.patchRequest({ links: state }, null, options));
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MediaLinks;
