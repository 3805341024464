import { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// App
import { userAuth as userAuthApi, focus as focusApi, userFocus as userFocusApi } from 'store/index';
import { buttonPrimary, buttonSuccess } from 'styles/classnames';
import { FocusType } from 'types/index';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles(() => ({
  gridContainer: {
    paddingTop: 30,
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
  },

  buttonStyle: {
    width: '100%',
    height: '100%',
    padding: '12px 30px',
    margin: 0,
    textTransform: 'capitalize',
  },
}));

interface EditFocusListProps {
  name: string;
  focusMap: any;
}

const RenderEditFocusList: FC<EditFocusListProps> = ({ name, focusMap }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentAuthUser = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { focus = [] } = currentAuthUser;
  const focusDict = focus.reduce((output: any, current: any) => {
    output[current.focus__name] = current;
    return output;
  }, {});

  const selected = focusDict[name];
  const onSubmit = async () => {
    try {
      if (selected) {
        await dispatch(userFocusApi.deleteRequest(selected.id));
        dispatch(userAuthApi.getDetailRequest('user'));
      } else {
        await dispatch(
          userFocusApi.postRequest({
            user: currentAuthUser.id,
            focus: focusMap[name],
          }),
        );
        dispatch(userAuthApi.getDetailRequest('user'));
      }
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <Grid key={name} item xs={6} sm={6} md={4} lg={4} alignItems="center">
      <Button
        color="primary"
        variant={selected ? 'contained' : 'outlined'}
        className={classnames(classes.buttonStyle, selected && buttonPrimary)}
        onClick={onSubmit}
      >
        {name}
      </Button>
    </Grid>
  );
};

interface SelectFocusProps {
  isOnboarding?: boolean;
  rootClass?: string;
}

const SelectFocus: FC<SelectFocusProps> = ({ isOnboarding, rootClass }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentAuthUser = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [inputValue, setInputValue] = useState('');
  const focusList: FocusType[] = useSelector((state: any) => state[focusApi.APP_NAME].list);
  const focusLoading = useSelector((state: any) => state[focusApi.APP_NAME].loading);
  const userLoading = useSelector((state: any) => state[userAuthApi.APP_NAME].loading);
  const userFocusLoading = useSelector((state: any) => state[userFocusApi.APP_NAME].loading);
  const isLoading = focusLoading || userLoading || userFocusLoading;

  useEffect(() => {
    dispatch(focusApi.setGetParams({ limit: 150 }));
    dispatch(focusApi.getRequest());
  }, []);

  const focusMap = focusList
    .sort((a: any, b: any) => b.name.toLowerCase() - a.name.toLowerCase())
    .reduce((out, x) => {
      // @ts-ignore
      out[x.name] = x.id;
      return out;
    }, {});

  const filteredFocusKeys = inputValue
    ? Object.keys(focusMap).filter(x => x.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
    : Object.keys(focusMap);

  const addFocus = async () => {
    try {
      const resp: any = await dispatch(
        focusApi.postRequest({
          name: inputValue.toLowerCase(),
        }),
      );
      if (resp.id) {
        await dispatch(
          userFocusApi.postRequest({
            user: currentAuthUser.id,
            focus: resp.id,
          }),
        );
        dispatch(userAuthApi.getDetailRequest('user'));
      }
    } catch (err) {
      log.exception(err);
    }
  };
  return (
    <div className={classnames(classes.gridContainer, rootClass || '')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ marginBottom: 7, display: 'flex', alignItems: 'center' }}
            display="block"
          >
            What is your focus?{' '}
            {isLoading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
          </Typography>
        </Grid>

        <Grid item xs={isOnboarding ? 9 : 12}>
          <Autocomplete
            id="focus search"
            options={Object.keys(focusMap)}
            freeSolo
            fullWidth
            renderInput={params => (
              <TextField {...params} variant="outlined" placeholder="Search for your interests" />
            )}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" style={{ marginTop: 30 }}>
        {filteredFocusKeys.length !== 0 ? (
          filteredFocusKeys.map(name => (
            <RenderEditFocusList key={name} name={name} focusMap={focusMap} />
          ))
        ) : (
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: 30,
            }}
          >
            <Typography variant="h6" style={{ marginBottom: 7 }} display="block">
              <b>{inputValue}</b> was not found, do you want to add it?
            </Typography>

            <Button
              variant="outlined"
              className={classnames(buttonPrimary, 'tw-mt-6 tw-w-40 tw-py-2 tw-text-lg')}
              onClick={addFocus}
            >
              Add
            </Button>
          </Grid>
        )}

        {isOnboarding ? (
          <Grid item xs={12} sm={12} md={12} lg={12} className="tw-mt-12">
            <Link to="/">
              <Button
                variant="outlined"
                className={classnames(classes.buttonStyle, buttonSuccess, 'tw-text-lg')}
              >
                Finish
              </Button>
            </Link>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default SelectFocus;
