import { AxiosError } from 'axios';

// helper functions
export const findAndReplace = (array: any[], find: { id: number | string }, replace: any): any => {
  const arr = [...array];
  let i;
  for (i = 0; i < arr.length && arr[i].id !== find.id; i++) {}
  if (i < arr.length) arr[i] = replace;
  else arr.push(replace);
  return arr;
};

export const encodeUrl = (data: Record<string, any>): string =>
  Object.keys(data)
    .map(function (k) {
      if (Array.isArray(data[k])) {
        return data[k]
          .map((v: string) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
          .join('&');
      }
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
    })
    .join('&');

export function generateErrorDict(err: AxiosError): Record<string, string> {
  return {
    name: err.name,
    message: err.message,
  };
}

export const notifyDict = (
  success: string,
  error: string,
): Record<string, Record<string, string>> => {
  return { notify: { success, error } };
};

export const fancyTimeFormat = (_time?: number): string => {
  if (_time) return '';

  // Hours, minutes and seconds
  const time = Number(_time);
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
};

export const stripAt = (str: string): string => (str[0] === '@' ? str.substring(1) : str);

export const processWebsite = (str: string): string => {
  // If it doesn't begin with http(s) format
  if (!/(http(s?)):\/\//i.test(str)) {
    return `https://${str}`;
  }
  return str;
};

export const socialWebsite = (link: string, initial: string): string => {
  // https://www.facebook.com/mkgituma
  return link.indexOf('http') !== -1 || link.indexOf('www') !== -1 ? link : initial;
};

export const getSocialLink = (links: Record<string, string>, key: string): string => {
  let href = '';
  if (links && links[key]) {
    switch (key) {
      case 'linkedin': {
        href = processWebsite(links[key]);
        break;
      }
      case 'instagram': {
        href = socialWebsite(links[key], `https://www.instagram.com/${stripAt(links[key])}`);
        break;
      }
      case 'website': {
        href = processWebsite(links[key]);
        break;
      }
      case 'medium': {
        href = processWebsite(links[key]);
        break;
      }
      case 'tiktok': {
        href = socialWebsite(links[key], `https://www.tiktok.com/${stripAt(links[key])}`);
        break;
      }
      case 'snapchat': {
        href = socialWebsite(links[key], `https://story.snapchat.com/s/${links[key]}`);
        break;
      }
      case 'twitter': {
        href = socialWebsite(links[key], `https://twitter.com/${links[key]}`);
        break;
      }
      case 'facebook': {
        href = socialWebsite(links[key], `https://www.facebook.com/${links[key]}`);
        break;
      }
      case 'youtube': {
        href = processWebsite(links[key]);
        break;
      }
    }
  }
  return href;
};
