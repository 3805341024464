import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TimeType } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default dayjs;

/*
 * Converts military time to obj
 */
export const mtime_to_obj = (value?: string): TimeType => {
  const obj = dayjs(value, 'hh:mm:ss');
  let hour = obj.hour();
  let ampm = 'AM';

  if (hour >= 12) {
    ampm = 'PM';
  }

  if (hour > 12) {
    hour -= 12;
  }

  return {
    hour,
    minute: obj.minute(),
    ampm,
  };
};

export const obj_to_mtime = (data: TimeType): string => {
  return dayjs(`${data.hour}:${data.minute} ${data.ampm}`, 'h:mm A').format('HH:mm');
};
