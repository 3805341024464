import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// App
import CommentSection from 'components/Comment/Section';
import FeedbackResponse from 'components/Feedback/FeedbackResponse';
import FileUnavailableComponent from 'containers/File/components/Unavailable';
import WebSocketRequestProvider from 'context/websocket-request';
import RequestResponseProvider from 'context/request-response';
import { feedbackRequest as feedbackRequestApi, userAuth as userAuthApi } from 'store/index';
import UsernameLink from 'components/User/UsernameLink';
import RequestHeader from 'containers/Feedback/components/RequestHeader';
import { GridContainer } from 'components/Common/index';
import { buttonPrimary } from 'styles/classnames';
import ActionButton from 'containers/Feedback/components/ActionButton';
import PlayerComponent from 'containers/File/components/Player';
import { useDispatch, useSelector, useBusiness } from 'hooks';
import { API_URL } from 'utils/constants';

const FeedbackDetail: FC = () => {
  const [isCommenting, setIsCommenting] = useState(false);
  const dispatch = useDispatch();
  const routerParams = useParams();

  const { business } = useBusiness();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const initialized = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].initialized);
  const request = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].detail);
  const error = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].errorDetail);
  const requestId = routerParams?.id;
  const action =
    request && request.created_by && request.created_by.id === user.id ? 'SENT' : 'RECEIVED';

  useEffect(() => {
    dispatch(feedbackRequestApi.reset());
    if (requestId) dispatch(feedbackRequestApi.getDetailRequest(requestId));
  }, []);

  if (initialized && error) {
    return <FileUnavailableComponent />;
  }
  const { video } = request;

  const commentParams: any = {};
  if ((requestId || '').length > 5) {
    commentParams.request_uuid = requestId;
  } else {
    commentParams.request_id = requestId;
  }
  return (
    <div style={{ marginBottom: 100 }}>
      {request?.video ? (
        <Grid container>
          <Grid item xs={12}>
            <PlayerComponent videoDetail={request?.video || {}} />
          </Grid>
        </Grid>
      ) : null}

      <RequestHeader request={request} action={action} initialized={initialized} />

      <GridContainer>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            {isCommenting ? (
              <RequestResponseProvider>
                <FeedbackResponse
                  videoDetail={video}
                  handleClose={() => {
                    const { status } = request;
                    // Only triggered if submitted, and only on received requests
                    if (status === 'PENDING' && action === 'RECEIVED') {
                      dispatch(feedbackRequestApi.patchRequest({}, `${request.id}/accept`));
                    }
                    setIsCommenting(false);
                  }}
                  requestId={requestId}
                  request={request}
                  primaryText={
                    <UsernameLink
                      username={request && request.created_by && request.created_by.username}
                    />
                  }
                  hideAvatarSection
                />
              </RequestResponseProvider>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            {business?.feature_config?.whiteboard ? (
              <RouterLink to={`/request/${requestId}/whiteboard`}>
                <Button
                  variant="outlined"
                  className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}
                >
                  Whiteboard
                </Button>
              </RouterLink>
            ) : null}

            {business?.feature_config?.code_share ? (
              <>
                <a href={`${API_URL}/fb/code/${request.uuid}/`} target="_blank" className="tw-ml-3">
                  {/* <RouterLink to={`/request/${requestId}/code`} className="tw-ml-3"> */}
                  <Button
                    variant="outlined"
                    className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}
                  >
                    Code
                  </Button>
                  {/* </RouterLink> */}
                </a>

                {/* <RouterLink to={`/request/${requestId}/document`} className="tw-ml-3">
                  <Button
                    variant="outlined"
                    className={classnames(buttonPrimary, 'tw-text-base tw-py-2')}
                  >
                    Document
                  </Button>
                </RouterLink> */}
              </>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6} className="tw-mt-2 md:tw-mt-0">
            <ActionButton
              action={action}
              request={request}
              setIsCommenting={setIsCommenting}
              isCommenting={isCommenting}
            />
          </Grid>
        </Grid>
      </GridContainer>

      <CommentSection commentParams={commentParams} />
    </div>
  );
};

const RequestDetailContainer: FC = () => {
  const routerParams = useParams();
  const request_id = routerParams && routerParams.id;

  return (
    <Container maxWidth="xl">
      <WebSocketRequestProvider request_id={request_id}>
        <FeedbackDetail />
      </WebSocketRequestProvider>
    </Container>
  );
};

export default RequestDetailContainer;
