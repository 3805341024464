import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { firebaseReducer, getFirebase } from 'react-redux-firebase';

// App
import rootReducer from './index';
import { storageReducer } from './firebase';

const reducer = combineReducers({
  ...rootReducer,
  firebase: firebaseReducer,
  firebaseStorage: storageReducer,
});

const store = configureStore({
  reducer,
  preloadedState: { app: { dimensions: {} } }, // Initial state
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          getFirebase,
        },
      },
    }),
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
