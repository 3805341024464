import { makeStyles, useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// App
import PlayerComponent from 'containers/File/components/Player';
import { FileType, CommentType } from 'types';
import LightboxModal from 'components/Minimals/LightboxModal';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
    backgroundColor: 'rgba(22, 28, 36, 0.8)',
  },
}));

interface FileDialogProps {
  file: FileType;
  comment?: CommentType;
  open: boolean;
  handleClose: VoidFunction;
}

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    // backgroundColor: 'rgba(0, 0, 0, 0.85)',
    // outline: 'none',
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(22, 28, 36, 0.88)',
  },

  '& .MuiDialogContent-root': {
    marginTop: 50,
  },
}));

export default function FileDialog({ file, open, handleClose }: FileDialogProps): JSX.Element {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const extraProps: any = {};
  if (!fullScreen) {
    extraProps.maxWidth = 'md';
  }

  if (file.media_type === 'IMAGE') {
    return <LightboxModal file={file} isOpen={open} onClose={handleClose} />;
  }

  return (
    <DialogStyled
      fullScreen
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      {...extraProps}
    >
      <DialogTitle id="responsive-dialog-title">
        {/* <Typography variant="h6" align="left" display="block">
          Comment by <UsernameLink username={comment?.created_by?.username || ''} />
        </Typography>

        <Typography variant="subtitle1" align="left" display="block">
          <span className={classes.createdAtText}>{comment?.created_at_humanize}</span>
        </Typography> */}

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <PlayerComponent videoDetail={file || {}} />
      </DialogContent>
    </DialogStyled>
  );
}
