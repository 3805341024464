import { useRef, useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// material
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Menu,
  Stack,
  Table,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
} from '@mui/material';

import { UserAvatar } from 'components/Common/Avatar';
import Label, { LabelColor } from 'components/@material-extend/Label';
import Scrollbar from 'components/Minimals/Scrollbar';
import { MIconButton } from 'components/@material-extend';
import { BusinessMemberType, BusinessMemberTypeEnum } from 'types';
import { businessMember as businessMemberApi } from 'store/index';
import { CircularLoading } from 'components/Loading/index';
import { useDispatch, useSelector } from 'hooks';

import './index.css';

// ----------------------------------------------------------------------
const MySwal = withReactContent(Swal);

type MoreMenuButtonProps = {
  onDownload?: VoidFunction;
  onPrint?: VoidFunction;
  onDelete: VoidFunction;
  isAdmin: boolean;
};

function MoreMenuButton({ isAdmin, onDelete }: MoreMenuButtonProps) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <MIconButton ref={menuRef} size="large" onClick={handleOpen}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </MIconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* <MenuItem onClick={onDownload}>
          <Icon icon={eyeOutline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            View
          </Typography>
        </MenuItem> */}
        {/* <MenuItem onClick={onPrint}>
          <Icon icon={editOutline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Edit
          </Typography>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
          disabled={isAdmin}
        >
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

interface TableRowProps {
  row: BusinessMemberType;
}

const LABEL_COLOR: Record<string, LabelColor> = {
  pending: 'primary',
  secondary: 'secondary',
  info: 'info',
  active: 'success',
  inactive: 'warning',
  cancelled: 'error',
};

function MemberRow({ row }: TableRowProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLight = theme.palette.mode === 'light';

  const name = row.member?.full_name || row.member?.username || (row.email || '').split('@')[0];
  const isAdmin = row?.member_type === BusinessMemberTypeEnum.ADMIN;

  const handleClickDownload = () => '';
  const handleClickPrint = () => '';
  const handleClickDelete = () => {
    MySwal.fire({
      title: `Delete ${name}`,
      text: `Are you sure you want to delete ${name}?`,
      showCancelButton: true,
    }).then((result: any) => {
      if (result.isConfirmed && !isAdmin && row.uid) {
        // @ts-ignore
        dispatch(businessMemberApi.deleteRequest(row.uid)).then(resp => {
          if (resp.error) {
            MySwal.fire({
              title: `Something went wrong`,
              text: resp.data.message || '',
            });
          }
          dispatch(businessMemberApi.getRequest());
        });
      }
    });
  };

  return (
    <TableRow key={row.uid}>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <UserAvatar user={row.member} size={40} />
          <Typography variant="subtitle2">{name}</Typography>
        </Stack>
      </TableCell>

      <TableCell>{row?.email?.toLowerCase()}</TableCell>
      <TableCell>{row?.member_type?.toLowerCase()}</TableCell>

      <TableCell>
        <Label
          variant={isLight ? 'ghost' : 'filled'}
          color={LABEL_COLOR[row.status.toLowerCase()] || 'default'}
        >
          {sentenceCase(row.status)}
        </Label>
      </TableCell>

      <TableCell align="right">
        <MoreMenuButton
          onDownload={handleClickDownload}
          onPrint={handleClickPrint}
          onDelete={handleClickDelete}
          isAdmin={isAdmin}
        />
      </TableCell>
    </TableRow>
  );
}

export default function MemberTable(): JSX.Element {
  const dispatch = useDispatch();

  const members: BusinessMemberType[] = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].list,
  );
  const initialized: boolean = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].initialized,
  );

  useEffect(() => {
    dispatch(businessMemberApi.getRequest());
  }, []);

  if (!initialized) {
    return <CircularLoading height="40vh" />;
  }

  if (!members.length) {
    return (
      <Card>
        <CardHeader title="Clients" sx={{ mb: 3 }} />
        <CardContent>
          You don't have any clients, click add client to begin adding your clients
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Clients" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 240 }}>Name</TableCell>
                <TableCell sx={{ minWidth: 160 }}>Email</TableCell>
                <TableCell sx={{ minWidth: 160 }}>Type</TableCell>
                <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {members
                .filter(member => member.member_type === 'CLIENT')
                .map(row => (
                  <MemberRow row={row} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
