import Container from '@mui/material/Container';

import MemberInvite from './components/MemberInvite';

export default function MemberAdd(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <MemberInvite />
    </Container>
  );
}
