import { useState, FC } from 'react';
import numeral from 'numeral';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';
import { capitalCase } from 'change-case';

// MUI
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';

// App
import Label, { LabelColor } from 'components/@material-extend/Label';
import RenderBio from 'components/Bio/View';
import SocialIcons from 'components/Icon/Social';
import FocusItemList from 'components/Focus/List';
import { UserAvatar } from 'components/Common/Avatar';
import { userPublic as userPublicApi, businessMember as businessMemberApi } from 'store/index';
import { card } from 'styles/classnames';
import { UserBaseType, BusinessMemberType } from 'types';
import { VideoTitleLoading } from 'components/Loading/index';
import { useSelector } from 'hooks';
import { BASE_DOMAIN } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: 'auto',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
      marginBottom: 20,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

const LABEL_COLOR: Record<string, LabelColor> = {
  pending: 'primary',
  secondary: 'secondary',
  info: 'info',
  active: 'success',
  inactive: 'warning',
  cancelled: 'error',
};

const ProfileSummary: FC = () => {
  const classes = useStyles();
  const initialized: UserBaseType = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].initialized,
  );
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const memberInitialized: BusinessMemberType = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].initialized,
  );
  const member: BusinessMemberType = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].detail,
  );
  const [state, setState] = useState({
    copied: false,
    showHelp: false,
  });
  const { instructor } = user;
  const rate = (instructor && instructor.rate) || 0;
  const rate_list = (instructor && instructor.rate_list) || [];

  if (!memberInitialized || (!initialized && member?.member?.username)) {
    return (
      <div className={classnames(classes.root, card)}>
        <VideoTitleLoading />
      </div>
    );
  } else if (!user?.id) {
    return (
      <div className={classnames(classes.root, card)}>
        <Grid
          container
          style={{ paddingTop: 20, paddingBottom: 20 }}
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={4} lg={3} xl={2} className={classes.avatarContainer}>
            <UserAvatar user={user} size={200} />
          </Grid>

          <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
            <Typography variant="h5" display="block" style={{ marginTop: 10, marginBottom: 3 }}>
              {member?.email}
            </Typography>

            <Typography variant="body1" display="block" className="tw-mt-2">
              <span className="tw-w-32">
                <Label variant="outlined" color="info">
                  {capitalCase(member?.member_type || '')}
                </Label>
              </span>{' '}
              <span className="tw-w-32">
                <Label
                  variant="outlined"
                  color={LABEL_COLOR[(member?.status || '').toLowerCase()] || 'default'}
                >
                  {capitalCase(member?.status || '')}
                </Label>
              </span>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classnames(classes.root, card)}>
      <Grid
        container
        style={{ paddingTop: 20, paddingBottom: 20 }}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4} lg={3} xl={2} className={classes.avatarContainer}>
          <UserAvatar user={user} size={200} />
        </Grid>

        <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
          <Typography variant="h5" display="block" style={{ marginTop: 10, marginBottom: 3 }}>
            @{user.username}
          </Typography>

          <Typography variant="body1" display="block" style={{ marginBottom: 3 }}>
            {user.full_name}
          </Typography>

          <Typography variant="body1" display="block" className="tw-mt-1" id="rate-per-question">
            <b>{numeral(rate || 0).format('$ 0.00')}</b> / question
          </Typography>

          {rate_list
            .sort((a: any, b: any) => a.rate - b.rate)
            .map(row => (
              <Typography
                variant="body1"
                display="block"
                style={{ marginBottom: 3 }}
                id={`rate-per-video-${row.uid}`}
              >
                <b>{numeral(row.rate || 0).format('$ 0.00')}</b> / {row.duration} minute call
              </Typography>
            ))}

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ marginTop: 3 }} id="copy-and-share-link">
                <a
                  href={`https://${BASE_DOMAIN}/${user.username}`}
                  style={{
                    padding: 3,
                    borderRadius: 5,
                    backgroundColor: state.copied ? '#c4e5d6' : 'initial',
                  }}
                >
                  https://{BASE_DOMAIN}/{user.username}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                href={`https://${BASE_DOMAIN}/${user.username}`}
                target="_blank"
                color="primary"
                startIcon={<VisibilityIcon fontSize="medium" />}
                className="tw-mr-4 tw-text-blue-700"
              >
                View
              </Button>

              <CopyToClipboard
                text={`https://${BASE_DOMAIN}/${user.username}`}
                onCopy={() => setState({ ...state, copied: true })}
              >
                <Button
                  color="primary"
                  className="tw-mr-4 tw-text-blue-700"
                  startIcon={<FileCopyIcon fontSize="medium" />}
                >
                  Copy
                </Button>
              </CopyToClipboard>

              {state.copied ? (
                <Typography variant="subtitle1" style={{ marginTop: 3 }} id="copy-and-share-link">
                  (Copied)
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          {(user?.focus || []).length > 0 ? (
            <div className="tw-mt-2">
              <FocusItemList user={user} justify="flex-start" />
            </div>
          ) : null}

          {/*<div className="tw-mt-2" /> */}

          {Object.keys(user?.profile?.links || {}).length > 0 ? (
            <div className="tw-mt-2">
              <SocialIcons links={user?.profile?.links || {}} align="left" />
            </div>
          ) : null}
          {/*<div className="tw-mt-2" /> */}

          <RenderBio bio={user?.profile?.bio} />

          <Typography variant="body1" display="block" className="tw-mt-2">
            <span className="tw-w-32">
              <Label variant="outlined" color="info">
                {capitalCase(member?.member_type || '')}
              </Label>
            </span>{' '}
            <span className="tw-w-32">
              <Label
                variant="outlined"
                color={LABEL_COLOR[(member?.status || '').toLowerCase()] || 'default'}
              >
                {capitalCase(member?.status || '')}
              </Label>
            </span>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileSummary;
