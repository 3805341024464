// material
import { Card } from '@mui/material';
import Topup from 'components/Fund/Topup';
import Container from '@mui/material/Container';

export default function TopupPage(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <Card>
        <Topup />
      </Card>
    </Container>
  );
}
