import { useContext } from 'react';
import { ClientContext, ClientContextProps } from 'context/ClientContext';
// ----------------------------------------------------------------------

export default function useAuthContext(): ClientContextProps {
  const context = useContext(ClientContext);

  if (!context) throw new Error('Client context must be use inside ClientProvider');

  return context;
}
