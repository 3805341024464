import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Favorite from '@mui/icons-material/Favorite';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  madeWithLove: {
    padding: '15px 0',
    margin: 0,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  tos: {
    textAlign: 'left',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  list: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    fontSize: '14px',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
    display: 'flex',
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px',
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1140px',
    },
  },
}));

export default function Footer(): JSX.Element {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={classes.tos}>
              <List className={classes.list}>
                <ListItem style={{ width: 180 }}>
                  <a href="https://mbele.io/terms_of_service/" target="_blank">
                    Terms of Service
                  </a>
                </ListItem>
                <ListItem style={{ width: 180 }}>
                  <a href="https://mbele.io/privacy_policy_mbele/" target="_blank">
                    Privacy Policy
                  </a>
                </ListItem>
                <ListItem style={{ width: 180 }}>
                  <a href="mailto:support@mbele.io" target="_blank">
                    Contact Support
                  </a>
                </ListItem>
              </List>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={classes.madeWithLove}>
              &copy; {new Date().getFullYear()}. Made with <Favorite /> in Toronto.
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
