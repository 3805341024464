import { ReactNode } from 'react';
import { Container } from '../components/Container';

// import 'entry/tailwind-landing.css';

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <section className="tw-relative tw-flex tw-min-h-[80vh] tw-items-center tw-justify-center tw-bg-amber-100 tw-py-16 sm:tw-py-20">
      <Container className="tw-w-full">
        <div className="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center">{children}</div>
      </Container>
    </section>
  );
}
