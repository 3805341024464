/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

// App
import { UserAvatar } from 'components/Common/Avatar';
import FocusItemList from 'components/Focus/List';
import SocialIcons from 'components/Icon/Social';
import RenderBio from 'components/Bio/View';
import RateDetails from 'components/Instructor/rate-details';
import { UserAuthType, UserBaseType, BusinessMemberTypeEnum } from 'types';
import AskQuestion from 'components/Feedback/AskQuestion';
import { useClient } from 'hooks';
import { card } from 'styles/classnames';
import { userAuth as userAuthApi, userPublic as userPublicApi } from 'store/index';
import { VideoTitleLoading } from 'components/Loading/index';
import UserCard from './components/UserCard';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  profileContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  gridContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: '90vh',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

interface MentorProfileProps {
  mentor: UserBaseType;
}

const MentorProfile: FC<MentorProfileProps> = ({ mentor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const links = (mentor.profile && mentor.profile.links) || {};
  const requested = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const initialized = useSelector((state: any) => state[userPublicApi.APP_NAME].initialized);

  useEffect(() => {
    dispatch(userPublicApi.reset());
    dispatch(userPublicApi.getDetailRequest(encodeURI(mentor.username)));
  }, [mentor.username]);

  if (!initialized) {
    <div className={classes.root}>
      <div className="tw-container tw-mx-auto tw-pt-5">
        <Grid container className={classes.profileContainer}>
          <Grid item xs={12} md={4} className={classes.avatarContainer}>
            <VideoTitleLoading />
          </Grid>
        </Grid>
      </div>
    </div>;
  }

  return (
    <div className={classes.root}>
      <div className="tw-container tw-mx-auto tw-pt-5">
        <Grid container className={classes.profileContainer}>
          <Grid item xs={12} md={4} className={classes.avatarContainer}>
            <UserAvatar user={requested} size={200} />
          </Grid>

          <Grid item xs={12} md={8} className={classes.contentRight}>
            <Typography variant="h5" display="block" style={{ marginTop: 10, fontSize: '2.5rem' }}>
              {requested.username}
            </Typography>

            <RateDetails instructor={requested.instructor} />

            <SocialIcons links={links} align="left" />

            <div className="tw-mt-3" />

            <FocusItemList user={requested} justify="flex-start" />

            <div className="tw-mt-3" />

            <RenderBio bio={requested?.profile?.bio} />
          </Grid>
        </Grid>
      </div>

      <div className="tw-container tw-mx-auto tw-pb-10">
        <AskQuestion requested={requested} />
      </div>
    </div>
  );
};

export default function Mentor(): JSX.Element {
  const { membershipList } = useClient();
  const classes = useStyles();
  const authUser: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [selectedMember, setSelectedMember] = useState<UserBaseType | null>(null);

  const staff = membershipList?.filter(
    member =>
      member.member &&
      member.member_type !== BusinessMemberTypeEnum.CLIENT &&
      member.member.id !== authUser.id,
  );

  if (staff?.length === 1) {
    const member = staff[0];
    return (
      <div className={classes.gridContainer}>
        <div className={classnames(card, 'tw-mb-4')}>
          <MentorProfile key={member.uid} mentor={member!.member!} />
        </div>
      </div>
    );
  }

  const mentorElements = staff?.map(member => {
    return (
      <Grid key={member.uid} item xs={12} sm={6} md={4} lg={4}>
        <UserCard
          key={member.uid}
          user={member!.member!}
          onClick={() => setSelectedMember(member!.member!)}
        />
      </Grid>
    );
    return <div />;
  });

  return (
    <div>
      <Grid container justifyContent="center" spacing={2}>
        {selectedMember ? (
          <Grid xs={12} className={classes.gridContainer}>
            <div className={classnames(card, 'tw-mb-4')}>
              <MentorProfile mentor={selectedMember} />
            </div>
          </Grid>
        ) : null}

        {mentorElements}
      </Grid>
    </div>
  );
}
