import { useContext } from 'react';
import { AuthContext, AuthContextProps } from 'context/AuthContext';
// ----------------------------------------------------------------------

export default function useAuthContext(): AuthContextProps {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
}
