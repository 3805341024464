import { FC } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';

// Material UI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// App
import {
  feedbackRequest as feedbackRequestApi,
  userAuth as userAuthApi,
  comment as commentApi,
} from 'store/index';
import { notification } from 'store/notification';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import { buttonDanger, buttonPrimary, buttonSuccess } from 'styles/classnames';
import { RequestType } from 'types/index';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';

interface ActionButtonProps {
  request?: RequestType;
  action: string;
  setIsCommenting: (commenting: boolean) => void;
  isCommenting: boolean;
}

const ActionButton: FC<ActionButtonProps> = ({
  request,
  action,
  setIsCommenting,
  isCommenting,
}) => {
  const dispatch = useDispatch();
  const initialized: boolean = useSelector((state: any) => state[commentApi.APP_NAME].initialized);
  const requestInitialized: boolean = useSelector(
    (state: any) => state[feedbackRequestApi.APP_NAME].initialized,
  );
  const status = request?.status || '';

  const manageRequest = async (apiEndpoint: string, options: any = {}, commenting: boolean) => {
    const api = feedbackRequestApi;

    try {
      const resp: any = await dispatch(
        api.patchRequest({}, `${request?.id}/${apiEndpoint}`, options),
      );
      if (resp?.id) {
        dispatch(userAuthApi.getDetailRequest('user'));
        setIsCommenting(commenting);
      } else if (resp.error) {
        dispatch(notification((resp.data && resp.data.message) || resp.error, 'error'));
      } else {
        dispatch(notification('Something went wrong', 'error'));
      }
    } catch (err) {
      log.exception(err);
    }
  };

  if (!initialized || !requestInitialized) {
    return <CircularProgress size={30} style={{ float: 'right', marginRight: 30, marginTop: 5 }} />;
  }

  if (isCommenting) {
    return (
      <Button
        variant="outlined"
        className={classnames(buttonDanger, 'tw-float-left tw-py-2 tw-text-base md:tw-float-right')}
        style={{ width: '49%' }}
        onClick={() => {
          setIsCommenting(!isCommenting);
          segmentAnalytics.track(SEGMENT_CONSTANTS.FEEDBACK_ADD_COMMENT_REQUEST, {
            request_id: request?.id,
            status,
            cancel: true,
          });
        }}
      >
        Cancel
      </Button>
    );
  } else if (['REJECTED', 'COMPLETED', 'CANCELLED'].indexOf(status) !== -1) {
    return (
      <>
        <Button
          variant="outlined"
          className={classnames(
            buttonPrimary,
            'tw-float-left tw-py-2 tw-text-base md:tw-float-right',
          )}
          onClick={() => {
            setIsCommenting(true);
            segmentAnalytics.track(SEGMENT_CONSTANTS.FEEDBACK_ADD_COMMENT_REQUEST, {
              request_id: request?.id,
              status,
            });
          }}
          style={{ width: '49%' }}
        >
          Respond
        </Button>
      </>
    );
  } else if (action === 'SENT') {
    return (
      <>
        <Button
          variant="outlined"
          className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}
          onClick={() => {
            setIsCommenting(true);
            segmentAnalytics.track(SEGMENT_CONSTANTS.FEEDBACK_ADD_COMMENT_REQUEST, {
              request_id: request?.id,
              status,
            });
          }}
          style={{ width: '49%' }}
        >
          Respond
        </Button>

        <Button
          variant="outlined"
          className={classnames(buttonDanger, 'tw-py-2 tw-text-base')}
          style={{ float: 'right', width: '49%' }}
          onClick={() => {
            manageRequest('cancel', { notify: { success: 'Request was cancelled' } }, false);
            segmentAnalytics.track(SEGMENT_CONSTANTS.FEEDBACK_CANCEL_REQUEST, {
              request_id: request?.id,
              status,
            });
          }}
        >
          Cancel Request
        </Button>
      </>
    );
  } else if (action === 'RECEIVED') {
    let buttonText;
    let apiEndpoint = '';
    let disabled;
    let help;
    let buttonColor = buttonPrimary;
    const notify: { success?: string } = {};
    if (status === 'PENDING') {
      buttonText = 'Ignore';
      apiEndpoint = 'reject';
      buttonColor = buttonDanger;
      notify.success = 'Request was ignored';
    } else if (initialized && status === 'ACCEPTED') {
      disabled = !request?.can_complete;
      buttonColor = buttonSuccess;
      buttonText = 'Finish';
      apiEndpoint = 'complete';
      let success = 'Request was successfully completed';
      if (request?.rate) {
        success += ` and ${numeral(request?.rate || 0).format(
          '$ 0,0.00',
        )} was added to your account`;
      }
      notify.success = success;

      if (disabled) {
        help = `${
          request?.request_type === 'CALL' ? 'Complete video call' : 'Add a comment'
        } in order to finish the request`;
      }
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}
            onClick={() => {
              setIsCommenting(true);
              segmentAnalytics.track(SEGMENT_CONSTANTS.FEEDBACK_ADD_COMMENT_REQUEST, {
                request_id: request?.id,
                status,
              });
            }}
            style={{ width: '49%' }}
          >
            Respond
          </Button>

          <Button
            variant="outlined"
            className={classnames(buttonColor, 'tw-py-2 tw-text-base')}
            onClick={() => {
              manageRequest(apiEndpoint, { notify }, apiEndpoint !== 'complete');
              segmentAnalytics.track(
                apiEndpoint === 'complete'
                  ? SEGMENT_CONSTANTS.FEEDBACK_COMPLETE_REQUEST
                  : SEGMENT_CONSTANTS.FEEDBACK_ACCEPT_REQUEST,
                {
                  request_id: request?.id,
                  status,
                },
              );
            }}
            style={{ width: '49%' }}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        </div>

        {help && <Typography variant="caption">{help}</Typography>}
      </>
    );
  }
  return null;
};

export default ActionButton;
