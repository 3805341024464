/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

const selectClassName =
  'tw-appearance-none tw-w-12 tw-text-center tw-py-2 tw-px-1 tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-xl tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500';

interface ITimepicker {
  hour: number;
  minute: number;
  ampm: string;
  title: string;
  onChange: (data: any) => void;
}

const TimePicker: React.FC<ITimepicker> = ({
  hour = '9',
  minute = '0',
  ampm = 'AM',
  title = '',
  onChange,
}) => {
  const handleOnchange = (key: string) => (ev: any) => {
    onChange({
      hour,
      minute,
      ampm,
      [key]: ev.target.value,
    });
  };
  return (
    <div className="tw-w-[140px] tw-rounded-lg tw-bg-white">
      <Typography variant="body2" display="block">
        {title}
      </Typography>
      <div className="tw-flex">
        <select
          name="hours"
          // className="tw-bg-transparent tw-text-xl tw-appearance-none tw-outline-none"
          className={classNames(selectClassName)}
          value={hour}
          onChange={handleOnchange('hour')}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <span className="tw-m-1 tw-text-xl">:</span>
        <select
          name="minutes"
          className={classNames(selectClassName, 'tw-mr-2')}
          value={minute}
          onChange={handleOnchange('minute')}
        >
          <option value="0">00</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
        <select
          name="ampm"
          className={classNames(selectClassName)}
          value={ampm}
          onChange={handleOnchange('ampm')}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default TimePicker;
