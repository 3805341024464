import styled from 'styled-components';

export default styled.div`
  .highlighted-header {
    background-color: rgb(4, 76, 214) !important;
  }

  .pricing-slot-button {
    background-color: rgb(4, 76, 214) !important;
    color: white;
    height: 40px;
    border: none;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .basic-border {
    cursor: pointer;
    height: 300px;
    &:hover {
      border: 2px solid rgb(4, 76, 214);

      .basic-header {
        background-color: rgb(4, 76, 214, 0.8) !important;
        color: white !important;
      }
    }
  }
`;
