import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';

// App
import { UserAvatar } from 'components/Common/Avatar';
import FocusItemList from 'components/Focus/List';
import SocialIcons from 'components/Icon/Social';
import { CircularLoading } from 'components/Loading/index';
import RenderBio from 'components/Bio/View';
import RateDetails from 'components/Instructor/rate-details';
import { userPublic as userPublicApi } from 'store/index';
import { UserBaseType } from 'types';
import AskQuestion from 'components/Feedback/AskQuestion';
import { useDispatch, useSelector } from 'hooks';

function stripAt(str: string) {
  return str[0] === '@' ? str.substring(1) : str;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  contentContainer: {
    maxWidth: '60rem',
    width: '100%',
    alignItems: 'center',
  },
}));

const BreadcrumbsBack = () => {
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const params = useParams();

  return (
    <Grid item xs={12} className="tw-mb-10">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={`/embed/${params.businessId}/`}>
          <MuiLink underline="hover" color="inherit">
            Profiles
          </MuiLink>
        </Link>
        <Typography color="text.primary">{user?.username}</Typography>
      </Breadcrumbs>
    </Grid>
  );
};

const Profile: FC = () => {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialized: boolean = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].initialized,
  );
  const userPublic: UserBaseType = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].detail,
  );
  let username = params.username;
  if (username) {
    username = stripAt(username);
  }
  useEffect(() => {
    dispatch(userPublicApi.reset());
    if (username) dispatch(userPublicApi.getDetailRequest(encodeURI(username)));
  }, []);

  if (!initialized && username) {
    return <CircularLoading />;
  }

  if (!userPublic.id || !username) {
    return (
      <div>
        <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
          <Grid container style={{ paddingBottom: 40 }} className={classes.contentContainer}>
            <BreadcrumbsBack />

            <Grid item xs={12} md={4} className={classes.avatarContainer}>
              <UserAvatar user={userPublic} size={200} />
            </Grid>

            <Grid item xs={12} md={8} className={classes.contentRight}>
              <Typography
                variant="h5"
                display="block"
                style={{ marginTop: 10, fontSize: '1.5rem' }}
              >
                User Not Found
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className="tw-flex-1 tw-bg-white">
          <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
            <Grid container className={classes.contentContainer}>
              <AskQuestion disabled requested={userPublic} />
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  const links = (userPublic.profile && userPublic.profile.links) || {};
  return (
    <div>
      <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
        <Grid container style={{ paddingBottom: 40 }} className={classes.contentContainer}>
          <BreadcrumbsBack />

          <Grid item xs={12} md={4} className={classes.avatarContainer}>
            <UserAvatar user={userPublic} size={200} />
          </Grid>

          <Grid item xs={12} md={8} className={classes.contentRight}>
            <Typography variant="h5" display="block" style={{ marginTop: 10, fontSize: '2.5rem' }}>
              {userPublic.username}
            </Typography>

            {userPublic?.business?.financial_config?.disable_billing ? null : (
              <RateDetails instructor={userPublic.instructor} />
            )}

            <SocialIcons links={links} align="left" />

            <div className="tw-mt-3" />

            <FocusItemList user={userPublic} justify="flex-start" />

            <div className="tw-mt-3" />

            <RenderBio bio={userPublic?.profile?.bio} />
          </Grid>
        </Grid>
      </div>

      <div className="tw-flex-1 tw-bg-white">
        <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
          <Grid container className={classes.contentContainer}>
            <AskQuestion
              requested={userPublic}
              onSuccessRedirect={request_id => {
                navigate(
                  `/embed/${params.businessId}/${userPublic?.username}/success/${request_id}`,
                );
              }}
              isPublic
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Profile;
