// Material UI
import { Box, Button, Grid, Typography } from '@mui/material';
import { Img } from 'react-image';
import { enqueueSnackbar } from 'notistack';

// App
import Axios from 'utils/axios';
import eventbriteLogo from 'images/social/eventbrite.png';
import { API_URL } from 'utils/constants';

function IntegrationItem({
  title,
  content,
  logo,
  onClick,
}: {
  title: string;
  content: string;
  logo: string;
  onClick: VoidFunction;
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Img src={logo} className="tw-h-12 tw-w-12" alt="Feature 1" />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h6" paragraph>
            {title}
          </Typography>
          <Typography variant="body1" paragraph>
            {content}
          </Typography>

          <Button
            onClick={onClick}
            className="tw-mt-4 tw-w-52 tw-text-base tw-bg-[#ff8000] tw-border-[#ff8000] hover:tw-bg-amber-500 hover:tw-border-amber-500 tw-text-white tw-border tw-rounded-lg tw-border-solid"
          >
            + connect {title}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function Integrations(): JSX.Element {
  return (
    <Box className="tw-py-8">
      <IntegrationItem
        logo={eventbriteLogo}
        title="Eventbrite"
        content="Connect your Eventbrite account to Feedback Session to seamlessly provide feedback to workshop attendees when requested. This integration lets you follow up with participants, offering valuable insights and personalized feedback to enhance their learning experience and encourage future attendance. You can also charge different rates for standard tickets and tickets that include feedback."
        onClick={() => {
          Axios.get(`${API_URL}/integration/eventbrite/oauth/start`)
            .then((resp: any) => {
              const url = resp?.data?.url;
              if (url) {
                window.open(url);
              } else {
                enqueueSnackbar('Something went wrong when connecting to eventbrite', {
                  variant: 'error',
                });
              }
            })
            .catch(_err => {
              enqueueSnackbar('Something went wrong when connecting to eventbrite', {
                variant: 'error',
              });
            });
        }}
      />
    </Box>
  );
}
