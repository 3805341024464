import { useState } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';

// Material UI
import { makeStyles, useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// App
import { CustomTextField } from 'components/Input/index';
import { userAuth as userAuthApi } from 'store/index';
import { buttonPrimary } from 'styles/classnames';
import { useDispatch, useSelector } from 'hooks';
import { BASE_DOMAIN, API_URL } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  typoContent: {
    marginBottom: theme.spacing(4),
  },
}));

export default function Withdraw(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme: Theme = useTheme();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const userLoading = useSelector((state: any) => state[userAuthApi.APP_NAME].loading);
  const [state, setState] = useState<any>({});
  const [error, setError] = useState<any>({});

  const loading = userLoading;
  const textFieldProps = {
    state,
    error,
    setState: (data: any) => {
      if (data.amount) {
        setState({ amount: Number(data.amount) });
      } else {
        setState({ amount: data.amount });
      }
      setError({});
    },
  };

  const amount = user.balance && (user.balance.available_amount || 0);
  const MINIMUM_AMOUNT = user.withdrawal_mininum || 50;

  const validate = () => {
    let disableWithdraw = null;
    if (!state.amount) {
      disableWithdraw = 'Your current amount is 0';
    } else if (state.amount < MINIMUM_AMOUNT) {
      disableWithdraw = `Your current amount is less than the minimum (${numeral(
        MINIMUM_AMOUNT,
      ).format('$ 0,0')}) you can withdraw.`;
    } else if (state.amount > amount) {
      disableWithdraw = "Can't withdraw more money than you currently have.";
    }
    setError({ disable: disableWithdraw });
    return !disableWithdraw;
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Withdraw
        </Typography>

        <Typography variant="h6" gutterBottom align="center">
          Available amount is {numeral(amount).format('$ 0,0.00')}
        </Typography>

        {error.disable && (
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            style={{ color: theme.palette.error.main }}
          >
            {error.disable}
          </Typography>
        )}

        <Typography variant="subtitle1" gutterBottom align="center" className={classes.typoContent}>
          In order to get paid, you need to make a request with the amount to be transferred.
          However this cannot exceed your available amount, and the minimum amount you can request
          is <b>{numeral(MINIMUM_AMOUNT).format('$ 0,0.00')}</b>.
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center" className={classes.typoContent}>
          The amount will be sent through e-transfer if in Canada or paypal otherwise. This should
          take 2 - 3 business days to process your request. The money will be sent to the account
          associated with the email{' '}
          <b style={{ color: theme.palette.primary.main }}>{user.email}</b>
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center" className={classes.typoContent}>
          If there are any issues please do not hesitate to email support on{' '}
          <a target="_blank" href={`mailto:support@${BASE_DOMAIN}`}>
            support@{BASE_DOMAIN}
          </a>{' '}
          and we will get back to you immediately.
        </Typography>
      </Grid>

      <CustomTextField
        field="amount"
        placeholder="Set amount to withdraw"
        {...textFieldProps}
        lg={12}
        md={12}
        renderBottom={
          <Typography variant="caption" gutterBottom align="left" display="block">
            Must be more than {numeral(MINIMUM_AMOUNT).format('$ 0,0')} but lower than the amount
            you currently have in your account.
          </Typography>
        }
        textProps={{
          type: 'number',
        }}
      />

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 10 }}>
          <Button
            variant="outlined"
            className={classnames(buttonPrimary, 'tw-py-2 tw-text-lg tw-w-full md:tw-w-[250px]')}
            disabled={loading}
            onClick={() => {
              const notify = {
                success: `Request for ${numeral(state.amount).format(
                  '$ 0,0.00',
                )} withdrawal has been sent`,
              };

              if (validate()) {
                dispatch(
                  userAuthApi.patchRequest(
                    { amount: state.amount },
                    null, // no id
                    {
                      url: `${API_URL}/api/v1/user/user/${user.id}/withdraw/`,
                      notify,
                    },
                  ),
                );
              }
            }}
          >
            Withdraw{' '}
            {loading && <CircularProgress style={{ marginLeft: 15, color: 'white' }} size={20} />}
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
}
