import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// core components
import MainComponent from 'containers/Auth/components/Main';
import { onChange as appOnChange } from 'store/app';
import { useSelector, useDispatch } from 'hooks';
import { UserAuthType } from 'types';
import { APP_URL } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8%',
    marginBottom: '8%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      marginRight: '10%',
      marginTop: '3%',
      marginBottom: '3%',
    },
  },
}));

export default function AuthPage({ initialize }: { initialize?: string }): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(
    initialize === 'isLogin' || location.pathname === '/login',
  );
  const userAuthDetail: UserAuthType = useSelector((state: any) => state.userAuth.detail);

  if (location.pathname.indexOf('/register') !== -1 && params && params.username) {
    dispatch(appOnChange('preferredUsername', params.username));
  } else {
    dispatch(appOnChange('preferredUsername', null));
  }

  useEffect(() => {
    setIsLogin(location.pathname === '/login');
  }, [location.pathname]);

  useEffect(() => {
    if (userAuthDetail?.id) {
      window.location.href = APP_URL;
    }
  }, [userAuthDetail?.id]);

  return (
    <div className={classes.gridContainer}>
      <MainComponent
        onClose={() => {
          navigate('/');
        }}
        setIsEmail={value => {
          setIsEmail(value);
        }}
        setIsLogin={value => {
          if (value) {
            navigate('/login');
          } else {
            navigate('/register');
          }
        }}
        isLogin={isLogin}
        isEmail={isEmail}
      />
    </div>
  );
}
