import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { grey } from '@mui/material/colors';

// App
import { UserAvatar } from 'components/Common/Avatar';
import FocusItemList from 'components/Focus/List';
import SocialIcons from 'components/Icon/Social';
import { CircularLoading } from 'components/Loading/index';
import RenderBio from 'components/Bio/View';
import RateDetails from 'components/Instructor/rate-details';
import { userPublic as userPublicApi } from 'store/index';
import { UserBaseType } from 'types';
import AskQuestion from 'components/Feedback/AskQuestion';
import { useDispatch, useSelector } from 'hooks';
import { isFeedbackSession } from 'utils/constants';

function stripAt(str: string) {
  return str[0] === '@' ? str.substring(1) : str;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: grey[100],
    width: '100%',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  contentContainer: {
    maxWidth: '60rem',
    width: '100%',
    alignItems: 'center',
  },
}));

const Profile: FC = () => {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialized: boolean = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].initialized,
  );
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  let username = params.username;
  if (username) {
    username = stripAt(username);
  }
  useEffect(() => {
    dispatch(userPublicApi.reset());
    if (username) dispatch(userPublicApi.getDetailRequest(encodeURI(username)));
  }, []);

  if (!initialized && username) {
    return <CircularLoading />;
  }

  if (!user.id || !username) {
    return (
      <div className={classnames(classes.root, isFeedbackSession ? 'tw-bg-amber-100' : '')}>
        <div className="tw-flex tw-flex-col">
          <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
            <Grid
              container
              style={{ paddingTop: 40, paddingBottom: 40 }}
              className={classes.contentContainer}
            >
              <Grid item xs={12} md={4} className={classes.avatarContainer}>
                <UserAvatar user={user} size={200} />
              </Grid>

              <Grid item xs={12} md={8} className={classes.contentRight}>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ marginTop: 10, fontSize: '1.5rem' }}
                >
                  User Not Found
                </Typography>

                <RateDetails instructor={user.instructor} />

                <SocialIcons links={{}} align="left" />

                <FocusItemList user={user} justify="tw-flex-start" />
              </Grid>
            </Grid>
          </div>

          <div className="tw-flex-1 tw-bg-white">
            <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
              <Grid container className={classes.contentContainer}>
                <AskQuestion disabled requested={user} />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const links = (user.profile && user.profile.links) || {};
  return (
    <div className={classnames(classes.root, isFeedbackSession ? 'tw-bg-amber-100' : '')}>
      <div className="tw-flex tw-flex-col">
        <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
          <Grid
            container
            style={{ paddingTop: 40, paddingBottom: 40 }}
            className={classes.contentContainer}
          >
            <Grid item xs={12} md={4} className={classes.avatarContainer}>
              <UserAvatar user={user} size={200} />
            </Grid>

            <Grid item xs={12} md={8} className={classes.contentRight}>
              <Typography
                variant="h5"
                display="block"
                style={{ marginTop: 10, fontSize: '2.5rem' }}
              >
                {user.username}
              </Typography>

              <RateDetails instructor={user.instructor} />

              <SocialIcons links={links} align="left" />

              <div className="tw-mt-3" />

              <FocusItemList user={user} justify="flex-start" />

              <div className="tw-mt-3" />

              <RenderBio bio={user?.profile?.bio} />
            </Grid>
          </Grid>
        </div>

        <div className="tw-flex-1 tw-bg-white">
          <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10 tw-flex-col">
            <div className={classes.contentContainer}>
              <AskQuestion isPublic requested={user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
