import { FC } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import classnames from 'classnames';

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import grey from '@mui/material/colors/grey';
import { UserAvatar } from 'components/Common/Avatar';
import striptags from 'striptags';
import Button from '@mui/material/Button';

// App
import FocusItemList from 'components/Focus/List';
import UsernameLink from 'components/User/UsernameLink';
import SocialIcons from 'components/Icon/Social';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import { UserBaseType } from 'types';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 380,
  },
}));

interface PublicUserItemProps {
  user: UserBaseType;
  viewLink?: string;
}

const PublicUserItem: FC<PublicUserItemProps> = ({ user, viewLink }) => {
  const classes = useStyles();
  const rate = user.instructor && user.instructor.rate;
  const socialLinks = (user.profile && user.profile.links) || {};

  let bio = striptags(user?.profile?.bio || '');
  if (bio && bio.length > 60) {
    bio = bio.substring(0, 60);
    bio += ' ...';
  }

  return (
    <div className="-translate-y-20 sm:-translate-y-24 tw-mx-auto tw-transform tw-rounded-lg tw-bg-white tw-px-10 tw-py-8 tw-text-center tw-shadow-lg">
      <Link
        to={viewLink || `/${user.username}`}
        onClick={() => {
          segmentAnalytics.track(SEGMENT_CONSTANTS.PUBLIC_USER_CLICKED, {
            user_id: user.id,
          });
        }}
      >
        <div className={classes.content}>
          <div style={{ display: 'flex' }}>
            <UserAvatar user={user} size={120} />
          </div>

          <Grid container style={{ padding: 10 }} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: grey[700] }} display="block" align="center">
                <b>
                  <UsernameLink username={user.username} />
                </b>
              </Typography>

              {rate ? (
                <Typography
                  variant="body1"
                  style={{ color: grey[700] }}
                  display="block"
                  align="center"
                >
                  Rate: {numeral(rate).format('$ 0.00')} / question
                </Typography>
              ) : null}

              <FocusItemList user={user} fontSize={12} limit={3} size="small" isPublic />

              <SocialIcons links={socialLinks} width={200} isList />

              <Typography className="tw-mt-3">{bio}</Typography>
            </Grid>
          </Grid>
        </div>

        <Button
          variant="outlined"
          className={classnames('tw-py-2 tw-text-lg')}
          style={{
            bottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
          }}
        >
          View Profile
        </Button>
      </Link>
    </div>
  );
};

export default PublicUserItem;
