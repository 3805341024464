import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import { capitalCase } from 'change-case';
import videoFill from '@iconify/icons-eva/video-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Box, Link, Avatar, Typography, AvatarGroup } from '@mui/material';
import Button from '@mui/material/Button';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Fab from '@mui/material/Fab';

import { fToNow } from 'utils/formatTime';
import { Participant } from 'types/chat';
import { MIconButton } from 'components/@material-extend';
import BadgeStatus from 'components/Minimals/BadgeStatus';
import { buttonPrimary, primaryColor } from 'styles/classnames';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 72,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

function OneAvatar({ participants }: { participants: Participant[] }) {
  const participant = [...participants][0];

  if (participant === undefined || !participant.status) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar src={participant.avatar} alt={participant.name} />
        <BadgeStatus
          status={participant.status}
          sx={{ position: 'absolute', right: 2, bottom: 2 }}
        />
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{participant.name}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {participant.status !== 'offline'
            ? capitalCase(participant.status)
            : fToNow(participant.lastActivity || '')}
        </Typography>
      </Box>
    </Box>
  );
}

function GroupAvatar({ participants }: { participants: Participant[] }) {
  return (
    <div>
      <AvatarGroup
        max={3}
        sx={{
          mb: 0.5,
          '& .MuiAvatar-root': { width: 32, height: 32 },
        }}
      >
        {participants.map(participant => (
          <Avatar key={participant.id} alt={participant.name} src={participant.avatar} />
        ))}
      </AvatarGroup>
      <Link
        variant="body2"
        underline="none"
        component="button"
        color="text.secondary"
        onClick={() => ''}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {participants.length} persons
          <Icon icon={arrowIosForwardFill} />
        </Box>
      </Link>
    </div>
  );
}

export function ChatHeaderDetail({ participants }: { participants: Participant[] }): JSX.Element {
  const isGroup = participants.length > 1;

  return (
    <RootStyle>
      {isGroup ? (
        <GroupAvatar participants={participants} />
      ) : (
        <OneAvatar participants={participants} />
      )}

      <Box sx={{ flexGrow: 1 }} />
      <MIconButton>
        <Icon icon={phoneFill} width={20} height={20} />
      </MIconButton>
      <MIconButton>
        <Icon icon={videoFill} width={20} height={20} />
      </MIconButton>
      <MIconButton>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </MIconButton>
    </RootStyle>
  );
}

export default function ChatHeader({ setCollapsed }: { setCollapsed: VoidFunction }): JSX.Element {
  const routerParams = useParams();
  const requestId = routerParams?.id;

  return (
    <RootStyle>
      <RouterLink to={`/request/${requestId}`}>
        <Button variant="outlined" className={classnames(buttonPrimary, 'tw-py-2 tw-text-lg')}>
          Back
        </Button>
      </RouterLink>

      <Box sx={{ flexGrow: 1 }} />
      {/* <MIconButton>
        <Icon icon={phoneFill} width={20} height={20} />
      </MIconButton>
      <MIconButton>
        <Icon icon={videoFill} width={20} height={20} />
      </MIconButton> */}

      <Fab color="primary" className={primaryColor} onClick={setCollapsed} size="small">
        <CloseFullscreenIcon />
      </Fab>
    </RootStyle>
  );
}
