import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Excalidraw } from '@excalidraw/excalidraw';

// App
import ChatWindow from 'components/Chat/ChatWindow';
import FileUnavailableComponent from 'containers/File/components/Unavailable';
import WebSocketRequestProvider from 'context/websocket-request';
import { feedbackRequest as feedbackRequestApi, comment as commentApi } from 'store/index';
import { useDispatch, useSelector } from 'hooks';

const Tool: FC = () => {
  const dispatch = useDispatch();
  const routerParams = useParams();
  const requestId = routerParams?.id;
  const view = routerParams?.view;
  const commentId = routerParams?.commentId;

  const initialized = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].initialized);
  const request = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].detail);
  const comment = useSelector((state: any) => state[commentApi.APP_NAME].detail);
  const error = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].errorDetail);

  useEffect(() => {
    dispatch(feedbackRequestApi.reset());
    if (requestId) dispatch(feedbackRequestApi.getDetailRequest(requestId));

    dispatch(commentApi.reset());
    if (commentId) dispatch(commentApi.getDetailRequest(commentId));
  }, [commentId]);

  let url = null;
  if (view === 'whiteboard') {
    return (
      <div>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <div style={{ width: '100%', height: '100vh' }}>
            <Excalidraw />
          </div>
        </div>

        <ChatWindow requestId={request?.id} shouldNotResetCommentApi />
      </div>
    );
  } else if (view === 'code') {
    url = request?.coding_url;
  } else if (view === 'doc' && commentId) {
    url = comment?.video_comment?.document_url;
  } else {
    return <FileUnavailableComponent />;
  }

  if (initialized && error) {
    return <FileUnavailableComponent />;
  }

  // if (view === 'code') {
  //   return (
  //     <div>
  //       <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
  //         <embed
  //           src={url}
  //           title={`${view} Session`}
  //           style={{ width: '100%', height: '100vh' }}
  //           width="100%"
  //           height="100vh"
  //         />
  //       </div>

  //       <ChatWindow requestId={request?.id} />
  //     </div>
  //   );
  // }

  return (
    <div>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <iframe
          src={url}
          title={`${view} Session`}
          style={{ width: '100%', height: '100vh' }}
          frameBorder="0"
          loading="lazy"
        />
      </div>

      <ChatWindow requestId={request?.id} shouldNotResetCommentApi />
    </div>
  );
};

const ToolContainer: FC = () => {
  const routerParams = useParams();
  const request_id = routerParams && routerParams.id;

  return (
    <WebSocketRequestProvider request_id={request_id}>
      <Tool />
    </WebSocketRequestProvider>
  );
};

export default ToolContainer;
