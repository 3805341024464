'use client';

import SimpleBarReact, { Props } from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { isMobile } from 'react-device-detect';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

export default function Scrollbar({
  children,
  sx,
  scrollableNodeProps,
  ...other
}: BoxProps & Props): JSX.Element {
  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle clickOnTrack={false} sx={sx} scrollableNodeProps={scrollableNodeProps}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
