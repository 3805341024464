import { FC, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import Calendar from 'react-calendar';
import { v4 as uuidv4 } from 'uuid';

// App
import dayjs from 'utils/dayjs';
import { userPublic as userPublicApi } from 'store/index';
import { RateListItemType, UserBaseType } from 'types/index';
import { useSelector } from 'hooks';

import TimezonePicker from './TimezonePicker';
import './Calendar.scss';

interface IData {
  rateItem?: RateListItemType;
  timezone: string;
  time?: string;
  date: Date;
  duration: number;
}

interface IRenderCalendar {
  data: IData;
  handleChange: (data: any) => void;
  daysAvailable?: string[];
  availability?: any;
  username?: string;
  meetingType: 'online' | 'in_person';
}

type DayObjType = {
  [key: string]: dayjs.Dayjs[];
};

const DAY_TO_STRING: { [key: number]: string } = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
};

const getTimeRange = (_start: string, _end: string, tz1: string) => {
  let current = dayjs(_start, 'HH:mm:ss').tz(tz1);
  const end = dayjs(_end, 'HH:mm:ss').tz(tz1);
  const result: dayjs.Dayjs[] = [current];

  while (current < end) {
    current = current.add(15, 'minute');
    result.push(current);
  }
  return result;
};

function SelectDuration({ meetingType, data, handleChange }: IRenderCalendar) {
  const requested: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const { rateItem } = data;
  let rateList =
    meetingType === 'in_person'
      ? requested?.instructor?.lesson_rate_list
      : requested?.instructor?.rate_list;

  if (!rateList) {
    rateList = [
      { uid: uuidv4(), rate: 0, duration: 15, ignoreRate: true },
      { uid: uuidv4(), rate: 0, duration: 30, ignoreRate: true },
      { uid: uuidv4(), rate: 0, duration: 45, ignoreRate: true },
      { uid: uuidv4(), rate: 0, duration: 60, ignoreRate: true },
    ];
  }

  const rateComponentList = rateList.map(item => {
    return (
      <Grid item xs={4} key={item.uid}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => handleChange({ rateItem: item, duration: item.duration })}
          className={classnames(
            'tw-mr-4 tw-mt-2 tw-h-10 tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-p-2',
            item.uid === rateItem?.uid ? 'tw-bg-blue-600 tw-text-white' : 'tw-bg-white',
          )}
        >
          {item.duration} minutes for ${item.rate}
        </Button>
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className="tw-flex tw-items-center">
        <Typography variant="h6" className="tw-text-base">
          Select Duration
        </Typography>
      </Grid>

      {rateComponentList}
    </Grid>
  );
}

const RenderCalendar: FC<IRenderCalendar> = ({
  data,
  handleChange,
  availability,
  username,
  meetingType,
}) => {
  const { date, rateItem, timezone, time } = data;
  const daysAvailable = [...new Set((availability || []).map((x: any) => x.days).flat())];
  const dayObj = useMemo(() => {
    const dayObj: DayObjType = {};
    (availability || []).forEach((x: any) => {
      (x.days || []).forEach((y: string) => {
        if (!dayObj[y]) dayObj[y] = [];
        dayObj[y] = dayObj[y].concat(getTimeRange(x.start, x.end, timezone));
      });
    });

    Object.keys(dayObj).forEach((key: string) => {
      dayObj[key] = dayObj[key].sort((a, b) => b.valueOf() - a.valueOf()).reverse();
    });
    return dayObj;
  }, [availability, timezone]);

  const timeList = [
    ...new Set((dayObj[DAY_TO_STRING[date.getDay()]] || []).map(x => x.format('HH:mm'))),
  ];

  if (!availability || availability.length === 0) {
    return (
      <Grid container spacing={1} className="tw-mb-1">
        <Grid item xs={12} sm={12}>
          <Box className="tw-flex tw-h-full tw-flex-col tw-bg-white tw-p-4 tw-shadow">
            <Typography variant="h6">
              Unfortunately, {username} does not currently{' '}
              {meetingType === 'in_person' ? 'offer private lessons' : 'take any video calls'}.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={1} className="tw-mb-8">
      <Grid item xs={12} sm={12}>
        <Box className="tw-flex tw-h-full tw-flex-col tw-bg-white">
          <Typography variant="h6">
            Select the day and time you want to have the{' '}
            {meetingType === 'in_person' ? 'private lesson' : 'video call'}.
          </Typography>

          {time ? (
            <Typography variant="subtitle1" className="tw-text-base">
              Selected {dayjs(date).format('DD-MMM-YYYY')} at {time} for {rateItem?.duration}{' '}
              minutes.
            </Typography>
          ) : null}
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Calendar
          onChange={(value: any) => handleChange({ date: value })}
          value={date}
          tileDisabled={({ date }) => {
            //@ts-ignore
            return daysAvailable.indexOf(DAY_TO_STRING[date.getDay()]) === -1;
          }}
          tileClassName={({ activeStartDate, date }) => {
            if (activeStartDate === date) {
              return 'tw-blue-600';
            }
            return '';
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Box className="tw-flex tw-h-full tw-flex-col tw-bg-white tw-p-4">
          <Box className="tw-mt-2">
            <Grid container spacing={1} className="tw-mt-4">
              <Grid item xs={12}>
                <TimezonePicker
                  timezone={timezone}
                  setTimeZone={value => handleChange({ timezone: value })}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            className={classnames(
              'tw-mt-6 tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-overflow-y-auto',
              timeList.length > 18 ? 'tw-h-64' : 'tw-h-auto',
            )}
          >
            {timeList.map(t => (
              <Button
                key={t}
                onClick={() => handleChange({ time: t })}
                className={classnames(
                  'tw-mr-4 tw-mt-2 tw-h-10 tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-p-2',
                  t === time ? 'tw-bg-blue-600 tw-text-white' : 'tw-bg-white',
                )}
              >
                {t}
              </Button>
            ))}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <SelectDuration meetingType="in_person" data={data} handleChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export default RenderCalendar;
