import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

import TimezonePicker from './TimezonePicker';
import TimePicker from './Timepicker';
import { UserAuthType, FeedbackAvailabilityType } from 'types/index';
import {
  userAuth as userAuthApi,
  feedbackAvailability as feedbackAvailabilityApi,
} from 'store/index';
import dayjs, { mtime_to_obj, obj_to_mtime } from 'utils/dayjs';
import { buttonPrimary, buttonDanger, buttonSecondary } from 'styles/classnames';
import { ContentCard } from 'layouts/dashboard/Content';
import { useDispatch, useSelector } from 'hooks';
import { API_URL } from 'utils/constants';

interface ITimeWindow {
  id: number;
  start: string;
  end: string;
  days: string[];
}

const TimeWindow: React.FC<ITimeWindow> = ({ id, start, end, days }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    days: days || [],
    start: mtime_to_obj(start),
    end: mtime_to_obj(end),
    updated: false,
  });

  const WEEKLIST = [
    ['M', 'MONDAY'],
    ['T', 'TUESDAY'],
    ['W', 'WEDNESDAY'],
    ['T', 'THURSDAY'],
    ['F', 'FRIDAY'],
    ['S', 'SATURDAY'],
    ['S', 'SUNDAY'],
  ];

  useEffect(() => {
    if (state.updated) {
      const data = {
        start: obj_to_mtime(state.start),
        end: obj_to_mtime(state.end),
        days: state.days,
      };
      dispatch(feedbackAvailabilityApi.patchRequest(data, id));
    }
  }, [state]);

  return (
    <>
      <Grid item xs={12} sm={4} className="tw-h-20 tw-flex tw-items-center tw-justify-center">
        <Box className="tw-align-center tw-flex tw-justify-center tw-justify-evenly md:tw-justify-between tw-pt-4">
          {WEEKLIST.map(d => (
            <button
              key={d[1]}
              onClick={() => {
                if (state.days.indexOf(d[1]) === -1) {
                  setState({ ...state, days: [...state.days, d[1]], updated: true });
                } else {
                  setState({
                    ...state,
                    days: state.days.filter(x => d[1] !== x),
                    updated: true,
                  });
                }
              }}
              className={classnames(
                'tw-mr-1 tw-mt-1 tw-h-8 tw-w-8 tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 sm:tw-h-10 sm:tw-w-10',
                state.days.indexOf(d[1]) === -1
                  ? 'tw-bg-gray-300 tw-text-black'
                  : 'tw-bg-blue-600 tw-text-white',
              )}
            >
              {d[0]}
            </button>
          ))}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} className="tw-h-20 tw-flex tw-items-center tw-justify-center">
        <Box className="tw-align-center tw-flex tw-justify-center">
          <TimePicker
            hour={state.start.hour}
            minute={state.start.minute}
            ampm={state.start.ampm}
            title="From"
            onChange={(data: any) => {
              setState({ ...state, start: data, updated: true });
            }}
          />
          <span className="tw-mr-4" />
          <TimePicker
            hour={state.end.hour}
            minute={state.end.minute}
            ampm={state.end.ampm}
            title="To"
            onChange={(data: any) => {
              setState({ ...state, end: data, updated: true });
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Box className="tw-flex tw-justify-end">
          <Button
            className={classnames(buttonDanger, 'tw-mt-4 tw-mr-4 tw-w-24 tw-p-2 tw-text-base')}
            onClick={() => {
              dispatch(feedbackAvailabilityApi.deleteRequest(id));
            }}
          >
            Delete
          </Button>
        </Box>
      </Grid>
    </>
  );
};

interface IAvailability {
  isOnboarding?: boolean;
}

const Availability: React.FC<IAvailability> = ({ isOnboarding }) => {
  const dispatch = useDispatch();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const userAuthLoading = useSelector((state: any) => state[userAuthApi.APP_NAME].loading);
  const availability_list: FeedbackAvailabilityType[] = useSelector(
    (state: any) => state[feedbackAvailabilityApi.APP_NAME].list,
  );
  const feedbackAvailabilityLoading = useSelector(
    (state: any) => state[feedbackAvailabilityApi.APP_NAME].loading,
  );

  const [state, setState] = useState({
    timezone: user.timezone || dayjs.tz.guess(),
  });

  useEffect(() => {
    dispatch(feedbackAvailabilityApi.getRequest());
  }, []);

  useEffect(() => {
    if (user.id && !user.timezone) {
      // if timezone picker is shown and user doesn't have a timezone, let the browser geuss
      const url = `${API_URL}/api/v1/user/user/${user.id}/update_profile/`;
      dispatch(userAuthApi.patchRequest({ timezone: dayjs.tz.guess() }, null, { url }));
    }
  }, [user.id]);

  return (
    <ContentCard>
      <Grid container className="tw-mt-4">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ marginBottom: 20 }}
            display="block"
            className="tw-text-2xl tw-font-medium"
          >
            Manage Availability
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" display="block">
            Enter your timezone for a video call{' '}
            {userAuthLoading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
          </Typography>
        </Grid>

        <Grid item xs={12} className="tw-mb-8">
          <TimezonePicker
            timezone={state.timezone}
            setTimeZone={timezone => {
              setState({ ...state, timezone });
              const url = `${API_URL}/api/v1/user/user/${user.id}/update_profile/`;
              dispatch(userAuthApi.patchRequest({ timezone }, null, { url }));
            }}
          />
        </Grid>

        <Grid item xs={10} className="tw-mt-4">
          <Typography variant="h6" display="block">
            Select your weekly availability{' '}
            {feedbackAvailabilityLoading ? (
              <CircularProgress size={18} style={{ marginLeft: 7 }} />
            ) : null}
          </Typography>
        </Grid>

        <Grid item xs={2} className="tw-mt-4">
          <Box className="tw-flex tw-justify-end">
            <Button
              className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-base')}
              onClick={() => {
                dispatch(
                  feedbackAvailabilityApi.postRequest({
                    days: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
                    start: '09:00',
                    end: '17:00',
                  }),
                );
              }}
            >
              + Add
            </Button>
          </Box>
        </Grid>

        {availability_list.map(({ id, start, end, days }) => (
          <TimeWindow key={id} id={id} start={start} end={end} days={days} />
        ))}

        {isOnboarding ? (
          <Grid item xs={12} className="tw-align-start tw-flex tw-justify-start tw-mt-8">
            <Button
              variant="outlined"
              className={classnames(
                buttonSecondary,
                'tw-text-lg tw-py-2 tw-w-full md:tw-w-[250px] tw-ml-1',
              )}
              component={Link}
              to="/onboard-4"
            >
              Next
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </ContentCard>
  );
};

export default Availability;
