import { ReactNode, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import numeral from 'numeral';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import grey from '@mui/material/colors/grey';
import imageOutlineIcon from '@iconify/icons-eva/image-outline';
import fileTextOutlineIcon from '@iconify/icons-eva/file-text-outline';
import notificationIcon from '@iconify/icons-ic/outline-notifications-active';
import addCommentIcon from '@iconify/icons-ic/outline-add-comment';
import calendarIcon from '@iconify/icons-eva/calendar-outline';
import eventbriteIcon from '@iconify-icons/logos/eventbrite-icon';
import { Theme } from '@mui/material/styles';

// App
import { getUserAnalyticsQuery } from 'store/graphql/query';
import { useSelector } from 'hooks';
import { userAuth as userAuthApi } from 'store/index';
import { UserAuthType } from 'types/api';
import StatChart from './StatChart';
import CompleteStep from './CompleteStep';
import { ProfileImageDialog } from 'components/User/ChangeProfileImage';
import EditBio from 'components/Bio/Edit';
import { Rate } from 'containers/Manage/Rate';
import { EditNotifications } from 'components/Instructor/Configure';
import ProfileSummaryComponent from 'components/User/ProfileSummary';
import { BASE_DOMAIN } from 'utils/constants';
import { buttonPrimary } from 'styles/classnames';
import { EventbriteCard } from 'containers/Integration/eventbrite/components/connect';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 5,
    borderWidth: 5,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
      fontWeight: 'bold',
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },

  dialogPaper: {
    minHeight: '40vh',
    maxHeight: '80vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
  },
}));

function DialogContainer({
  handleClose,
  open,
  children,
  title,
}: {
  handleClose: VoidFunction;
  open: boolean;
  children: ReactNode;
  title?: string;
}) {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const extraProps: any = {};
  if (!fullScreen) {
    extraProps.maxWidth = 'sm';
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      {...extraProps}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="responsive-dialog-title">
        {!!title && (
          <Typography variant="h6" align="left" display="block">
            {title}
          </Typography>
        )}

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

// ----------------------------------------------------------------------

interface AppWelcomeProps extends CardProps {
  displayName?: string;
}

function AppWelcome({ displayName }: AppWelcomeProps) {
  return (
    <RootStyle>
      <CardContent
        sx={{
          color: 'grey.800',
          width: '100%',
        }}
      >
        <Typography gutterBottom variant="h4">
          Welcome {!displayName ? '...' : displayName} 🙂
        </Typography>

        <Typography variant="subtitle1" sx={{ pb: { xs: 3, xl: 5 }, mx: 'auto' }}>
          Let's finish getting you set up!
        </Typography>
      </CardContent>
    </RootStyle>
  );
}

function StepComponent({ steps }: { steps: any[] }) {
  const [activeStep, _] = useState(steps.filter(x => x.completed).length);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((x, _) => {
          return (
            <Step key={x.id}>
              <StepLabel StepIconComponent={QontoStepIcon} />
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

function ShowFeedbackRequest() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [copied, setCopied] = useState(false);

  return (
    <Box className="tw-mt-4">
      <Typography variant="h6" display="block">
        Copy and share your profile link below to allow your clients to ask for feedback.
      </Typography>

      <Typography variant="body1" className="tw-mt-4" id="copy-and-share-link">
        <a
          href={`https://${BASE_DOMAIN}/${user.username}`}
          style={{
            padding: 3,
            borderRadius: 5,
            backgroundColor: copied ? '#c4e5d6' : 'initial',
          }}
          target="_blank"
          className="tw-mr-1 tw-cursor-pointer"
        >
          https://{BASE_DOMAIN}/{user.username}
        </a>

        <CopyToClipboard
          text={`https://${BASE_DOMAIN}/${user.username}`}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
        >
          <Button className={classnames(buttonPrimary, 'tw-mr-4 tw-py-0 tw-w-18 tw-text-base')}>
            Copy
          </Button>
        </CopyToClipboard>
      </Typography>
    </Box>
  );
}

export default function Dashboard(): JSX.Element {
  const theme = useTheme();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { loading, data: analyticsData } = useQuery(getUserAnalyticsQuery, {
    fetchPolicy: 'network-only',
  });
  const defaultState = {
    showProfileImage: false,
    showBio: false,
    showRate: false,
    showNotifications: false,
    showBusiness: false,
    showFeedbackRequest: false,
    showEventbrite: false,
  };
  const [state, setState] = useState(defaultState);

  const stepsCompleted = [
    {
      id: 'profile_image',
      icon: imageOutlineIcon,
      title: 'Add a profile picture',
      content: 'Profile pictures makes it easy for your clients to know its you.',
      completed: user?.completed_steps?.profile_image?.completed,
      onClick: () => {
        setState({ ...defaultState, showProfileImage: true });
      },
    },

    {
      id: 'bio',
      icon: fileTextOutlineIcon,
      title: 'Complete your bio',
      content: 'Let your clients know what they expect from you.',
      completed: user?.completed_steps?.bio?.completed,
      onClick: () => {
        setState({ ...defaultState, showBio: true });
      },
    },

    {
      id: 'rate',
      icon: calendarIcon,
      title: 'Set up rate',
      content: 'Set up the hours your are available and how much you charge for feedback.',
      completed: user?.completed_steps?.rate?.completed,
      onClick: () => {
        setState({ ...defaultState, showRate: true });
      },
    },

    {
      id: 'notification',
      icon: notificationIcon,
      title: 'Set up notifications',
      content: 'Be notified as soon as your clients ask you for feedback.',
      completed: user?.completed_steps?.notification?.completed,
      onClick: () => {
        setState({ ...defaultState, showNotifications: true });
      },
    },

    {
      id: 'eventbrite',
      icon: eventbriteIcon,
      title: 'Connect eventbrite',
      content: 'Connect your Eventbrite account to provide feedback to workshop attendees. ',
      completed: user?.integrations?.eventbrite?.is_connected,
      onClick: () => {
        setState({ ...defaultState, showEventbrite: true });
      },
    },

    {
      id: 'requests',
      icon: addCommentIcon,
      title: 'Completed feedback requests',
      content: 'Share your profile link and complete a request for feeback with your audience.',
      completed: user?.completed_steps?.request_completed?.completed,
      onClick: () => {
        setState({ ...defaultState, showFeedbackRequest: true });
      },
    },

    // {
    //   id: 'payout',
    //   icon: moneyIcon,
    //   title: 'Set up payout method',
    //   content: 'Connect to your Stripe account to be able to withdraw funds.',
    //   completed: user?.completed_steps?.payout?.completed,
    // },

    // {
    //   id: 'business',
    //   icon: businessIcon,
    //   title: 'Activate business account',
    //   content: 'Create a business account if you have employees and want to manage their rate.',
    //   completed: user?.completed_steps?.business_account?.completed,
    // },
  ];

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppWelcome displayName={user.full_name || `@${user.username}`} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" display="block">
            Your Profile
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ProfileSummaryComponent />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" display="block">
            Your Stats
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <StatChart
            title="Requests"
            value={analyticsData?.getUserAnalytics?.totalRequests}
            percent={0}
            color={theme.palette.chart.blue[0]}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <StatChart
            title="Revenue"
            value={numeral(analyticsData?.getUserAnalytics?.totalRevenue || 0).format('$ 0.00')}
            percent={0}
            color={theme.palette.primary.main}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <StatChart
            title="Page Views"
            value={analyticsData?.getUserAnalytics?.totalViews}
            percent={0}
            color={theme.palette.chart.red[0]}
            loading={loading}
          />
        </Grid>

        <Grid item xs={12} className="tw-pt-12 tw-pb-4">
          <StepComponent steps={stepsCompleted} />
        </Grid>

        {stepsCompleted.map(x => (
          <Grid key={x.id} item xs={12} md={4}>
            <CompleteStep
              icon={x.icon}
              content={x.content}
              title={x.title}
              completed={!!x.completed}
              onClick={x.onClick}
            />
          </Grid>
        ))}
      </Grid>

      <ProfileImageDialog
        open={state?.showProfileImage}
        handleClose={() => setState({ ...defaultState, showProfileImage: false })}
      />

      <DialogContainer
        open={state?.showBio}
        handleClose={() => setState({ ...defaultState, showBio: false })}
      >
        <EditBio />
      </DialogContainer>

      <DialogContainer
        open={state?.showRate}
        handleClose={() => setState({ ...defaultState, showRate: false })}
      >
        <Rate />
      </DialogContainer>

      <DialogContainer
        open={state?.showNotifications}
        handleClose={() => setState({ ...defaultState, showNotifications: false })}
      >
        <EditNotifications />
      </DialogContainer>

      <DialogContainer
        open={state?.showFeedbackRequest}
        handleClose={() => setState({ ...defaultState, showFeedbackRequest: false })}
      >
        <ShowFeedbackRequest />
      </DialogContainer>

      <DialogContainer
        open={state?.showEventbrite}
        handleClose={() => setState({ ...defaultState, showEventbrite: false })}
      >
        <EventbriteCard />
      </DialogContainer>
    </Container>
  );
}
