import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import FourZeroFourStyleWrapper from './404.style';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 400,
    },
  },
}));

export default function NotFound(): JSX.Element {
  const classes = useStyles();
  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Content">
        <h1>404</h1>
        <h3>Looks like you got lost</h3>
        <p>The page youre looking for doesnt exist or has been moved.</p>
      </div>

      <div className="iso404Artwork">
        <img
          className={classes.img}
          alt="Page Not Found"
          src="https://dancelogue.s3.amazonaws.com/static/images/404.png"
        />
      </div>
    </FourZeroFourStyleWrapper>
  );
}
