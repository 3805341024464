import log from 'loglevel';

const logger = (method, event, params) => log.info(`${method} not implemented`, event, params);

export const segmentAnalytics = window.analytics || {
  identify: (event, params) => logger('identify', event, params),
  track: (event, params) => logger('track', event, params),
  page: () => logger('page'),
  group: () => logger('group'),
};

export const SEGMENT_CONSTANTS = {
  VIDEO_CLICKED: 'Video Item Clicked',
  VIDEO_UPLOADED: 'Video Uploaded',
  VIDEO_DELETE: 'Video Delete',
  DL_GENERATED: 'Dynamic Link Generated',
  GET_FEEDBACK_SHARED_SUBMIT: 'Get Feedback Shared Submit',
  GET_FEEDBACK_SUBMIT: 'Get Feedback Submit',
  SHARED_DETAIL_FEEDBACK_AUTH_MODAL: 'Shared Detail Feedback Auth Modal',
  LOGIN_CLICKED: 'Login Clicked',
  LOGIN_EMAIL_COMPLETE: 'Login Email Complete',
  SIGNUP_CLICKED: 'Signup Clicked',
  FORGOTTEN_PASSWORD_CLICKED: 'Password Forgotten Clicked',
  SIGNUP_EMAIL_COMPLETE: 'Signup Email Complete',
  SOCIAL_AUTH_COMPLETE: 'Social Auth Complete',
  NAV_CLICKED: 'Nav Clicked',
  FEEDBACK_ITEM_CLICKED: 'Feedback Video Item Clicked',
  FEEDBACK_ACCEPT_REQUEST: 'Feedback Accept Request',
  FEEDBACK_COMPLETE_REQUEST: 'Feedback Complete Request',
  FEEDBACK_CANCEL_REQUEST: 'Feedback Cancel Request',
  FEEDBACK_REJECT_REQUEST: 'Feedback Reject Request',
  FEEDBACK_ADD_COMMENT_REQUEST: 'Feedback Add Comment Request',
  COMMENT_DELETE: 'Comment Delete',

  PUBLIC_USER_CLICKED: 'Public User Clicked',
};
