import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// App
import Availability from 'components/Meeting/Availability';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: 50,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
}));

const App: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Availability isOnboarding />
    </div>
  );
};

export default App;
