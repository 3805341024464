import { useEffect, FC, ReactNode } from 'react';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

// Material ui
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

// App
import UsernameLink from 'components/User/UsernameLink';
import { UserAvatar } from 'components/Common/Avatar';
import { StatusRequest } from 'components/Feedback/StatusItem';
import { userAuth as userAuthApi, feedbackRequest as feedbackRequestApi } from 'store/index';
import { buttonPrimary, buttonSuccess, card } from 'styles/classnames';
import { UserAuthType, RequestType } from 'types';
import { VideoListLoading } from 'components/Loading/index';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  buttonAction: {
    flex: 1,
    marginRight: 3,
    marginLeft: 3,
    padding: 7,
  },

  noCallsContainer: {
    height: '60vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '5%',
    },
  },
}));

interface CardContentRowProps {
  name: string;
  value: ReactNode;
}

const CardContentRow: FC<CardContentRowProps> = ({ name, value }) => {
  return (
    <Grid container spacing={1} className="tw-my-0">
      <Grid item xs={5}>
        <Typography variant="body1" className="tw-text-sm">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1" className="tw-break-all tw-text-sm">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Schedule: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestList: RequestType[] = useSelector(
    (state: any) => state[feedbackRequestApi.APP_NAME].list,
  );
  const initialized: boolean = useSelector(
    (state: any) => state[feedbackRequestApi.APP_NAME].initialized,
  );
  const currentUser: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  useEffect(() => {
    dispatch(feedbackRequestApi.reset());
    dispatch(feedbackRequestApi.setGetParams({ limit: 30, request_type: 'CALL' }));
    dispatch(feedbackRequestApi.getRequest());
  }, []);

  if (!initialized) {
    return <VideoListLoading lg={4} md={4} />;
  }

  if (initialized && requestList.length === 0) {
    return (
      <Paper elevation={0} className={classnames(classes.noCallsContainer, card)}>
        <Typography variant="h6">You have no calls pending.</Typography>
      </Paper>
    );
  }

  const rows = requestList.map(row => {
    let other = row.created_by;
    if (other && currentUser.id === other.id) {
      other = row.requested;
    }

    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <div className="-translate-y-20 sm:-translate-y-24 tw-mx-auto tw-transform tw-rounded-lg tw-bg-white tw-px-2 tw-py-4 tw-shadow-lg">
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 320,
            }}
          >
            <UserAvatar user={other} size={70} />

            <div style={{ marginTop: 5 }} />

            <CardContentRow
              name="Meeting With"
              value={<UsernameLink username={other && other.username} />}
            />

            <CardContentRow
              name="Rate"
              value={row.rate ? numeral(row.rate).format('$ 0.00') : 'Free'}
            />

            <CardContentRow name="Status" value={<StatusRequest item={row} />} />

            <CardContentRow
              name="Meeting Date"
              value={
                row.meeting_date ? dayjs(row.meeting_date).format('D-MMM-YYYY: HH:mm a') : 'Not Set'
              }
            />

            <CardContentRow
              name="Created on"
              value={dayjs(row.created_at).format('D-MMM-YYYY: HH:mm a')}
            />
          </CardContent>

          <CardActions>
            <Button
              variant="outlined"
              className={classnames(classes.buttonAction, buttonPrimary, 'tw-text-lg')}
              onClick={() => navigate(`/request/${row.id}`)}
            >
              View
            </Button>

            <Button
              variant="outlined"
              className={classnames(classes.buttonAction, buttonSuccess, 'tw-text-lg')}
              href={row.call_url}
            >
              Join
            </Button>
          </CardActions>
        </div>
      </Grid>
    );
  });
  return <>{rows}</>;
};

export default Schedule;
