import { useEffect, FC, useRef } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// App
import UploadFile from 'components/Upload/File';
import UploadRecord from 'components/Upload/Record';
import Calendar from 'components/Meeting/Calendar';
import { comment as commentApi, userPublic as userPublicApi } from 'store/index';
import SubmitButton from 'components/Feedback/SubmitButton';
import useRequestResponseContext from 'hooks/useRequestResponse';
import EmojiPicker from 'components/Minimals/EmojiPicker';
import { buttonPrimary } from 'styles/classnames';
import { useRequestResponse, useSelector } from 'hooks';
import StripeWrapper from 'components/Fund/Stripe';
import { VideoPreviewType } from 'types';

const COMMENT_REQUIRED_ERROR = 'You need to add a reason for the call.';
const TIME_REQUIRED_ERROR = 'You need to select a time for the meeting.';

const useStyles = makeStyles((theme: Theme) => ({
  inputMultiline: {
    borderRadius: 0,
  },

  notchedOutline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },

  paperRounded: {
    borderRadius: '0px 0px 10px 10px',
    borderTop: 0,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingTop: 5,
    paddingBottom: 5,
  },

  filecontentTypography: {
    padding: 0,
    margin: 0,
    paddingLeft: 10,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
      wordWrap: 'break-word',
      textAlign: 'left',
    },
  },
}));

interface EntryProps {
  onSubmit: () => void;
  textLabel?: string;
  isAsking?: boolean; // This only applies for the external view
}

const VideoPreview = ({
  recording: { stream, mediaBlobUrl, status, type },
  clearRecording,
}: VideoPreviewType) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (videoRef.current && stream && type === 'video') {
      videoRef.current.srcObject = stream;
    }
    if (audioRef.current && stream && type === 'audio') {
      audioRef.current.srcObject = stream;
    }
  }, [stream]);

  if (mediaBlobUrl) {
    return (
      <>
        <Button className="tw-py-0 tw-lowercase tw-text-red-600" onClick={clearRecording}>
          x remove
        </Button>
        {type === 'video' ? (
          <video src={mediaBlobUrl} controls className="m-auto" />
        ) : (
          <audio src={mediaBlobUrl} controls className="tw-my-2 tw-w-full" />
        )}
      </>
    );
  }

  if (stream && status === 'recording') {
    if (type === 'video')
      return <video ref={videoRef} autoPlay controls muted className="m-auto" />;
    if (type === 'audio')
      return <audio ref={audioRef} autoPlay controls muted className="tw-my-2 tw-w-full" />;
  }

  return null;
};

function RenderBookPrivateLesson() {
  const requested = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const { state, setState, requestedUser, requestData, setRequestData } =
    useRequestResponseContext();
  const instructor = requested.instructor || requestedUser?.instructor || {};
  const username = requested.username || requestedUser?.username;

  if (Object.keys(instructor?.lesson_rate_list || []).length === 0) return null;

  const lessonList = Object.keys(instructor.lesson_rate_list).map((key: string) => {
    const rateItem = instructor.lesson_rate_list[key];
    const { uid, rate, duration } = rateItem;
    return (
      <Grid key={uid} item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div">
              Book a private lesson
            </Typography>

            <Typography className="tw-mb-4">
              Book an in person {duration} minute private lesson for ${rate} where you will meet
              with {username} in Toronto.
            </Typography>

            <Button
              onClick={() => {
                setState({
                  ...state,
                  isVideoCall: true,
                  meetingType: 'in_person',
                });
                setRequestData({ ...requestData, rateItem });
              }}
              className={classnames(buttonPrimary, 'tw-py-1 tw-text-base')}
              size="small"
            >
              Book now
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  });
  return (
    <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
      {/* <Typography variant="h6">Book a private session</Typography> */}

      <Grid container justifyContent="flex-start" spacing={4}>
        {lessonList}
      </Grid>
    </Grid>
  );
}

const Entry: FC<EntryProps> = ({ onSubmit, textLabel, isAsking }: EntryProps) => {
  const { requestedUser, disabled } = useRequestResponseContext();
  const classes = useStyles();
  const requested = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const firebaseStorage = useSelector((state: any) => state.firebaseStorage);
  const commentLoading = useSelector((state: any) => state[commentApi.APP_NAME].loading);
  const progress = useSelector((state: any) => state.app.uploadProgress);

  const {
    error,
    setError,
    setRequestData,
    requestData,
    showPayment,
    fileList,
    setFileList,
    recording,
    setRecording,
    recordedFile,
    setRecordedFile,
    state,
    setState,
    setRequestValue,
  } = useRequestResponse();

  const requestedTimezone = requested && requested.timezone;
  const instructor = requested.instructor || requestedUser?.instructor || {};

  // If the user data loads set the default time
  useEffect(() => {
    if (requestedTimezone) dayjs.tz.setDefault(requestedTimezone);
    setRequestData({ ...requestData, timezone: requestedTimezone });
    return () => dayjs.tz.setDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedTimezone]);

  useEffect(() => {
    if (!state.isVideoCall) {
      setRequestValue(instructor?.rate);
    }
  }, [state.isVideoCall, instructor?.rate]);

  const buttonDisabled =
    (!state.comment && fileList.length === 0 && !recordedFile) || commentLoading;

  return (
    <Grid container justifyContent="center">
      {state.isVideoCall ? (
        <>
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Calendar
              data={requestData}
              handleChange={data => {
                setRequestData({ ...requestData, ...data });
                if (data?.rateItem) setRequestValue(data.rateItem.rate);
                if (data.time && error === TIME_REQUIRED_ERROR) setError(null);
              }}
              availability={requested.availability}
              username={requested.username}
              meetingType={state.meetingType}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 10 }}>
            <Box className="tw-flex tw-h-full tw-flex-col tw-bg-white">
              <Typography variant="h6">Add reason for call</Typography>
            </Box>
          </Grid>
        </>
      ) : (
        <RenderBookPrivateLesson />
      )}

      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 20 }}>
        <TextField
          id="feedback-question-input"
          label={textLabel}
          multiline
          rows={5}
          defaultValue=""
          variant="outlined"
          fullWidth
          value={state.comment}
          onChange={ev => {
            setState({ ...state, comment: ev.target.value });
            if (error === COMMENT_REQUIRED_ERROR) setError(null);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              multiline: classes.inputMultiline,
              notchedOutline: classes.notchedOutline,
            },
          }}
          style={{ backgroundColor: 'white' }}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
        <Paper
          className="tw-px-3"
          elevation={0}
          variant="outlined"
          classes={{
            rounded: classes.paperRounded,
          }}
        >
          {!!fileList.length && (
            <Typography variant="body1" gutterBottom className={classes.filecontentTypography}>
              Selected file is: <b>{fileList[0].name}</b>{' '}
              <Button
                className="tw-py-0 tw-lowercase tw-text-red-600"
                onClick={() => setFileList([])}
              >
                x remove
              </Button>
            </Typography>
          )}
          {recording.mediaBlobUrl || (recording.stream && recording.status === 'recording') ? (
            <VideoPreview
              recording={recording}
              clearRecording={() => {
                setRecording({
                  status: null,
                  mediaBlobUrl: '',
                  type: '',
                  stream: null,
                  clearBlobUrl: undefined,
                });
                setRecordedFile(null);
                recording?.clearBlobUrl?.();
              }}
            />
          ) : null}

          {progress && progress.percent !== undefined && (
            <div style={{ display: 'relative' }}>
              <span>{(firebaseStorage.status || '').toLowerCase()}</span>
              <LinearProgress
                variant="buffer"
                value={progress.percent}
                valueBuffer={progress.percent * 1.2}
                style={{ height: 30 }}
              />
            </div>
          )}

          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <EmojiPicker
                disabled={disabled}
                value={state.comment}
                setValue={value => setState({ ...state, comment: value })}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span className="tw-mr-1">
                <UploadRecord
                  setFile={(file: Blob) => setRecordedFile(file)}
                  type="audio"
                  setRecording={(data: any) => setRecording({ ...recording, ...data })}
                />
              </span>
              <span className="tw-mr-1">
                <UploadRecord
                  setFile={(file: Blob) => setRecordedFile(file)}
                  type="video"
                  setRecording={(data: any) => setRecording({ ...recording, ...data })}
                />
              </span>
              <span>
                <UploadFile setFileList={(fileList: any[]) => setFileList(fileList)} />
              </span>

              {!isAsking ? (
                <span className="tw-flex tw-items-center tw-justify-center">
                  <Button
                    className={classnames(buttonPrimary, 'tw-py-1 tw-text-base')}
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </span>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {error ? (
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 10 }}>
          <Box className="tw-flex tw-h-full tw-flex-col tw-bg-white tw-p-2">
            <Typography variant="h6" className="tw-text-base tw-text-red-600">
              {error}
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {showPayment && <StripeWrapper />}

      {isAsking && !showPayment ? (
        <SubmitButton
          disabled={buttonDisabled}
          setState={(entry: any) => {
            setError(null);
            setState({ ...state, ...entry });
          }}
          state={state}
          onSubmit={onSubmit}
        />
      ) : null}
    </Grid>
  );
};

export default Entry;
