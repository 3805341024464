import styled from 'styled-components';

export const AppHolder = styled.div``;
export const CardMediaContentOverlay = styled.div`
  position: relative;

  .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 10;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  :hover .content-overlay {
    opacity: 0.7;
  }

  :hover .content-icon {
    color: white !important;
  }

  :hover {
    cursor: pointer;
  }
`;
