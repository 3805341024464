// material
import { Grid, Container, Typography } from '@mui/material';
// import classnames from 'classnames';
import { useQuery } from '@apollo/client';

import { getBusinessAnalyticsQuery } from 'store/graphql/query';
import TotalCard from 'components/Analytics/TotalCard';
import MonthlyBreakdown from 'components/Analytics/MonthlyBreakdown';
import { useBusiness } from 'hooks';

export default function GeneralAnalytics(): JSX.Element {
  const { business } = useBusiness();
  const { loading, data } = useQuery(getBusinessAnalyticsQuery, {
    fetchPolicy: 'network-only',
    variables: { businessUid: business?.uid },
    skip: !business?.uid,
  });

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Analytics
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getBusinessAnalytics?.totalClients}
              loading={loading}
              title="Total Clients"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getBusinessAnalytics?.totalRequests}
              loading={loading}
              title="Total Requests"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getBusinessAnalytics?.totalRevenue}
              loading={loading}
              title="Total Revenue"
              isCurrency
            />
          </Grid>

          <Grid item xs={12}>
            <MonthlyBreakdown
              title="Requests"
              data={data?.getBusinessAnalytics?.monthlyRequests}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <MonthlyBreakdown
              title="Revenue"
              data={data?.getBusinessAnalytics?.monthlyRevenue}
              loading={loading}
              isCurrency
            />
          </Grid>
        </Grid>
      </Container>

      {/* {business?.financial_config?.disable_billing ? null : (
        <Container maxWidth="xl">
          <div className={classnames(card)}>
            <DepositHistory />
          </div>
        </Container>
      )}

      {business?.financial_config?.disable_billing ? null : (
        <Container maxWidth="xl">
          <div className={classnames(card)}>
            <TransactionHistory />
          </div>
        </Container>
      )} */}
    </>
  );
}
