import { FC, createContext, useState } from 'react';
import { FileWithPath } from 'file-selector';
import dayjs from 'dayjs';

// App
import { uploadVideo } from 'store/firebase';
import {
  comment as commentApi,
  userAuth as userAuthApi,
  userPublic as userPublicApi,
  feedbackRequest as feedbackRequestApi,
} from 'store/index';
import { notification } from 'store/notification';
import { RecordingType, SubmitVideoType, UserBaseType } from 'types';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';
import { RateListItemType, RequestType } from 'types/index';
import { APP_URL } from 'utils/constants';

const COMMENT_REQUIRED_ERROR = 'You need to add a reason for the call.';
const TIME_REQUIRED_ERROR = 'You need to select a time for the meeting.';
const DURATION_REQUIRED_ERROR = 'You need to add a duration for the meeting';

interface ProviderProps {
  disabled?: boolean;
  textLabel?: string;
  requestedUser?: UserBaseType;
  canRequestCall?: boolean;
  children: React.ReactNode;
  isPublic?: boolean;
  onSuccessRedirect?: (request_id?: string) => void;
}

interface ISubmitRequest {
  requested_id?: number;
  instructor_id?: number;
  business_id?: number;
  rate?: number | string;
  attendee_id?: string;
}

interface ISubmitDataTime {
  rate_item: RateListItemType;
  timezone: string;
  date: string;
  time: string;
  duration: number;
}

interface ISubmitData {
  requested_id?: number;
  request_type: string;
  details: ISubmitDataTime;
  created_by_availability: string[];
  created_by_timezone?: string;
  instructor_id?: number;
  rate?: number | string | null;
  business_id?: number;
  attendee_id?: string;
}

interface IRequestData {
  rateItem: RateListItemType;
  timezone: string;
  time: string;
  date: Date;
  duration: number;
}

interface AttendeeType {
  attendee_id?: string;
  email?: string;
  can_ask?: boolean;
  error?: string;
  completed: boolean;
}

type IState = {
  isPublic?: boolean;
  disabled?: boolean;
  textLabel?: string;
  requestedUser?: UserBaseType;
  canRequestCall?: boolean;
  error?: string | null;
  requestData: IRequestData;
  showPayment: boolean;
  hasInSufficientFundsForMeeting: boolean;
  hasInSufficientFundsForMessage: boolean;
  fileList: FileWithPath[];
  recording: RecordingType;
  recordedFile: Blob | null;
  requestValue: number;
  state: any;
  attendee: AttendeeType;
};

type IDispatch = {
  setError: (value: string | null) => void;
  setRequestData: (data: IRequestData) => void;
  getFeedback: () => void;
  submitVideoCallRequest: () => Promise<void>;
  setShowPayment: (value: boolean) => void;
  setFileList: (value: FileWithPath[]) => void;
  setRecording: (value: RecordingType) => void;
  setRecordedFile: (value: Blob | null) => void;
  setRequestValue: (value: number) => void;
  setState: (value: any) => void;
  setAttendee: (attendee: AttendeeType) => void;
};

export type RequestResponseContextProps = IState & IDispatch;

const RequestResponseContext = createContext<RequestResponseContextProps>(
  {} as RequestResponseContextProps,
);

const RequestResponseProvider: FC<ProviderProps> = ({
  children,
  disabled,
  textLabel,
  requestedUser,
  canRequestCall,
  isPublic,
  onSuccessRedirect,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const userRequested: UserBaseType = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].detail,
  );
  const [state, setState] = useState({ isVideoCall: false, comment: '', meetingType: 'in_person' });
  const [attendee, setAttendee] = useState<AttendeeType>({ completed: false });
  const [showPayment, setShowPayment] = useState(false);
  const userAuthenticated = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const requested = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);

  const business = userRequested?.business;
  const rateList = userRequested?.instructor?.rate_list || [];
  const requestedTimezone = userRequested?.timezone;
  const instructor = requested.instructor || requestedUser?.instructor || {};

  const [requestData, setRequestData] = useState<IRequestData>({
    // Default to Toronto time if the user timezone was not set
    timezone: requestedTimezone ? requestedTimezone : 'America/Toronto',
    rateItem: rateList[0] || {},
    date: new Date(),
    time: '',
    duration: 0,
  });
  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const [recording, setRecording] = useState<RecordingType>({
    status: null,
    mediaBlobUrl: '',
    type: '',
    stream: null,
  });
  const [recordedFile, setRecordedFile] = useState<Blob | null>(null);
  const [requestValue, setRequestValue] = useState(instructor?.rate || 0);

  const onSuccess = (request_uid?: string) => {
    dispatch(userAuthApi.getDetailRequest('user')); // Need to update the available balance
    dispatch(commentApi.reset());
    dispatch(commentApi.setGetParams({ request_uuid: request_uid }));
    dispatch(commentApi.getRequest());
    if (onSuccessRedirect) {
      onSuccessRedirect(request_uid);
    } else {
      window.location.href = `${APP_URL}/request/${request_uid}/`;
    }
  };

  const getRate = (): number => {
    let rate = userRequested?.instructor?.rate;
    if (business?.financial_config?.business_set_rate) {
      rate = business?.financial_config?.rate as number;
    }
    if (business?.financial_config?.disable_billing) {
      rate = 0;
    }
    return rate || 0;
  };

  const getVideoRate = (): number => {
    let rate = requestData?.rateItem?.rate as number;
    if (business?.financial_config?.disable_billing) {
      rate = 0;
    }
    return rate || 0;
  };

  // For now always show the user has sufficient funds and we can deal with the fallout later.
  const getHasInSufficientFunds = (rate: number) => {
    if (attendee?.attendee_id && attendee?.can_ask) return false;

    log.info(
      !business?.financial_config?.external_billing &&
        !business?.financial_config?.disable_billing &&
        Number(userAuthenticated?.balance?.available_amount) < rate,
    );
    return false;
    // return (
    //   !business?.financial_config?.external_billing &&
    //   !business?.financial_config?.disable_billing &&
    //   Number(userAuthenticated?.balance?.available_amount) < rate
    // );
  };

  const hasInSufficientFundsForMeeting = getHasInSufficientFunds(getVideoRate());
  const hasInSufficientFundsForMessage = getHasInSufficientFunds(getRate());

  const submitVideo = async (
    file: FileWithPath | null,
    recording: Blob | null,
    request_id?: number,
    request_uid?: string,
  ) => {
    if (!file && !recording) onSuccess(request_uid);

    if (file) {
      const data: SubmitVideoType = {
        title: file.name,
        metadata: file,
        upload_type: 'COMMENT',
        recorded_timestamp: file.lastModified,
        is_comment: true,
        request_id,
      };

      dispatch(
        uploadVideo(data, file, () => {
          onSuccess(request_uid);
        }),
      );
    }

    if (recording) {
      let media_type = '';
      if (recording.type.includes('audio')) {
        media_type = 'AUDIO';
      } else if (recording.type.includes('video')) {
        media_type = 'VIDEO';
      }
      const data: SubmitVideoType = {
        title: '',
        metadata: {
          size: recording.size,
          type: recording.type,
        },
        upload_type: 'COMMENT',
        recorded_timestamp: 0,
        is_comment: true,
        request_id,
        media_type: media_type || undefined,
      };

      dispatch(
        uploadVideo(data, recording, () => {
          onSuccess(request_uid);
        }),
      );
    }
  };

  // This needs to be in the feedback entry component
  const getFeedback = async () => {
    let request_id;
    let request_uid;
    const comment: string = state.comment;
    const file: FileWithPath | null = fileList?.[0] || null;
    const recording: Blob | null = recordedFile || null;

    // If it's a video call then
    if (state.isVideoCall) {
      submitVideoCallRequest();
      return;
    }

    if (!comment && !file && !recording) {
      return;
    }

    if (hasInSufficientFundsForMessage) {
      setShowPayment(true);
      return;
    }

    try {
      const data: ISubmitRequest = {
        requested_id: userRequested.id,
        instructor_id: userRequested?.instructor?.id,
        rate: getRate(),
        attendee_id: attendee.attendee_id,
      };

      if (business?.id) {
        data.business_id = business?.id;
      }
      const resp: RequestType = await dispatch(feedbackRequestApi.postRequest(data));
      request_id = resp.id;
      request_uid = resp.uuid;
      if (!request_id) {
        dispatch(notification('Something went wrong', 'error'));
        return;
      }
    } catch (err) {
      // SHOW MODAL TO TRY AGAIN
      log.exception(err);
    }

    if (comment) {
      const data = {
        comment,
        request_id,
      };

      try {
        const resp: any = await dispatch(commentApi.postRequest(data));
        if (!resp.id) {
          setError(resp?.data?.error || 'Something went wrong.');
          dispatch(notification(resp?.data?.error || 'Something went wrong.', 'error'));
        }
      } catch (err) {
        log.exception(err);
      }
    }
    submitVideo(file, recording, request_id, request_uid);
  };

  const submitVideoCallRequest = async () => {
    setError(null);
    if (state.isVideoCall) {
      if (hasInSufficientFundsForMeeting) {
        setShowPayment(true);
        return;
      }

      if (!requestData.time) {
        setError(TIME_REQUIRED_ERROR);
        return;
      }

      if (!requestData.duration) {
        setError(DURATION_REQUIRED_ERROR);
        return;
      }

      if (!state.comment) {
        setError(COMMENT_REQUIRED_ERROR);
        return;
      }

      const availability = dayjs(
        `${dayjs(requestData.date).format('DD-MMM-YYYY')} ${requestData.time}`,
        'DD-MMM-YYYY HH:MM',
      );

      const data: ISubmitData = {
        requested_id: requestedUser?.id,
        request_type: state.meetingType === 'in_person' ? 'IN_PERSON' : 'CALL',
        rate: getVideoRate(),
        details: {
          rate_item: requestData?.rateItem || {}, // Idea is to save the whole json object
          timezone: requestData.timezone,
          date: dayjs(requestData.date).format('DD-MMM-YYYY'),
          time: requestData?.time,
          duration: requestData?.duration,
        },
        created_by_timezone: requestData.timezone,
        created_by_availability: [availability.toISOString()],
        instructor_id: requestedUser?.instructor?.id,
        attendee_id: attendee.attendee_id,
      };

      // If the business id is set then use that.
      if (business?.id) {
        data.business_id = business?.id;
      }

      const errMessage = 'Something went wrong, unable to create your request';
      try {
        const resp: any = await dispatch(feedbackRequestApi.postRequest(data));
        if (resp.id) {
          const commentData = {
            request_id: resp.id,
            comment: state.comment,
          };
          await dispatch(commentApi.postRequest(commentData));
          dispatch(notification('Request was sent.', 'success'));
          if (onSuccessRedirect) {
            onSuccessRedirect();
          } else {
            window.location.href = `${APP_URL}/request/${resp.id}/`;
          }
        } else {
          dispatch(notification(errMessage, 'error'));
        }
      } catch (err) {
        log.exception(err);
        dispatch(notification(err?.data?.message || err?.data?.error || errMessage, 'error'));
      }
    } else {
      setState({ ...state, isVideoCall: true });
    }
  };

  const value: RequestResponseContextProps = {
    isPublic,
    disabled,
    textLabel,
    requestedUser,
    canRequestCall,
    error,
    setError,
    requestData,
    setRequestData,
    showPayment,
    setShowPayment,
    getFeedback,
    submitVideoCallRequest,
    hasInSufficientFundsForMeeting,
    hasInSufficientFundsForMessage,
    fileList,
    setFileList,
    recording,
    setRecording,
    recordedFile,
    setRecordedFile,
    requestValue,
    setRequestValue,
    state,
    setState,
    attendee,
    setAttendee,
  };

  return (
    <RequestResponseContext.Provider value={value}>{children}</RequestResponseContext.Provider>
  );
};

export { RequestResponseContext };
export default RequestResponseProvider;
