import numeral from 'numeral';
import { Box, Card, Typography } from '@mui/material';

interface TotalRevenueProps {
  value?: number;
  loading?: boolean;
  title: string;
  isCurrency?: boolean;
}

export default function TotalRevenue({ value, title, isCurrency }: TotalRevenueProps): JSX.Element {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" paragraph>
          {title}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {value !== undefined ? numeral(value).format(isCurrency ? '$ 0.00' : '') : '-'}
        </Typography>
      </Box>
    </Card>
  );
}
