import Typography from '@mui/material/Typography';
import numeral from 'numeral';

// App
import { InstructorType, RateListItemType } from 'types';

interface RateDetailsProps {
  instructor?: InstructorType;
}

export default function RateDetails({ instructor }: RateDetailsProps): JSX.Element {
  if (instructor?.id) {
    const rate = instructor.rate;
    const rate_list = instructor?.rate_list || [];

    return (
      <>
        <Typography component="span" variant="subtitle2" color="textPrimary" display="block">
          <b>{numeral(rate).format('$ 0.00')}</b> / question
        </Typography>

        {rate_list
          .sort((a: any, b: any) => a.rate - b.rate)
          .map((row: RateListItemType) => (
            <Typography
              component="span"
              variant="subtitle2"
              color="textPrimary"
              display="block"
              style={{ marginBottom: 3 }}
            >
              <b>{numeral(row.rate || 0).format('$ 0.00')}</b> / {row.duration} minute video call
            </Typography>
          ))}
      </>
    );
  }
  return <span />;
}
