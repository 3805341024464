import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import grey from '@mui/material/colors/grey';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles({
  center: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: grey[300],
  },
});

const Unavailable: FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container style={{ height: 500, backgroundColor: 'black' }}>
        <Grid item xs={12} className={classes.center}>
          <Typography variant="h4" align="center" className={classes.center}>
            <ErrorOutlineIcon style={{ fontSize: 50, marginRight: 10 }} /> File unavailable
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Unavailable;
