'use client';

import { filter } from 'lodash';
import { useState } from 'react';

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';

// routes
import Scrollbar from 'components/Common/Scrollbar';
import SearchNotFound from 'components/Common/SearchNotFound';
import TableListHead from './TableListHead';
import { TableComponentProps, SortOrderEnum } from './types';

type Anonymous = Record<string | number, string | any>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy]?.value < a[orderBy]?.value) {
    return -1;
  }
  if (b[orderBy]?.value > a[orderBy]?.value) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === SortOrderEnum.DESC
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any[], comparator: (a: any, b: any) => number, query: string) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, _user => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map(el => el[0]);
}

export default function TableComponent({ dataList, headerList, emptyNode }: TableComponentProps) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrderEnum>(SortOrderEnum.ASC);
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, _setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? SortOrderEnum.DESC : SortOrderEnum.ASC);
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = dataList.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;
  const dataFiltered = applySortFilter(dataList, getComparator(order, orderBy), filterName);
  const isEmpty = dataFiltered.length === 0;

  return (
    <Card sx={{ pt: 2 }}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={headerList}
              rowCount={dataList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                const isItemSelected = selected.indexOf(row.id) !== -1;
                return (
                  <TableRow
                    hover
                    key={row.id?.value}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} onClick={() => handleClick(row.id)} />
                    </TableCell> */}

                    {headerList.map(x => {
                      return x.id !== 'IGNORE' ? (
                        <TableCell key={x.id} align="left">
                          {row[x.id]?.display || row[x.id]?.value}
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isEmpty && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    {emptyNode || <SearchNotFound searchQuery={filterName} />}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={dataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_e, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
