import { FC, useEffect } from 'react';
import classnames from 'classnames';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';

// App
import { userAuth as userAuthApi, focus as focusApi, userFocus as userFocusApi } from 'store/index';
import { FocusType } from 'types/index';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
    minWidth: 100,
    height: 35,
    padding: 3,
  },

  fabNotSelected: {
    backgroundColor: 'white',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    color: theme.palette.primary.main,
  },

  gridContainer: {
    paddingTop: 30,
    paddingLeft: '15%',
    paddingRight: '15%',
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3%',
      paddingRight: '3%',
    },
  },
}));

const Focus: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const focusList = useSelector((state: any) => state[focusApi.APP_NAME].list);

  useEffect(() => {
    dispatch(focusApi.getRequest());
    dispatch(userFocusApi.getRequest());
  }, []);

  const { focus = [] } = user;
  const focusDict = focus.reduce((output: any, current: any) => {
    output[current.focus__id] = current;
    return output;
  }, {});

  const onSubmit = async (selected: FocusType, item: FocusType) => {
    try {
      if (selected) {
        await dispatch(userFocusApi.deleteRequest(selected.id));
        dispatch(userAuthApi.getDetailRequest('user'));
      } else {
        await dispatch(
          userFocusApi.postRequest({
            user: user.id,
            focus: item.id,
          }),
        );
        dispatch(userAuthApi.getDetailRequest('user'));
      }
    } catch (err) {
      log.exception(err);
    }
  };
  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" gutterBottom align="center">
          What are your interests
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        {focusList.map((f: FocusType) => {
          const selected = focusDict[f.id];
          return (
            <Fab
              key={f.id}
              variant="extended"
              color="primary"
              aria-label="add"
              className={classnames(classes.fab, { [classes.fabNotSelected]: !selected })}
              onClick={() => onSubmit(selected, f)}
            >
              {f.name}
            </Fab>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Focus;
