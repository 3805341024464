import { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import classnames from 'classnames';
import 'react-image-crop/dist/ReactCrop.css';

// Material UI
import { makeStyles, useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// App
import BusinessAvatar from 'containers/Business/components/Avatar';
import { business as businessApi } from 'store/index';
import { notification } from 'store/notification';
import { buttonPrimary, buttonSuccess, buttonDanger } from 'styles/classnames';
import { useDispatch, useBusiness } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },

  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  saveButton: {
    width: 140,
    [theme.breakpoints.down('sm')]: {
      width: '49%',
    },
  },

  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
  },

  gridContainer: {
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh',
      maxHeight: '80vh',
      width: '100%',
    },
  },
}));

interface ChangeProfileImageDialogProps {
  open: boolean;
  handleClose: VoidFunction;
}

function ChangeProfileImageDialog({
  open,
  handleClose,
}: ChangeProfileImageDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme: Theme = useTheme();
  const imageRef = useRef<HTMLImageElement>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { business, loading } = useBusiness();
  const [imageFile, setFile] = useState({
    file: null,
    object: '',
  });
  const [state, setState] = useState({
    scaleX: 1,
    scaleY: 1,
  });
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const logo = business?.logo;
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile({
      file: acceptedFiles[0],
      object: URL.createObjectURL(acceptedFiles[0]),
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const newProfile = imageFile.file;
  const extraProps: any = {};
  if (!fullScreen) {
    extraProps.maxWidth = 'md';
  }

  const renderReplace = (
    <div style={{ width: '100%' }} {...getRootProps()}>
      <BusinessAvatar
        initials={business?.name?.[0] || ''}
        size={200}
        src={imageFile.object || logo}
      />

      <input {...getInputProps()} />
      <Button
        variant="outlined"
        className={classnames(classes.saveButton, buttonPrimary, 'tw-mt-12 tw-py-2 tw-text-lg')}
        style={{ width: 200 }}
      >
        Replace
      </Button>
    </div>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      {...extraProps}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography variant="h6" align="left" display="block">
          Change Logo
        </Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} className={classes.avatarContainer}>
            {logo || newProfile ? (
              <ReactCrop
                src={imageFile.object || logo || ''}
                // @ts-ignore
                crop={crop}
                onChange={newCrop => {
                  // @ts-ignore
                  setCrop(newCrop);
                }}
                circularCrop
                ruleOfThirds
                crossorigin="anonymous"
              >
                <img
                  ref={imageRef}
                  src={imageFile.object || logo || ''}
                  onLoad={(e: any) => {
                    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
                    const _crop = centerCrop(
                      makeAspectCrop(
                        {
                          // You don't need to pass a complete crop into
                          // makeAspectCrop or centerCrop.
                          unit: '%',
                          width: 90,
                        },
                        1,
                        width,
                        height,
                      ),
                      width,
                      height,
                    );
                    setCrop(_crop);
                    setState({
                      scaleX: naturalWidth / width,
                      scaleY: naturalHeight / height,
                    });
                  }}
                />
              </ReactCrop>
            ) : (
              renderReplace
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classnames(
            classes.saveButton,
            buttonSuccess,
            'tw-mr-2 tw-mt-12 tw-w-36 tw-py-2 tw-text-lg',
          )}
          disabled={(!logo && !newProfile) || loading}
          onClick={() => {
            if (crop) {
              const data = new FormData();
              data.append('logo', newProfile || logo || '');
              data.append(
                'crop',
                JSON.stringify({
                  ...crop,
                  scaleX: state.scaleX,
                  scaleY: state.scaleY,
                }),
              );
              // @ts-ignore
              dispatch(businessApi.patchRequest(data, business?.uid || '')).then((resp: any) => {
                if (resp.error) {
                  dispatch(notification('Something went wrong', 'error'));
                } else {
                  handleClose();
                }
              });
            }
          }}
        >
          Save{' '}
          {loading && <CircularProgress style={{ marginLeft: 15, color: 'white' }} size={20} />}
        </Button>

        {!imageFile.object && !logo ? (
          <Button
            variant="outlined"
            className={classnames(
              classes.saveButton,
              buttonDanger,
              'tw-mr-2 tw-mt-12 tw-w-36 tw-py-2 tw-text-lg',
            )}
            onClick={handleClose}
          >
            Close
          </Button>
        ) : (
          <div className={classes.saveButton} {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              variant="outlined"
              className={classnames(
                classes.saveButton,
                buttonPrimary,
                'tw-mt-12 tw-w-36 tw-py-2 tw-text-lg',
              )}
            >
              Replace
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default function BusinessLogo(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { business } = useBusiness();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          align="left"
          style={{ marginBottom: 10 }}
        >
          Change Business Logo
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.avatarContainer}>
        <BusinessAvatar initials={business?.name?.[0] || ''} size={150} src={business?.logo} />

        <Button
          variant="outlined"
          className={classnames(classes.saveButton, buttonPrimary, 'tw-mt-12 tw-py-2 tw-text-lg')}
          onClick={() => setOpen(true)}
        >
          Edit Logo
        </Button>
      </Grid>

      {open && <ChangeProfileImageDialog handleClose={() => setOpen(false)} open={open} />}
    </Grid>
  );
}
