import { useState, FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { notification } from 'store/notification';
import {
  BusinessStructureType,
  BusinessIndustryEnum,
  BusinessServiceEnum,
  BusinessClientEnum,
  BusinessStaffEnum,
} from 'types/index';
import { business as businessApi } from 'store/index';
import { RenderHelpIcon } from 'components/Help/index';
import { log } from 'utils/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

const sectionList = [
  {
    title: 'What industry is your business in?',
    helpText: 'This is used to optimize your experience on the platform',
    label: 'Industry Type',
    field: 'industry',
    items: [
      {
        value: BusinessIndustryEnum.CLINIC,
        name: 'Clinic',
      },
      {
        value: BusinessIndustryEnum.DANCE,
        name: 'Dance',
      },
      {
        value: BusinessIndustryEnum.LIFE_COACH,
        name: 'Life Coach',
      },
      {
        value: BusinessIndustryEnum.SPORT,
        name: 'Sports',
      },
      {
        value: BusinessIndustryEnum.TUTOR,
        name: 'Tutoring',
      },

      {
        value: BusinessIndustryEnum.OTHER,
        name: 'Other',
      },
    ],
  },

  {
    title: 'How do you refer to your services?',
    helpText: 'This is used to optimize your experience on the platform',
    label: 'Service Type',
    field: 'service',
    items: [
      {
        value: BusinessServiceEnum.CLASS,
        name: 'Class',
      },

      {
        value: BusinessServiceEnum.SERVICE,
        name: 'Service',
      },
      {
        value: BusinessServiceEnum.SESSION,
        name: 'Session',
      },
      {
        value: BusinessServiceEnum.TREATMENT,
        name: 'Treatment',
      },

      {
        value: BusinessServiceEnum.OTHER,
        name: 'Other',
      },
    ],
  },

  {
    title: 'How do you refer to your staff?',
    helpText: 'This is used to optimize your experience on the platform',
    label: 'Staff Type',
    field: 'staff',
    items: [
      {
        value: BusinessStaffEnum.THERAPIST,
        name: 'Therapist',
      },
      {
        value: BusinessStaffEnum.TEACHER,
        name: 'Teacher',
      },
      {
        value: BusinessStaffEnum.TUTOR,
        name: 'Tutor',
      },
      {
        value: BusinessStaffEnum.COACH,
        name: 'Coach',
      },
      {
        value: BusinessStaffEnum.INSTRUCTOR,
        name: 'Instructor',
      },
      {
        value: BusinessStaffEnum.STAFF,
        name: 'Staff',
      },
      {
        value: BusinessStaffEnum.OTHER,
        name: 'Other',
      },
    ],
  },

  {
    title: 'How do you refer to your clients?',
    helpText: 'This is used to optimize your experience on the platform',
    label: 'Client Type',
    field: 'client',
    items: [
      {
        value: BusinessClientEnum.CLIENT,
        name: 'Client',
      },
      {
        value: BusinessClientEnum.CUSTOMER,
        name: 'Customer',
      },
      {
        value: BusinessClientEnum.GUEST,
        name: 'Guest',
      },
      {
        value: BusinessClientEnum.MEMBER,
        name: 'Member',
      },
      {
        value: BusinessClientEnum.PATIENT,
        name: 'Patient',
      },
      {
        value: BusinessClientEnum.STUDENT,
        name: 'Student',
      },
      {
        value: BusinessClientEnum.OTHER,
        name: 'Other',
      },
    ],
  },
];

interface SelectMenuItems {
  value: string;
  name: string;
}

interface SelectItemProps {
  title: string;
  helpText: string;
  label: string;
  field: string;
  state: BusinessStructureType;
  setState: (value: BusinessStructureType) => void;
  items: SelectMenuItems[];
}

function SelectItem({ state, setState, title, helpText, label, items, field }: SelectItemProps) {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h6"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-font-normal"
        >
          {title}
          <RenderHelpIcon text={helpText} />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id={`${label}-label`}>{label}</InputLabel>
          <Select
            labelId={`${label}-label`}
            id={`${label}-select`}
            // @ts-ignore
            value={state[field]}
            label={label}
            onChange={ev => setState({ ...state, [field]: ev.target.value })}
          >
            {items.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

const StructureConfig: FC = () => {
  const dispatch = useDispatch();
  const { business, loading } = useBusiness();

  const [state, setState] = useState<BusinessStructureType>({
    industry: business?.structure_config?.industry,
    service: business?.structure_config?.service,
    client: business?.structure_config?.client,
    staff: business?.structure_config?.staff,
  });

  const callback = (resp: any) => {
    if (resp.id) {
      dispatch(notification('The business structure configuration was updated', 'success'));
    } else if (resp.error) {
      const msg = (resp.data && resp.data.error) || resp.error;
      dispatch(notification(msg, 'error'));
    }
  };

  const apiPatchRequest = async () => {
    try {
      const structure_config: BusinessStructureType = {
        ...(business?.structure_config || {}),
        ...state,
      };
      const resp = await dispatch(
        businessApi.patchRequest(
          {
            structure_config,
          },
          business?.uid || '',
        ),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <Grid container spacing={2} className="tw-mt-4">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-text-2xl tw-font-medium"
        >
          Manage Business Structure
          {loading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
        </Typography>

        <Typography
          variant="subtitle1"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-text-xl tw-font-normal"
        >
          This section configures how your business refers to it's staff, clients, services and
          industry
        </Typography>
      </Grid>

      {sectionList.map(item => (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <SelectItem key={item.field} {...item} state={state} setState={setState} />
        </>
      ))}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item sm={12} md={6} className="tw-align-start tw-mt-5 tw-flex tw-justify-start">
        <SaveButton onClick={apiPatchRequest} />
      </Grid>
    </Grid>
  );
};

export default StructureConfig;
