import { useState } from 'react';
import { Link } from 'react-router-dom';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Alert, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import isEmail from 'validator/lib/isEmail';

import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';
import { useAuth } from 'hooks';
import { useEffect } from 'react';
import { API_URL } from 'utils/constants';
import Axios, { getHeaders } from 'utils/axios';
import AuthLayout from './layout';

export default function PasswordResetPage() {
  const { setCredentials } = useAuth();

  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const headers = getHeaders();

  const passwordReset = async () => {
    if (!email) {
      setError('The email field is required.');
      return;
    }

    if (!isEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      setError(null);
      const resp = await Axios({
        // url: `${API_URL}/api/v1/rest-auth/password/reset/`,
        url: `${API_URL}/api/accounts/browser/v1/auth/password/request`,
        method: 'POST',
        data: { email },
        headers,
      });
      console.log(resp.data);
      setIsComplete(true);
    } catch (err) {
      const { email, non_field_errors } = err.response.data;
      if (email) setError(email[0]);
      else if (non_field_errors) setError(non_field_errors[0]);
      else setError('Oops sorry. Something went wrong.');
    }
  };

  useEffect(() => {
    setCredentials({});
  }, []);

  function onKeyDown(e: any) {
    if (e.keyCode === 13) {
      passwordReset();
    }
  }

  let renderErrorAll;
  if (error) {
    renderErrorAll = (
      <Typography
        id="error-text"
        variant="body2"
        gutterBottom
        color="error"
        className="tw-mb-4 tw-text-md"
      >
        {error}
      </Typography>
    );
  }

  let renderComplete;
  if (isComplete) {
    renderComplete = (
      <Alert
        icon={<CheckIcon fontSize="inherit" className="tw-text-white" />}
        severity="success"
        className="tw-mb-4 tw-bg-green-600 tw-text-white"
      >
        We've sent you an e-mail with instructions on how to reset your password.
      </Alert>
    );
  }

  return (
    <AuthLayout>
      <h1 className="tw-text-center tw-text-4xl tw-font-semibold tw-text-slate-900">
        Reset your password
      </h1>
      <p className="tw-mx-auto tw-mt-4 tw-max-w-md tw-text-center tw-leading-relaxed tw-text-slate-600">
        Enter your email below and we will send you an email with instruction to reset your
        password.
      </p>

      <div className="tw-mt-10 tw-w-full">
        {renderErrorAll}

        {renderComplete}
        <form action="#" method="POST">
          <div>
            <label
              htmlFor="email"
              className="tw-block tw-text-base tw-font-medium tw-text-slate-700"
            >
              Email
            </label>
            <div className="tw-relative tw-mt-1.5">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                placeholder="Enter recovery email"
                startAdornment={
                  <EnvelopeIcon className="tw-absolute tw-left-4 tw-top-1/2 tw-h-5 tw-w-5 tw--translate-y-1/2 tw-text-slate-400" />
                }
                inputProps={{
                  type: 'email',
                  onChange: (ev: any) => {
                    setEmail(ev.target.value);
                  },
                  value: email,
                  variant: 'outlined',
                  onKeyDown,
                }}
              />
            </div>
          </div>

          <Button className="tw-mt-6 tw-w-full" onClick={passwordReset}>
            Reset password
          </Button>
        </form>
        <p className="tw-mt-4 tw-text-center tw-leading-relaxed tw-text-slate-600">
          Remembered your password?
          <Link
            to="/signin"
            className="tw-ml-1.5 tw-font-medium tw-text-slate-800 tw-underline tw-duration-150 hover:tw-text-slate-900"
          >
            Sign in
          </Link>
          .
        </p>
      </div>
    </AuthLayout>
  );
}
