import { useState } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// App
import { CustomTextField } from 'components/Input/index';
import { notification } from 'store/notification';
import Axios from 'utils/axios';
import { useDispatch } from 'hooks';
import { API_URL } from 'utils/constants';
import { SaveButton } from 'components/Common/Button';

export default function ChangePassword(): JSX.Element {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    old_password: '',
    password1: '',
    password2: '',
  });
  const [error, setError] = useState({});

  const textFieldProps = {
    state,
    error,
    setState,
  };

  const textProps = {
    type: 'password',
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          align="left"
          style={{ marginBottom: 10 }}
        >
          Change Password
        </Typography>
      </Grid>

      <CustomTextField
        field="old_password"
        placeholder="Old Password"
        {...textFieldProps}
        textProps={textProps}
        lg={12}
        md={12}
      />

      <CustomTextField
        field="password1"
        placeholder="Password"
        {...textFieldProps}
        textProps={textProps}
        lg={12}
        md={12}
      />

      <CustomTextField
        field="password2"
        placeholder="Confirm Password"
        {...textFieldProps}
        textProps={textProps}
        lg={12}
        md={12}
      />

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SaveButton
          onClick={() => {
            setError({});
            const data = {
              old_password: state.old_password,
              new_password1: state.password1,
              new_password2: state.password2,
            };
            Axios({
              method: 'post',
              url: `${API_URL}/api/v1/rest-auth/password/change/`,
              data,
            })
              .then(() => {
                dispatch(notification('The password has been updated', 'success'));
              })
              .catch(err => {
                const tempError: any = {};
                const errData = err?.response?.data;

                if (errData) {
                  if (errData.new_password1) {
                    tempError.password1 = errData.new_password1;
                    tempError.empty = false;
                  }

                  if (errData.new_password2) {
                    tempError.password2 = errData.new_password2;
                    tempError.empty = false;
                  }

                  if (errData.old_password) {
                    tempError.old_password = errData.old_password;
                    tempError.empty = false;
                  }
                }

                if (!tempError.empty) {
                  setError(tempError);
                  return;
                }
                dispatch(notification('Something went wrong', 'error'));
              });
          }}
        />
      </Grid>
    </Grid>
  );
}
