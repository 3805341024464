import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Theme } from '@mui/material/styles';

// App
import { EditNotifications } from 'components/Instructor/Configure';
import MediaLink from 'components/User/MediaLink';
import ChangeProfileImage from 'components/User/ChangeProfileImage';
import UpdateBio from 'components/Bio/Edit';
import ChangePassword from 'components/User/ChangePassword';
import ChangeDetails from 'components/User/ChangeDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  instructorRoot: {
    marginBottom: 10,
  },
}));

function ConfigureNotifications({}) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.instructorRoot}>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Notifications
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.instructorRoot}>
        <EditNotifications />
      </Grid>
    </Grid>
  );
}

export default function GeneralProfile(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ChangeProfileImage />

      <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

      <ChangeDetails />

      <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

      <UpdateBio />

      <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

      <ConfigureNotifications />

      <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

      <MediaLink />

      <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

      <ChangePassword />
    </div>
  );
}
