import axios from 'axios';
import { BASE_DOMAIN } from './constants';
import Cookies from 'js-cookie';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? `https://${BASE_DOMAIN}` : '';
export default axios;

export const getHeaders = () => {
  const headers = {
    'X-CSRFToken': Cookies.get('csrftoken'),
  };
  return headers;
};
