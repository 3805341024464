import { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

// App
import Axios from 'utils/axios';
import { userAuth as userAuthApi } from 'store/index';
import { onChange as appOnChange } from 'store/app';
import { notification } from 'store/notification';
import { buttonPrimary, buttonSecondary, card } from 'styles/classnames';
import { useDispatch, useSelector } from 'hooks';
import { API_URL, APP_HUMAN_NAME, BASE_DOMAIN } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '15%',
    paddingRight: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3%',
      paddingRight: '3%',
    },
  },
}));

export default function UsernameOnboard(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const preferredUsername = useSelector((state: any) => state?.app?.preferredUsername);
  const [username, setUsername] = useState(preferredUsername || user.username);
  const [error, setError] = useState('');

  return (
    <div className={classes.gridContainer}>
      <div className={classnames(card, 'tw-w-full tw-bg-white tw-px-4 tw-py-24 md:tw-px-16')}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" style={{ marginBottom: 7 }} display="block">
              Set your {APP_HUMAN_NAME} url
            </Typography>

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Username</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={username}
                onChange={ev => {
                  setUsername((ev.target.value || '').replace(/\s/g, ''));
                }}
                startAdornment={<InputAdornment position="start">{BASE_DOMAIN}/</InputAdornment>}
                error={!!error}
              />
              <Typography
                variant="body1"
                display="block"
                className="tw-mt-2 tw-font-medium tw-text-blue-800"
              >
                <a href={`https://${BASE_DOMAIN}/${user.username}`} target="_blank">
                  {BASE_DOMAIN}/{user.username}{' '}
                </a>
              </Typography>

              {error && (
                <Typography
                  variant="subtitle1"
                  display="block"
                  className="tw-mt-2 tw-font-normal tw-text-red-500"
                >
                  {error}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} className="tw-align-start tw-mt-2 tw-flex tw-justify-start">
            <Button
              variant="outlined"
              className={classnames(
                buttonPrimary,
                'tw-text-lg tw-py-2 tw-w-full md:tw-w-[250px] tw-mr-1',
              )}
              onClick={() => {
                setError('');
                Axios({
                  method: 'patch',
                  url: `${API_URL}/api/v1/rest-auth/user/`,
                  data: { username },
                })
                  .then(() => {
                    dispatch(userAuthApi.getDetailRequest('user'));
                    dispatch(notification('Your username has been updated', 'success'));
                    dispatch(appOnChange('preferredUsername', null));
                  })
                  .catch(err => {
                    const errData = err.response.data;
                    if (errData.username) {
                      setError(errData.username);
                    } else {
                      dispatch(notification('Something went wrong', 'error'));
                    }
                  });
              }}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              className={classnames(
                buttonSecondary,
                'tw-text-lg tw-py-2 tw-w-full md:tw-w-[250px] tw-ml-1',
              )}
              component={Link}
              to="/onboard-3"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
