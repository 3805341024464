// import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import classnames from 'classnames';

// App
import { buttonPrimary } from 'styles/classnames';
import MemberTable from './MemberTable';

export default function Dashboard(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <Link to="/client/add">
        <Button
          variant="contained"
          className={classnames(buttonPrimary, 'tw-mb-4 tw-w-full tw-py-2 tw-text-base md:tw-w-32')}
        >
          Add Client
        </Button>
      </Link>
      <MemberTable />
    </Container>
  );
}
