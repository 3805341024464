import React from 'react';

// Material
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

// App
import { UserAvatar } from 'components/Common/Avatar';
import Header from 'components/Header/Header';
import { userAuth as userAuthApi } from 'store/index';
import profileImage from 'images/mbele-icon.png';
import { NavLink } from './common';
import styles from 'styles/navbarsStyle';
import { useSelector } from 'hooks';
import { APP_URL, LANDING_PAGE_URL } from 'utils/constants';

const useStyles = makeStyles(styles);

function RenderRightLinks({ handleDrawerClose }) {
  const classes = useStyles();
  const userAuthDetail = useSelector(state => state[userAuthApi.APP_NAME].detail);
  const appConfig = useSelector(state => state.app.config) || {};

  if (userAuthDetail.id) {
    return (
      <List
        className={classes.list}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {appConfig.app !== 'main' ? (
          <>
            <NavLink
              path={APP_URL}
              title="Dashboard"
              handleDrawerClose={handleDrawerClose}
              external
            />
          </>
        ) : null}

        <ListItem
          className={classes.listItem}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography variant="body1" style={{ color: 'black', marginRight: 8 }} display="inline">
            Hi, {userAuthDetail.username}
          </Typography>
          <UserAvatar user={userAuthDetail} size={40} />
        </ListItem>
      </List>
    );
  }

  return null;
}

function Navbar(props) {
  const classes = useStyles();
  const { color } = props;
  const userAuthDetail = useSelector(state => state[userAuthApi.APP_NAME].detail);

  return (
    <Header
      brand={
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <a id="navbar-brand-link" href={LANDING_PAGE_URL}>
              <img src={profileImage} className={classes.img} alt="profile" />
            </a>
          </ListItem>
        </List>
      }
      color={color}
      rightLinks={handleDrawerClose => <RenderRightLinks handleDrawerClose={handleDrawerClose} />}
      leftLinks={() => {
        if (!userAuthDetail.id) return null;
        return null;
      }}
      {...props}
    />
  );
}

export default Navbar;
