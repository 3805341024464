import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Material ui
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';

// App
import Button from 'components/Elements/Button';
import { CircularLoading } from 'components/Loading/index';
import FileManageListPage from 'containers/File/manage-list';
import { board as boardApi } from 'store/index';
import { useDispatch, useSelector } from 'hooks';
import { BoardType } from 'types';

type BoardIdType = number | string;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'auto',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  iconPaperRoot: {
    width: '100%',
    padding: '0px 10px',
    display: 'flex',
  },

  folderDetailContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12px !important',
      paddingBottom: '0px !important',
    },
  },

  folderButtons: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface FolderProps {
  folder: BoardType;
  onClose: (value?: any) => void;
}

function ManageFolder({ folder, onClose }: FolderProps) {
  const dispatch = useDispatch();
  const [state, setState] = useState({ ...folder });

  return (
    <Grid container spacing={2} style={{ marginTop: 20, padding: '0px 10px' }}>
      <Paper style={{ width: '100%', padding: 16 }} elevation={0} variant="outlined">
        <Typography variant="h5" gutterBottom align="left" style={{ marginBottom: 30 }}>
          {folder.id ? `Edit ${folder.name}` : 'Create New'} Folder
        </Typography>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="outlined-basic"
            label="Folder Name (required)"
            variant="outlined"
            onChange={ev => setState({ ...state, name: ev.target.value })}
            value={state.name}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />

          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            onChange={ev => setState({ ...state, description: ev.target.value })}
            value={state.description}
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            multiline
            style={{ marginTop: 10 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="danger"
            style={{ width: '49%', marginRight: '1%' }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ width: '49%', float: 'right' }}
            onClick={() => {
              if (folder && folder.id) {
                dispatch(
                  boardApi.patchRequest(
                    {
                      name: state.name,
                      description: state.description,
                    },
                    folder.id,
                  ),
                  // @ts-ignore
                ).then(() => onClose());
              } else {
                dispatch(
                  boardApi.postRequest({
                    name: state.name,
                    description: state.description,
                  }),
                  // @ts-ignore
                ).then(() => {
                  onClose();
                });
              }
            }}
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}

function RenderTabContent({ boardId }: { boardId: BoardIdType }) {
  if (!boardId || boardId === 'current_board') return <CircularLoading />;

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FileManageListPage boardId={boardId} />
      </Grid>
    </Grid>
  );
}

function FolderItem({ item, folderClick }: { item: BoardType; folderClick: VoidFunction }) {
  return (
    <Link
      to={`/folder/${item.id}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
      onClick={folderClick}
      key={item.id}
    >
      <ListItem button>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{
            noWrap: true,
            style: {
              width: 300,
            },
          }}
        />
      </ListItem>
    </Link>
  );
}

export default function FolderContainer(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [drawerOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [boardId, setBoardId] = useState<BoardIdType>(
    params.id ? Number(params.id) : 'current_board',
  );

  const api = boardApi;
  const list = useSelector((state: any) => state[api.APP_NAME].list);
  const detail = useSelector((state: any) => state[api.APP_NAME].detail);
  const initialized = useSelector((state: any) => state[api.APP_NAME].initialized);

  useEffect(() => {
    dispatch(api.reset());
    dispatch(api.setGetParams({ limit: 50 }));
    dispatch(api.getRequest());
    // @ts-ignore
    dispatch(api.getDetailRequest(boardId)).then((resp: any) => {
      if (boardId === 'current_board' && resp.id) {
        setBoardId(resp.id);
      }
    });
  }, []);

  const handleDrawerToggle = () => {
    setIsOpen(!drawerOpen);
  };

  if (!initialized) {
    return (
      <Grid container spacing={2} style={{ marginTop: 20, padding: '0px 10px' }}>
        <Paper style={{ width: '100%', padding: 16 }} elevation={0} variant="outlined">
          <Typography variant="h6" display="block">
            Loading ...
          </Typography>

          <LinearProgress variant="indeterminate" style={{ marginTop: 10 }} />
        </Paper>
      </Grid>
    );
  }

  const renderTitle = (
    <Grid container justifyContent="center" spacing={2} style={{ marginBottom: 0 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper className={classes.iconPaperRoot} elevation={0} variant="outlined">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} md={8} lg={8} className={classes.folderDetailContainer}>
              <Typography variant="h6" display="block" style={{ paddingRight: 16 }}>
                {detail.name}
              </Typography>

              {detail.id && detail.description ? (
                <Typography
                  variant="subtitle1"
                  display="block"
                  style={{ paddingRight: 16, paddingTop: 8 }}
                >
                  {detail.description}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} className={classes.folderButtons}>
              {!isAdd && detail.id && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '48%', height: 40 }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                style={{ width: '48%', float: 'right', height: 40 }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Change Folder
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <div className={classes.root}>
      {!isAdd ? renderTitle : null}

      <Drawer variant="temporary" anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          <ListItem
            button
            onClick={() => {
              setIsEdit(false);
              setTimeout(() => setIsAdd(true), 200); // Need time to reset
              setIsOpen(false);
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add Folder" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            <ListItemText primary="Close" />
          </ListItem>

          <Divider component="li" />

          {list.map((item: BoardType) => (
            <FolderItem
              item={item}
              folderClick={() => {
                dispatch(api.resetDetail());
                setBoardId('');
                // Need to refresh the videos
                // @ts-ignore
                dispatch(api.getDetailRequest(item.id)).then(() => {
                  setBoardId(item.id);
                });
                handleDrawerToggle();
                setIsEdit(false);
                setIsAdd(false);
              }}
            />
          ))}
        </List>
      </Drawer>

      {isEdit || isAdd ? (
        <ManageFolder
          folder={isEdit ? detail : {}}
          onClose={nF => {
            let bId = boardId;
            if (nF && nF.id) {
              bId = nF.id;
              setBoardId(nF.id);
            }

            // @ts-ignore
            dispatch(api.getDetailRequest(bId)).then(() => {
              navigate(`/folder/${bId}`);
              setIsEdit(false);
              setIsAdd(false);
            });
          }}
        />
      ) : (
        <RenderTabContent boardId={boardId} />
      )}
    </div>
  );
}
