import React from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

// App
import Button from 'components/Elements/Button';
import moneyImg from 'images/money-v1.png';
import { userAuth as userAuthApi, instructor as instructorApi } from 'store/index';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles(theme => ({
  paperRoot: {
    marginTop: 30,
    width: '100%',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      height: 500,
      paddingTop: 50,
      paddingBottom: 30,
    },
  },

  img: {
    width: 200,
    height: 200,
  },

  buttonActivate: {
    width: 450,
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },

  gridContainer: {
    paddingRight: '5%',
    paddingLeft: '5%',
  },
}));

export default function ActivateInstructor({ paperProps = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state[userAuthApi.APP_NAME].detail);
  const userLoading = useSelector(state => state[userAuthApi.APP_NAME].loading);
  const instructorLoading = useSelector(state => state[instructorApi.APP_NAME].loading);
  const loading = userLoading || instructorLoading;

  return (
    <Paper className={classes.paperRoot} elevation={0} variant="outlined" {...paperProps}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" gutterBottom align="center">
            Want to get paid?
          </Typography>

          <Typography variant="h6" align="center" style={{ fontWeight: 400, marginTop: 15 }}>
            Set your rate, and get paid whenever you respond to feedback requests.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} align="center">
          <img src={moneyImg} className={classes.img} alt="get paid" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 10 }}>
            <Button
              id="button-activate-instructor"
              variant="contained"
              color="primary"
              className={classes.buttonActivate}
              fontSize={16}
              disabled={loading}
              onClick={() => {
                dispatch(instructorApi.postRequest({ instructor_id: user.id })).then(() => {
                  dispatch(userAuthApi.getDetailRequest('user'));
                  navigate('/get-paid?showGuide');
                });
              }}
            >
              Click to activate{' '}
              {loading && <CircularProgress style={{ marginLeft: 15, color: 'white' }} size={20} />}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
