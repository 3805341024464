import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import EditLink from 'containers/Business/components/EditLink';
import ChangeBusinessLogo from 'containers/Business/components/ChangeBusinessLogo';
import EditInfo from 'containers/Business/components/EditInfo';
import FinancialConfig from 'containers/Business/components/FinancialConfig';
import FeatureConfig from 'containers/Business/components/FeatureConfig';
import TaxonomyConfig from 'containers/Business/components/TaxonomyConfig';
import CallConfig from 'containers/Business/components/CallConfig';
import { CardLoading } from 'components/Loading/index';
import { useBusiness } from 'hooks';
import { ContentCard } from 'layouts/dashboard/Content';

export default function AccountPage(): JSX.Element {
  const { initialized, business } = useBusiness();

  if (!initialized) {
    return (
      <ContentCard>
        <CardLoading />
      </ContentCard>
    );
  }

  if (!business?.uid) {
    return (
      <ContentCard>
        <Alert severity="error">The business was not found.</Alert>
      </ContentCard>
    );
  }

  return (
    <>
      <ContentCard>
        <ChangeBusinessLogo />

        <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

        <EditInfo />

        <Divider light style={{ marginBottom: 40, marginTop: 40 }} />

        <EditLink />
      </ContentCard>

      <ContentCard>
        <FinancialConfig />
      </ContentCard>

      <ContentCard>
        <FeatureConfig />
      </ContentCard>

      <ContentCard>
        <CallConfig />
      </ContentCard>

      <ContentCard>
        <TaxonomyConfig />
      </ContentCard>
    </>
  );
}
