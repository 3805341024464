import { FC, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import parse from 'html-react-parser';

import './view.scss';

interface BioProps {
  bio?: string;
}

const Bio: FC<BioProps> = ({ bio }) => {
  const [readMore, setReadMore] = useState(false);
  if (bio) {
    if ((bio || '').length < 50) {
      return (
        <Typography
          variant="body2"
          gutterBottom
          display="block"
          className="bio-content tw-mt-3 tw-text-base"
        >
          {parse(bio || '')}
        </Typography>
      );
    }

    return (
      <>
        <Collapse in={readMore} collapsedSize={55}>
          <Typography
            variant="body2"
            gutterBottom
            display="block"
            className="bio-content tw-mt-3 tw-text-base"
          >
            {parse(bio || '')}
          </Typography>
        </Collapse>
        {bio?.length > 250 ? (
          <Button
            color="primary"
            onClick={() => setReadMore(!readMore)}
            style={{
              marginTop: 5,
              textTransform: 'lowercase',
            }}
            className="tw-w-24"
          >
            {readMore ? 'close' : 'read More'}
          </Button>
        ) : null}
      </>
    );
  }
  return null;
};

export default Bio;
