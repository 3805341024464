import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'react-virtualized/dist/es/List';
import { CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

// Material UI
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';

// App
import { userAuth as userAuthApi, video as videoApi } from 'store/index';
import PlayerComponent from 'containers/File/components/Player';
import FileFeedbackComponent from 'containers/File/components/FileFeedback';
import FileUnavailableComponent from 'containers/File/components/Unavailable';
import AuthButton from 'containers/Auth/components/Modal';
import UsernameLink from 'components/User/UsernameLink';
import { buttonPrimary } from 'styles/classnames';
import './style.scss';

function VideoSegmentComponent({ video, initializePage }) {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <img
          src={video.thumbnail}
          style={{ objectFit: 'cover', width: 208, height: 130 }}
          onClick={() => {
            navigate(`/video/${video.id}`);
            initializePage(video.id);
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
    </Grid>
  );
}

class PlayerContainer extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      expandedSection: null,
      player: {},
      loop: {},
      tabValue: 0,
      isCommenting: false,
      sliderStart: 0,
      sliderStop: 10,
    };

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 200,
    });

    this._initializePage(props.match && props.match.params && props.match.params.id);
  }

  _initializePage = videoId => {
    const { videoGetDetailRequest, videoSetGetParams, videoGetRequest } = this.props;
    videoGetDetailRequest(videoId);
    videoSetGetParams({ board_id: 751 });
    videoGetRequest();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expandedSection !== this.state.expandedSection) {
      this.virtualizedList.recomputeRowHeights();
    }

    if (prevState.loop.uuid !== this.state.loop.uuid) {
      this.virtualizedList.forceUpdateGrid();
    }
  }

  seek = time => {
    this._player.seek(time);
  };

  stopLoop = () => {
    this.setState({ loop: {} });
    this.pause();
  };

  pause = () => {
    this._player.pause();
  };

  renderVideoRow = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style,
  }) => {
    const video = this.props.videoList[index];

    return (
      <div key={key} style={style}>
        <VideoSegmentComponent video={video} initializePage={this._initializePage} />
      </div>
    );
  };

  renderVideoList() {
    const { videoList } = this.props;
    return (
      <List
        ref={node => (this.virtualizedList = node)}
        width={400}
        height={777 - 200}
        rowCount={videoList.length}
        rowHeight={170}
        rowRenderer={this.renderVideoRow}
        overscanRowCount={12}
      />
    );
  }

  renderBottomRight() {
    const { videoDetail = {}, currentAuthDetail = {} } = this.props;
    const username = videoDetail.created_by ? videoDetail.created_by.username : '';

    return (
      <Grid container justifyContent="center">
        <Grid item xs={6} sm={6} md={8} lg={8}>
          <Typography variant="h5" gutterBottom>
            {videoDetail.title}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            <UsernameLink username={username} /> - {videoDetail.created_at_humanize}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={4}>
          {currentAuthDetail.id ? (
            <Button
              variant="outlined"
              className={classnames(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg')}
              onClick={() => {
                this.setState({ isCommenting: true });
              }}
            >
              Get Feedback
            </Button>
          ) : (
            <AuthButton
              title="Sign In & Get Feedback"
              onSuccess={() => {
                this._initializePage(this.props.match.params && this.props.match.params.id);
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  renderBottomComments() {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 20 }}>
          <Typography variant="subtitle1" gutterBottom>
            No feedback requested or given
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderViewBottom() {
    const { classes, videoDetail = {} } = this.props;
    const username = videoDetail.created_by ? videoDetail.created_by.username : '';

    return (
      <div className={classes.gridContainer}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={9} lg={9}>
            {this.renderBottomRight()}

            <Divider light />

            {this.renderBottomComments()}
          </Grid>

          <Hidden smDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.08)' }}
            >
              <Typography variant="h6" gutterBottom style={{ marginTop: 10 }}>
                @{username} Shared Videos
              </Typography>

              {this.renderVideoList()}
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }

  render() {
    if (this.props.videoInitialized && this.props.videoErrorDetail) {
      return <FileUnavailableComponent />;
    }

    return (
      <div style={{ marginBottom: 100 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PlayerComponent
              ref={node => (this._player = node)}
              loop={this.state.loop}
              start={this.state.start}
              setParentState={data => this.setState(data)}
              videoDetail={this.props.videoDetail}
              postVideo={this.props.postVideo}
              stopLoop={this.stopLoop}
            />
          </Grid>
        </Grid>

        {this.state.isCommenting ? (
          <FileFeedbackComponent
            setParentState={data => this.setState(data)}
            setIsCommenting={value => this.setState({ isCommenting: value })}
            sliderStart={this.state.sliderStart}
            sliderStop={this.state.sliderStop}
            loop={this.state.loop}
            seek={this.state.seek}
            videoDetail={this.props.videoDetail}
            handleClose={this.setState({ isCommenting: false })}
          />
        ) : (
          this.renderViewBottom()
        )}
      </div>
    );
  }
}

PlayerComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapDispatchToProps = (dispatch, _props) => {
  return {
    videoReset: () => dispatch(videoApi.reset()),
    videoGetRequest: (url, append) => dispatch(videoApi.getRequest(url, append)),
    videoGetDetailRequest: id => dispatch(videoApi.getDetailRequest(id)),
    videoSetGetParams: data => dispatch(videoApi.setGetParams(data)),
  };
};

const mapStateToProps = (state, props) => {
  return {
    ...createStructuredSelector({
      videoDetail: videoApi.makeSelectBase('detail'),
      videoErrorDetail: videoApi.makeSelectBase('errorDetail'),
      videoList: videoApi.makeSelectBase('list'),
      videoLoading: videoApi.makeSelectBase('loading'),
      videoInitialized: videoApi.makeSelectBase('initialized'),

      currentAuthDetail: userAuthApi.makeSelectBase('detail'),
    })(state, props),
  };
};

const styles = theme => ({
  gridContainer: {
    display: 'flex',
    flex: 1,
    paddingLeft: '15%',
    paddingRight: '15%',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(PlayerContainer));
