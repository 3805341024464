import { useState } from 'react';
import numeral from 'numeral';
import { Theme } from '@mui/material/styles';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// App
import { PricingTable, PricingSlot, PricingDetail } from 'framework/react-pricing-table';
import { userAuth as userAuthApi, deposit as depositApi } from 'store/index';
import { notification } from 'store/notification';
import StyledContainer from './pricing.style';
import { useDispatch, useSelector, useBusiness } from 'hooks';
import log from 'utils/log';

const useStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: '80vh',
    paddingLeft: '10%',
    paddingRight: '10%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },

  gridContainer: {
    marginBottom: 20,
  },
}));

interface TopUpProps {
  onSuccess?: VoidFunction;
  selectedValue?: number;
}

export default function TopUp({ selectedValue, onSuccess }: TopUpProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { business } = useBusiness();
  const [value, setValue] = useState<number | undefined>(selectedValue);
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const buttonClass =
    'tw-bg-blue-600 tw-hover:bg-blue-700 tw-text-white tw-text-lg tw-font-bold tw-py-2 tw-px-4 tw-mt-4 tw-rounded';

  if (business?.financial_config?.external_billing || business?.financial_config?.disable_billing) {
    return <div />;
  }

  const items = [
    {
      value: 25,
      title: 'Once off',
      subtitle: 'When you just need quick feedback or to try it out.',
    },
    {
      value: 50,
      title: 'Moderate',
      subtitle: 'You are looking to improve at your own pace.',
    },
    {
      value: 150,
      title: 'Frequent',
      subtitle: 'You are looking to improve quickly and ask for feedback often.',
    },
  ];

  return (
    <PayPalScriptProvider
      options={{ clientId: window.PAYPAL_CLIENT_ID, currency: 'CAD', components: 'buttons' }}
    >
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom align="center" style={{ marginTop: 30 }}>
            Your current balance is{' '}
            {numeral(user.balance ? user.balance.available_amount : 0).format('$ 0.00')}.
          </Typography>

          {onSuccess ? (
            <Typography variant="h6" gutterBottom align="center">
              Top up your account to ask questions or schedule a video call
            </Typography>
          ) : null}

          {!selectedValue ? (
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              display="block"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              {value
                ? `${numeral(value || 0).format('$ 0.00')} selected`
                : 'Select amount to top up'}
            </Typography>
          ) : null}

          {!selectedValue ? (
            <StyledContainer>
              <PricingTable>
                {items.map(row => (
                  <PricingSlot
                    key={row.value}
                    highlighted={value === row.value}
                    onClick={() => setValue(row.value)}
                    buttonText="Select"
                    title={row.title}
                    priceText={`$${row.value}`}
                    buttonClass={buttonClass}
                  >
                    <PricingDetail>{row.subtitle}</PricingDetail>
                  </PricingSlot>
                ))}
              </PricingTable>
            </StyledContainer>
          ) : null}
        </Grid>

        {value && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 30 }}>
              Complete {numeral(value || 0).format('$ 0.00')} Payment With Paypal{' '}
            </Typography>

            <div style={{ width: '100%', textAlign: 'center' }}>
              <PayPalButtons
                createOrder={(_data: any, actions: any) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value,
                          currency_code: 'CAD',
                        },
                      },
                    ],
                  });
                }}
                onApprove={(_data: any, actions: any) => {
                  return actions.order.capture().then((details: any) => {
                    if (details.status && details.status === 'COMPLETED') {
                      const amount =
                        (details.purchase_units[0] &&
                          details.purchase_units[0].amount &&
                          details.purchase_units[0] &&
                          details.purchase_units[0].amount) ||
                        {};
                      const data = {
                        response_data: details,
                        deposit_type: 'PAYPAL',
                        amount: amount.value || value,
                        currency: amount.currency_code,
                      };
                      dispatch(depositApi.postRequest(data))
                        // @ts-ignore
                        .then(resp => {
                          // @ts-ignore
                          dispatch(userAuthApi.getDetailRequest('user')).then(() => {
                            onSuccess && onSuccess();
                          });
                          if (resp.id) {
                            dispatch(
                              notification(
                                `${numeral(value).format(
                                  '$ 0,0.00',
                                )} was successfully added to your account`,
                                'success',
                              ),
                            );
                          } else if (resp.error) {
                            if (resp.data) {
                              dispatch(notification(resp.data.error || resp.message, 'error'));
                              return;
                            }
                            dispatch(notification(resp.message || 'Something went wrong', 'error'));
                          }
                        })
                        .catch((err: any) => {
                          log.exception(err);
                        });
                    } else {
                      alert('Something went wrong');
                    }
                  });
                }}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </PayPalScriptProvider>
  );
}

interface TopUpModalProps {
  handleClose: VoidFunction;
  onSuccess: VoidFunction;
  value?: number;
}

export function TopUpModal({ value, handleClose, onSuccess }: TopUpModalProps): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog fullScreen open onClose={handleClose}>
      <div className={classes.modalRoot}>
        <IconButton
          edge="start"
          onClick={handleClose}
          aria-label="close"
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
        >
          <CloseIcon />
        </IconButton>

        <div style={{ marginTop: 50 }}>
          <TopUp onSuccess={onSuccess} selectedValue={value} />
        </div>
      </div>
    </Dialog>
  );
}
