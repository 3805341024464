import { FC, useState, createContext } from 'react';
import { enqueueSnackbar } from 'notistack';
import log from 'loglevel';
import Cookies from 'js-cookie';

// App
import { UserAuthType } from 'types/api';
import Axios, { getHeaders } from 'utils/axios';
import { userAuth as userAuthApi } from 'store/index';
import { useDispatch } from 'hooks';
import { API_URL, APP_URL, LANDING_PAGE_URL } from 'utils/constants';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';

interface ErrorType {
  all?: string;
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
  emailReset?: string;
}

interface CredentialsType {
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
}

interface PasswordResetType {
  email?: string;
  isComplete?: boolean;
  message?: string;
}

type IState = {
  user?: UserAuthType;
  error: ErrorType;
  credentials: CredentialsType;
  isLoading: boolean;
  passwordResetState: PasswordResetType;
};

export type IDispatch = {
  setCurrentUser: (user: UserAuthType) => void;
  setError: (error: ErrorType) => void;
  logout: () => void;
  handleAuthenticate: (isLogin?: boolean, onSuccess?: VoidFunction) => Promise<any>;
  setCredentials: (value: CredentialsType) => void;
  setPasswordResetState: (value: PasswordResetType) => void;
  handleSocialAuth: (
    access_token: string,
    app: string,
    isLogin?: boolean,
    onSuccess?: VoidFunction,
  ) => Promise<any>;
};

type ProviderProps = {
  children: React.ReactNode;
};

const InitialState: Partial<IState> = {};

export type AuthContextProps = IState & IDispatch;
export const AuthContext = createContext<AuthContextProps>(InitialState as AuthContextProps);

const AuthProvider: FC<ProviderProps> = ({ children }) => {
  const [user, setCurrentUser] = useState<UserAuthType>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorType>({});
  const [credentials, setCredentials] = useState<CredentialsType>({});
  const [passwordResetState, setPasswordResetState] = useState<PasswordResetType>({});

  const dispatch = useDispatch();
  const headers = getHeaders();

  const logout = () => {
    Axios({
      url: `${API_URL}/api/v1/rest-auth/logout/`,
      method: 'POST',
      headers,
    })
      .then(() => {
        window.localStorage.clear();
        dispatch(userAuthApi.resetDetail());
        window.location.href = LANDING_PAGE_URL;
      })
      .catch((err: any) => {
        log.error(err?.response?.data || err);
        enqueueSnackbar('Something went wrong with logging out', { variant: 'error' });

        // Logging out doesn't seem to be working so removing the cookie
        Cookies.remove('sessionid');
        window.localStorage.clear();
        dispatch(userAuthApi.resetDetail());
      });
  };

  async function handleAuthenticate(isLogin?: boolean, onSuccess?: VoidFunction) {
    setError({});
    setIsLoading(true);

    try {
      await Axios.post(
        isLogin
          ? `${API_URL}/api/v1/rest-auth/login/`
          : `${API_URL}/api/v1/rest-auth/registration/`,
        credentials,
        { headers },
      );

      const authResponse: UserAuthType = await dispatch(userAuthApi.getDetailRequest('user'));
      setIsLoading(false);
      if (!(authResponse.id || authResponse.uid)) {
        setError({ all: 'Oops sorry. Something went wrong.' });
        return;
      }

      // @ts-ignore
      segmentAnalytics.track(
        isLogin ? SEGMENT_CONSTANTS.LOGIN_EMAIL_COMPLETE : SEGMENT_CONSTANTS.SIGNUP_EMAIL_COMPLETE,
        {
          user_id: authResponse.id,
          type: isLogin ? 'Login' : 'Register',
          app: 'Email',
        },
      );

      if (onSuccess) {
        onSuccess();
      } else {
        window.location.href = APP_URL;
      }
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data) {
        const { email, password, password1, password2, non_field_errors } = err.response.data;
        const _error: ErrorType = {};
        if (email) _error.email = email[0];
        if (password) _error.password = password[0];
        if (password1) _error.password1 = password1[0];
        if (password2) _error.password2 = password2[0];
        if (non_field_errors) _error.all = non_field_errors[0];
        setError(_error);
      } else {
        setError({ all: 'Oops sorry. Something went wrong.' });
      }
      setIsLoading(false);
    }
  }

  const handleSocialAuth = async (
    access_token: string,
    app: string,
    isLogin?: boolean,
    onSuccess?: VoidFunction,
  ) => {
    setIsLoading(true);
    try {
      const resp: any = await Axios.post(
        `${API_URL}/rest-auth/${app}/auth/`,
        { access_token },
        { headers },
      );
      if (resp?.data?.key) {
        // @ts-ignore
        const authResponse = await dispatch(userAuthApi.getDetailRequest('user'));
        setIsLoading(false);
        segmentAnalytics.track(
          isLogin
            ? SEGMENT_CONSTANTS.LOGIN_EMAIL_COMPLETE
            : SEGMENT_CONSTANTS.SIGNUP_EMAIL_COMPLETE,
          {
            user_id: authResponse.id,
            type: isLogin ? 'Login' : 'Register',
            app: 'Email',
          },
        );
        if (onSuccess) {
          onSuccess();
        } else {
          window.location.href = APP_URL;
        }
      } else {
        setIsLoading(false);
        setError({ all: 'Oops sorry. Something went wrong.' });
      }
    } catch (err) {
      setIsLoading(false);

      if (err?.response?.status === 400 && err?.response?.data) {
        const { non_field_errors } = err.response.data;
        const _error: ErrorType = {};
        if (non_field_errors) {
          _error.all = non_field_errors[0];
        }
        setError(_error);
      } else {
        setError({ all: 'Oops sorry. Something went wrong.' });
      }
    }
  };

  const value: AuthContextProps = {
    user,
    error,
    isLoading,
    setError,
    credentials,
    setCredentials,
    setCurrentUser,
    handleAuthenticate,
    handleSocialAuth,
    logout,
    passwordResetState,
    setPasswordResetState,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
