// Npm
import { createSelector } from 'reselect';

// Constants for Actions
export const APP_NAME = 'app';

const ON_CHANGE = `${APP_NAME}/ON_CHANGE`;

export const onChange = (key, data) => {
  return {
    type: ON_CHANGE,
    data,
    key,
  };
};

const initial = {
  dimensions: {},
};

export default (state = initial, action) => {
  switch (action.type) {
    case ON_CHANGE: {
      return {
        ...state,
        [action.key]: action.data,
      };
    }

    default:
      return state;
  }
};

const selectGlobal = state => state[APP_NAME];

export const makeSelectBase = key => {
  return createSelector(selectGlobal, globalState => globalState[key]);
};
