import { useEffect } from 'react';
import classnames from 'classnames';

// Material ui
import Grid from '@mui/material/Grid';
import InfiniteScroll from 'react-infinite-scroller';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// core components
import ImageGridComponent from 'components/File/ItemGrid';
import { VideoListLoading } from 'components/Loading/index';
import { clientFile as clientFileApi } from 'store/index';
import { FileType } from 'types';
import { ContentCard } from 'layouts/dashboard/Content';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

export default function SharedFileList(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const initialized = useSelector((state: any) => state[clientFileApi.APP_NAME].initialized);
  const list: FileType[] = useSelector((state: any) => state[clientFileApi.APP_NAME].list);
  const nextUrl = useSelector((state: any) => state[clientFileApi.APP_NAME].url);

  useEffect(() => {
    const params: {
      limit: number;
      upload_type: string;
      no_parent?: boolean;
    } = { limit: 30, upload_type: 'PRIMARY', no_parent: true };

    dispatch(clientFileApi.reset());
    dispatch(clientFileApi.setGetParams(params));
    dispatch(clientFileApi.getRequest());
  }, []);

  if (!initialized) {
    return <VideoListLoading />;
  }

  // If no video, or a single video and assuming the video is the one being currently uploaded
  if (initialized && list.length === 0) {
    return (
      <ContentCard>
        <Typography variant="h5" display="inline">
          No file has been shared with you yet
        </Typography>
      </ContentCard>
    );
  }

  return (
    <>
      <Grid container spacing={3} className={classnames(classes.paperContainer, 'tw-mb-4')}>
        <Grid
          item
          md={12}
          className="tw-flex tw-items-center tw-justify-center md:tw-justify-start"
        >
          <Typography variant="h5" display="inline">
            Shared Files
          </Typography>
        </Grid>
      </Grid>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (nextUrl) {
            dispatch(clientFileApi.getRequest(nextUrl, true));
          }
        }}
        hasMore={nextUrl}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
      >
        <Grid container spacing={3} className={classes.paperContainer}>
          {list?.map(datum => {
            return (
              <Grid key={datum.id} item xs={12} sm={6} md={4} lg={4}>
                <ImageGridComponent
                  video={datum}
                  style={{ height: 350, objectFit: 'contain' }}
                  path={`/file/${datum.uuid}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </>
  );
}
