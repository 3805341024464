import { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { UserAuthType, UserBaseType } from 'types';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';

import { EditNotifications } from 'components/Instructor/Configure';
import {
  userAuth as userAuthApi,
  userPublic as userPublicApi,
  feedbackRequest as feedbackRequestApi,
} from 'store/index';
import { UserAvatar } from 'components/Common/Avatar';
import { useDispatch, useSelector } from 'hooks';
import { CircularLoading } from 'components/Loading/index';
import CommentSection from 'components/Comment/Section';

function stripAt(str: string) {
  return str[0] === '@' ? str.substring(1) : str;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },
  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  contentContainer: {
    maxWidth: '60rem',
    width: '100%',
    alignItems: 'center',
  },
}));

const BreadcrumbsBack = () => {
  const userRequested: UserBaseType = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].detail,
  );
  return (
    <Grid item xs={12} className="tw-mb-10">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">
          <MuiLink underline="hover" color="inherit">
            Profiles
          </MuiLink>
        </Link>

        <Link to={`/u/${userRequested?.username}`}>
          <MuiLink underline="hover" color="inherit">
            {userRequested?.username}
          </MuiLink>
        </Link>

        <Typography color="text.primary">Success</Typography>
      </Breadcrumbs>
    </Grid>
  );
};

export default function EmbedSuccess(): JSX.Element {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRequested: UserBaseType = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].detail,
  );
  const userAuthenticated: UserAuthType = useSelector(
    (state: any) => state[userAuthApi.APP_NAME].detail,
  );
  const initialized: boolean = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].initialized,
  );
  const request = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].detail);

  let username = params.username;
  const request_uuid = params.uid;
  if (username) {
    username = stripAt(username);
  }
  useEffect(() => {
    if (username) dispatch(userPublicApi.getDetailRequest(encodeURI(username)));
    if (request_uuid) dispatch(feedbackRequestApi.getDetailRequest(request_uuid));
  }, []);

  if ((!initialized || !request.uuid) && username) {
    return <CircularLoading />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10"
      >
        <Grid container style={{ paddingBottom: 40 }} className={classes.contentContainer}>
          <BreadcrumbsBack />

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 5 }} style={{ marginTop: 10, fontSize: '1.5rem' }}>
              Request successfully sent
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} className={classes.avatarContainer}>
            <UserAvatar user={userRequested} size={200} />
          </Grid>

          <Grid item xs={12} md={8} className={classes.contentRight}>
            <Typography variant="h5" display="block" style={{ marginTop: 10, fontSize: '1.5rem' }}>
              {userRequested?.username}
            </Typography>

            <Typography variant="subtitle1" className="tw-mt-5 tw-text-xl">
              Notifications will be sent to:
            </Typography>

            <Typography variant="subtitle1">Email: {userAuthenticated?.email}</Typography>

            {userAuthenticated?.phone_number ? (
              <Typography variant="subtitle1">Phone: {userAuthenticated?.phone_number}</Typography>
            ) : null}
          </Grid>
        </Grid>
      </Container>

      <div className="tw-flex-1 tw-bg-white">
        <Container
          maxWidth="xl"
          className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10"
        >
          <Grid container className={classes.contentContainer}>
            {!userAuthenticated?.phone_number ? (
              <Grid item xs={12}>
                <EditNotifications />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <CommentSection commentParams={{ request_uuid }} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
