import { useState, FC } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

import { notification } from 'store/notification';
import { BusinessFeaturesType } from 'types/index';
import { business as businessApi } from 'store/index';
import { RenderHelpIcon } from 'components/Help/index';
import { log } from 'utils/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

const FeaturesConfig: FC = () => {
  const dispatch = useDispatch();
  const { business, loading } = useBusiness();

  const [state, setState] = useState<BusinessFeaturesType>({
    files: business?.feature_config?.files || false,
    code_share: business?.feature_config?.code_share || false,
    whiteboard: business?.feature_config?.whiteboard || false,
  });

  const callback = (resp: any) => {
    if (resp.id) {
      dispatch(notification('The feature configuration was updated', 'success'));
    } else if (resp.error) {
      const msg = (resp.data && resp.data.error) || resp.error;
      dispatch(notification(msg, 'error'));
    }
  };

  const apiPatchRequest = async () => {
    try {
      const feature_config: BusinessFeaturesType = {
        ...(business?.feature_config || {}),
        files: state.files,
        code_share: state.code_share,
        whiteboard: state.whiteboard,
      };
      const resp = await dispatch(
        businessApi.patchRequest(
          {
            feature_config,
          },
          business?.uid || '',
        ),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <Grid container spacing={2} className="tw-mt-4">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-text-2xl tw-font-medium"
        >
          Manage Features
          {loading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={11}>
        <Typography
          variant="h6"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-font-normal"
        >
          Allow file upload
          <RenderHelpIcon text="This allows you and your staff to upload files which your users can view and ask for feedback as they need" />
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Switch
          defaultChecked={state.files}
          onChange={(ev: any) => setState({ ...state, files: ev.target.checked })}
          className="tw-float-right"
        />
      </Grid>

      <Grid item xs={12} className="tw-pt-0">
        <Divider />
      </Grid>

      <Grid item xs={11}>
        <Typography
          variant="h6"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-font-normal"
        >
          Allow pair programming session
          <RenderHelpIcon text="We understand that you might have a separate billing system for your clients. This option allows your clients to make requests for free without having to top up their account. We will then send you an invoice once a month of how much your clients owe you." />
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Switch
          defaultChecked={state.code_share}
          onChange={(ev: any) => setState({ ...state, code_share: ev.target.checked })}
          className="tw-float-right"
        />
      </Grid>

      <Grid item xs={12} className="tw-pt-0">
        <Divider />
      </Grid>

      <Grid item xs={11}>
        <Typography
          variant="h6"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-font-normal"
        >
          Allow whiteboarding session
          <RenderHelpIcon text="We understand that you might have a separate billing system for your clients. This option allows your clients to make requests for free without having to top up their account. We will then send you an invoice once a month of how much your clients owe you." />
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Switch
          defaultChecked={state.whiteboard}
          onChange={(ev: any) => setState({ ...state, whiteboard: ev.target.checked })}
          className="tw-float-right"
        />
      </Grid>

      <Grid item xs={12} className="tw-pt-0">
        <Divider />
      </Grid>

      <Grid item sm={12} md={6} className="tw-align-start tw-mt-5 tw-flex tw-justify-start">
        <SaveButton onClick={apiPatchRequest} />
      </Grid>
    </Grid>
  );
};

export default FeaturesConfig;
