import { FC } from 'react';

import { makeStyles } from '@mui/styles';
import Grid, { GridSize } from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ContentLoader from 'react-content-loader';

// App
import { GridContainer } from 'components/Common/index';

const useStyles = makeStyles(() => ({
  loadingGrid: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: (props: { height: number | string }) => props.height,
  },
}));

interface CircularLoadingProps {
  height?: string | number;
}

export const CircularLoading: FC<CircularLoadingProps> = ({ height = 'auto' }) => {
  const classes = useStyles({ height });
  return (
    <Grid container justifyContent="center" style={{ marginBottom: 25 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.loadingGrid}>
        <CircularProgress style={{ marginTop: 75 }} />
      </Grid>
    </Grid>
  );
};

interface CardLoadingProps {
  height?: number;
}

export const CardLoading: FC<CardLoadingProps> = ({ height = 200 }) => {
  return (
    <ContentLoader width="100%" height={height}>
      <rect x="0" y="0" rx="2" ry="2" width="100%" height={height * 0.7} />
      <rect x="0" y={height * 0.75} rx="2" ry="2" width="100%" height={height * 0.08} />
      <rect x="0" y={height * 0.9} rx="2" ry="2" width="100%" height={height * 0.08} />
    </ContentLoader>
  );
};

interface VideoListLoadingProps {
  height?: number;
  md?: GridSize;
  lg?: GridSize;
}

export const VideoListLoading: FC<VideoListLoadingProps> = ({ height = 350, lg = 4, md = 4 }) => {
  const list = [];
  for (let i = 1; i <= 7; i++) {
    list.push(
      <Grid item xs={12} sm={6} md={md} lg={lg}>
        <CardLoading height={height} />
      </Grid>,
    );
  }
  return (
    <Grid container justifyContent="center" spacing={2}>
      {list}
    </Grid>
  );
};

export const VideoCommentListLoading: FC = () => {
  const list = [];
  for (let i = 1; i <= 2; i++) {
    list.push(
      <GridContainer key={i}>
        <ContentLoader
          height={75}
          width="100%"
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="70" y="0" rx="3" ry="3" width="80%" height="20" />
          <rect x="70" y="40" rx="3" ry="3" width="50%" height="20" />
        </ContentLoader>
      </GridContainer>,
    );
  }
  return <>{list}</>;
};

export const VideoTitleLoading: FC = () => {
  return (
    <GridContainer>
      <ContentLoader
        height={120}
        width="100%"
        speed={1}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="32" cy="32" r="32" />
        <rect x="100" y="0" rx="3" ry="3" width="80%" height="25" />
        <rect x="100" y="40" rx="3" ry="3" width="50%" height="20" />
        <rect x="100" y="70" rx="3" ry="3" width="20%" height="16" />
        <rect x="100" y="100" rx="3" ry="3" width="20%" height="16" />
      </ContentLoader>
    </GridContainer>
  );
};

interface FeedItemLoadingProps {
  height?: number;
}
export const FeedItemLoading: FC<FeedItemLoadingProps> = ({ height = 300 }) => {
  const heightRatio = 0.7;
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} style={{ height, marginBottom: 30 }}>
      <Paper elevation={0}>
        <ContentLoader
          height={height}
          width="100%"
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="2" ry="2" width="100%" height={height * heightRatio} />
          <rect x="10" y={height * heightRatio + 10} rx="3" ry="3" width="80%" height="15" />
          <rect
            x="10"
            y={height * heightRatio + 10 + 15 + 5}
            rx="3"
            ry="3"
            width="50%"
            height="15"
          />
          <rect
            x="10"
            y={height * heightRatio + 10 + 15 + 5 + 15 + 5}
            rx="3"
            ry="3"
            width="20%"
            height="16"
          />
        </ContentLoader>
      </Paper>
    </Grid>
  );
};
