import { FC } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// App
import { notification } from 'store/notification';
import { useDispatch, useSelector } from 'hooks';

const Focus: FC = () => {
  const dispatch = useDispatch();
  const message = useSelector((state: any) => state.notification.message);
  const status = useSelector((state: any) => state.notification.status);

  const handleClose = () => {
    dispatch(notification(null, ''));
  };
  return (
    <Snackbar open={message} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={status} elevation={6} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Focus;
