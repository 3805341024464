import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

// Material UI
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// core components
import { UserAvatar } from 'components/Common/Avatar';
import { CardMediaContentOverlay } from 'styles/common.style';
import chatBubbleImage from 'images/chat-bubble.png';
import pdfImage from 'images/pdf.png';
import cameraImage from 'images/camera.png';
import playImage from 'images/play.png';
import microphoneImage from 'images/microphone.png';
import { UserBaseType, MediaTypeEnum } from 'types/api';

type PropTypes = {
  path: string;
  isPublic?: boolean;
  thumbnail_uri?: string;
  text?: string;
  height: number;
  file_type?: string;
  created_by?: UserBaseType;
  title?: string;
};

type StyleType = {
  height: number;
  isPublic?: boolean;
};

type ImageType = {
  src: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  mediaChat: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  media: {
    height: (props: StyleType) => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: (props: StyleType) => (props.isPublic ? 150 : props.height),
      width: (props: StyleType) => (props.isPublic ? 150 : 'auto'),
    },
  },

  thumbMissing: {
    textAlign: 'center',
    height: (props: StyleType) => (props.isPublic ? 'auto' : props.height),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ImageAsIcon: FC<ImageType> = ({ src }) => {
  return <img src={src} style={{ width: 40, height: 40 }} alt="icon" />;
};

export default function FileThumbnail({
  path,
  thumbnail_uri,
  file_type,
  text,
  height,
  created_by,
  isPublic,
  title,
}: PropTypes): JSX.Element {
  const classes = useStyles({ height, isPublic });

  if (file_type === 'AUDIO') {
    return (
      <Link to={path}>
        <Box sx={{ height: height }}>
          <div className="content-overlay" />
          <Box
            className={classes.media}
            style={{ flexDirection: 'column' }}
            sx={{ height: height }}
          >
            <UserAvatar user={created_by} size={80} />
            <div style={{ marginTop: 15, marginBottom: 15 }} />
            <ImageAsIcon src={microphoneImage} />
          </Box>
        </Box>
      </Link>
    );
  }

  if (file_type === MediaTypeEnum.DOC) {
    return (
      <Link to={path}>
        <Box
          className="tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center"
          sx={{ height: height }}
        >
          <CardMedia className={classes.media} title={title}>
            <Icon icon="file-icons:microsoft-word" style={{ fontSize: 80, color: '#3b82f6' }} />
          </CardMedia>

          <Typography variant="body1" align="left" className="tw-mt-6 tw-text-xs tw-text-blue-700">
            {title}
          </Typography>
        </Box>
      </Link>
    );
  } else if (thumbnail_uri) {
    let icon = <ImageAsIcon src={playImage} />;

    if (file_type === 'IMAGE') {
      icon = <ImageAsIcon src={cameraImage} />;
    } else if (file_type === 'PDF') {
      icon = <ImageAsIcon src={pdfImage} />;
    }
    return (
      <Link to={path}>
        <CardMediaContentOverlay>
          <div className="content-overlay" />
          <CardMedia className={classes.media} image={thumbnail_uri} sx={{ height: height }}>
            {icon}
          </CardMedia>
        </CardMediaContentOverlay>
      </Link>
    );
  } else if (text) {
    return (
      <Link to={path}>
        <Box className={classes.thumbMissing} sx={{ height: height }}>
          <Typography
            variant="body1"
            gutterBottom
            style={{
              color: 'black',
              paddingLeft: 20,
              paddingRight: 20,
              width: '100%',
              wordBreak: 'break-word',
            }}
          >
            {text.length < 250 ? text : text.substring(0, 250) + ' ...'}
          </Typography>
        </Box>
      </Link>
    );
  } else {
    return (
      <Link to={path}>
        <Box className={classes.thumbMissing} sx={{ height: height }}>
          <CardMedia className={classes.mediaChat} image={chatBubbleImage} />
        </Box>
      </Link>
    );
  }
}
