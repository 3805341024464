import { FC } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 20,
  },
  paper: {
    width: '100%',
    padding: 20,
  },
}));

interface GridContainerProps {
  paperProps?: any;
  children: React.ReactNode;
}

export const GridContainer: FC<GridContainerProps> = ({ children, paperProps = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} variant="outlined" className={classes.paper} {...paperProps}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
