import { useState, FC } from 'react';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

import { notification } from 'store/notification';
import { RateListItemType, BusinessFinancialType, BusinessFeaturesType } from 'types/index';
import { business as businessApi } from 'store/index';
import { buttonPrimary, buttonDanger } from 'styles/classnames';
import { RenderHelpIcon } from 'components/Help/index';
import { log } from 'utils/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

interface RateDurationItemProps {
  disable_billing?: boolean;
  item: RateListItemType;
  setRateItem: (item: RateListItemType) => void;
  removeRateItem: (item: RateListItemType) => void;
}

const DURATIONS = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const RateDurationItem: FC<RateDurationItemProps> = ({
  disable_billing,
  item,
  setRateItem,
  removeRateItem,
}) => {
  const [error] = useState({ rate: undefined });
  const options = DURATIONS.map(dur => (
    <MenuItem key={dur} value={`${dur}`}>
      {dur} minutes
    </MenuItem>
  ));

  return (
    <Grid item xs={12}>
      <Box>
        <Grid container spacing={2}>
          {disable_billing ? null : (
            <Grid item xs={12} sm={5}>
              <Box className="tw-align-center tw-flex tw-justify-center tw-justify-evenly">
                <TextField
                  id="input-add-video-rate"
                  label={error?.rate ? error?.rate : 'Video call rate ($)'}
                  error={error?.rate}
                  variant="outlined"
                  onChange={ev => setRateItem({ ...item, rate: ev.target.value })}
                  value={item.rate}
                  placeholder="Enter rate for video call"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={5}>
            <FormControl className="tw-w-full" variant="outlined">
              <InputLabel id="select-duration-label">Duration of call</InputLabel>
              <Select
                labelId="select-duration-label"
                id="select-duration"
                value={(item.duration || '').toString()}
                onChange={(ev: any) => {
                  setRateItem({
                    ...item,
                    duration: ev.target.value,
                    rate: disable_billing ? 0 : item.rate,
                  });
                }}
              >
                {options}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Box className="tw-flex tw-justify-end">
              <Button
                className={classnames(buttonDanger, 'tw-w-24 tw-p-2 tw-text-base')}
                onClick={() => {
                  removeRateItem(item);
                }}
              >
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

interface RateListConfigType {
  allowCall: boolean;
  rateList: RateListItemType[];
  setRateList: (value: RateListItemType[]) => void;
}

const RateListConfig: FC<RateListConfigType> = ({ allowCall, rateList, setRateList }) => {
  const { business } = useBusiness();
  if (allowCall) {
    return (
      <>
        <Grid container spacing={2} className="tw-mt-4">
          <Grid item xs={10} className="tw-mt-2 tw-flex tw-items-center">
            <Typography variant="h6" display="block" className="tw-font-normal">
              How long should your video calls last
              {business?.financial_config?.disable_billing
                ? '? (Billing disabled)'
                : ' and much does your business charge for a video call ($)?'}
              {/* <RenderHelpIcon text="How much you charge for a video call with your audience." /> */}
            </Typography>
          </Grid>

          <Grid item xs={2} className="tw-mt-2">
            <Box className="tw-flex tw-justify-end">
              <Button
                className={classnames(buttonPrimary, 'tw-w-24 tw-text-base')}
                onClick={() => {
                  const rate_list = [
                    ...rateList,
                    {
                      duration: null,
                      rate: null,
                      uid: nanoid(),
                    },
                  ];

                  setRateList(rate_list);
                }}
              >
                + Add
              </Button>
            </Box>
          </Grid>

          {rateList.map(row => (
            <Grid item xs={12} key={row.uid} className="tw-mt-3">
              <Box className={classnames('tw-align-start tw-flex tw-justify-start')}>
                <RateDurationItem
                  item={row}
                  setRateItem={(item: RateListItemType) => {
                    const rate_list = [...rateList].map(x => {
                      if (x.uid === item.uid) return item;
                      return x;
                    });
                    setRateList(rate_list);
                  }}
                  removeRateItem={(item: RateListItemType) => {
                    const rate_list = [...rateList].filter(x => x.uid !== item.uid);
                    setRateList(rate_list);
                  }}
                  disable_billing={business?.financial_config?.disable_billing}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  return null;
};

const CallConfig: FC = () => {
  const dispatch = useDispatch();
  const { business, loading } = useBusiness();
  const [allowCall, setAllowCall] = useState(business?.feature_config?.allow_video_call || false);
  const [rateList, setRateList] = useState<RateListItemType[]>(
    business?.financial_config?.rate_list || [
      {
        duration: null,
        rate: null,
        uid: nanoid(),
      },
    ],
  );

  const callback = (resp: any) => {
    if (resp.id) {
      dispatch(notification('The financial policy was updated', 'success'));
    } else if (resp.error) {
      const msg = (resp.data && resp.data.error) || resp.error;
      dispatch(notification(msg, 'error'));
    }
  };

  const apiPatchRequest = async () => {
    try {
      const feature_config: BusinessFeaturesType = {
        ...(business?.feature_config || {}),
        allow_video_call: allowCall,
      };

      const financial_config: BusinessFinancialType = {
        ...(business?.financial_config || {}),
        rate_list: rateList.filter(x => x.duration && x.rate !== null),
      };
      const resp = await dispatch(
        businessApi.patchRequest(
          {
            financial_config,
            feature_config,
          },
          business?.uid || '',
        ),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <>
      <Grid container spacing={0} className="tw-mt-4">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ marginBottom: 20 }}
            display="block"
            className="tw-text-2xl tw-font-medium"
          >
            Manage Video Calls
            {loading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
          </Typography>
        </Grid>

        <Grid item xs={12} className="tw-py-2">
          <Divider />
        </Grid>

        <Grid item xs={11}>
          <Typography variant="h6" display="block" className="tw-font-normal">
            Allow video calls
            <RenderHelpIcon text="Does your business allow clients to request a short video call with you." />
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Switch
            defaultChecked={business?.feature_config?.allow_video_call}
            onChange={(ev: any) => setAllowCall(ev.target.checked)}
            className="tw-float-right"
          />
        </Grid>

        <Grid item xs={12} className="tw-py-2">
          <Divider />
        </Grid>

        <RateListConfig rateList={rateList} setRateList={setRateList} allowCall={allowCall} />

        <Grid item sm={12} md={6} className="tw-align-start tw-mt-5 tw-flex tw-justify-start">
          <SaveButton onClick={apiPatchRequest} />
        </Grid>
      </Grid>
    </>
  );
};

export default CallConfig;
