import { useState } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReactQuill, { Quill } from 'react-quill';
// @ts-ignore
import quillEmoji from 'quill-emoji';

// App
import { CustomTextField } from 'components/Input/index';
import { business as businessApi } from 'store/index';
import { notification } from 'store/notification';
import { log } from 'utils/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

import './editor.scss';

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true,
);

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    ['emoji'],
    ['link'],
    ['clean'],
  ],
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
};

const formats = [
  // 'font',
  // 'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'color',
  // 'background',
  'list',
  'indent',
  'align',
  'link',
  'clean',
  'emoji',
];

export default function ChangeDetails(): JSX.Element {
  const dispatch = useDispatch();
  const { business } = useBusiness();
  const [state, setState] = useState({
    name: business?.name || '',
  });
  const [about, setAbout] = useState(business?.about || '');
  const [error] = useState({});

  const textFieldProps = {
    state,
    error,
    setState,
  };

  const saveData = async () => {
    const data: {
      about?: string;
      name?: string;
    } = {};
    if (business?.about !== about) {
      data.about = about;
    }

    if (business?.name !== state.name) {
      data.name = state.name;
    }

    try {
      const resp: any = await dispatch(businessApi.patchRequest(data, business?.uid || ''));
      if (resp.error) {
        dispatch(notification('Something went wrong', 'error'));
      } else if (resp.id) {
        dispatch(notification('The details has been updated', 'success'));
      }
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          align="left"
          style={{ marginBottom: 10 }}
        >
          Business Info
        </Typography>
      </Grid>

      <CustomTextField field="name" placeholder="Name" {...textFieldProps} lg={12} md={12} />

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="text-editor">
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={about}
            onChange={setAbout}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SaveButton onClick={saveData} />
      </Grid>
    </Grid>
  );
}
