import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App
import ItemSummary from 'components/Feedback/ItemSummary';
import { VideoListLoading } from 'components/Loading/index';
import { feedbackRequest as feedbackRequestApi } from 'store/index';
import { RequestType } from 'types';
import { ContentCard } from 'layouts/dashboard/Content';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 15,
    paddingLeft: '1%',
    paddingRight: '1%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
  },
}));

interface RequestListProps {
  list: RequestType[];
  initialized: boolean;
  loadMore: VoidFunction;
  hasMore: boolean;
}

function RequestList({ list, initialized, loadMore, hasMore }: RequestListProps): JSX.Element {
  if (!initialized) {
    return <VideoListLoading lg={4} md={4} />;
  } else if (list.length === 0) {
    return (
      <ContentCard>
        <Typography variant="h5" align="center">
          You have not asked for feedback yet.
        </Typography>
      </ContentCard>
    );
  } else {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
      >
        <Grid container justifyContent="center" spacing={2}>
          {list.map(item => (
            <ItemSummary item={item} height={300} lg={4} />
          ))}
        </Grid>
      </InfiniteScroll>
    );
  }
}

export default function FeedbackListPage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const list = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].list);
  const initialized = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].initialized);
  const nextUrl = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].url);

  useEffect(() => {
    dispatch(feedbackRequestApi.setGetParams({ limit: 15 }));
    dispatch(feedbackRequestApi.getRequest());
  }, []);

  return (
    <div className={classes.container}>
      <RequestList
        list={list}
        initialized={initialized}
        hasMore={nextUrl}
        loadMore={() => {
          dispatch(feedbackRequestApi.getRequest(nextUrl, true));
        }}
      />
    </div>
  );
}
