import { useState, FC, ReactNode } from 'react';
import classnames from 'classnames';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

// App
import { buttonPrimary, buttonDanger } from 'styles/classnames';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
}));

interface ConfirmDeleteModalProps {
  onClose: VoidFunction;
  open: boolean;
  handleDelete: VoidFunction;
  content: ReactNode;
}

export const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  onClose,
  open,
  handleDelete,
  content,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="scroll-dialog-title">
        Confirm delete
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          className={classnames(buttonPrimary, 'tw-text-sm')}
        >
          No
        </Button>
        <Button
          onClick={handleDelete}
          variant="outlined"
          className={classnames(buttonDanger, 'tw-text-sm')}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmDeleteProps {
  onDelete: VoidFunction;
  content: ReactNode;
  buttonProps?: any;
}

const ConfirmDelete: FC<ConfirmDeleteProps> = ({ content, onDelete, buttonProps = {} }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classnames(buttonDanger, 'tw-w-full tw-py-2 tw-text-lg')}
        onClick={handleClickOpen}
        {...buttonProps}
      >
        Delete
      </Button>
      <ConfirmDeleteModal
        content={content}
        open={open}
        onClose={handleClose}
        handleDelete={() => {
          onDelete();
          handleClose();
        }}
      />
    </>
  );
};

export default ConfirmDelete;

interface ConfirmTextProps {
  onDelete: VoidFunction;
  content: ReactNode;
  buttonProps: any;
}

export const ConfirmText: FC<ConfirmTextProps> = ({ content, onDelete, buttonProps = {} }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classnames(buttonDanger, 'tw-w-full tw-py-2 tw-text-lg')}
        onClick={handleClickOpen}
        {...buttonProps}
      >
        Delete
      </Button>
      <ConfirmDeleteModal
        content={content}
        open={open}
        onClose={handleClose}
        handleDelete={() => {
          onDelete();
          handleClose();
        }}
      />
    </>
  );
};
