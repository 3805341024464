import { FC } from 'react';
import classnames from 'classnames';

// Material UI
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import { blue, red } from '@mui/material/colors';

// App
import { UserBaseType } from 'types/api';

type StyleType = {
  size: number;
};

type PropsType = {
  size: number;
  user?: UserBaseType;
  src?: string;
};

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    width: (props: StyleType) => props.size,
    height: (props: StyleType) => props.size,
  },

  initials: {
    width: (props: StyleType) => props.size,
    height: (props: StyleType) => props.size,

    fontSize: (props: StyleType) => (props.size > 70 ? 36 : 22),
  },

  audience: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },

  teacher: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
}));

export const UserAvatar: FC<PropsType> = ({ user, size = 150, src }) => {
  const classes = useStyles({ size });
  const avatarSrc = src || user?.profile?.image;
  if (!avatarSrc) {
    const isInstructor = user?.instructor?.id;
    return (
      <Avatar
        aria-label={user?.username}
        className={classnames({
          [classes.initials]: true,
          [classes.audience]: !isInstructor,
          [classes.teacher]: isInstructor,
        })}
        sx={{ width: size, height: size }}
      >
        {user?.initials}
      </Avatar>
    );
  } else {
    return (
      <Avatar
        alt={user?.username}
        src={avatarSrc}
        className={classes.avatar}
        sx={{ width: size, height: size }}
      />
    );
  }
};
