import { FC } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';

type StyleType = {
  size: number;
};

type PropsType = {
  size: number;
  initials?: string;
  src?: string;
};

const useStyles = makeStyles(() => ({
  avatar: {
    width: (props: StyleType) => props.size,
    height: (props: StyleType) => props.size,
  },

  initials: {
    width: (props: StyleType) => props.size,
    height: (props: StyleType) => props.size,

    fontSize: (props: StyleType) => (props.size > 70 ? 36 : 22),
  },
}));

const BusinessAvatar: FC<PropsType> = ({ initials, size = 150, src }) => {
  const classes = useStyles({ size });
  if (!src) {
    return (
      <Avatar aria-label={initials} className={classes.initials} sx={{ width: size, height: size }}>
        {initials}
      </Avatar>
    );
  } else {
    return (
      <Avatar
        alt={initials}
        src={src}
        className={classes.avatar}
        sx={{ width: size, height: size }}
      />
    );
  }
};

export default BusinessAvatar;
