import { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';

// App
import SelectFocus from 'components/Focus/Select';
import { card } from 'styles/classnames';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: 50,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
}));

const App: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SelectFocus
            rootClass={classnames(card, 'tw-bg-white tw-w-full tw-px-4 md:tw-px-16 tw-py-24')}
            isOnboarding
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
