import { FC, ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import { FileWithPath } from 'file-selector';

// App
import { UserAvatar } from 'components/Common/Avatar';
import { GridContainer } from 'components/Common/index';
import UsernameLink from 'components/User/UsernameLink';
import { uploadVideo } from 'store/firebase';
import {
  comment as commentApi,
  userAuth as userAuthApi,
  video as videoApi,
  feedbackRequest as feedbackRequestApi,
} from 'store/index';
import { notification } from 'store/notification';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import FeedbackEntry from 'components/Feedback/Entry';
import { InstructorType, UserBaseType, RequestType, FileType } from 'types/index';
import { log } from 'utils/index';
import { useRequestResponse, useDispatch, useSelector, useBusiness } from 'hooks';

const useStyles = makeStyles(() => ({
  textDisabled: {
    color: '#707070',
  },

  insufficientFunds: {
    color: 'red',
  },
}));

interface RateDetailsProps {
  instructor?: InstructorType;
}

const RateDetails: FC<RateDetailsProps> = ({ instructor }) => {
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const classes = useStyles();
  const { business } = useBusiness();

  if (instructor?.id) {
    const insufficientFunds = instructor.rate > (user.balance && user.balance.available_amount);
    return (
      <>
        {!business?.financial_config?.disable_billing ? (
          <Typography
            component="span"
            variant="subtitle2"
            color="textPrimary"
            display="block"
            className={classnames({ [classes.textDisabled]: false })}
          >
            Rate {numeral(instructor.rate).format('$ 0.00')}
          </Typography>
        ) : null}

        {user &&
          user.id &&
          !business?.financial_config?.disable_billing &&
          !business?.financial_config?.external_billing && (
            <>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
                display="block"
                className={classnames({ [classes.insufficientFunds]: insufficientFunds })}
              >
                Balance: {numeral(user.balance && user.balance.available_amount).format('$ 0.00')}
              </Typography>

              {insufficientFunds ? (
                <a href="/top-up">
                  <Button
                    variant="outlined"
                    className={classnames('tw-text-base tw-text-blue-700')}
                    style={{ padding: 0, margin: 0 }}
                    id="feedback-top-up-button"
                  >
                    Top up
                  </Button>
                </a>
              ) : null}
            </>
          )}
      </>
    );
  }
  return null;
};

interface FeedbackProps {
  handleClose?: VoidFunction;
  isShared?: boolean;
  requestId?: number | string; // This is only if from an existing request
  request?: RequestType;
  primaryText?: string | ReactNode;
  hideAvatarSection?: boolean;
  videoDetail: FileType;
}

interface ISubmitVideoData {
  title: string;
  metadata: any;
  upload_type: string;
  request_id?: number | string;
  recorded_timestamp: number;
  is_comment: boolean;
  video_comment_video_id?: number;
  media_type?: string;
}

const Feedback: FC<FeedbackProps> = ({
  handleClose,
  isShared,
  requestId,
  request,
  primaryText,
  hideAvatarSection,
  videoDetail: video,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const { business } = useBusiness();
  const { fileList, recordedFile, state } = useRequestResponse();

  const shouldClose = () => {
    handleClose?.();
  };

  const onSuccess = (videoId?: number, requestId?: number | string) => {
    dispatch(userAuthApi.getDetailRequest('user')); // Need to update the available balance
    dispatch(commentApi.getRequest());

    if (isShared) {
      if (requestId) {
        navigate(`/request/${requestId}/`);
      } else {
        navigate(`/manage-file/${videoId}/`);
      }
    } else {
      shouldClose();
    }
  };

  let request_user: UserBaseType | undefined = undefined;

  if (video && video.created_by) {
    request_user = video.created_by;
  } else if (request && request.requested) {
    request_user = request.requested;
  }

  const username = request_user?.username || '';
  const instructor = request_user?.instructor;
  let insufficientFunds = false;

  if (
    !business?.financial_config?.external_billing &&
    !business?.financial_config?.disable_billing
  ) {
    insufficientFunds =
      instructor &&
      user &&
      user.balance &&
      request_user?.id !== user.id && // make sure the owner is not the one commenting
      (!request || !request.id) && // make sure this is not in response to a request i.e. must be a new request
      instructor.rate > user.balance.available_amount;
  }

  const submitVideo = async (
    file: FileWithPath | null,
    recording: Blob | null,
    vidObj?: FileType,
    request_id?: number | string,
  ) => {
    if (!file && !recording) onSuccess(vidObj?.id, request_id);

    if (file) {
      const data: ISubmitVideoData = {
        title: file.name,
        metadata: file,
        upload_type: 'COMMENT',
        recorded_timestamp: file.lastModified,
        is_comment: true,
        video_comment_video_id: vidObj?.id,
        request_id: request_id,
      };

      dispatch(
        uploadVideo(data, file, (_vid?: FileType) => {
          onSuccess(vidObj?.id, request_id);
        }),
      );
    }

    if (recording) {
      let media_type = '';
      if (recording.type.includes('audio')) {
        media_type = 'AUDIO';
      } else if (recording.type.includes('video')) {
        media_type = 'VIDEO';
      }

      const data: ISubmitVideoData = {
        title: '',
        metadata: {
          size: recording.size,
          type: recording.type,
        },
        upload_type: 'COMMENT',
        recorded_timestamp: 0,
        is_comment: true,
        video_comment_video_id: vidObj?.id,
        request_id,
        media_type: media_type || undefined,
      };

      dispatch(
        uploadVideo(data, recording, (_vid?: FileType) => {
          onSuccess(vidObj?.id, request_id);
        }),
      );
    }
  };

  // This get's feedback on a particular file.
  const getFeedback = async (
    vidObj: FileType,
    comment: string,
    file: FileWithPath | null,
    recording: Blob | null,
  ) => {
    let request_id = requestId;

    if (!request_id) {
      try {
        const resp: any = await dispatch(
          feedbackRequestApi.postRequest({
            rate: request_user?.instructor?.rate,
            requested_id: request_user?.id,
            instructor_id: request_user?.instructor?.id,
            business_id: business?.id,
            video_id: vidObj?.id,
          }),
        );
        request_id = resp.id;

        if (!request_id) {
          dispatch(notification(resp?.data?.error || 'Something went wrong', 'error'));
          return;
        }
      } catch (err) {
        // SHOW MODAL TO TRY AGAIN
        log.exception(err);
      }
    }

    if (comment) {
      dispatch(
        commentApi.postRequest({
          comment,
          video_id: vidObj?.id,
          request_id,
        }),
      );
    }
    submitVideo(file, recording, vidObj, request_id);
  };

  const onSubmit = async () => {
    const comment: string = state.comment;
    const file: FileWithPath | null = fileList?.[0] || null;
    const recording: Blob | null = recordedFile || null;

    if (instructor?.rate && insufficientFunds) {
      dispatch(
        notification(
          "You don't have enough money to complete this request, please top up",
          'error',
        ),
      );
      return;
    }

    if (isShared) {
      const data: { upload_type: string; parent_id?: number; title?: string } = {
        upload_type: 'COMMENT',
      };

      if (video && video.id) {
        data.parent_id = video.id;
        data.title = video.title;
      }

      try {
        const videoResponse: any = await dispatch(videoApi.postRequest(data));
        getFeedback(videoResponse, comment, file, recording);
      } catch (err) {
        log.exception(err);
      }

      segmentAnalytics.track(SEGMENT_CONSTANTS.GET_FEEDBACK_SHARED_SUBMIT, {
        video_id: video && video.id,
      });
    } else {
      getFeedback(video, comment, file, recording);
      segmentAnalytics.track(SEGMENT_CONSTANTS.GET_FEEDBACK_SUBMIT, {
        video_id: video && video.id,
      });
    }
  };

  let renderPrimaryText;
  if (primaryText) {
    renderPrimaryText = primaryText;
  } else {
    renderPrimaryText = (
      <span>
        Get response from <UsernameLink username={username} />
      </span>
    );
  }

  return (
    <GridContainer>
      {!hideAvatarSection && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <List disablePadding>
              <ListItem alignItems="flex-start" disableGutters>
                <ListItemAvatar style={{ marginRight: 30 }}>
                  <UserAvatar user={request_user} size={70} />
                </ListItemAvatar>

                <ListItemText
                  style={{
                    height: 70,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  primary={
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="textPrimary"
                      display="block"
                      className={classnames({ [classes.textDisabled]: false })}
                    >
                      {renderPrimaryText}
                    </Typography>
                  }
                  secondary={<RateDetails instructor={instructor} />}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}

      <FeedbackEntry onSubmit={onSubmit} />
    </GridContainer>
  );
};

export default Feedback;
