import { FC, useEffect, useState, ReactNode } from 'react';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

// Material UI
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App
import ProfileClientSummaryComponent from 'components/User/ProfileClientSummary';
import { FeedItemLoading } from 'components/Loading/index';

import { comment as commentApi } from 'store/index';
import FeedItemComponent from 'components/Comment/FeedItem';
import { card } from 'styles/classnames';
import { FileType } from 'types';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles(() => {
  return {
    paperContainer: {
      marginTop: 10,
      padding: 10,
      width: '100%',
    },
  };
});

interface FeedContainerProps {
  style: any;
  children: ReactNode;
}

const FeedContainer: FC<FeedContainerProps> = ({ children, style = {} }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper elevation={0} className={classnames(classes.paperContainer, card)} style={style}>
        {children}
      </Paper>
    </Grid>
  );
};

interface RenderFeedType {
  feed: FileType[];
  initialized: boolean;
}

const RenderFeed: FC<RenderFeedType> = ({ feed, initialized }) => {
  const [] = useState({});

  if (!initialized) {
    const rows = [...Array(5)].map(i => <FeedItemLoading key={i} height={300} />);
    return <>{rows}</>;
  }

  if (feed.length === 0) {
    return (
      <FeedContainer
        style={{
          minHeight: '40vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" display="block" align="center" style={{ padding: 10 }}>
          You don't have any responses yet.
        </Typography>
      </FeedContainer>
    );
  }

  const rows = feed.map(item => {
    return <FeedItemComponent key={item.id} item={item} />;
  });
  return <>{rows}</>;
};

export default function Dashboard(): JSX.Element {
  const dispatch = useDispatch();
  const commentList = useSelector((state: any) => state[commentApi.APP_NAME].list);
  const commentInitialized = useSelector((state: any) => state[commentApi.APP_NAME].initialized);
  const commentUrl = useSelector((state: any) => state[commentApi.APP_NAME].url);

  useEffect(() => {
    dispatch(commentApi.reset());
    dispatch(commentApi.setGetParams({ limit: 10, no_request: false }));
    dispatch(commentApi.getRequest());
  }, []);

  return (
    <>
      <ProfileClientSummaryComponent />

      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          dispatch(commentApi.getRequest(commentUrl, true));
        }}
        hasMore={commentInitialized && commentUrl}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
      >
        <Grid container justifyContent="center" spacing={2} style={{ marginTop: 20 }}>
          <RenderFeed feed={commentList} initialized={commentInitialized} />
        </Grid>
      </InfiniteScroll>
    </>
  );
}
