import React from 'react';
import { makeStyles } from '@mui/styles';
import numeral from 'numeral';

// Material UI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// core components
import FileThumbnail from 'components/File/Thumbnail';
import { UserAvatar } from 'components/Common/Avatar';
import { StatusRequest } from 'components/Feedback/StatusItem';
import UsernameLink from 'components/User/UsernameLink';

const useStyles = makeStyles({
  media: {
    height: props => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mediaChat: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  thumbMissing: {
    textAlign: 'center',
    // backgroundColor: grey[900],
    height: props => props.height,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardSubheader: {
    textTransform: 'capitalize',
  },
});

export default function FeedItemComponent({ item, height = 250 }) {
  const classes = useStyles({ item, height });

  const { request } = item;
  let detailPath = `/manage-file/${item.id}`.toLowerCase();
  if (request) {
    detailPath = `/request/${request.id}`.toLowerCase();
  }

  const text = item.comment;
  let file_type;
  let thumbnail_uri;
  let created_by = {};
  let title = '';

  if (item.video_comment) {
    file_type = item.video_comment.media_type;
    thumbnail_uri = item.video_comment.thumbnail;
    created_by = item.video_comment.created_by; // Used in audio
    title = item.video_comment.title;
  }

  let subheader;
  if (request && request.id) {
    subheader = (
      <span>
        {request.rate ? (
          <>
            <Typography variant="subtitle" display="inline">
              {numeral(request.rate).format('$ 0.00')}
            </Typography>{' '}
            •{' '}
          </>
        ) : null}

        <StatusRequest item={request} />
      </span>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.root} elevation={1}>
        <CardHeader
          avatar={<UserAvatar user={item.created_by} size={40} />}
          title={
            <>
              <Typography variant="subtitle" display="inline">
                <UsernameLink username={item.created_by && (item.created_by.username || '')} />
              </Typography>

              {(!item.last_viewed || new Date(item.last_viewed) < new Date(item.created_at)) && (
                <Typography variant="subtitle" display="inline" style={{ float: 'right' }}>
                  new
                </Typography>
              )}
            </>
          }
          subheader={subheader}
          classes={{
            subheader: classes.cardSubheader,
          }}
          style={{ paddingBottom: 10, paddingTop: 10 }}
        />

        <FileThumbnail
          path={detailPath}
          height={height}
          thumbnail_uri={thumbnail_uri}
          file_type={file_type}
          text={text}
          created_by={created_by || {}}
          title={title}
        />

        <CardContent style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" display="inline">
                {(item.created_at_humanize || '').toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
