import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { CircularProgress, Typography } from '@mui/material';

import { useAuth } from 'hooks';
import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';
import AuthLayout from './layout';
import GoogleButton from './google-auth';

export default function SigninPage() {
  const {
    setError,
    setPasswordResetState,
    handleAuthenticate,
    credentials,
    isLoading,
    error,
    setCredentials,
  } = useAuth();
  const enabled = !!(credentials.email && credentials.password);

  useEffect(() => {
    setError({});
    setPasswordResetState({});
    setCredentials({ ...credentials, password: '', password1: '', password2: '' });
  }, []);

  function onKeyDown(e: any) {
    if (e.keyCode === 13 && enabled) {
      handleAuthenticate(true);
    }
  }

  let renderErrorAll;
  if (error.all) {
    renderErrorAll = (
      <Typography
        id="error-text"
        variant="body2"
        gutterBottom
        color="error"
        className="tw-mb-4 tw-text-md"
      >
        {error.all}
      </Typography>
    );
  }

  return (
    <AuthLayout>
      <h1 className="tw-mt-10 tw-text-center tw-text-4xl tw-font-semibold tw-text-slate-900">
        Welcome back
      </h1>
      <p className="tw-mt-4 tw-text-center tw-leading-relaxed tw-text-slate-600">
        Don’t have an account yet?
        <Link
          to="/signup"
          className="tw-block tw-text-center tw-font-medium tw-text-slate-800 tw-underline tw-duration-150 hover:tw-text-slate-900 sm:tw-ml-1.5 sm:tw-inline-block sm:tw-text-left"
        >
          Create new account
        </Link>
        .
      </p>
      <div className="tw-mt-10 tw-w-full">{renderErrorAll}</div>

      {isLoading && (
        <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-mt-10">
          <CircularProgress size={24} />
        </div>
      )}

      <div className="tw-mt-8 tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 sm:tw-mt-10 sm:tw-flex-row sm:tw-gap-6">
        <GoogleButton isLogin={true} />
      </div>
      <div className="tw-mt-10 tw-flex tw-w-full tw-items-center">
        <div className="tw-h-px tw-flex-1 tw-bg-gray-secondary-400/60"></div>
        <h4 className="tw-flex-shrink-0 tw-px-4 tw-text-base tw-tracking-wide tw-text-slate-500">
          or sign in with
        </h4>
        <div className="tw-h-px tw-flex-1 tw-bg-gray-secondary-400/60"></div>
      </div>
      <div className="tw-mt-10 tw-w-full">
        <form action="#" method="POST">
          <div>
            <div className="tw-relative tw-mt-1.5">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                placeholder="Email address"
                startAdornment={
                  <EnvelopeIcon className="tw-absolute tw-left-4 tw-top-1/2 tw-h-5 tw-w-5 tw--translate-y-1/2 tw-text-slate-400" />
                }
                value={credentials.email}
                onChange={(ev: any) => setCredentials({ ...credentials, email: ev.target.value })}
                onKeyDown={onKeyDown}
                inputProps={{
                  type: 'email',
                  variant: 'outlined',
                }}
                errorText={error.email}
              />
            </div>
          </div>
          <div className="tw-mt-5">
            <div className="tw-relative tw-mt-1.5">
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="Password"
                startAdornment={
                  <LockClosedIcon className="tw-absolute tw-left-4 tw-top-1/2 tw-h-5 tw-w-5 tw--translate-y-1/2 tw-text-slate-400" />
                }
                value={credentials.password}
                onChange={(ev: any) => {
                  setCredentials({ ...credentials, password: ev.target.value });
                }}
                onKeyDown={onKeyDown}
                inputProps={{
                  type: 'password',
                  autoComplete: 'off',
                  variant: 'outlined',
                }}
                errorText={error.password}
              />
            </div>
            <div className="tw-mt-4 tw-flex tw-justify-between">
              <Link
                to="/password-reset"
                className="tw-font-medium tw-text-slate-800 tw-underline tw-duration-150 hover:tw-text-slate-900"
              >
                Forgot password?
              </Link>
            </div>
          </div>
          <Button
            onClick={() => handleAuthenticate(true)}
            className="tw-mt-8 tw-w-full sm:tw-mt-10"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign in'}
          </Button>
        </form>
      </div>
    </AuthLayout>
  );
}
