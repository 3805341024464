import { FC } from 'react';
import { BASE_DOMAIN } from 'utils/constants';

interface UsernameLinkProps {
  username: string;
}

const UsernameLink: FC<UsernameLinkProps> = ({ username }) => {
  return (
    <a href={`https://${BASE_DOMAIN}/${username}`} style={{ color: 'black', fontWeight: 500 }}>
      @{username}
    </a>
  );
};

export default UsernameLink;
