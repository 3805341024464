import { useEffect } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import classnames from 'classnames';

import { card } from 'styles/classnames';
import { Table as TableComponent, ButtonRouterLink } from 'components/Table';
import {
  eventbriteEvent as eventbriteEventApi,
  eventbriteAttendee as eventbriteAttendeeApi,
} from 'store/index';
import { useDispatch, useSelector } from 'hooks';
import { EventbriteEventType, EventbriteAttendeeType } from 'types/api';
import { ROUTE_MAP } from 'utils/constants';
import Label from 'components/Common/Label';
import { EventbriteCard } from 'containers/Integration/eventbrite/components/connect';

function EventsTable() {
  const dispatch = useDispatch();

  const eventbriteEventList: EventbriteEventType[] = useSelector(
    (state: any) => state[eventbriteEventApi.APP_NAME].list,
  );

  useEffect(() => {
    dispatch(eventbriteEventApi.getRequest());
  }, []);

  const headerList = [
    {
      id: 'name',
      label: 'Event Name',
    },
    // {
    //   id: 'start_date',
    //   label: 'Start',
    // },
    // {
    //   id: 'end_date',
    //   label: 'End',
    // },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'ticket_count',
      label: '# Tickets',
    },
    {
      id: 'feedback_enabled',
      label: 'Feedback Enabled',
    },
    {
      id: 'view',
      label: 'View',
    },
  ];

  const dataList = eventbriteEventList.map(x => {
    const viewLink = `${ROUTE_MAP.integration.eventbrite.event}/${x?.uid}`;

    return {
      id: { value: x.id, display: x.id },
      name: { value: x.name, display: x.name },
      // start_date: { value: x.start_date, display: x.start_date },
      // end_date: { value: x.end_date, display: x.end_date },
      status: { value: x.status, display: x.status },
      ticket_count: { value: x.ticket_count, display: x.ticket_count },
      feedback_enabled: {
        value: x.feedback_enabled,
        display: (
          <Label variant="filled" color={x.feedback_enabled ? 'success' : 'error'}>
            {sentenceCase(x.feedback_enabled ? 'yes' : 'no')}
          </Label>
        ),
      },
      view: { value: viewLink, display: <ButtonRouterLink link={viewLink} /> },
    };
  });

  return (
    <Grid item xs={12} marginTop={5}>
      <Typography variant="h4" display="block" className="tw-mb-4">
        Upcoming events with feedback
      </Typography>

      <TableComponent
        headerList={headerList}
        dataList={dataList}
        emptyNode={
          <Paper>
            <Typography gutterBottom align="center" variant="subtitle1">
              No upcoming events
            </Typography>
            <Typography variant="body2" align="center">
              You currently don't have any upcoming events.
            </Typography>
          </Paper>
        }
      />
    </Grid>
  );
}

function EventsAttendees() {
  const dispatch = useDispatch();

  const eventbriteAttendeeList: EventbriteAttendeeType[] = useSelector(
    (state: any) => state[eventbriteAttendeeApi.APP_NAME].list,
  );

  useEffect(() => {
    dispatch(eventbriteAttendeeApi.setGetParams({ is_feedback: true }));
    dispatch(eventbriteAttendeeApi.getRequest());
  }, []);

  const headerList = [
    {
      id: 'name',
      label: 'Attendee Name',
    },
    {
      id: 'feedback_requested',
      label: 'Requested Feedback',
    },
    {
      id: 'feedback_completed',
      label: 'Received Feedback',
    },
    {
      id: 'view',
      label: 'View',
    },
  ];

  const dataList = eventbriteAttendeeList.map(x => {
    return {
      id: { value: x.id, display: x.id },
      name: { value: x.name, display: x.name },
      feedback_requested: {
        value: x.feedback_requested,
        display: (
          <Label variant="filled" color={x.feedback_requested ? 'success' : 'error'}>
            {sentenceCase(x.feedback_requested ? 'yes' : 'no')}
          </Label>
        ),
      },
      feedback_completed: {
        value: x.feedback_completed,
        display: (
          <Label variant="filled" color={x.feedback_completed ? 'success' : 'error'}>
            {sentenceCase(x.feedback_completed ? 'yes' : 'no')}
          </Label>
        ),
      },
    };
  });

  return (
    <Grid item xs={12} marginTop={5}>
      <Typography variant="h4" display="block" className="tw-mb-4">
        Attendees signed up for feedback
      </Typography>

      <TableComponent
        headerList={headerList}
        dataList={dataList}
        emptyNode={
          <Paper>
            <Typography gutterBottom align="center" variant="subtitle1">
              No attendees
            </Typography>
            <Typography variant="body2" align="center">
              No attendee has signed up yet.
            </Typography>
          </Paper>
        }
      />
    </Grid>
  );
}

export default function Eventbrite(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" display="block">
            Eventbrite Connection
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classnames(card)}>
            <EventbriteCard />
          </div>
        </Grid>

        <EventsTable />

        <EventsAttendees />
      </Grid>
    </Container>
  );
}
