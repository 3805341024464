// App
import ActivateComponent from 'components/Instructor/Activate';
import ConfigureComponent from 'components/Instructor/Configure';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';

export default function FolderContainer(): JSX.Element {
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  if (user && user.instructor && user.instructor.id) {
    return (
      <div>
        <ConfigureComponent />
      </div>
    );
  }
  return (
    <div>
      <ActivateComponent />
    </div>
  );
}
