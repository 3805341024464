// Material UI
import { ContentCard } from 'layouts/dashboard/Content';

// App
import Withdraw from 'components/Fund/Withdraw';
import TransactionHistory from 'components/Fund/TransactionHistory';
import DepositHistory from 'components/Fund/DepositHistory';
import { useBusiness } from 'hooks';

export default function Balance(): JSX.Element {
  const { business } = useBusiness();

  return (
    <>
      <ContentCard>
        {business?.financial_config?.external_billing ||
        business?.financial_config?.disable_billing ? null : (
          <Withdraw />
        )}
      </ContentCard>

      <ContentCard>
        <TransactionHistory />
      </ContentCard>

      <ContentCard>
        <DepositHistory />
      </ContentCard>
    </>
  );
}
