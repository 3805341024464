import Grid from '@mui/material/Grid';

// Material ui
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// App
import Withdraw from 'components/Fund/Withdraw';
import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    paddingLeft: '7%',
    paddingRight: '7%',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },

  gridContainer: {
    marginBottom: 20,
  },
}));

export default function WithdrawContainer(): JSX.Element | null {
  const classes = useStyles();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  if (user && user.instructor && user.instructor.id) {
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Withdraw />
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
}
