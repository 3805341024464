import { gql } from '@apollo/client';

export const getInvitedMemberQuery = gql`
  query getInvitedMember($inviteToken: ID!) {
    getInvitedMember(inviteToken: $inviteToken) {
      uid
      email
      status
      memberType
      business {
        uid
        name
        subdomain
      }
      member {
        id
        username
        fullName
        initials
        profile {
          uid
          image
          bio
        }
      }
      admin {
        id
        username
        fullName
        initials
        profile {
          uid
          image
          bio
        }
      }
    }
  }
`;

export const getStaffAnalyticsQuery = gql`
  query getStaffAnalytics($businessUid: ID!, $memberUid: ID!) {
    getStaffAnalytics(businessUid: $businessUid, memberUid: $memberUid) {
      totalClients
      totalRequests
      totalRevenue
      monthlyRevenue {
        month
        value
      }
      monthlyRequests {
        month
        value
      }
    }
  }
`;

export const getBusinessAnalyticsQuery = gql`
  query getBusinessAnalytics($businessUid: ID!) {
    getBusinessAnalytics(businessUid: $businessUid) {
      totalClients
      totalRequests
      totalRevenue
      monthlyRevenue {
        month
        value
      }
      monthlyRequests {
        month
        value
      }
    }
  }
`;

export const getUserAnalyticsQuery = gql`
  query getUserAnalytics {
    getUserAnalytics {
      totalViews
      totalRequests
      totalRevenue
      viewHistory
      monthlyRevenue {
        month
        value
      }
      monthlyRequests {
        month
        value
      }
    }
  }
`;
