import { useEffect, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import log from 'loglevel';

// @types
import { SendMessage } from 'types/chat';
import ChatMessageList from 'components/Chat/ChatMessageList';
import ChatHeader from 'components/Chat/ChatHeaderDetail';
import ChatMessageInput from 'components/Chat/ChatMessageInput';
import { feedbackRequest as feedbackRequestApi, comment as commentApi } from 'store/index';
import { RequestType } from 'types/index';
import { useDispatch, useSelector } from 'hooks';
import { primaryColor } from 'styles/classnames';

const MySwal = withReactContent(Swal);

interface ChatWindowProps {
  requestId?: number;
  shouldNotResetCommentApi?: boolean;
}

export default function ChatWindow({
  requestId,
  shouldNotResetCommentApi,
}: ChatWindowProps): JSX.Element {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const request: RequestType = useSelector(
    (state: any) => state[feedbackRequestApi.APP_NAME].detail,
  );

  useEffect(() => {
    if (!shouldNotResetCommentApi) dispatch(commentApi.reset());

    if (requestId) {
      dispatch(commentApi.setGetParams({ request_id: requestId }));
      // @ts-ignore
      dispatch(commentApi.getRequest());
    }
  }, [requestId]);

  const handleSendMessage = async ({ message }: SendMessage) => {
    if (!request.id) {
      MySwal.fire({
        title: 'The request was not set',
        text: '',
      });
      return;
    }
    try {
      const data = {
        request_id: request.id,
        comment: message,
      };
      // @ts-ignore
      await dispatch(commentApi.postRequest(data));
    } catch (error) {
      log.error(error);
    }
  };

  if (collapsed) {
    return (
      <Fab
        color="primary"
        style={{
          position: 'absolute',
          bottom: 20,
          right: 15,
          zIndex: 10,
          cursor: 'pointer',
        }}
        className={primaryColor}
      >
        <ChatIcon onClick={() => setCollapsed(!collapsed)} style={{ width: 30, height: 30 }} />
      </Fab>
    );
  }

  return (
    <div
      className="tw-bg-white tw-shadow"
      style={{ position: 'absolute', width: 300, top: 0, right: 0, bottom: 0, zIndex: 10 }}
    >
      <ChatHeader setCollapsed={() => setCollapsed(!collapsed)} />

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList />

          <Divider style={{ position: 'absolute', bottom: 55, right: 0, left: 0 }} />

          <ChatMessageInput onSend={handleSendMessage} />
        </Stack>
      </Box>
    </div>
  );
}
