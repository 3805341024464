import ReduxAPI from './api';
import appReducer from './app';

import { reducer as notificationReducer } from './notification';

export const board = new ReduxAPI('board', '/api/v1/board/board/');

export const userAuth = new ReduxAPI('userAuth', '/api/v1/rest-auth/');
export const userPublic = new ReduxAPI('userPublic', '/api/v1/user/username/');
export const connection = new ReduxAPI('connection', '/api/v1/user/connection/');

// Video
export const video = new ReduxAPI('video', '/api/v1/source/video/');
export const videoShared = new ReduxAPI('videoShared', '/api/v1/source/shared-video/');
export const clientFile = new ReduxAPI('videoShared', '/api/v1/source/client-file/');
export const comment = new ReduxAPI('comment', '/api/v1/comment/videocomment/');

// Feedback
export const feedbackRequest = new ReduxAPI('feedbackRequestReceived', '/api/v1/feedback/request/');
export const feedbackRequestReceived = new ReduxAPI(
  'feedbackRequestReceived',
  '/api/v1/feedback/request/',
);
export const feedbackRequestSent = new ReduxAPI('feedbackRequestSent', '/api/v1/feedback/request/');
export const feedbackAvailability = new ReduxAPI(
  'feedbackAvailability',
  '/api/v1/feedback/availability/',
);

// Transaction
export const transaction = new ReduxAPI('transaction', '/api/v1/transaction/transaction/');
export const transactionPending = new ReduxAPI(
  'transactionPending',
  '/api/v1/transaction/pending/',
);
export const deposit = new ReduxAPI('deposit', '/api/v1/transaction/deposit/');

// Focus
export const focus = new ReduxAPI('focus', '/api/v1/core/focus/');
export const userFocus = new ReduxAPI('userFocus', '/api/v1/user/focus/');

export const instructor = new ReduxAPI('instructor', '/api/v1/instructor/instructor/');

export const business = new ReduxAPI('business', '/api/v1/business/business/');
export const businessMember = new ReduxAPI('businessMember', '/api/v1/business/business-member/');

export const fcmDevice = new ReduxAPI('fcmDevice', '/api/v1/notification/devices/');

export const eventbriteEvent = new ReduxAPI(
  'eventbriteEvent',
  '/api/v1/integration/eventbrite_event/',
);
export const eventbriteTicketClass = new ReduxAPI(
  'eventbriteTicketClass',
  '/api/v1/integration/eventbrite_ticket_class/',
);
export const eventbriteAttendee = new ReduxAPI(
  'eventbriteAttendee',
  '/api/v1/integration/eventbrite_attendee/',
);

export default {
  userAuth: userAuth.reducer,
  board: board.reducer,
  video: video.reducer,
  videoShared: videoShared.reducer,
  clientFile: clientFile.reducer,

  feedbackRequest: feedbackRequest.reducer,
  feedbackRequestReceived: feedbackRequestReceived.reducer,
  feedbackRequestSent: feedbackRequestSent.reducer,
  feedbackAvailability: feedbackAvailability.reducer,
  userPublic: userPublic.reducer,

  // Transaction
  transaction: transaction.reducer,
  transactionPending: transactionPending.reducer,
  deposit: deposit.reducer,

  // Focus
  focus: focus.reducer,
  userFocus: userFocus.reducer,

  connection: connection.reducer,
  comment: comment.reducer,

  instructor: instructor.reducer,

  fcmDevice: fcmDevice.reducer,

  business: business.reducer,
  businessMember: businessMember.reducer,

  eventbriteEvent: eventbriteEvent.reducer,
  eventbriteTicketClass: eventbriteTicketClass.reducer,
  eventbriteAttendee: eventbriteAttendee.reducer,

  notification: notificationReducer,
  app: appReducer,
};
