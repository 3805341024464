import { useState } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

// App
import { FileType } from 'types';

export default function VideoDescription({ video }: { video?: FileType }): JSX.Element {
  const [readMore, setReadMore] = useState(false);
  if (!video?.description) return <span />;

  return (
    <>
      <Collapse in={readMore} collapsedSize={45}>
        <Typography
          variant="body2"
          gutterBottom
          display="block"
          className="tw-whitespace-pre-wrap tw-pr-4 tw-text-base"
        >
          {video?.description}
        </Typography>
      </Collapse>

      {video?.description?.length > 100 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setReadMore(!readMore)}
          style={{
            padding: 0,
            marginTop: 20,
            textTransform: 'capitalize',
            fontSize: 16,
          }}
        >
          {readMore ? 'Close' : 'Read More'}
        </Button>
      ) : null}
    </>
  );
}
