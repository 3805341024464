import { FC, useState } from 'react';

// Material UI
import { makeStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';

// App
import MainComponent from 'containers/Auth/components/Main';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
}));

const CustomDialog = withStyles((theme: Theme) => ({
  paperWidthSm: {
    [theme.breakpoints.up('sm')]: {
      height: 750,
    },
  },
}))(Dialog);

interface AuthDialogProps {
  onClose: VoidFunction;
  open: boolean;
  onSuccess?: VoidFunction;
}

const AuthDialog: FC<AuthDialogProps> = ({ onClose, open, onSuccess }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEmail, setIsEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="scroll-dialog-title">
        {isEmail ? (
          <IconButton
            aria-label="back"
            className={classes.backButton}
            onClick={() => {
              setIsEmail(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        ) : null}

        {onClose ? (
          <IconButton
            id="auth-modal-close"
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <MainComponent
          onClose={onClose}
          onSuccess={onSuccess}
          setIsEmail={setIsEmail}
          setIsLogin={setIsLogin}
          isLogin={isLogin}
          isEmail={isEmail}
        />
      </DialogContent>
    </CustomDialog>
  );
};

interface AuthModalProps {
  title: string;
  onSuccess?: VoidFunction;
  buttonProps?: any;
  disabled?: boolean;
  className?: string;
}

export default function AuthModal({
  title,
  onSuccess,
  buttonProps = {},
  disabled,
  className,
}: AuthModalProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        id="auth-button"
        variant="contained"
        fullWidth
        onClick={() => {
          handleClickOpen();
          segmentAnalytics.track(SEGMENT_CONSTANTS.SHARED_DETAIL_FEEDBACK_AUTH_MODAL);
        }}
        disabled={disabled}
        className={className}
        {...buttonProps}
      >
        {title}
      </Button>
      <AuthDialog open={open} onClose={handleClose} onSuccess={onSuccess} />
    </>
  );
}

interface AuthIconProps {
  icon: JSX.Element;
  onSuccess: VoidFunction;
  className: string;
}

export const AuthIcon: FC<AuthIconProps> = ({ icon, onSuccess, className }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        id="auth-icon-button"
        aria-label="authenticate user"
        component="span"
        className={className}
        onClick={() => {
          handleClickOpen();
          segmentAnalytics.track(SEGMENT_CONSTANTS.SHARED_DETAIL_FEEDBACK_AUTH_MODAL);
        }}
      >
        {icon}
      </IconButton>

      <AuthDialog open={open} onClose={handleClose} onSuccess={onSuccess} />
    </>
  );
};
