// import 'styles/tailwind.css';
import { ReactNode } from 'react';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

export default function RootLayout({ children }: { children: ReactNode }) {
  return (
    <div className="tw-min-h-screen tw-font-sans">
      <Header />
      {children}
      <Footer />
    </div>
  );
}
