import clsx from 'clsx';
import { ReactNode } from 'react';

export function Container({
  className,
  children,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={clsx('tw-mx-auto tw-max-w-screen-xl tw-px-4 sm:tw-px-6 lg:tw-px-8', className)}
      {...props}
    >
      {children}
    </div>
  );
}
