import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FileWithPath } from 'file-selector';
import { useNavigate } from 'react-router-dom';

// Material ui
import Grid from '@mui/material/Grid';
import InfiniteScroll from 'react-infinite-scroller';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

// core components
import ImageGridComponent from 'components/File/ItemGrid';
import UploadPage from 'containers/Upload/index';
import { VideoListLoading } from 'components/Loading/index';
import { video as videoApi } from 'store/index';
import { FileType, SubmitVideoType } from 'types';
import { buttonPrimary } from 'styles/classnames';
import { uploadVideo } from 'store/firebase';
import { useDispatch, useSelector } from 'hooks';

interface ManageVideoListProps {
  boardId?: number | string;
  renderEmpty?: () => JSX.Element;
}

export default function ManageVideoList({
  boardId,
  renderEmpty,
}: ManageVideoListProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const initialized = useSelector((state: any) => state[videoApi.APP_NAME].initialized);
  const list: FileType[] = useSelector((state: any) => state[videoApi.APP_NAME].list);
  const nextUrl = useSelector((state: any) => state[videoApi.APP_NAME].url);
  const firebaseStorage = useSelector((state: any) => state.firebaseStorage);
  const progress = useSelector((state: any) => state.app.uploadProgress);

  useEffect(() => {
    const params: {
      limit: number;
      upload_type: string;
      board_id?: number | string;
      no_parent?: boolean;
    } = { limit: 30, upload_type: 'PRIMARY' };
    if (boardId) {
      params.board_id = boardId;
    } else {
      // if from a folder return all videos in the folder even if shared
      params.no_parent = true;
    }
    dispatch(videoApi.reset());
    dispatch(videoApi.setGetParams(params));
    dispatch(videoApi.getRequest());
  }, []);

  useEffect(() => {
    const file = fileList?.[0];

    if (!file) return;

    // submitVideo(file, recording, request_id);
    const data: SubmitVideoType = {
      title: file.name,
      metadata: file,
      upload_type: 'MANUAL',
      recorded_timestamp: file.lastModified,
      is_comment: false,
    };

    dispatch(
      uploadVideo(data, file, (video: any) => {
        navigate(`/manage-file/${video.id}`);
      }),
    );
  }, [fileList]);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFileList?.(acceptedFiles);
    },
    [setFileList],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { ...rootProps } = getRootProps();

  if (!initialized) {
    return (
      <Container maxWidth="xl">
        <VideoListLoading />
      </Container>
    );
  }

  // If no video, or a single video and assuming the video is the one being currently uploaded
  if (
    (initialized && list.length === 0) ||
    (list.length === 1 && firebaseStorage.payload && firebaseStorage.payload.percent)
  ) {
    if (renderEmpty) return renderEmpty();
    return (
      <Container maxWidth="xl">
        <UploadPage />
      </Container>
    );
  }
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item md={8} className="tw-flex tw-items-center tw-justify-center md:tw-justify-start">
          <Typography variant="h5" display="inline">
            Files
          </Typography>
        </Grid>

        <Grid item md={4}>
          <span {...getRootProps()} className={classnames('dropzone dz-clickable')}>
            <input {...getInputProps()} id="feedback-row-dropzone-input" />
            <div {...rootProps}>
              <Button
                id="button-activate-instructor"
                variant="outlined"
                className={classnames(buttonPrimary, 'tw-float-right tw-flex tw-flex-1 tw-text-lg')}
              >
                Upload File
              </Button>
            </div>
          </span>
        </Grid>

        {progress && progress.percent !== undefined && (
          <Grid item md={12}>
            <span>{(firebaseStorage.status || '').toLowerCase()}</span>
            <LinearProgress
              variant="buffer"
              value={progress.percent}
              valueBuffer={progress.percent * 1.2}
              style={{ height: 30 }}
            />
          </Grid>
        )}
      </Grid>

      <Grid item md={12} style={{ marginTop: 8 }} />

      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (nextUrl) {
            dispatch(videoApi.getRequest(nextUrl, true));
          }
        }}
        hasMore={nextUrl}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
      >
        <Grid container spacing={3}>
          {list?.map(datum => {
            return (
              <Grid key={datum.id} item xs={12} sm={6} md={4} lg={4}>
                <ImageGridComponent
                  video={datum}
                  style={{ height: 350, objectFit: 'contain' }}
                  path={`/manage-file/${datum.id}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
}
