import { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';

// MUI
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';

// App
import RenderBio from 'components/Bio/View';
import SocialIcons from 'components/Icon/Social';
import FocusItemList from 'components/Focus/List';
import { UserAvatar } from 'components/Common/Avatar';
import { userAuth as userAuthApi } from 'store/index';
import { buttonPrimary, card } from 'styles/classnames';
import { UserAuthType, UserBaseType } from 'types';
import { useSelector } from 'hooks';
import { BASE_DOMAIN, APP_HUMAN_NAME } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: 'auto',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
      marginBottom: 20,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  editContainer: {
    marginTop: 7,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

interface ShowHelpProps {
  open: boolean;
  handleClose: VoidFunction;
  user: UserBaseType;
}

const ShowHelp: FC<ShowHelpProps> = ({ open, handleClose, user }) => {
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Help Guide</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" style={{ marginTop: 10 }} display="block" gutterBottom>
            Copy and share your {APP_HUMAN_NAME} profile link (
            <a
              href={`https://${BASE_DOMAIN}/${user.username}`}
            >{`https://${BASE_DOMAIN}/${user.username}`}</a>
            ) with your followers or students on your social media platforms e.g. Instagram, TikTok,
            YouTube, Facebook or send it to them as an email.
          </Typography>
          <Typography variant="body1" style={{ marginTop: 10 }} display="block" gutterBottom>
            When they click the link and ask you a question/comment or upload a video requesting
            feedback, they will pay you the rate you set through the {APP_HUMAN_NAME} platform.
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} className={classnames(buttonPrimary, 'tw-text-lg')}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const ProfileSummary: FC = () => {
  const classes = useStyles();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [state, setState] = useState({
    copied: false,
    showHelp: false,
  });
  const { instructor } = user;
  const rate = (instructor && instructor.rate) || 0;
  const rate_list = (instructor && instructor.rate_list) || [];

  if (user?.is_client) {
    return (
      <div className={classnames(classes.root, card)}>
        <Grid
          container
          style={{ paddingTop: 20, paddingBottom: 20 }}
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={4} lg={3} xl={2} className={classes.avatarContainer}>
            <UserAvatar user={user} size={150} />
          </Grid>

          <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
            <Typography variant="h5" display="block" style={{ marginTop: 10, marginBottom: 3 }}>
              {user.full_name || `@${user.username}`}
            </Typography>

            {user.full_name ? (
              <Typography variant="body1" display="block" style={{ marginBottom: 3 }}>
                @{user.username}
              </Typography>
            ) : null}

            <div className={classnames(classes.editContainer, 'tw-mt-7')}>
              <Link to="/account">
                <Button
                  variant="outlined"
                  className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-sm')}
                  id="edit-profile"
                >
                  Edit
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={classnames(classes.root, card)}>
      <Grid
        container
        style={{ paddingTop: 20, paddingBottom: 20 }}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4} lg={3} xl={2} className={classes.avatarContainer}>
          <UserAvatar user={user} size={150} />
        </Grid>

        <Grid item xs={12} md={8} lg={9} xl={10} className={classes.contentRight}>
          <Typography variant="h5" display="block" style={{ marginTop: 10, marginBottom: 3 }}>
            {user.full_name || `@${user.username}`}
          </Typography>

          {user.full_name ? (
            <Typography variant="body1" display="block" style={{ marginBottom: 3 }}>
              @{user.username}
            </Typography>
          ) : null}

          <Typography variant="body1" display="block" className="tw-mt-4" id="rate-per-question">
            <b>{numeral(rate || 0).format('$ 0.00')}</b> / question
          </Typography>

          {rate_list
            .sort((a: any, b: any) => a.rate - b.rate)
            .map(row => (
              <Typography
                variant="body1"
                display="block"
                style={{ marginBottom: 3 }}
                id={`rate-per-video-${row.uid}`}
              >
                <b>{numeral(row.rate || 0).format('$ 0.00')}</b> / {row.duration} minute call
              </Typography>
            ))}

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ marginTop: 3 }} id="copy-and-share-link">
                <a
                  href={`https://${BASE_DOMAIN}/${user.username}`}
                  style={{
                    padding: 3,
                    borderRadius: 5,
                    backgroundColor: state.copied ? '#c4e5d6' : 'initial',
                  }}
                  target="_blank"
                  className="tw-mr-1 tw-cursor-pointer"
                >
                  https://{BASE_DOMAIN}/{user.username}
                </a>

                <CopyToClipboard
                  text={`https://${BASE_DOMAIN}/${user.username}`}
                  onCopy={() => {
                    setState({ ...state, copied: true });
                    setTimeout(() => {
                      setState({ ...state, copied: false });
                    }, 2000);
                  }}
                >
                  <IconButton
                    color="primary"
                    id="edit-profile"
                    className="tw-text-black"
                    style={{ padding: 0, marginRight: 3 }}
                  >
                    <ContentCopyIcon fontSize="small" className="tw-mr-1 tw-cursor-pointer" />
                  </IconButton>
                </CopyToClipboard>

                <IconButton
                  color="primary"
                  id="edit-profile"
                  onClick={() => {
                    setState({ ...state, showHelp: true });
                  }}
                  className="tw-text-black"
                  style={{ padding: 0, marginRight: 3 }}
                >
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {state.copied ? (
                <Typography variant="subtitle1" style={{ marginTop: 3 }} id="copy-and-share-link">
                  (Copied)
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          {Object.keys(user?.profile?.links || {}).length ? (
            <SocialIcons links={user?.profile?.links || {}} align="left" />
          ) : null}

          <div className="tw-mt-1" />

          <FocusItemList user={user} justify="flex-start" />

          <div className="tw-mt-1" />

          <RenderBio bio={user?.profile?.bio} />

          <div className={classnames(classes.editContainer, 'tw-mt-7')}>
            <Link to="/account">
              <Button
                variant="outlined"
                className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-sm')}
                id="edit-profile"
              >
                Edit
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>

      {state.showHelp && (
        <ShowHelp
          handleClose={() => setState({ ...state, showHelp: false })}
          open={state.showHelp}
          user={user}
        />
      )}
    </div>
  );
};

export default ProfileSummary;
