import { FC, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// core components
import UserItem from 'components/User/PublicItem';
import { CircularLoading } from 'components/Loading/index';
import { userPublic as userPublicApi } from 'store/index';
import { UserAuthType } from 'types/index';
import { useDispatch, useSelector } from 'hooks';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

const PublicEmployeeList: FC = ({}) => {
  const dispatch = useDispatch();
  const initialized = useSelector((state: any) => state[userPublicApi.APP_NAME].initialized);
  const list: UserAuthType[] = useSelector((state: any) => state[userPublicApi.APP_NAME].list);
  const nextUrl = useSelector((state: any) => state[userPublicApi.APP_NAME].url);
  const params = useParams();

  useEffect(() => {
    dispatch(userPublicApi.reset());
    dispatch(userPublicApi.setGetParams({ limit: 20, business_uid: params.businessId }));
    dispatch(userPublicApi.getRequest());
  }, []);

  if (!initialized) {
    return <CircularLoading />;
  }

  return (
    <Container maxWidth="lg" className="tw-py-8">
      <InfiniteScroll
        pageStart={0}
        hasMore={!!nextUrl}
        loadMore={() => {
          if (nextUrl) {
            dispatch(userPublicApi.getRequest(nextUrl, true));
          }
        }}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
      >
        <Grid container justifyContent="center" spacing={2}>
          {list.length !== 0 ? (
            list?.map(datum => {
              return (
                <Grid key={datum.id} item xs={12} sm={6} md={4} lg={4}>
                  <UserItem
                    user={datum}
                    viewLink={`/embed/${params.businessId}/${datum.username}`}
                  />
                </Grid>
              );
            })
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 50,
              }}
            >
              <Grid item xs={12} className="tw-mt-4">
                <Alert severity="error" className="tw-font-bold tw-text-md tw-flex tw-items-center">
                  No instructors found for this business.
                </Alert>
              </Grid>
            </div>
          )}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

export default PublicEmployeeList;
