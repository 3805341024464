import { ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import Grid, { GridSize } from '@mui/material/Grid';

interface CustomTextFieldProps {
  field: string;
  placeholder?: string;
  state: Record<string, unknown>;
  error: Record<string, string>;
  setState: (state: any) => void;
  lg?: GridSize;
  md?: GridSize;
  xs?: GridSize;
  sm?: GridSize;
  renderBottom?: ReactNode;
  textProps?: Record<string, unknown>;
  gridId?: string;
  inputId?: string;
}

export function CustomTextField({
  field,
  placeholder,
  state = {},
  error,
  setState,
  lg = 6,
  md = 6,
  xs = 12,
  sm = 12,
  renderBottom,
  textProps = {},
  gridId = '',
  inputId = 'outlined-basic',
}: CustomTextFieldProps): JSX.Element {
  return (
    <Grid id={gridId} item xs={xs} sm={sm} md={md} lg={lg} style={{ marginBottom: 10 }}>
      <TextField
        id={inputId}
        label={error[field] ? error[field] : placeholder}
        error={!!error[field]}
        variant="outlined"
        onChange={ev => setState({ ...state, [field]: ev.target.value })}
        value={state[field]}
        placeholder={placeholder}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        {...textProps}
      />

      {renderBottom && renderBottom}
    </Grid>
  );
}
