// material
import { Grid, Container, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import { getUserAnalyticsQuery } from 'store/graphql/query';
import TotalCard from 'components/Analytics/TotalCard';
import MonthlyBreakdown from 'components/Analytics/MonthlyBreakdown';

export default function Analytics(): JSX.Element {
  const { loading, data } = useQuery(getUserAnalyticsQuery, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Analytics
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getUserAnalytics?.totalViews}
              loading={loading}
              title="Total Views"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getUserAnalytics?.totalRequests}
              loading={loading}
              title="Total Requests"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TotalCard
              value={data?.getUserAnalytics?.totalRevenue}
              loading={loading}
              title="Total Revenue"
              isCurrency
            />
          </Grid>

          <Grid item xs={12}>
            <MonthlyBreakdown
              title="Requests"
              data={data?.getUserAnalytics?.monthlyRequests}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <MonthlyBreakdown
              title="Revenue"
              data={data?.getUserAnalytics?.monthlyRevenue}
              loading={loading}
              isCurrency
            />
          </Grid>
        </Grid>
      </Container>

      {/* {business?.financial_config?.disable_billing ? null : (
        <Container maxWidth="xl">
          <div className={classnames(card)}>
            <DepositHistory />
          </div>
        </Container>
      )}

      {business?.financial_config?.disable_billing ? null : (
        <Container maxWidth="xl">
          <div className={classnames(card)}>
            <TransactionHistory />
          </div>
        </Container>
      )} */}
    </>
  );
}
