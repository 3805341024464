// import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

// Material UI
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import Grid, { GridSize } from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import phoneImage from 'images/phone.png';
import { UserAvatar } from 'components/Common/Avatar';
import FileThumbnail from 'components/File/Thumbnail';
import UsernameLink from 'components/User/UsernameLink';
import { userAuth as userAuthApi } from 'store/index';
import { StatusRequest } from './StatusItem';
import { UserAuthType, RequestType } from 'types/api';
import dayjs from 'utils/dayjs';
import { useSelector } from 'hooks';

interface StyleProps {
  height: number;
}

const useStyles = makeStyles({
  media: {
    height: (props: StyleProps) => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mediaChat: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  thumbMissing: {
    textAlign: 'center',
    height: (props: StyleProps) => props.height,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardSubheader: {
    textTransform: 'capitalize',
  },
  icon: {
    fontSize: 60,
    color: grey[300],
  },
});

interface IMeetingDetails {
  request: RequestType;
}

function MeetingDetails({ request }: IMeetingDetails) {
  const { details } = request;
  const { date, time, duration, timezone } = details;
  return (
    <Box>
      <Typography variant="h6" align="center" className="tw-w-full tw-text-lg">
        {dayjs(date).format('DD-MMM-YYYY')} at {time}
      </Typography>

      <Typography variant="h6" align="center" className="tw-w-full tw-text-lg">
        for {duration} minutes.
      </Typography>

      <Typography variant="body1" display="block" className="tw-w-full tw-text-base">
        {timezone}
      </Typography>
    </Box>
  );
}

interface IFeedbackComponent {
  item: RequestType;
  height: number;
  lg: GridSize;
}

export default function FeedbackComponent({
  item,
  height = 150,
  lg = 3,
}: IFeedbackComponent): JSX.Element {
  const currentAuthUser: UserAuthType = useSelector(
    (state: any) => state[userAuthApi.APP_NAME].detail,
  );
  const classes = useStyles({ height });
  const detailPath = `/request/${item.uuid}`;
  const file_type = item?.video?.media_type || item?.list_view?.media_type;
  const requestText = item?.list_view?.comment;
  const thumbnail_uri = item?.video?.thumbnail || item?.list_view?.thumbnail;
  const title = item?.video?.title || item?.list_view?.title;
  let cardTitle;
  let username;
  let action;
  const created_by = item.created_by;
  let other = created_by;

  if (currentAuthUser.id === created_by?.id) {
    cardTitle = 'To ';
    // @ts-ignore
    other = item.requested ? item.requested : {};
    username = other?.username;
    action = 'sent';
  } else {
    cardTitle = 'From ';
    other = item.created_by;
    username = other?.username;
    action = 'received';
  }

  cardTitle = (
    <span className="tw-break-all">
      {cardTitle} <UsernameLink username={username} />
    </span>
  );

  if (item?.rate) {
    cardTitle = (
      <>
        {cardTitle} for {numeral(item?.rate).format('$ 0.00')}{' '}
      </>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={lg}>
      <Card>
        <CardHeader
          avatar={<UserAvatar user={other} size={40} />}
          title={cardTitle}
          subheader={`${action} ${item.created_at_humanize}`.toLowerCase()}
          classes={{
            subheader: classes.cardSubheader,
          }}
          style={{ paddingBottom: 10, paddingTop: 10 }}
          action={
            <div style={{ padding: 10 }}>
              <StatusRequest item={item} />
            </div>
          }
        />

        {item.request_type === 'CALL' ? (
          <Link to={detailPath}>
            <Box className={classes.thumbMissing} sx={{ height: height }}>
              <img src={phoneImage} style={{ width: 30, height: 30, marginRight: 7 }} />
              <Typography
                variant="body1"
                gutterBottom
                style={{ color: 'black', padding: 20, textDecoration: 'none', paddingBottom: 5 }}
              >
                Video Call Request
              </Typography>

              <MeetingDetails request={item} />
            </Box>
          </Link>
        ) : (
          <FileThumbnail
            path={detailPath}
            height={height}
            thumbnail_uri={thumbnail_uri}
            file_type={file_type}
            text={requestText}
            created_by={created_by}
            title={title}
          />
        )}

        <CardContent style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Tooltip title="Total Comments" placement="bottom">
                <Typography
                  variant="caption"
                  style={{ display: 'flex', alignItems: 'center', color: grey[700] }}
                >
                  {item.video_comment_count} response{item.video_comment_count === 1 ? '' : 's'}
                </Typography>
              </Tooltip>

              <Typography
                variant="caption"
                style={{ display: 'flex', alignItems: 'center', color: grey[700] }}
              >
                Updated: {item.last_activity_humanize}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
