import { Button, ButtonProps } from '@mui/material';
import classnames from 'classnames';

import { buttonPrimary } from 'styles/classnames';

interface ButtonSaveProps extends ButtonProps {
  onClick?: VoidFunction;
}

export function SaveButton({ onClick }: ButtonSaveProps) {
  return (
    <Button
      variant="outlined"
      className={classnames(buttonPrimary, 'tw-w-36 tw-text-lg')}
      onClick={onClick}
    >
      Save
    </Button>
  );
}
