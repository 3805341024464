/**
 * Once someone asks a question and they are directed to the payment, this component intercepts the request,
 * retrieves the intent and then completes the feedback assuming success
 */
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { grey } from '@mui/material/colors';
import queryString from 'query-string';

// App
import { UserAvatar } from 'components/Common/Avatar';
import FocusItemList from 'components/Focus/List';
import SocialIcons from 'components/Icon/Social';
import { CircularLoading } from 'components/Loading/index';
import RenderBio from 'components/Bio/View';
import RateDetails from 'components/Instructor/rate-details';
import { userPublic as userPublicApi } from 'store/index';
import { UserBaseType } from 'types';
import AskQuestion from 'components/Feedback/AskQuestion';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: grey[100],
    width: '100%',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },

  contentRight: {
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  contentContainer: {
    maxWidth: '60rem',
    width: '100%',
    alignItems: 'center',
  },
}));

const Profile: FC = () => {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialized: boolean = useSelector(
    (state: any) => state[userPublicApi.APP_NAME].initialized,
  );
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const location = useLocation();
  const intentId = params?.intentId;
  const query = queryString.parse(location.search) || {};
  const { payment_intent, payment_intent_client_secret, redirect_status } = query || {};
  const data = { payment_intent, payment_intent_client_secret, redirect_status };

  useEffect(() => {
    dispatch(userPublicApi.reset());
    if (
      intentId &&
      data?.redirect_status === 'success' &&
      data?.payment_intent_client_secret &&
      data?.payment_intent
    )
      dispatch(
        // deposintIntentApi
        userPublicApi.postRequest({
          intentId,
          ...data,
        }),
      );
  }, [intentId, data]);

  if (!initialized) {
    return <CircularLoading />;
  }

  const links = (user.profile && user.profile.links) || {};
  return (
    <div className={classnames(classes.root)}>
      <div className="tw-flex tw-flex-col">
        <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
          <Grid
            container
            style={{ paddingTop: 40, paddingBottom: 40 }}
            className={classes.contentContainer}
          >
            <Grid item xs={12} md={4} className={classes.avatarContainer}>
              <UserAvatar user={user} size={200} />
            </Grid>

            <Grid item xs={12} md={8} className={classes.contentRight}>
              <Typography
                variant="h5"
                display="block"
                style={{ marginTop: 10, fontSize: '2.5rem' }}
              >
                {user.username}
              </Typography>

              <RateDetails instructor={user.instructor} />

              <SocialIcons links={links} align="left" />

              <div className="tw-mt-3" />

              <FocusItemList user={user} justify="flex-start" />

              <div className="tw-mt-3" />

              <RenderBio bio={user?.profile?.bio} />
            </Grid>
          </Grid>
        </div>

        <div className="tw-flex-1 tw-bg-white">
          <div className="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center tw-py-10">
            <Grid container className={classes.contentContainer}>
              <AskQuestion requested={user} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
