import { Icon, IconifyIcon } from '@iconify/react';

// import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
import checkIcon from '@iconify/icons-ic/check-circle';
import classnames from 'classnames';

interface Props {
  title: string;
  content: string;
  icon: IconifyIcon;
  completed: boolean;
  onClick?: VoidFunction;
}

export default function CompleteStep({ title, content, icon, completed, onClick }: Props) {
  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3 }}
      className={classnames(
        'tw-py-8 tw-h-[250px] hover:tw-bg-gray-100',
        completed ? 'tw-bg-green-100' : 'tw-cursor-pointer',
      )}
      onClick={() => onClick?.()}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">
          <Stack direction="row" alignItems="center">
            {completed && (
              <Icon width={20} height={20} icon={checkIcon} className="tw-mr-2 tw-text-green-500" />
            )}{' '}
            {title}
          </Stack>
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
          <Icon width={32} height={32} icon={icon} />
        </Stack>

        <Typography variant="subtitle1" className="tw-pt-6">
          {content}
        </Typography>
      </Box>
    </Card>
  );
}
