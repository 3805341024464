import { FC } from 'react';
import { Outlet } from 'react-router-dom';

// material
import { styled, useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

// hooks
import useCollapseDrawer from 'hooks/useCollapseDrawer';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 10,
  paddingBottom: theme.spacing(10),
  backgroundColor: grey[100],
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout: FC = () => {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  // const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: '102px',
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default DashboardLayout;
