import { InputBase, InputBaseProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const InputStyled = styled(InputBase)`
  & .MuiInputBase-input {
    border: inherit;
  }
`;

interface Props extends InputBaseProps {
  errorText?: string;
}

export default function InputComponent({ errorText, ...props }: Props) {
  const error = !!errorText;
  return (
    <>
      <InputStyled
        className="tw-block tw-w-full"
        slotProps={{
          input: {
            className: clsx(
              'tw-w-10/12 tw-appearance-none tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-py-2.5 tw-pl-12 tw-pr-8 tw-text-slate-800 tw-placeholder-slate-500/75 tw-outline-none tw-duration-150 hover:tw-bg-vanilla focus:tw-border-gray-secondary-200 focus:tw-bg-vanilla focus:tw-outline-none focus:tw-ring-gray-secondary-200',
              error ? 'tw-border-red-300' : 'tw-border-gray-secondary-400/60',
            ),
          },
        }}
        error={!!error}
        {...props}
      />

      {errorText && (
        <Typography id="error-text" variant="body2" gutterBottom color="error">
          {errorText}
        </Typography>
      )}
    </>
  );
}
