interface Base {
  id: number;
  uid?: number | string;
  created_at: string;
  created_at_humanize?: string;
}

export interface ProfileType extends Base {
  image: string;
  links?: any;
  bio: string;
  headline?: string;
  image_origin?: string;
}

export interface RateListItemType {
  uid: string;
  rate?: number | null | string;
  duration?: number | null | string;
  time?: number;
  ignoreRate?: boolean;
}

export interface InstructorEventbriteType {
  uid: string;
  name: string;
  status: string;
  url: string;
  start: string;
}

export interface SelectedPlaceType {
  uid: string;
  place: google.maps.places.PlaceResult | null;
}

export interface InstructorType extends Base {
  rate: number;
  total_feedback_given: number;
  call_rate: number;
  rate_list: RateListItemType[];
  lesson_rate_list: RateListItemType[];
  lesson_location_list: SelectedPlaceType[];
  private_lesson?: boolean;
  eventbrite_list?: InstructorEventbriteType[];
}

export interface BalanceType extends Base {
  pending_amount?: number;
  available_amount?: number;
  welcome_amount?: number;
}

export interface StatsType {
  requests_received_pending_count: number;
  requests_sent_pending_count: number;
  requests_sent_completed_count: number;
  requests_received_completed_count: number;
  folder_invites_received_count: number;
  connection_invites_received_count: number;
}

export interface FocusType extends Base {
  name: string;
  focus__id: number;
  focus__name: string;
  focus__primary: string;
  primary?: string;
}

export interface UserBaseType extends Base {
  username: string;
  full_name: string;
  fullName: string; // when working with graphql
  focus?: FocusType[];
  profile?: ProfileType;
  initials: string;
  timezone?: string;
  instructor?: InstructorType;
  availability?: string[];
  business?: BusinessType;
}

interface CompletedStepsItemType {
  completed: boolean;
}

export interface CompletedStepsType {
  profile_image: CompletedStepsItemType;
  bio: CompletedStepsItemType;
  payout: CompletedStepsItemType;
  rate: CompletedStepsItemType;
  notification: CompletedStepsItemType;
  business_account: CompletedStepsItemType;
  request_completed: CompletedStepsItemType;
}

export interface IntegrationEventbriteType {
  is_connected: boolean;
  uid: string;
}

export interface IntegrationType {
  eventbrite: IntegrationEventbriteType;
}

export interface UserAuthType extends UserBaseType {
  phone_number?: string;
  email: string;
  balance?: BalanceType;
  total_money_earned?: number;
  stats?: StatsType;
  device_token: number;
  business?: BusinessType;
  is_client: boolean;
  completed_steps?: CompletedStepsType;
  integrations: IntegrationType;
}

export interface BoardMembership {
  status: string;
  user_id: number;
  username: string;
  is_admin: boolean;
  member_id: number;
}

export interface BoardType extends Base {
  name: string;
  description?: string;
  created_by: UserBaseType;
  members?: BoardMembership[];
  delete_disabled: boolean;
  video_mobile_count: number;
  is_public: boolean;
  is_private: boolean;
  allow_upload: boolean;
}

export interface FileType extends Base {
  thumbnail?: string;
  media_type?: string;
  video?: string;
  title: string;
  description?: string;
  created_by: UserBaseType;
  dynamic_link?: string;
  board?: BoardType;
  feedback_request?: RequestType;
  videoCommentCount: number;
  local_path?: string;
  duration?: number;
  view_count?: number;
  request_count?: number;
  parent?: FileType;
  shared_with?: number[];
  uuid?: number | string;
}

export interface ConnectionType extends Base {
  created_by: UserBaseType;
}

interface RequestDetailsType {
  date: string;
  time: string;
  duration: number;
  timezone: string;
  rate_item?: RateListItemType;
}

interface ListViewType {
  thumbnail?: string;
  media_type?: string;
  comment?: string;
  video?: string;
  title?: string;
}

export interface RequestType extends Base {
  video?: FileType;
  details: RequestDetailsType;
  list_view?: ListViewType;
  requested: UserBaseType;
  created_by: UserBaseType;
  instructor?: InstructorType;
  request_type: 'CALL';
  meeting_date: string;
  video_comment_count: number;
  last_activity_humanize: string;
  status: string;
  unread_count: number;
  can_complete: boolean;
  transaction_pending?: TransactionPending;
  uuid?: string;
  rate?: number;
  call_url?: string;
}

export interface FeedbackAvailabilityType extends Base {
  start: string;
  end: string;
  days: string[];
}

export interface MembershipType extends Base {
  status: string;
  board: BoardType;
}

export interface TransactionPending extends Base {
  status: string;
  amount: number;
}

export interface TransactionType extends Base {
  status: string;
  amount: number;
  transaction_type: string;
  incoming_amount?: number;
  outgoing_amount?: number;
}

export interface CommentType extends Base {
  comment: string;
  video: string;
  video_comment: FileType;
  created_by: UserBaseType;
  request?: RequestType;
}

export interface ReduxState {
  detail: RequestType | UserAuthType | FileType;
}

interface EndpointType {
  detail: any;
  list: any;
  initialized: boolean;
  loading: boolean;
  url: string;
}

interface NotificationType {
  message: string;
  status: string;
}

interface AppStateType {
  isOnline: boolean;
  permissionStatus: string;
  linkedVideoUUID: string;
}

export interface StateType {
  user: EndpointType;
  boardMembership: EndpointType;
  notification: NotificationType;
  app: AppStateType;
}

export interface BusinessRateType extends Base {
  name: string;
  subdomain: string;
}

export interface BusinessFinancialType {
  rate?: number | string;
  rate_list?: RateListItemType[];
  // If the business set's the rate then use the business rate
  business_set_rate?: boolean;

  // This means we send the business an invoice every month where they pay us our cut
  // and they ask their parents to pay them their cut.
  external_billing?: boolean;

  // If disabled, means that we collect payment using some other means and no rate can be set
  disable_billing?: boolean;
}

export interface BusinessFeaturesType {
  files?: boolean;
  code_share?: boolean;
  whiteboard?: boolean;
  allow_video_call?: boolean;
  view_calls?: boolean;
  analytics?: boolean;
  integrations?: boolean;
}

export enum BusinessIndustryEnum {
  CLINIC = 'CLINIC',
  LIFE_COACH = 'LIFE_COACH',
  TUTOR = 'TUTOR',
  SPORT = 'SPORT',
  DANCE = 'DANCE',
  OTHER = 'OTHER',
}

export enum BusinessServiceEnum {
  TREATMENT = 'TREATMENT',
  SERVICE = 'SERVICE',
  SESSION = 'SESSION',
  CLASS = 'CLASS',
  OTHER = 'OTHER',
}

export enum BusinessStaffEnum {
  THERAPIST = 'THERAPIST',
  TEACHER = 'TEACHER',
  TUTOR = 'TUTOR',
  COACH = 'COACH',
  INSTRUCTOR = 'INSTRUCTOR',
  STAFF = 'STAFF',
  OTHER = 'OTHER',
}

export enum BusinessClientEnum {
  PATIENT = 'PATIENT',
  CUSTOMER = 'CUSTOMER',
  CLIENT = 'CLIENT',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
  STUDENT = 'STUDENT',
  OTHER = 'OTHER',
}

export enum BusinessMemberTypeEnum {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  CLIENT = 'CLIENT',
}

export interface BusinessStructureType {
  industry?: BusinessIndustryEnum;
  service?: BusinessServiceEnum;
  client?: BusinessClientEnum;
  staff?: BusinessStaffEnum;
}

interface BusinessIntegrationItemType {
  is_connected: boolean;
  connect_url: string;
}

export interface BusinessIntegrationType {
  MINDBODY?: BusinessIntegrationItemType;
}

export interface BusinessType extends Base {
  name: string;
  about: string;
  subdomain?: string;
  financial_config?: BusinessFinancialType;
  feature_config?: BusinessFeaturesType;
  structure_config?: BusinessStructureType;
  theme_config?: Record<string, unknown>;
  link_config?: Record<string, unknown>;
  phone_number?: string;
  logo: string;
  is_admin: boolean;
  is_activated: boolean;
  integration_config: BusinessIntegrationType;
  embed_url?: string;
}

export enum BusinessMemberStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export interface InviteMemberType {
  uuid?: string;
  email: string;
  member_type: BusinessMemberTypeEnum;
}

export interface BusinessMemberType extends Base {
  email: string;
  status: BusinessMemberStatusEnum;
  member_type: BusinessMemberTypeEnum;
  isOwner: boolean;
  business: BusinessType;
  member?: UserBaseType;
  admin: UserBaseType;
}

export enum MediaTypeEnum {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  AUDIO = 'AUDIO',
  DOC = 'DOC',
}

export interface EventbriteEventType extends Base {
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  ticket_count: string;
  feedback_enabled: string;
  summary: string;
  event_id: string;
}

export interface EventbriteAttendeeType extends Base {
  name: string;
  feedback_requested: boolean;
  feedback_completed: boolean;
  feedback_enabled: boolean;
}

export interface EventbriteTicketClassType extends Base {
  name: string;
  is_feedback: boolean;
  price: boolean;
}
