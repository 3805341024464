import { useState, FC } from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CustomTextField } from 'components/Input/index';
import InputAdornment from '@mui/material/InputAdornment';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import PublicIcon from '@mui/icons-material/Public';

// App
import { business as businessApi } from 'store/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

const MediaLinks: FC = () => {
  const dispatch = useDispatch();
  const { business } = useBusiness();

  const [state, setState] = useState(business?.link_config || {});

  const textFieldProps = {
    state,
    error: {},
    setState,
  };

  const media_list = [
    {
      field: 'website',
      placeholder: 'Website Link',
      icon: <PublicIcon />,
    },
    {
      field: 'instagram',
      placeholder: 'Instagram username',
      icon: <InstagramIcon />,
    },
    {
      field: 'facebook',
      placeholder: 'Facebook Profile',
      icon: <FacebookIcon />,
    },

    {
      field: 'linkedin',
      placeholder: 'LinkedIn Link',
      icon: <LinkedInIcon />,
    },
  ];

  return (
    <Grid id="profile-social-link" container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" gutterBottom align="left">
          Business Links
        </Typography>
      </Grid>

      {media_list.map(m => {
        return (
          <CustomTextField
            key={m.field}
            field={m.field}
            placeholder={m.placeholder}
            lg={12}
            md={12}
            textProps={{
              InputProps: {
                startAdornment: <InputAdornment position="start">{m.icon}</InputAdornment>,
              },
            }}
            {...textFieldProps}
            renderBottom={null}
          />
        );
      })}

      <Grid item xs={12}>
        <SaveButton
          onClick={() => {
            dispatch(businessApi.patchRequest({ link_config: state }, business?.uid || ''));
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MediaLinks;
