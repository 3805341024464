import { Fragment } from 'react';
import { Img } from 'react-image';
import { Link, useLocation } from 'react-router-dom';
import { Transition, Popover } from '@headlessui/react';
import clsx from 'clsx';

import { userAuth as userAuthApi } from 'store/index';
import { useSelector } from 'hooks';
import { UserAuthType } from 'types/index';
import logo from 'images/fs/logo-dark.svg';
import logoShort from 'images/fs/logo-short.svg';
import { Container } from './Container';
import Button from 'components/Elements/Button';
import { APP_URL } from 'utils/constants';

const links = [
  { label: 'Home', href: '/' },
  { label: 'About', href: '/about' },
  { label: 'Pricing', href: '/pricing' },
  { label: 'Contact', href: '/contact' },
];

function MenuIcon({ open }: { open: boolean }) {
  return (
    <span className={`tw-relative tw-h-3.5 tw-w-4`}>
      <span
        className={clsx(
          'tw-absolute tw-block tw-h-0.5 tw-rotate-0 tw-transform tw-rounded-full tw-bg-slate-600 tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-linear group-hover:tw-bg-slate-900',
          open ? 'tw-left-1/2 tw-top-1.5 tw-w-0' : 'tw-left-0 tw-top-0 tw-w-full',
        )}
      />
      <span
        className={clsx(
          'tw-absolute tw-left-0 tw-top-1.5 tw-block tw-h-0.5 tw-w-full tw-transform tw-rounded-full tw-bg-slate-600 tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-linear group-hover:tw-bg-gray-900',
          open ? 'tw-rotate-45' : 'tw-rotate-0',
        )}
      />
      <span
        className={clsx(
          'tw-absolute tw-left-0 tw-top-1.5 tw-block tw-h-0.5 tw-w-full tw-transform tw-rounded-full tw-bg-slate-600 tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-linear group-hover:tw-bg-gray-900',
          open ? 'tw--rotate-45' : 'tw-rotate-0',
        )}
      />
      <span
        className={clsx(
          'tw-absolute tw-block tw-h-0.5 tw-rotate-0 tw-transform tw-rounded-full tw-bg-slate-600 tw-opacity-100 tw-transition-all tw-duration-200 tw-ease-linear group-hover:tw-bg-gray-900',
          open ? 'tw-left-1/2 tw-top-1.5 tw-w-0' : 'tw-left-0 tw-top-3 tw-w-full',
        )}
      />
    </span>
  );
}

function MobileNav() {
  return (
    <Popover>
      <Popover.Button
        className="tw-group tw-relative tw-z-50 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-border tw-border-gray-secondary-400/75 tw-bg-gray-secondary-50 tw-p-3 tw-transition tw-duration-300 tw-ease-in-out focus:tw-outline-none md:tw-hidden"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MenuIcon open={open} />}
      </Popover.Button>

      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="tw-duration-200 tw-ease-out"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-duration-150 tw-ease-in"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Popover.Overlay className="tw-fixed tw-inset-0 tw-z-20 tw-bg-slate-900 tw-bg-opacity-25 tw-backdrop-blur" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="tw-duration-300 tw-ease-out"
          enterFrom="tw-opacity-0 tw-scale-90"
          enterTo="tw-opacity-100 tw-scale-100 "
          leave="tw-duration-200 tw-ease-in"
          leaveFrom="tw-opacity-100 tw-scale-100 "
          leaveTo="tw-opacity-0 tw-scale-90"
        >
          <Popover.Panel
            as="div"
            className="tw-absolute tw-inset-x-0 tw-top-0 tw-z-30 tw-overflow-hidden tw-bg-amber-100 tw-px-5 tw-pb-8 tw-pt-24"
          >
            <div>
              <div className="tw-flex tw-flex-col tw-divide-y tw-divide-gray-secondary-400/75">
                {links.map(link => (
                  <Link
                    key={`${link.label}-mobile`}
                    to={link.href}
                    className={
                      'tw-block tw-px-4 tw-pb-2 tw-pt-4 tw-font-medium tw-text-slate-700 hover:tw-bg-amber-50 hover:tw-text-slate-900'
                    }
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
              <div className="tw-mt-6">
                <Button size="md" href="/signin" variant="ghost" className="tw-w-full">
                  Sign in
                </Button>
              </div>
            </div>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <header className="tw-h-24 tw-bg-amber-100 tw-border-b tw-border-gray-secondary-300/60">
      <Container className="tw-flex tw-h-full tw-w-full tw-items-center">
        <nav className="tw-relative tw-z-50 tw-flex tw-w-full tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-space-x-8 lg:tw-space-x-12">
            <Link to="/" className="tw-flex tw-flex-shrink-0 tw-items-center" aria-label="Home">
              <Img
                src={logo}
                className="tw-hidden tw-h-7 tw-w-auto sm:tw-h-8 lg:tw-block lg:tw-h-9"
                alt="Logo"
              />
              <Img
                src={logoShort}
                className="tw-h-7 tw-w-auto md:tw-block lg:tw-hidden"
                alt="Logo Icon"
              />
            </Link>
            <div className="tw-hidden tw-items-center tw-space-x-3 md:tw-flex lg:tw-space-x-4">
              {links.map(link => (
                <Link
                  key={`${link.label}-desktop`}
                  to={link.href}
                  className={clsx(
                    pathname == link.href
                      ? 'tw-bg-amber-50 tw-text-slate-900'
                      : 'tw-text-slate-700 hover:tw-bg-amber-50 hover:tw-text-slate-900',
                    'tw-inline-block tw-px-4 tw-py-2 tw-font-medium',
                  )}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>

          <div>
            {!user?.id ? (
              <div className="tw-flex tw-items-center tw-space-x-4">
                <div className="tw-hidden lg:tw-block">
                  <Link
                    to="/signin"
                    className="tw-inline-block tw-px-4 tw-py-2 tw-font-medium tw-text-slate-700 hover:tw-bg-amber-50 hover:tw-text-slate-900"
                  >
                    Sign in
                  </Link>
                </div>
                <Button size="md" href="/signup">
                  Sign up free
                </Button>
              </div>
            ) : (
              <div className="tw-flex tw-items-center tw-space-x-4">
                <Button size="md" href={APP_URL}>
                  Dashboard
                </Button>
              </div>
            )}
          </div>
        </nav>
        <div className="tw-ml-4 md:tw-hidden">
          <MobileNav />
        </div>
      </Container>
    </header>
  );
}
