import { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

// Layout
import DashboardLayout from 'layouts/dashboard';
import DashboardMiniLayout from 'layouts/dashboard/mini';

// App
import { AppHolder } from 'styles/common.style';
import Navbar from 'components/Header/Navbar';
import Notification from 'components/Notification/index';

// Views
import OnboardRate from 'containers/Onboard/Rate';
import OnboardUsername from 'containers/Onboard/Username';
import OnboardAvailability from 'containers/Onboard/Availability';
import OnboardFocus from 'containers/Onboard/Focus';

import Auth from 'containers/Auth/index';
import DashboardPage from 'containers/Dashboard/summary';
import AccountPage from 'containers/Account/index';
import UploadPage from 'containers/Upload/index';
import TopupPage from 'containers/Fund/Topup';
import AccountBalancePage from 'containers/Account/Balance';

import FolderPage from 'containers/Folder/index';
import FolderDetailPage from 'containers/Folder/index';

import FileDetailPage from 'containers/File/detail';
import FileManageListPage from 'containers/File/manage-list';
import FileManageDetailPage from 'containers/File/manage-detail';
import FileSharedDetailPage from 'containers/File/shared-detail';
import FileClientListPage from 'containers/File/client-list';
import FileClientDetailPage from 'containers/File/client-detail';

import MemberListPage from 'containers/Member/index';
import MemberAddPage from 'containers/Member/add';
import MemberEditPage from 'containers/Member/edit';
import MemberViewPage from 'containers/Member/view';

import FeedbackListPage from 'containers/Feedback/list';
import FeedbackRequestDetailPage from 'containers/Feedback/detail';
import FeedbackRequestToolPage from 'containers/Feedback/tool';

import InstructorPage from 'containers/Instructor/index';
import WithdrawPage from 'containers/Instructor/withdraw';

import ManageCallPage from 'containers/Call/index';
import AvailabilityPage from 'containers/Availability/index';
import ManageRatePage from 'containers/Manage/Rate/index';

import ClientMentorPage from 'containers/Client/mentor';
import ClientResponseListPage from 'containers/Client/response-list';
import ClientDashboardPage from 'containers/Client/dashboard';
import ClientAccountPage from 'containers/Client/account';

import BusinessSettingPage from 'containers/Business/Settings';
import BusinessAnalyticsPage from 'containers/Business/Analytics';
import BusinessIntegrationsPage from 'containers/Business/Integrations';
import BusinessMemberListPage from 'containers/Business/Member/list';
import BusinessMemberAddPage from 'containers/Business/Member/add';
import BusinessMemberEditPage from 'containers/Business/Member/edit';
import BusinessMemberViewPage from 'containers/Business/Member/view';
import BusinessEmbedPage from 'containers/Business/Embed';
import UserAnalyticsPage from 'containers/Business/Analytics/user';

import NotFoundPage from 'containers/NotFoundPage/index';

import PublicProfile from 'containers/User/public-profile';
import IntentPage from 'containers/User/intent';

import Footer from 'components/Footer/Footer';
import { CircularLoading } from 'components/Loading/index';

import WebSocketUserProvider from 'context/websocket-user';
import ClientProvider from 'context/ClientContext';
import BusinessProvider from 'context/BusinessContext';

import FSSignIn from 'containers/Landing/FeebackSession/auth/signin';
import FSSignUp from 'containers/Landing/FeebackSession/auth/signup';
import FSPasswordReset from 'containers/Landing/FeebackSession/auth/password-reset';
import FSLayout from 'containers/Landing/FeebackSession/layout';
import FSPasswordResetSetPassword from 'containers/Landing/FeebackSession/auth/password-reset-set-password';

// Integrations
import EventbriteIndex from 'containers/Integration/eventbrite/index';
import EventbriteDetail from 'containers/Integration/eventbrite/detail';

import { ROUTE_MAP, isFeedbackSession } from 'utils/constants';
import { useSelector } from 'hooks';
import './style.scss';
import { EmbedRoutes } from './common-routes';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  footer: {
    flexShrink: 0,
    width: '100%',
  },
}));

function Loading() {
  return (
    <AppHolder>
      <CircularLoading height="100vh" />
    </AppHolder>
  );
}

function App(): JSX.Element {
  const location = useLocation();
  const userAuthInitialized = useSelector((state: any) => state.userAuth.initialized);
  const user = useSelector((state: any) => state.userAuth.detail);

  const includeNav =
    location.pathname === '/onboard-1' ||
    location.pathname === '/onboard-2' ||
    location.pathname === '/onboard-3' ||
    location.pathname === '/onboard-4';

  const classes = useStyles();

  if (!userAuthInitialized) {
    return <Loading />;
  } else if (user.id) {
    if (user.is_client) {
      return (
        <Suspense fallback={<Loading />}>
          <BusinessProvider>
            <ClientProvider>
              <WebSocketUserProvider>
                <div className={classes.container}>
                  {includeNav && <Navbar color="transparent" />}

                  <Suspense fallback={<Loading />}>
                    <Routes>
                      <Route element={<DashboardLayout />}>
                        <Route path="/login" element={<Navigate to="/" replace={true} />} />
                        <Route path="/register" element={<Navigate to="/" replace={true} />} />
                        <Route
                          path={ROUTE_MAP.client.dashboard}
                          element={<ClientDashboardPage />}
                        />
                        <Route path={ROUTE_MAP.client.ask} element={<ClientMentorPage />} />
                        <Route
                          path={ROUTE_MAP.client.request}
                          element={<ClientResponseListPage />}
                        />
                        <Route path="/top-up" element={<TopupPage />} />
                        <Route
                          path={`${ROUTE_MAP.client.request}/:id`}
                          element={<FeedbackRequestDetailPage />}
                        />
                        <Route
                          path={`/${ROUTE_MAP.client.account}`}
                          element={<ClientAccountPage />}
                        />
                        <Route
                          path={`/${ROUTE_MAP.client.account}/:tab`}
                          element={<ClientAccountPage />}
                        />
                        <Route
                          path={`/${ROUTE_MAP.client.resource}`}
                          element={<FileClientListPage />}
                        />
                        <Route
                          path={`/${ROUTE_MAP.client.resource}/:uuid`}
                          element={<FileClientDetailPage />}
                        />
                      </Route>

                      <Route element={<DashboardMiniLayout />}>
                        <Route
                          path={`${ROUTE_MAP.request}/:id/:view`}
                          element={<FeedbackRequestToolPage />}
                        />
                      </Route>
                      <Route element={<DashboardMiniLayout />}>
                        <Route
                          path={`${ROUTE_MAP.request}/:id/:view/comment/:commentId`}
                          element={<FeedbackRequestToolPage />}
                        />
                      </Route>

                      <Route path="/:username" element={<PublicProfile />} />
                    </Routes>
                  </Suspense>

                  {includeNav && (
                    <div className={classes.footer}>
                      <Footer />
                    </div>
                  )}
                </div>

                <Notification />
              </WebSocketUserProvider>
            </ClientProvider>
          </BusinessProvider>
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<Loading />}>
          <BusinessProvider>
            <WebSocketUserProvider>
              <div className={classes.container}>
                {includeNav && <Navbar color="transparent" />}

                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route path="/login" element={<Navigate to="/" replace={true} />} />
                    <Route path="/register" element={<Navigate to="/" replace={true} />} />

                    <Route path="/video" element={<FileManageListPage />} />
                    <Route path="/video/:id" element={<FileManageDetailPage />} />

                    {/* set the users rate */}
                    <Route path="/onboard-1" element={<OnboardRate />} />
                    {/* set the users preferred url */}
                    <Route path="/onboard-2" element={<OnboardUsername />} />
                    <Route path="/onboard-3" element={<OnboardAvailability />} />
                    {/* let the users select their focus */}
                    <Route path="/onboard-4" element={<OnboardFocus />} />

                    <Route element={<DashboardLayout />}>
                      <Route path="/" element={<DashboardPage />} />

                      {/* set the users rate */}
                      <Route path="/onboard-1" element={<OnboardRate />} />

                      {/* set the users preferred url */}
                      <Route path="/onboard-2" element={<OnboardUsername />} />

                      {/* add the availability for the video call */}
                      <Route path="/onboard-3" element={<OnboardAvailability />} />

                      {/* let the users select their focus */}
                      <Route path="/onboard-4" element={<OnboardFocus />} />

                      <Route path={ROUTE_MAP.request} element={<FeedbackListPage />} />
                      <Route
                        path={`${ROUTE_MAP.request}/:id`}
                        element={<FeedbackRequestDetailPage />}
                      />

                      <Route path="/folder" element={<FolderPage />} />
                      <Route path="/folder/:id" element={<FolderDetailPage />} />

                      <Route path="/manage-file" element={<FileManageListPage />} />
                      <Route path="/manage-file/:id" element={<FileManageDetailPage />} />

                      <Route path={ROUTE_MAP.session} element={<ManageCallPage />} />

                      <Route path={`/${ROUTE_MAP.manage.account}`} element={<AccountPage />} />
                      <Route path={`/${ROUTE_MAP.manage.account}/:tab`} element={<AccountPage />} />
                      <Route path="/upload" element={<UploadPage />} />

                      <Route path="/get-paid" element={<InstructorPage />} />
                      <Route path="/get-paid/:tab" element={<InstructorPage />} />

                      <Route path="/withdraw" element={<WithdrawPage />} />
                      <Route path="/top-up" element={<TopupPage />} />

                      <Route path={ROUTE_MAP.manage.availability} element={<AvailabilityPage />} />
                      <Route path={ROUTE_MAP.manage.rate} element={<ManageRatePage />} />
                      <Route path={ROUTE_MAP.analytics} element={<UserAnalyticsPage />} />
                      <Route path={ROUTE_MAP.manage.financial} element={<AccountBalancePage />} />

                      {/* Integrations */}
                      <Route
                        path={ROUTE_MAP.integration.eventbrite.index}
                        element={<EventbriteIndex />}
                      />

                      <Route
                        path={`${ROUTE_MAP.integration.eventbrite.event}/:uid`}
                        element={<EventbriteDetail />}
                      />

                      {user?.business?.is_admin ? (
                        <>
                          <Route
                            path={ROUTE_MAP.business.settings}
                            element={<BusinessSettingPage />}
                          />
                          <Route
                            path={ROUTE_MAP.business.analytics}
                            element={<BusinessAnalyticsPage />}
                          />
                          <Route
                            path={ROUTE_MAP.business.integrations}
                            element={<BusinessIntegrationsPage />}
                          />
                          <Route
                            path={ROUTE_MAP.business.member}
                            element={<BusinessMemberListPage />}
                          />
                          {/* Add client */}
                          <Route
                            path={`${ROUTE_MAP.business.member}/add`}
                            element={<BusinessMemberAddPage />}
                          />
                          {/* View client */}
                          <Route
                            path={`${ROUTE_MAP.business.member}/:id`}
                            element={<BusinessMemberViewPage />}
                          />
                          {/* Edit client */}
                          <Route
                            path={`${ROUTE_MAP.business.member}/:id/edit`}
                            element={<BusinessMemberEditPage />}
                          />
                          {/* Client requests */}
                          <Route
                            path={`${ROUTE_MAP.business.member}/:id/requests`}
                            element={<BusinessMemberViewPage />}
                          />

                          {/* Embed */}
                          <Route path={ROUTE_MAP.business.embed} element={<BusinessEmbedPage />} />
                        </>
                      ) : (
                        <>
                          <Route path="client" element={<MemberListPage />} />
                          <Route path="client/add" element={<MemberAddPage />} />
                          <Route path="client/edit/:id" element={<MemberEditPage />} />
                          <Route path="client/view/:id" element={<MemberViewPage />} />
                        </>
                      )}

                      <Route path="/vs/:uuid" element={<FileSharedDetailPage />} />

                      <Route path="/:username" element={<PublicProfile />} />

                      <Route path="*" element={<NotFoundPage />} />
                    </Route>

                    <Route element={<DashboardMiniLayout />}>
                      <Route
                        path={`${ROUTE_MAP.request}/:id/:view`}
                        element={<FeedbackRequestToolPage />}
                      />
                    </Route>
                    <Route element={<DashboardMiniLayout />}>
                      <Route
                        path={`${ROUTE_MAP.request}/:id/:view/comment/:commentId`}
                        element={<FeedbackRequestToolPage />}
                      />
                    </Route>

                    <Route
                      path={`${ROUTE_MAP.intent}/:intentId/complete`}
                      element={<IntentPage />}
                    />

                    <Route path="/embed/*" element={<EmbedRoutes />} />

                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Suspense>

                {includeNav && (
                  <div className={classes.footer}>
                    <Footer />
                  </div>
                )}
              </div>

              <Notification />
            </WebSocketUserProvider>
          </BusinessProvider>
        </Suspense>
      );
    }
  } else {
    if (isFeedbackSession) {
      return (
        <FSLayout>
          <Routes>
            <Route path="/signin" element={<FSSignIn />} />
            <Route path="/signup" element={<FSSignUp />} />
            <Route path="/password-reset" element={<FSPasswordReset />} />
            <Route
              path="/account/password/reset/key/:key"
              element={<FSPasswordResetSetPassword />}
            />
            <Route path="*" element={<FSSignIn />} />
          </Routes>
        </FSLayout>
      );
    }
    return (
      <Suspense fallback={<Loading />}>
        <div className={classes.container}>
          <Navbar color="transparent" />

          <Box
            sx={{
              flex: '1 0 auto',
              display: 'flex',
              justifyItems: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppHolder>
              <Routes>
                <Route path="/login" element={<Auth />} />
                <Route path="/register" element={<Auth />} />
                <Route path="/register/:username" element={<Auth />} />
                <Route path="/video/:id" element={<FileDetailPage />} />
                <Route path="/vs/:uuid" element={<FileSharedDetailPage />} />
                <Route path="*" element={<Auth />} />

                <Route path="/embed/*" element={<EmbedRoutes />} />
              </Routes>
            </AppHolder>
          </Box>

          <div className={classes.footer}>
            <Footer />
          </div>
        </div>

        <Notification />
      </Suspense>
    );
  }
}

export default App;
