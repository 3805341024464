import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// App
import RequestResponseProvider from 'context/request-response';
import FileFeedbackComponent from 'containers/File/components/FileFeedback';
import { VideoTitleLoading } from 'components/Loading/index';
import { userAuth as userAuthApi, clientFile as clientFileApi } from 'store/index';
import PlayerComponent from 'containers/File/components/Player';
import FileUnavailableComponent from 'containers/File/components/Unavailable';
import { GridContainer } from 'components/Common/index';
import FileDescription from 'components/File/Description';
import UsernameLink from 'components/User/UsernameLink';
import { useDispatch, useSelector } from 'hooks';

const RenderTitleContent: FC = () => {
  const initialized = useSelector((state: any) => state[clientFileApi.APP_NAME].initialized);
  const file = useSelector((state: any) => state[clientFileApi.APP_NAME].detail);
  const username = file?.created_by?.username;

  if (!initialized) {
    return <VideoTitleLoading />;
  }

  return (
    <GridContainer>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={10}>
          <Typography variant="h6" gutterBottom>
            {file.title}
          </Typography>

          <Typography variant="body1" gutterBottom display="block">
            <UsernameLink username={username} /> - {file.created_at_humanize}
          </Typography>

          <FileDescription video={file} />
        </Grid>
      </Grid>
    </GridContainer>
  );
};

const ManageDetailContainer: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setState] = useState({
    start: null,
    expandedSection: null,
    player: {},
    loop: {},
    tabValue: 0,
    isEditing: false,
    sliderStart: 0,
    sliderStop: 10,
  });
  const fileDetail = useSelector((state: any) => state[clientFileApi.APP_NAME].detail);
  const fileInitialized = useSelector((state: any) => state[clientFileApi.APP_NAME].initialized);
  const fileErrorDetail = useSelector((state: any) => state[clientFileApi.APP_NAME].errorDetail);
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  useEffect(() => {
    dispatch(clientFileApi.reset());
    if (params.uuid) dispatch(clientFileApi.getDetailRequest(params.uuid));
  }, []);

  if (fileInitialized && fileErrorDetail) {
    return <FileUnavailableComponent />;
  }

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PlayerComponent videoDetail={fileDetail} />
        </Grid>
      </Grid>

      <RenderTitleContent />

      <RequestResponseProvider
        disabled={!user.id}
        textLabel="Add your question"
        requestedUser={fileDetail.created_by}
        canRequestCall
      >
        <FileFeedbackComponent
          handleClose={() => setState({ ...state, isEditing: false })}
          videoDetail={fileDetail}
          isShared
        />
      </RequestResponseProvider>
    </div>
  );
};

export default ManageDetailContainer;
