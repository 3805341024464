import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// App
import { buttonPrimary } from 'styles/classnames';
import { FormContainer } from 'components/Form/Form';
import { BusinessMemberTypeEnum } from 'types';
import { businessMember as businessMemberApi, userAuth as userAuthApi } from 'store/index';
import { useDispatch, useBusiness } from 'hooks';

export default function InviteMember(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { business } = useBusiness();

  const [member, setMember] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    member_type: BusinessMemberTypeEnum.CLIENT,
  });

  const saveMembers = async () => {
    await dispatch(
      businessMemberApi.postRequest({
        ...member,
        business_id: business?.id,
      }),
    );
    dispatch(userAuthApi.getDetailRequest('user'));
    navigate('/business/member');
  };

  return (
    <FormContainer title="Add Member">
      <Grid item sm={12} md={6}>
        <TextField
          id="email"
          label="Email (required)"
          variant="outlined"
          onChange={ev => setMember({ ...member, email: ev.target.value })}
          value={member.email}
          placeholder="Email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={member.member_type}
            label="Age"
            // @ts-ignore
            onChange={ev => setMember({ ...member, member_type: ev.target.value })}
          >
            <MenuItem value={BusinessMemberTypeEnum.ADMIN}>Admin</MenuItem>
            <MenuItem value={BusinessMemberTypeEnum.STAFF}>Mentor / Staff</MenuItem>
            <MenuItem value={BusinessMemberTypeEnum.CLIENT}>Client / Student</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          variant="outlined"
          className={clsx(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg md:tw-w-48')}
          onClick={saveMembers}
        >
          Submit
        </Button>
      </Grid>
    </FormContainer>
  );
}
