import { createContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';

// App
import { BusinessType, UserAuthType, BusinessMemberType } from 'types';
import {
  business as businessApi,
  userAuth as userAuthApi,
  businessMember as businessMemberApi,
} from 'store/index';
import { useDispatch, useSelector } from 'hooks';
import {
  BusinessIndustryEnum,
  BusinessServiceEnum,
  BusinessClientEnum,
  BusinessStaffEnum,
} from 'types/index';

interface TaxonomyType {
  industry: BusinessIndustryEnum;
  service: BusinessServiceEnum;
  client: BusinessClientEnum;
  staff: BusinessStaffEnum;
}

type TaxonomyContainerType = Record<string, TaxonomyType>;

const businessTaxonomy: TaxonomyContainerType = {
  default: {
    industry: BusinessIndustryEnum.OTHER,
    service: BusinessServiceEnum.SESSION,
    staff: BusinessStaffEnum.INSTRUCTOR,
    client: BusinessClientEnum.CLIENT,
  },
};

export type IState = {
  business?: BusinessType;
  members?: BusinessMemberType[];
  initialized: boolean;
  memberInitialized: boolean;
  loading: boolean;
  taxonomy: TaxonomyType;
};

const InitialState: Partial<IState> = {};

export type BusinessContextProps = IState;
export const BusinessContext = createContext<BusinessContextProps>(
  InitialState as BusinessContextProps,
);

export default function BusinessProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const dispatch = useDispatch();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const business: BusinessType = useSelector((state: any) => state[businessApi.APP_NAME].detail);
  const members: BusinessMemberType[] = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].list,
  );
  const initialized: boolean = useSelector((state: any) => state[businessApi.APP_NAME].initialized);
  const memberInitialized: boolean = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].initialized,
  );
  const loading: boolean = useSelector((state: any) => state[businessApi.APP_NAME].loading);
  const [taxonomy, setTaxonomy] = useState(businessTaxonomy.default);

  useEffect(() => {
    if (user?.business?.uid && user?.business?.is_activated) {
      dispatch(businessApi.getDetailRequest(user?.business?.uid));
      dispatch(businessMemberApi.getRequest());
    }
  }, [user.id]);

  useEffect(() => {
    if (business?.uid && business.structure_config) {
      const config = business.structure_config;
      setTaxonomy({
        industry: config.industry || BusinessIndustryEnum.OTHER,
        service: config.service || BusinessServiceEnum.SESSION,
        staff: config.staff || BusinessStaffEnum.INSTRUCTOR,
        client: config.client || BusinessClientEnum.CLIENT,
      });
    }
  }, [business?.uid]);

  const value: BusinessContextProps = {
    business,
    members,
    initialized,
    memberInitialized,
    loading,
    taxonomy,
  };
  return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
}
