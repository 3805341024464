// @ts-nocheck
// brute force ignore all ts-issues
import { ReactNode } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  BoxProps,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';

// App
import useAuth from 'hooks/useAuth';
import { DashboardProps } from 'layouts/dashboard/hooks/useDashboard';

const ListSubheaderStyle = styled(props => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type ItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  external?: boolean;
  children?: {
    title: string;
    path: string;
  }[];
};

interface NavItemProps {
  item: ItemProps;
  isShow?: boolean | undefined;
}

function NavItem({ item, isShow }: NavItemProps) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { title, path, icon, info, external } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: true }, pathname) : false;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  if (title.toLowerCase() === 'logout') {
    return (
      <ListItemStyle
        onClick={() => logout()}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    );
  }

  const listItemProps: { to?: any; component: JSX.Element | string; href?: string } = {
    to: path,
    component: RouterLink,
  };

  if (external) {
    listItemProps.href = path;
    listItemProps.component = 'a';
  }

  return (
    <ListItemStyle
      component={RouterLink}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      {...listItemProps}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: DashboardProps[];
}

export default function NavSection({
  navConfig,
  isShow = true,
  ...other
}: NavSectionProps): JSX.Element {
  return (
    <Box {...other}>
      {navConfig.map(list => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && subheader && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps) => {
              return <NavItem key={item.title} item={item} isShow={isShow} />;
            })}
          </List>
        );
      })}
    </Box>
  );
}
