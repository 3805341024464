import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import { FileWithPath } from 'file-selector';

const useStyles = makeStyles(() => ({
  uploadIcon: {
    padding: 4,
  },
}));

interface DropzoneProps {
  disabled?: boolean;
  setFileList?: (fileList: FileWithPath[]) => void;
}

export default function UploadIcon({ setFileList, disabled }: DropzoneProps): JSX.Element {
  const classes = useStyles();
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFileList?.(acceptedFiles);
    },
    [setFileList],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: disabled });
  const { _ref, ...rootProps } = getRootProps();

  return (
    <span {...getRootProps()} className={classnames('dropzone dz-clickable')}>
      <input {...getInputProps()} id="feedback-row-dropzone-input" />

      {/* Allow user to select photo and such, but only when they log in can they proceed */}
      {/* @ts-ignore */}
      <IconButton
        // color="primary"
        aria-label="upload picture"
        component="span"
        className={classes.uploadIcon}
        {...rootProps}
        id="feedback-row-select-file-button"
      >
        <Icon icon={attach2Fill} width={30} height={30} />
      </IconButton>
    </span>
  );
}
