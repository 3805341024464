import classnames from 'classnames';

// @material-ui
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// core components
import CustomInput from 'components/CustomInput/CustomInput';
import { buttonPrimary, buttonMuted } from 'styles/classnames';
import { useAuth } from 'hooks';

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  grid: {
    marginRight: '-15px',
    marginLeft: '-15px',
    width: 'auto',
  },
});

interface EmailAuthProps {
  onSuccess?: VoidFunction;
  isLogin: boolean;
  initialEmail?: string;
}

export default function EmailAuth({ isLogin, onSuccess }: EmailAuthProps): JSX.Element {
  const classes = useStyles();
  const { handleAuthenticate, credentials, isLoading, error, setCredentials } = useAuth();

  let enabled = false;

  if (isLogin) {
    enabled = !!(credentials.email && credentials.password);
  } else {
    enabled = !!(credentials.email && credentials.password1 && credentials.password2);
  }

  function onKeyDown(e: any) {
    if (e.keyCode === 13 && enabled) {
      handleAuthenticate(isLogin, onSuccess);
    }
  }

  const passwordComponent = isLogin ? (
    <>
      <CustomInput
        labelText={error.password ? error.password : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password: ev.target.value }),
          onKeyDown,
        }}
        error={error.password}
      />
    </>
  ) : (
    <>
      <CustomInput
        labelText={error.password1 ? error.password1 : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password1,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password1: ev.target.value }),
          onKeyDown,
        }}
        error={error.password1}
      />

      <CustomInput
        labelText={error.password2 ? error.password2 : 'Confirm Password'}
        id="pass2"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password2,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password2: ev.target.value }),
          onKeyDown,
        }}
        error={error.password2}
      />
    </>
  );

  let renderErrorAll;
  if (error.all) {
    renderErrorAll = (
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
        <Typography id="error-text" variant="body2" gutterBottom color="error">
          {error.all}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" className={classes.grid}>
      {renderErrorAll}
      <Grid item xs={12} sm={12} md={12}>
        <CustomInput
          labelText={error.email ? error.email : 'Email...'}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'email',
            onChange: (ev: any) => setCredentials({ ...credentials, email: ev.target.value }),
            value: credentials.email,
            variant: 'outlined',
            onKeyDown,
          }}
          error={error.email}
        />

        {passwordComponent}
      </Grid>

      <Grid item xs={12} sm={12} md={12} marginTop={4}>
        <Button
          disabled={!enabled || isLoading}
          onClick={() => handleAuthenticate(isLogin, onSuccess)}
          id="submit-auth-button"
          className={classnames(
            'tw-w-full tw-p-3 tw-text-base',
            enabled ? buttonPrimary : buttonMuted,
          )}
        >
          {isLogin ? 'Login' : 'Sign Up'}
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Grid>
    </Grid>
  );
}
