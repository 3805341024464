import { ReactNode } from 'react';

export interface HeaderListProps {
  id: string | any;
  label: string;
  alignRight?: boolean;
}

export interface TableComponentProps {
  headerList: HeaderListProps[];
  dataList: any[];
  emptyNode?: ReactNode;
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export type TableListHeadProps = {
  order: SortOrderEnum;
  orderBy: string;
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  onRequestSort: (id: string) => void;
  onSelectAllClick: (checked: boolean) => void;
};
