import { FC, createContext, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { useFirebase } from 'react-redux-firebase';

import { userAuth as userAuthApi } from 'store/index';
import { segmentAnalytics } from 'utils/analytics';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';

export type IState = {
  app: string;
};

type ProviderProps = {
  children: React.ReactNode;
  app: string;
};

const InitialState: Partial<IState> = {};

export type AppContextProps = IState;
export const AppContext = createContext<AppContextProps>(InitialState as AppContextProps);

const AppProvider: FC<ProviderProps> = ({ children, app }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const userAuthDetail = useSelector((state: any) => state.userAuth.detail);

  useEffect(() => {
    dispatch(userAuthApi.getDetailRequest('user'));
  }, []);

  useEffect(() => {
    if (userAuthDetail.id) {
      if (userAuthDetail.fb_app && userAuthDetail.fb_secret) {
        firebase.login({
          email: userAuthDetail.fb_app,
          password: userAuthDetail.fb_secret,
        });
      } else {
        // Can be an anonymous user if there is no app or secret
        firebase
          .auth()
          .signInAnonymously()
          .catch(function (err) {
            log.exception(err);
          });
      }

      segmentAnalytics.identify(userAuthDetail.id, {
        username: userAuthDetail.username,
        email: userAuthDetail.email,
        release: window.RELEASE,
      });
    }
  }, [userAuthDetail.id]);

  const value: AppContextProps = {
    app,
  };
  return (
    <AppContext.Provider value={value}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
