import type { SVGProps } from 'react';

import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import CableIcon from '@mui/icons-material/Cable';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BadgeIcon from '@mui/icons-material/Badge';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import { ROUTE_MAP } from 'utils/constants';
import { userAuth as userAuthApi } from 'store/index';
import { UserAuthType, BusinessType } from 'types/api';
import { useSelector } from 'hooks';

type ItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  external?: boolean;
  children?: {
    title: string;
    path: string;
  }[];
};

export interface SidebarType {
  subheader: string;
  items: ItemProps[];
}

export function LogosEventbriteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <circle cx={128} cy={128} r={128} fill="#f05537"></circle>
      <path
        fill="#fff"
        d="M117.475 82.729c19.297-4.288 38.595 3.574 49.315 18.582L81.502 120.61c2.859-18.345 16.676-33.592 35.973-37.88m49.792 70.756c-6.671 9.768-16.915 16.677-28.589 19.297c-19.297 4.288-38.832-3.573-49.553-18.82l85.527-19.298l13.818-3.097l26.682-5.956c-.238-5.717-.953-11.435-2.144-16.914c-10.72-45.98-57.653-74.806-104.586-64.086s-76.235 56.462-65.276 102.68s57.653 74.806 104.585 64.085c27.636-6.194 49.077-24.776 60.036-48.361z"
      ></path>
    </svg>
  );
}

const clientConfig = (business: BusinessType | undefined): SidebarType[] => {
  const top: ItemProps[] = [];
  top.push({
    title: 'Dashboard',
    path: ROUTE_MAP.client.dashboard,
    icon: <SpaceDashboardOutlinedIcon />,
  });
  top.push({
    title: 'Get Feedback',
    path: ROUTE_MAP.client.ask,
    icon: <HistoryEduIcon />,
  });
  top.push({
    title: 'Response',
    path: ROUTE_MAP.client.request,
    icon: <AddCommentOutlinedIcon />,
  });
  if (business?.feature_config?.files) {
    top.push({
      title: 'Resources',
      path: ROUTE_MAP.client.resource,
      icon: <AutoAwesomeMotionIcon />,
    });
  }
  const bottom: ItemProps[] = [];
  bottom.push({
    title: 'Account',
    path: ROUTE_MAP.client.account,
    icon: <AccountCircleOutlinedIcon />,
  });
  bottom.push({
    title: 'Logout',
    path: '',
    icon: <ExitToAppIcon />,
  });

  return [
    {
      subheader: '',
      items: top,
    },
    {
      subheader: 'manage',
      items: bottom,
    },
  ];
};

const businessConfig = (business?: BusinessType, user?: UserAuthType): SidebarType[] => {
  const top: ItemProps[] = [];
  const middle: ItemProps[] = [];
  const bottom: ItemProps[] = [];
  const sidebar = [];

  // Top
  top.push({
    title: 'Dashboard',
    path: ROUTE_MAP.dashboard,
    icon: <SpaceDashboardOutlinedIcon />,
  });
  top.push({
    title: 'Requests',
    path: ROUTE_MAP.request,
    icon: <AddCommentOutlinedIcon />,
  });

  if (business?.feature_config?.view_calls) {
    top.push({
      title: 'Video Calls',
      path: ROUTE_MAP.session,
      icon: <VideoCallIcon />,
    });
  }

  if (business?.feature_config?.files) {
    top.push({
      title: 'Manage Files',
      path: ROUTE_MAP.resource,
      icon: <AutoAwesomeMotionIcon />,
    });
  }

  sidebar.push({
    subheader: '',
    items: top,
  });

  // Middle
  if (business?.is_admin) {
    middle.push({
      title: 'Members',
      path: ROUTE_MAP.business.member,
      icon: <BadgeIcon />,
    });
    middle.push({
      title: 'Settings',
      path: ROUTE_MAP.business.settings,
      icon: <SettingsApplicationsIcon />,
    });

    middle.push({
      title: 'Analytics',
      path: ROUTE_MAP.business.analytics,
      icon: <AnalyticsIcon />,
    });

    if (business?.feature_config?.integrations) {
      middle.push({
        title: 'Integrations',
        path: ROUTE_MAP.business.integrations,
        icon: <CableIcon />,
      });
    }

    middle.push({
      title: 'Embed',
      path: ROUTE_MAP.business.embed,
      icon: <OpenInBrowserIcon />,
    });

    sidebar.push({
      subheader: 'business',
      items: middle,
    });
  }

  if (user?.integrations?.eventbrite?.is_connected) {
    sidebar.push({
      subheader: 'integrations',
      items: [
        {
          title: 'Eventbrite',
          path: ROUTE_MAP.integration.eventbrite.index,
          icon: <LogosEventbriteIcon />,
        },
      ],
    });
  }

  // Bottom
  bottom.push({
    title: 'Account',
    path: ROUTE_MAP.manage.account,
    icon: <AccountCircleOutlinedIcon />,
  });

  if (
    !business?.financial_config?.disable_billing &&
    !business?.financial_config?.business_set_rate
  ) {
    bottom.push({
      title: 'Rate',
      path: ROUTE_MAP.manage.rate,
      icon: <MonetizationOnOutlinedIcon />,
    });
  }

  if (business?.feature_config?.allow_video_call) {
    bottom.push({
      title: 'Availability',
      path: ROUTE_MAP.manage.availability,
      icon: <EventIcon />,
    });
  }
  bottom.push({
    title: 'Financials',
    path: ROUTE_MAP.manage.financial,
    icon: <AccountBalanceWalletOutlinedIcon />,
  });
  bottom.push({
    title: 'Logout',
    path: '',
    icon: <ExitToAppIcon />,
  });

  sidebar.push({
    subheader: 'manage',
    items: bottom,
  });

  return sidebar;
};

const defaultConfig = (user: UserAuthType): SidebarType[] => {
  const top: ItemProps[] = [];
  const bottom: ItemProps[] = [];

  // Top
  top.push({
    title: 'Dashboard',
    path: ROUTE_MAP.dashboard,
    icon: <SpaceDashboardOutlinedIcon />,
  });
  top.push({
    title: 'Requests',
    path: ROUTE_MAP.request,
    icon: <AddCommentOutlinedIcon />,
  });
  top.push({
    title: 'Analytics',
    path: ROUTE_MAP.analytics,
    icon: <AnalyticsIcon />,
  });
  // top.push({
  //   title: 'Video Calls',
  //   path: ROUTE_MAP.session,
  //   icon: <VideoCallIcon />,
  // });
  // top.push({
  //   title: 'Clients',
  //   path: ROUTE_MAP.manage.client,
  //   icon: <SupervisedUserCircleIcon />,
  // });

  // Bottom
  bottom.push({
    title: 'Account',
    path: ROUTE_MAP.manage.account,
    icon: <AccountCircleOutlinedIcon />,
  });
  bottom.push({
    title: 'Rate',
    path: ROUTE_MAP.manage.rate,
    icon: <MonetizationOnOutlinedIcon />,
  });

  bottom.push({
    title: 'Availability',
    path: ROUTE_MAP.manage.availability,
    icon: <EventIcon />,
  });

  bottom.push({
    title: 'Financials',
    path: ROUTE_MAP.manage.financial,
    icon: <AccountBalanceWalletOutlinedIcon />,
  });

  bottom.push({
    title: 'Logout',
    path: '',
    icon: <ExitToAppIcon />,
  });

  const sidebar = [];
  sidebar.push({
    subheader: '',
    items: top,
  });

  if (user?.integrations?.eventbrite?.is_connected) {
    sidebar.push({
      subheader: 'integrations',
      items: [
        {
          title: 'Eventbrite',
          path: ROUTE_MAP.integration.eventbrite.index,
          icon: <LogosEventbriteIcon />,
        },
      ],
    });
  }

  sidebar.push({
    subheader: 'manage',
    items: bottom,
  });

  return sidebar;
};

export default function useDashboard(): SidebarType[] {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  if (user.is_client) {
    return clientConfig(user?.business);
  } else if (user?.business?.is_activated) {
    return businessConfig(user?.business, user);
  }
  return defaultConfig(user);
}
