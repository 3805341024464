import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

// App
import { buttonDanger } from 'styles/classnames';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
}));

interface Props {
  open?: boolean;
  handleClose: () => void;
  content: any;
}
const ConfirmDeleteModal: FC<Props> = ({ open, handleClose, content }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={!!open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="scroll-dialog-title">
        Error
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button onClick={handleClose} className={buttonDanger}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteModal;
