import { useState } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReactQuill, { Quill } from 'react-quill';
// @ts-ignore
import quillEmoji from 'quill-emoji';
import { UserAuthType } from 'types/api';
import { log } from 'utils/index';
import { useDispatch, useSelector } from 'hooks';

import './editor.scss';

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true,
);

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    ['emoji'],
    ['link'],
    ['clean'],
  ],
  'emoji-toolbar': true,
  // 'emoji-textarea': true,
  'emoji-shortname': true,
};

const formats = [
  // 'font',
  // 'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'color',
  // 'background',
  'list',
  'indent',
  'align',
  'link',
  'clean',
  'emoji',
];

// App
import { userAuth as userAuthApi } from 'store/index';
import { notification } from 'store/notification';
import { API_URL } from 'utils/constants';
import { SaveButton } from 'components/Common/Button';

export default function UpdateBio(): JSX.Element {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const dispatch = useDispatch();
  const [bio, setBio] = useState(user?.profile?.bio);
  const [] = useState({});

  const saveProfileData = async () => {
    if (user?.profile?.bio === bio) return;
    const options = {
      url: `${API_URL}/api/v1/user/user/${user.id}/update_profile/`,
    };

    try {
      // @ts-ignore
      const resp: any = await dispatch(userAuthApi.patchRequest({ bio }, null, options));
      if (resp.error) {
        dispatch(notification('Something went wrong', 'error'));
      } else if (resp.id) {
        dispatch(notification('The bio has been updated', 'success'));
      }
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          align="left"
          style={{ marginBottom: 10 }}
        >
          Update Bio
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div className="text-editor tw-h-32">
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            value={bio}
            onChange={setBio}
            className="tw-h-24"
          />
        </div>
      </Grid>

      <Grid item xs={12} mt={2}>
        <SaveButton
          onClick={() => {
            saveProfileData();
          }}
        />
      </Grid>
    </Grid>
  );
}
