import { useState } from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// App
import { CustomTextField } from 'components/Input/index';
import { userAuth as userAuthApi } from 'store/index';
import { notification } from 'store/notification';
import { UserAuthType } from 'types';
import { useDispatch, useSelector } from 'hooks';
import { BASE_DOMAIN, API_URL } from 'utils/constants';
import { SaveButton } from 'components/Common/Button';

export default function ChangeDetails(): JSX.Element {
  const dispatch = useDispatch();
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const [state, setState] = useState({
    email: user.email,
    username: user.username,
    full_name: user?.full_name,
    bio: user?.profile?.bio,
    headline: user?.profile?.headline,
  });
  const [error] = useState({});

  const textFieldProps = {
    state,
    error,
    setState,
  };

  const saveUserData = () => {
    const options = {
      url: `${API_URL}/api/v1/user/user/${user.id}/`,
    };
    dispatch(
      userAuthApi.patchRequest(
        {
          username: state.username,
          email: state.email,
          full_name: state.full_name || null,
          headline: state.headline,
        },
        null,
        options,
      ),
      // @ts-ignore
    ).then((resp: any) => {
      if (resp.error) {
        if (resp.data) {
          if (resp.data.username) {
            dispatch(notification(resp.data.username, 'error'));
            return;
          }

          if (resp.data.email) {
            dispatch(notification(resp.data.email, 'error'));
            return;
          }

          if (resp.data.error) {
            dispatch(notification(resp.data.error, 'error'));
            return;
          }
        }
        dispatch(notification('Something went wrong', 'error'));
      } else if (resp.id) {
        dispatch(notification('The user details has been updated', 'success'));
        return;
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          align="left"
          style={{ marginBottom: 10 }}
        >
          Update Info
        </Typography>
      </Grid>

      <CustomTextField
        field="full_name"
        placeholder="Full Name"
        {...textFieldProps}
        lg={12}
        md={12}
      />

      <CustomTextField
        field="username"
        placeholder={`Change Username`}
        {...textFieldProps}
        textProps={{
          helperText: (
            <span className="tw-text-sm tw-font-medium tw-text-blue-700">{`${BASE_DOMAIN}/${state.username}`}</span>
          ),
          FormHelperTextProps: { filled: false, variant: 'standard' },
        }}
      />

      <CustomTextField field="email" placeholder="Change Email" {...textFieldProps} />

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SaveButton
          onClick={() => {
            saveUserData();
          }}
        />
      </Grid>
    </Grid>
  );
}
