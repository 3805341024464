import { useState, FC } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { notification } from 'store/notification';
import { BusinessFinancialType } from 'types/index';
import { business as businessApi } from 'store/index';
import { RenderHelpIcon } from 'components/Help/index';
import { log } from 'utils/index';
import { useDispatch, useBusiness } from 'hooks';
import { SaveButton } from 'components/Common/Button';

interface BillingConfigType {
  state: BusinessFinancialType;
  setState: (state: BusinessFinancialType) => void;
}

const QuestionRate: FC<BillingConfigType> = ({ state, setState }) => {
  return (
    <Grid container spacing={2} className="tw-mt-4">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-text-2xl tw-font-medium"
        >
          Manage Rate
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" style={{ marginBottom: 20 }} display="block">
          How much does your business charge per question ($)?
          <RenderHelpIcon text="How much does your business charge to give feedback. (Can be a video, audio or text response)" />
        </Typography>

        <TextField
          id="input-add-rate"
          label="$ Rate per question"
          variant="outlined"
          onChange={ev => setState({ ...state, rate: ev.target.value })}
          value={state.rate}
          placeholder="$ Rate per question"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

const BillingConfig: FC<BillingConfigType> = ({ state, setState }) => {
  if (!state.disable_billing) {
    return (
      <>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography variant="h6" display="block" className="tw-font-normal">
              Set the rate for your instructors / mentors.
              <RenderHelpIcon text="Prevent your instructors / mentors from setting their own rates and use business rate" />
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Switch
              defaultChecked={state.business_set_rate}
              onChange={(ev: any) => setState({ ...state, business_set_rate: ev.target.checked })}
              className="tw-float-right"
            />
          </Grid>

          {state.business_set_rate ? (
            <Grid item xs={12} className="tw-py-2">
              <QuestionRate state={state} setState={setState} />
            </Grid>
          ) : null}

          <Grid item xs={12} className="tw-py-2">
            <Divider />
          </Grid>

          <Grid item xs={11}>
            <Typography variant="h6" display="block" className="tw-font-normal">
              Pay as you go
              <RenderHelpIcon text="This is the pay as you go model where you client pays for each request directly." />
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Switch
              defaultChecked={state.external_billing}
              onChange={(ev: any) => setState({ ...state, external_billing: ev.target.checked })}
              className="tw-float-right"
            />
          </Grid>
        </Grid>
      </>
    );
  }
  return null;
};

const FinancialConfig: FC = () => {
  const dispatch = useDispatch();
  const { business, loading } = useBusiness();

  const [state, setState] = useState<BusinessFinancialType>({
    ...(business?.financial_config || {}),
    disable_billing: business?.financial_config?.disable_billing || false,
    rate: business?.financial_config?.rate,
    business_set_rate: business?.financial_config?.business_set_rate || false,
    external_billing: business?.financial_config?.external_billing || false,
  });

  const callback = (resp: any) => {
    if (resp.id) {
      dispatch(notification('The financial policy was updated', 'success'));
    } else if (resp.error) {
      const msg = (resp.data && resp.data.error) || resp.error;
      dispatch(notification(msg, 'error'));
    }
  };

  const apiPatchRequest = async () => {
    try {
      const financial_config: BusinessFinancialType = {
        ...(business?.financial_config || {}),
        disable_billing: state.disable_billing,
        business_set_rate: state.business_set_rate,
        rate: state.rate,
        external_billing: state.external_billing,
      };
      const resp = await dispatch(
        businessApi.patchRequest(
          {
            financial_config,
          },
          business?.uid || '',
        ),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  return (
    <>
      <Grid container spacing={0} className="tw-mt-4">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ marginBottom: 20 }}
            display="block"
            className="tw-text-2xl tw-font-medium"
          >
            Manage Financial Policy
            {loading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
          </Typography>
        </Grid>

        <Grid item xs={12} className="tw-py-2">
          <Divider />
        </Grid>

        <Grid item xs={11}>
          <Typography variant="h6" display="block" className="tw-font-normal">
            Disable billing
            <RenderHelpIcon text="This allows you to charge users per request. Switching it off allows the users to ask questions without worrying about payments" />
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Switch
            defaultChecked={state.disable_billing}
            onChange={(ev: any) => setState({ ...state, disable_billing: ev.target.checked })}
            className="tw-float-right"
          />
        </Grid>

        <Grid item xs={12} className="tw-py-2">
          <Divider />
        </Grid>

        <BillingConfig state={state} setState={setState} />

        <Grid item sm={12} md={6} className="tw-align-start tw-mt-5 tw-flex tw-justify-start">
          <SaveButton onClick={apiPatchRequest} />
        </Grid>
      </Grid>
    </>
  );
};

export default FinancialConfig;
