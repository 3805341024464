import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Typography, Grid, Button, InputBase, Alert } from '@mui/material';
import { FC } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { Img } from 'react-image';

// App
import InsufficientFund from 'components/Fund/Insufficient';
import FeedbackEntry from 'components/Feedback/Entry';
import RequestResponseProvider from 'context/request-response';
import { UserBaseType } from 'types';
import { useRequestResponse } from 'hooks';
import { anchorCls, buttonPrimary } from 'styles/classnames';
import Axios, { getHeaders } from 'utils/axios';
import { API_URL } from 'utils/constants';
import { useSelector } from 'hooks';
import { userPublic as userPublicApi } from 'store/index';
import eventbriteLogo from 'images/social/eventbrite.png';

const InputStyled = styled(InputBase)`
  & .MuiInputBase-input {
    border: inherit;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },

  askQuestionText: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

interface AskQuestionProps {
  isPublic?: boolean;
  disabled?: boolean;
  requested: UserBaseType;
  onSuccessRedirect?: (request_uid?: string) => void;
}

function EventbriteValidation() {
  const user: UserBaseType = useSelector((state: any) => state[userPublicApi.APP_NAME].detail);
  const { isPublic, attendee, setAttendee } = useRequestResponse();
  const instructor = user?.instructor;

  const name = user?.full_name ? user.full_name.split(' ')[0] : user?.username;
  if (!isPublic || !(instructor?.eventbrite_list || []).length) return null;
  const evenList = instructor?.eventbrite_list?.map((x, i) => [
    i > 0 && ', ',
    <a href={x.url} target="_blank" className={anchorCls}>
      {x.name} on {dayjs(x.start).format('D-MMM-YYYY')}
    </a>,
  ]);

  const validateEmail = async () => {
    if (!attendee.email) return null;

    const headers = getHeaders();
    try {
      const resp = await Axios.post(
        `${API_URL}/api/v1/instructor/instructor/${instructor?.uid || instructor?.id}/eventbrite_check/`,
        { email: attendee.email },
        { headers },
      );
      setAttendee({ ...attendee, ...resp.data, completed: true });
    } catch (err) {
      setAttendee({ ...attendee, error: 'Something went wrong', completed: true });
    }
  };

  return (
    <Grid container className="tw-mb-12">
      <Grid item xs={12} className="tw-mb-5">
        <Typography
          variant="h5"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gutterBottom
        >
          <Img src={eventbriteLogo} className="tw-h-5 tw-w-5 tw-mr-2" alt="Feature 1" /> Eventbrite
          Events
        </Typography>

        <Typography variant="body1" display="block" gutterBottom>
          {name} has connected their Eventbrite account to Feedback Session. If you purchased a
          ticket requesting feedback for any of the following events, please enter the email you
          used for your purchase to request feedback from {name} without additonal payment.{' '}
          {evenList}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2} className="tw-flex tw-items-center">
        <Typography variant="h6">Check Email</Typography>
      </Grid>

      <Grid item xs={12} sm={8} className="tw-flex tw-items-center tw-justify-center">
        <InputStyled
          className="tw-block tw-w-full"
          slotProps={{
            input: {
              className: classnames(
                'tw-w-full tw-appearance-none tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-py-2.5 tw-px-4 tw-text-slate-800 tw-placeholder-slate-500/75 tw-outline-none tw-duration-150 hover:tw-bg-vanilla focus:tw-border-gray-secondary-200 focus:tw-bg-vanilla focus:tw-outline-none focus:tw-ring-gray-secondary-200',
              ),
            },
          }}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          placeholder="Email address"
          inputProps={{
            type: 'email',
            onChange: (ev: any) => setAttendee({ ...attendee, email: ev.target.value }),
            value: attendee.email,
            variant: 'outlined',
          }}
        />
      </Grid>

      <Grid item xs={12} sm={2} className="tw-flex tw-items-center tw-justify-end">
        <Button
          className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}
          onClick={validateEmail}
        >
          Submit
        </Button>
      </Grid>

      {((attendee?.completed && !attendee?.can_ask) || attendee?.error) && (
        <Grid item xs={12} className="tw-mt-4">
          <Alert severity="error" className="tw-font-bold tw-text-md tw-flex tw-items-center">
            The email is not valid! But you can still ask your question below for a fee.
          </Alert>
        </Grid>
      )}

      {attendee?.completed && attendee?.can_ask && (
        <Grid item xs={12} className="tw-mt-4">
          <Alert
            severity="success"
            className="tw-text-white tw-font-bold tw-text-md tw-flex tw-items-center"
          >
            The email is valid! You can ask your question without additonal payment.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}

const AskQuestion: FC = () => {
  const classes = useStyles();
  const { error, setError, getFeedback } = useRequestResponse();

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12}>
        <EventbriteValidation />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" display="block" gutterBottom className={classes.askQuestionText}>
          Ask Me Anything
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 10 }}>
        <FeedbackEntry isAsking onSubmit={getFeedback} />
      </Grid>

      {error && (
        <InsufficientFund
          content={error}
          open
          handleClose={() => {
            setError(null);
          }}
        />
      )}
    </Grid>
  );
};

const AskQuestionContainer: FC<AskQuestionProps> = ({
  isPublic,
  disabled,
  requested,
  onSuccessRedirect,
}) => {
  return (
    <RequestResponseProvider
      disabled={disabled}
      textLabel="Add your question"
      requestedUser={requested}
      isPublic={isPublic}
      canRequestCall
      onSuccessRedirect={onSuccessRedirect}
    >
      <AskQuestion />
    </RequestResponseProvider>
  );
};
export default AskQuestionContainer;
