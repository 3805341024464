import { createContext, useEffect } from 'react';
import type { PropsWithChildren } from 'react';

// App
import { BusinessMemberType } from 'types';
import { businessMember as businessMemberApi } from 'store/index';
import { useDispatch, useSelector } from 'hooks';

export type IState = {
  membershipList?: BusinessMemberType[];
  membershipInitialized: boolean;
};

const InitialState: Partial<IState> = {};

export type ClientContextProps = IState;
export const ClientContext = createContext<ClientContextProps>(InitialState as ClientContextProps);

export default function ClientProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const dispatch = useDispatch();
  const membershipList: BusinessMemberType[] = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].list,
  );
  const membershipInitialized: boolean = useSelector(
    (state: any) => state[businessMemberApi.APP_NAME].initialized,
  );

  // If user id and anonymous businessMember id but no membership id, then get the authenticated user
  useEffect(() => {
    dispatch(businessMemberApi.getRequest());
  }, []);

  // THIS IS A TEMPORARY SOLUTION IN GETTING THE FIRST ADMIN AS USER PUBLIC API
  // useEffect(() => {
  //   if (!!membershipList.length) {
  //     const member = membershipList[0];
  //     if (member.admin) {
  //       dispatch(userPublicApi.getDetailRequest(encodeURI(member.admin.username)));
  //     }
  //   }
  // }, [membershipList]);

  const value: ClientContextProps = {
    membershipList,
    membershipInitialized,
  };
  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
}
