import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { TabPanel, a11yProps } from 'components/Tab/index';
import General from 'containers/Account/General';
import Integrations from 'containers/Account/Integrations';
import SelectFocus from 'components/Focus/Select';
import { ContentCard } from 'layouts/dashboard/Content';

export default function AccountPage(): JSX.Element {
  const params = useParams();
  const [value, setValue] = useState(params && params.tab === 'finance' ? 2 : 0);

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ContentCard>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Integrations" {...a11yProps(1)} />
        <Tab label="Specialization" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <General />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Integrations />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <SelectFocus />
      </TabPanel>
    </ContentCard>
  );
}
