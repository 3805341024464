import { FC } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

// App
import { userPublic as userPublicApi } from 'store/index';
import { UserBaseType } from 'types/index';
import { useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    display: 'flex',
    justifyContent: (props: { chipJustify: string }) => props.chipJustify,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

interface FocusItemListType {
  isPublic?: boolean;
  user: UserBaseType;
  fontSize?: number;
  size?: 'small' | 'medium';
  limit?: number;
  justify?: string;
}

export const FocusItemList: FC<FocusItemListType> = ({
  isPublic,
  user,
  fontSize = 16,
  size = 'medium',
  limit,
  justify = 'center',
}) => {
  const userParams = useSelector((state: any) => state[userPublicApi.APP_NAME].params);
  const classes = useStyles({ chipJustify: justify });
  let list = [...(user.focus || [])];
  const isPastLimit = limit && list.length > limit;

  if (limit) {
    list = list.splice(0, limit);
  }

  return (
    <div className={classes.chipRoot}>
      {list.map(x => {
        const isQuery =
          isPublic &&
          ((userParams.focus || []).indexOf(x.focus__name.toLowerCase()) !== -1 ||
            (userParams.search || []).indexOf(x.focus__name.toLowerCase()) !== -1);

        return (
          <Chip
            key={x.id}
            label={x.focus__name.toLowerCase()}
            variant={isQuery ? 'filled' : 'outlined'}
            color={isQuery ? 'primary' : 'default'}
            size={size}
            style={{
              fontSize,
              textTransform: 'capitalize',
              borderColor: x.focus__primary ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.23)',
              maxWidth: limit ? 100 : 400,
            }}
          />
        );
      })}

      {isPastLimit ? '...' : null}
    </div>
  );
};

export default FocusItemList;
