import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';

// App
import { buttonPrimary, primaryColor } from 'styles/classnames';
import { FormContainer } from 'components/Form/Form';
import { InviteMemberType, BusinessMemberTypeEnum } from 'types';
import { businessMember as businessMemberApi, userAuth as userAuthApi } from 'store/index';
import { useDispatch, useBusiness } from 'hooks';

interface MemberRowProps {
  item: InviteMemberType;
  memberList: InviteMemberType[];
  setMemberList: (state: any) => void;
  isLast: boolean;
}

function generateRateState(): InviteMemberType {
  return { email: '', member_type: BusinessMemberTypeEnum.CLIENT, uuid: uuidv4() };
}

function MemberRow({ setMemberList, memberList, isLast, item }: MemberRowProps) {
  return (
    <>
      <Grid item xs={1}>
        {!isLast ? (
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={() => setMemberList([...memberList].filter(x => x.uuid !== item.uuid))}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={primaryColor}
            onClick={() => setMemberList([...memberList, generateRateState()])}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>

      <Grid item xs={11}>
        <TextField
          id="email"
          label="Email"
          // error={!!error[field]}
          variant="outlined"
          onChange={ev =>
            setMemberList(
              [...memberList].map(x =>
                x.uuid === item.uuid ? { ...item, email: ev.target.value } : x,
              ),
            )
          }
          value={item.email}
          placeholder="Email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  );
}

export default function InviteMember(): JSX.Element {
  const [memberList, setMemberList] = useState<InviteMemberType[]>([generateRateState()]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { business } = useBusiness();

  const postMember = async (email: string) => {
    return await dispatch(
      businessMemberApi.postRequest({
        email,
        member_type: 'CLIENT',
        business_id: business?.id,
      }),
    );
  };

  const saveMembers = async () => {
    const members = [...memberList]
      .filter(x => x.email)
      .map(x => {
        if (x.uuid) delete x.uuid;
        return x;
      });
    await Promise.all(members.map(x => postMember(x.email)));
    dispatch(userAuthApi.getDetailRequest('user'));
    navigate('/client');
  };

  return (
    <FormContainer title="Add Client" isCenter>
      {memberList.map((item, index) => (
        <MemberRow
          item={item}
          memberList={memberList}
          setMemberList={setMemberList}
          isLast={memberList.length === index + 1}
        />
      ))}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          variant="outlined"
          className={clsx(buttonPrimary, 'tw-w-full tw-py-2 tw-text-lg md:tw-w-48')}
          onClick={saveMembers}
        >
          Submit
        </Button>
      </Grid>
    </FormContainer>
  );
}
