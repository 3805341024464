import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import RateContainer from 'containers/Manage/Rate';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: 50,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
}));

const OnboardRate: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <RateContainer isOnboarding />
    </div>
  );
};

export default OnboardRate;
