import { Button, Grid, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { enqueueSnackbar } from 'notistack';
import eventbriteIcon from '@iconify-icons/logos/eventbrite-icon';

import { eventbriteButton } from 'styles/classnames';
import { userAuth as userAuthApi } from 'store/index';
import { UserAuthType } from 'types/api';
import Axios from 'utils/axios';
import { API_URL } from 'utils/constants';
import { useSelector } from 'hooks';

export function EventbriteCard() {
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  const connectEventbrite = async () => {
    if (user?.integrations?.eventbrite?.is_connected) return;

    try {
      const resp = await Axios.get(`${API_URL}/integration/eventbrite/oauth/start`);
      const url = resp?.data?.url;
      if (url) {
        window.open(url);
      } else {
        enqueueSnackbar('Something went wrong when connecting to eventbrite', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong when connecting to eventbrite', {
        variant: 'error',
      });
    }
  };

  return (
    <Grid container spacing={1} className="tw-py-6 tw-px-6">
      <Grid item xs={1}>
        <Icon width={32} height={32} icon={eventbriteIcon} />
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h6" paragraph>
          Eventbrite
        </Typography>
        <Typography variant="body1" paragraph>
          Connect your Eventbrite account to Feedback Session to seamlessly provide feedback to
          workshop attendees when requested. This integration lets you follow up with participants,
          offering valuable insights and personalized feedback to enhance their learning experience
          and encourage future attendance. You can also charge different rates for standard tickets
          and tickets that include feedback.
        </Typography>
        <Button className={`tw-mt-4 tw-w-52 ${eventbriteButton}`} onClick={connectEventbrite}>
          {user?.integrations?.eventbrite?.is_connected ? 'Connected' : '+ connect Eventbrite'}
        </Button>
      </Grid>
    </Grid>
  );
}
