import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { FileWithPath } from 'file-selector';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// App
import AuthButton from 'containers/Auth/components/Modal';
import { userAuth as userAuthApi } from 'store/index';
import { buttonPrimary } from 'styles/classnames';
import { BoardType } from 'types';
import { useSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  dropzonePaper: {
    textAlign: 'center',
  },

  dropzone: {
    minHeight: 300,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      minHeight: 300,
    },
  },

  dropzoneContent: {
    flex: 1,
  },

  dropzoneButton: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },

  filecontentTypography: {
    marginBottom: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
      wordWrap: 'break-word',
      textAlign: 'center',
    },
  },
}));

interface FileContentProps {
  title: string;
  subtitle: string;
  fileList: FileWithPath[];
  hasFile: boolean;
  board?: BoardType;
}

const FileContent: FC<FileContentProps> = ({ title, subtitle, fileList, hasFile, board }) => {
  const classes = useStyles();
  if (!hasFile) {
    return (
      <>
        <Typography variant="h6" gutterBottom className={classes.filecontentTypography}>
          {title}
        </Typography>

        <Typography variant="body1" gutterBottom className={classes.filecontentTypography}>
          {subtitle || 'Drag and drop to upload.'}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h6" gutterBottom className={classes.filecontentTypography}>
          {fileList[0].name}
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.filecontentTypography}>
          Upload to {board ? `to ${board.name}` : null} folder
        </Typography>
      </>
    );
  }
};

interface DropzoneProps {
  title: string;
  subtitle: string;
  board: BoardType;
  progress: any;
  uploadStatus: string;
  disabled?: boolean;
  fileList: FileWithPath[];
  setFileList?: (fileList: FileWithPath[]) => void;
}

export default function DropzoneComponent({
  title,
  subtitle,
  progress = {},
  uploadStatus,
  fileList = [],
  setFileList,
  disabled,
}: DropzoneProps): JSX.Element {
  const classes = useStyles();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setFileList?.(acceptedFiles);
    },
    [setFileList],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: disabled });
  const { ...rootProps } = getRootProps();
  const hasFile = fileList.length > 0;

  return (
    <div {...getRootProps()}>
      <div {...rootProps} className={classes.dropzonePaper}>
        <div className={classnames('dropzone dz-clickable', classes.dropzone)}>
          <div className={classes.dropzoneContent}>
            <input {...getInputProps()} />

            <FileContent fileList={fileList} hasFile={hasFile} title={title} subtitle={subtitle} />

            {user && user.id ? (
              <Button
                variant="outlined"
                className={classnames(classes.dropzoneButton, buttonPrimary, 'tw-text-lg')}
                startIcon={<CloudUploadIcon />}
              >
                Click to {hasFile ? 'replace' : 'select'} file
              </Button>
            ) : (
              <AuthButton
                buttonProps={{
                  startIcon: <CloudUploadIcon />,
                  className: classnames(classes.dropzoneButton, buttonPrimary, 'tw-text-lg'),
                }}
                title={`Click to ${hasFile ? 'replace' : 'select'} file`}
              />
            )}
          </div>
        </div>

        {progress && progress.percent !== undefined && (
          <div style={{ display: 'relative' }}>
            <span>
              {(uploadStatus || '').toLowerCase()}{' '}
              {uploadStatus === 'PROCESSING' && (
                <CircularProgress style={{ marginLeft: 5 }} size={12} />
              )}
            </span>
            <LinearProgress
              variant="buffer"
              value={progress.percent}
              valueBuffer={progress.percent * 1.2}
              style={{ height: 30 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
