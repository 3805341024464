import { useState } from 'react';
import Joyride from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// App
import Focus from 'components/Focus/index';
import Withdraw from 'components/Fund/Withdraw';
import TransactionHistory from 'components/Fund/TransactionHistory';
import MediaLink from 'components/User/MediaLink';
import { TabPanel, a11yProps } from 'components/Tab/index';
import { CustomTextField } from 'components/Input/index';
import { userAuth as userAuthApi } from 'store/index';
import { useDispatch, useSelector, useBusiness } from 'hooks';
import { API_URL } from 'utils/constants';
import { SaveButton } from 'components/Common/Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

function GridDivider() {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Divider light style={{ marginBottom: 40, marginTop: 30 }} />
    </Grid>
  );
}

export function EditNotifications({ title }: { title?: string }): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  const [state, setState] = useState({
    ...user.instructor,
    phone_number: user.phone_number,
  });
  const [error] = useState({});

  const textFieldProps = {
    state,
    error,
    setState,
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 30 }}>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ marginBottom: 20 }} display="block">
          {title || 'Enter your phone number so as to be notified as soon as you get a request.'}
        </Typography>

        <CustomTextField
          gridId="input-add-phone-number"
          field="phone_number"
          placeholder="Enter phone number"
          {...textFieldProps}
          lg={12}
          md={12}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <SaveButton
          onClick={() => {
            dispatch(
              userAuthApi.patchRequest(
                { phone_number: state.phone_number },
                null, // no id
                {
                  url: `${API_URL}/api/v1/user/user/${user.id}/update_profile/`,
                  notify: {
                    success: `The profile was updated`,
                  },
                },
              ),
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

export function Info(): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Configure
        </Typography>
      </Grid>

      <GridDivider />

      <EditNotifications />

      <GridDivider />

      <Grid id="grid-profile-link" item xs={12} sm={12} md={12} lg={12}>
        <MediaLink />
      </Grid>

      <GridDivider />

      <Grid id="grid-focus" item xs={12} sm={12} md={12} lg={12}>
        <Focus />
      </Grid>
    </Grid>
  );
}

function RenderJoyRide(): JSX.Element {
  const location = useLocation();
  const steps = [
    {
      target: '#input-add-rate',
      content: <div>Set how much your followers will pay you to answer their questions.</div>,
    },

    {
      target: '#input-add-phone-number',
      content: (
        <div>
          Get notified immediately you get a request for feedback, this means you get paid faster
        </div>
      ),
    },

    {
      target: '#grid-profile-link',
      content: <div>This is where your users can find you outside this platform.</div>,
    },

    {
      target: '#grid-focus',
      content: (
        <div>
          Highlight what your specialities are, makes it easier for followers to find you on the
          app.
        </div>
      ),
    },
  ];

  if (location.search == '?showGuide') {
    return <Joyride steps={steps} continuous showProgress />;
  }
  return <div />;
}

export default function Configure(): JSX.Element {
  const params = useParams();
  const classes = useStyles();
  const [value, setValue] = useState(params && params.tab === 'withdraw' ? 1 : 0);
  const { business } = useBusiness();

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="General" {...a11yProps(0)} />
        {business?.financial_config?.external_billing ||
        business?.financial_config?.disable_billing ? null : (
          <Tab label="Withdraw" {...a11yProps(1)} />
        )}
        <Tab label="History" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Info />
      </TabPanel>
      {business?.financial_config?.external_billing ||
      business?.financial_config?.disable_billing ? null : (
        <TabPanel value={value} index={1}>
          <Withdraw />
        </TabPanel>
      )}

      <TabPanel value={value} index={2}>
        <TransactionHistory />
      </TabPanel>

      <RenderJoyRide />
    </div>
  );
}
