import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import classnames from 'classnames';

import { buttonPrimary } from 'styles/classnames';

export function ButtonRouterLink({ link, text = 'View' }: { link: string; text?: string }) {
  return (
    <Button className={classnames(buttonPrimary, 'tw-py-2 tw-text-base')}>
      <RouterLink to={link}>
        <span className="tw-flex tw-items-center tw-rounded-md tw-text-sm tw-px-2 tw-py-2 tw-h-4 tw-w-18">
          <span className="tw-flex tw-items-center tw-gap-x-2">
            {/* <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="0"
              viewBox="0 0 24 24"
              className="tw-text-lg"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              ></path>
            </svg> */}
            {text}
          </span>
        </span>
      </RouterLink>
    </Button>
  );
}
