import classnames from 'classnames';

// MUI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// App
import instagramImage from 'images/social/instagram.png';
import youtubeImage from 'images/social/youtube.png';
import tiktokImage from 'images/social/tik-tok.png';
import facebookImage from 'images/social/facebook.png';
import linkedinImage from 'images/social/linkedin.png';
import twitterImage from 'images/social/twitter.png';
import wwwImage from 'images/social/world-wide-web.png';
import snapchatImage from 'images/social/snapchat.png';
import mediumImage from 'images/social/medium.png';
import { getSocialLink } from 'utils/core';

interface StyleProps {
  align?: 'left' | 'center';
  width?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 20,
    justifyContent: 'space-around',
    alignItems: (props: StyleProps) => props.align || 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
  },

  iconRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 10,
    width: (props: StyleProps) => props.width || 270,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-evenly',
    },
  },

  svgDisabled: {
    opacity: 0.3,
  },
}));

interface SocialIconItemProps {
  src: string;
  links: Record<string, string>;
  socialKey: string;
  isList?: boolean;
}

function SocialIconItem({ src, links, socialKey, isList }: SocialIconItemProps) {
  const classes = useStyles({});
  const linkHref = getSocialLink(links, socialKey);

  const render = (
    <img
      key={socialKey}
      src={src}
      style={{ width: 25, height: 25, marginRight: 7 }}
      className={classnames({
        [classes.svgDisabled]: !links || !links[socialKey],
      })}
    />
  );

  if (isList) {
    return render;
  }

  return (
    <a key={socialKey} target="_blank" href={linkHref} className="tw-mr-6">
      {render}
    </a>
  );
}

interface SocialProps {
  width?: number;
  align?: 'left' | 'center';
  links: Record<string, string>;
  isList?: boolean;
}

export default function Social({ width, align, isList, links }: SocialProps): JSX.Element {
  const classes = useStyles({ width, align });
  let row1 = [];
  let row2 = [];

  const linkList = [
    { key: 'linkedin', img: linkedinImage },
    { key: 'medium', img: mediumImage },
    { key: 'instagram', img: instagramImage },
    { key: 'tiktok', img: tiktokImage },
    { key: 'snapchat', img: snapchatImage },
    { key: 'facebook', img: facebookImage },
    { key: 'youtube', img: youtubeImage },
    { key: 'twitter', img: twitterImage },
    { key: 'website', img: wwwImage },
  ];
  let hasLinksList = linkList.filter(x => links?.[x.key]);

  if (isList) {
    hasLinksList = hasLinksList.splice(0, 4);
  }
  const renderLinkSource = hasLinksList.map(x => {
    return (
      <SocialIconItem key={x.key} src={x.img} socialKey={x.key} links={links} isList={isList} />
    );
  });

  row1 = [...renderLinkSource].splice(0, 4);
  row2 = [...renderLinkSource].splice(4, renderLinkSource.length);

  let renderRow1;
  let renderRow2;

  if (row1.length > 0) {
    renderRow1 = <div className={classes.iconRow}>{row1}</div>;
  }

  if (row2.length > 0) {
    renderRow2 = <div className={classes.iconRow}>{row2}</div>;
  }

  return (
    <div className={classes.iconContainer}>
      {renderRow1}
      {renderRow2}
    </div>
  );
}
