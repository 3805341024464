import { useEffect } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { Img } from 'react-image';
import { sentenceCase } from 'change-case';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import eventbriteLogo from 'images/social/eventbrite.png';
import { Table as TableComponent } from 'components/Table';
import {
  eventbriteEvent as eventbriteEventApi,
  eventbriteAttendee as eventbriteAttendeeApi,
  eventbriteTicketClass as eventbriteTicketClassApi,
} from 'store/index';
import { useDispatch, useSelector } from 'hooks';
import { EventbriteEventType, EventbriteAttendeeType, EventbriteTicketClassType } from 'types/api';
import Label from 'components/Common/Label';
import { card } from 'styles/classnames';
import { VideoTitleLoading } from 'components/Loading/index';
import HeaderBreadcrumbs from 'components/Common/HeaderBreadcrumbs';
import { ROUTE_MAP } from 'utils/constants';

function EventbriteHeader() {
  const eventbriteEventDetail: EventbriteEventType = useSelector(
    (state: any) => state[eventbriteEventApi.APP_NAME].detail,
  );

  return (
    <div className={classnames(card)}>
      <Grid container spacing={2} className="tw-py-6 tw-px-6">
        <Grid item xs={12} sm={1}>
          <Img src={eventbriteLogo} className="tw-h-12 tw-w-12" alt="Feature 1" />
        </Grid>
        <Grid item xs={12} sm={11}>
          <Typography variant="h6" paragraph>
            {eventbriteEventDetail?.name}
          </Typography>
          <Typography variant="body1" paragraph>
            {eventbriteEventDetail?.summary}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

function EventsTicketClassTable() {
  const dispatch = useDispatch();

  const eventbriteTicketClassApiList: EventbriteTicketClassType[] = useSelector(
    (state: any) => state[eventbriteTicketClassApi.APP_NAME].list,
  );
  const eventbriteEventDetail: EventbriteEventType = useSelector(
    (state: any) => state[eventbriteEventApi.APP_NAME].detail,
  );

  useEffect(() => {
    dispatch(eventbriteTicketClassApi.reset());
    dispatch(eventbriteTicketClassApi.setGetParams({ event_id: eventbriteEventDetail.event_id }));
    dispatch(eventbriteTicketClassApi.getRequest());
  }, []);

  const headerList = [
    {
      id: 'name',
      label: 'Ticket Name',
    },
    // {
    //   id: 'start_date',
    //   label: 'Start',
    // },
    // {
    //   id: 'end_date',
    //   label: 'End',
    // },
    {
      id: 'feedback_enabled',
      label: 'Feedback Enabled',
    },
    {
      id: 'total_attendees',
      label: '# Attendees',
    },
    {
      id: 'total_requests',
      label: '# Requests',
    },
  ];

  const dataList = eventbriteTicketClassApiList.map(x => {
    return {
      id: { value: x.id, display: x.id },
      name: { value: x.name, display: x.name },
      feedback_enabled: {
        value: x.is_feedback,
        display: (
          <Label variant="filled" color={x.is_feedback ? 'success' : 'error'}>
            {sentenceCase(x.is_feedback ? 'yes' : 'no')}
          </Label>
        ),
      },
    };
  });

  return (
    <Grid item xs={12} marginTop={5}>
      <Typography variant="h4" display="block" className="tw-mb-4">
        Event Tickets
      </Typography>

      <TableComponent
        headerList={headerList}
        dataList={dataList}
        emptyNode={
          <Paper>
            <Typography gutterBottom align="center" variant="subtitle1">
              No upcoming events
            </Typography>
            <Typography variant="body2" align="center">
              You currently don't have any upcoming events.
            </Typography>
          </Paper>
        }
      />
    </Grid>
  );
}

function EventsAttendees() {
  const dispatch = useDispatch();
  const params = useParams();

  const eventbriteAttendeeList: EventbriteAttendeeType[] = useSelector(
    (state: any) => state[eventbriteAttendeeApi.APP_NAME].list,
  );

  useEffect(() => {
    dispatch(eventbriteAttendeeApi.setGetParams({ event_id: params.uid }));
    dispatch(eventbriteAttendeeApi.getRequest());
  }, []);

  const headerList = [
    {
      id: 'name',
      label: 'Attendee Name',
    },
    {
      id: 'feedback_enabled',
      label: 'Feedback Enabled',
    },
    {
      id: 'feedback_requested',
      label: 'Requested Feedback',
    },
    {
      id: 'feedback_completed',
      label: 'Received Completed',
    },
  ];

  const dataList = eventbriteAttendeeList.map(x => {
    return {
      id: { value: x.id, display: x.id },
      name: { value: x.name, display: x.name },
      feedback_enabled: {
        value: x.feedback_enabled,
        display: (
          <Label variant="filled" color={x.feedback_enabled ? 'success' : 'error'}>
            {sentenceCase(x.feedback_enabled ? 'yes' : 'no')}
          </Label>
        ),
      },
      feedback_requested: {
        value: x.feedback_requested,
        display: (
          <Label variant="filled" color={x.feedback_requested ? 'success' : 'error'}>
            {sentenceCase(x.feedback_requested ? 'yes' : 'no')}
          </Label>
        ),
      },
      feedback_completed: {
        value: x.feedback_completed,
        display: (
          <Label variant="filled" color={x.feedback_completed ? 'success' : 'error'}>
            {sentenceCase(x.feedback_completed ? 'yes' : 'no')}
          </Label>
        ),
      },
    };
  });

  return (
    <Grid item xs={12} marginTop={5}>
      <Typography variant="h4" display="block" className="tw-mb-4">
        Event Attendees
      </Typography>

      <TableComponent
        headerList={headerList}
        dataList={dataList}
        emptyNode={
          <Paper>
            <Typography gutterBottom align="center" variant="subtitle1">
              No attendees
            </Typography>
            <Typography variant="body2" align="center">
              No attendee has signed up yet.
            </Typography>
          </Paper>
        }
      />
    </Grid>
  );
}

export default function Eventbrite(): JSX.Element {
  const params = useParams();
  const dispatch = useDispatch();

  const eventbriteEventInitalized: boolean = useSelector(
    (state: any) => state[eventbriteEventApi.APP_NAME].initialized,
  );

  useEffect(() => {
    dispatch(eventbriteEventApi.resetDetail());
    if (params.uid) dispatch(eventbriteEventApi.getDetailRequest(params.uid));
  }, []);

  if (!eventbriteEventInitalized) {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VideoTitleLoading />
          </Grid>
        </Grid>
      </Container>
    );
  }
  return (
    <Container maxWidth="xl">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <HeaderBreadcrumbs
            heading="Eventbrite detail"
            links={[
              { name: 'Integration', href: ROUTE_MAP.integration.eventbrite.index },
              { name: 'Eventbrite' },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <EventbriteHeader />
        </Grid>

        <EventsTicketClassTable />

        <EventsAttendees />
      </Grid>
    </Container>
  );
}
