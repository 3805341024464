// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// App
import TransactionHistory from 'components/Fund/TransactionHistory';
import DepositHistory from 'components/Fund/DepositHistory';
import Topup from 'components/Fund/Topup';
import { useBusiness } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },

  gridContainer: {
    marginBottom: 20,
  },
}));

export default function Balance(): JSX.Element {
  const classes = useStyles();
  const { business } = useBusiness();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.gridContainer}>
        {business?.financial_config?.external_billing ||
        business?.financial_config?.disable_billing ? null : (
          <>
            <Grid item xs={12}>
              <Topup />
            </Grid>

            <Grid item xs={12}>
              <Divider light style={{ marginBottom: 40, marginTop: 40 }} />
            </Grid>
          </>
        )}

        <TransactionHistory />

        <Grid item xs={12}>
          <Divider light style={{ marginBottom: 40, marginTop: 40 }} />
        </Grid>

        <DepositHistory />
      </Grid>
    </div>
  );
}
