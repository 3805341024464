import { formatDistanceToNowStrict } from 'date-fns';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

import { feedbackRequest as feedbackRequestApi } from 'store/index';
import { CommentType, UserAuthType, FileType, MediaTypeEnum } from 'types/index';
import { UserAvatar } from 'components/Common/Avatar';
import { useSelector } from 'hooks';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

const MessageImgStyle = styled('img')(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('md')]: {
    height: 200 / 1.5,
    minWidth: 296 / 1.5,
  },
}));

const CardMediaStyled = styled(CardMedia)(() => ({
  height: 100,
  width: 100,
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));
// ----------------------------------------------------------------------

type ChatMessageItemProps = {
  message: CommentType;
  onOpenLightbox: (value: FileType) => void;
  user: UserAuthType;
};

export default function ChatMessageItem({
  message,
  onOpenLightbox,
  user,
}: ChatMessageItemProps): JSX.Element {
  const request = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].detail);

  const senderDetails = message?.created_by;

  const isMe = senderDetails?.id === user.id;
  const isImage = message?.video_comment?.thumbnail;
  const media_type = message?.video_comment?.media_type;
  const isDoc = media_type === MediaTypeEnum.DOC;
  const firstName = senderDetails?.username;
  let content = <Typography variant="body2">{message.comment}</Typography>;

  if (isDoc) {
    content = (
      <Link to={`/request/${request.id}/doc/comment/${message?.id}`}>
        <div className="tw-flex tw-cursor-pointer tw-flex-col">
          <CardMediaStyled>
            <Icon icon="file-icons:microsoft-word" style={{ fontSize: 50, color: '#3b82f6' }} />
          </CardMediaStyled>
          <Typography variant="body1" align="left" className="tw-p-2 tw-text-xs tw-text-blue-700">
            {message.video_comment.title}
          </Typography>
        </div>
      </Link>
    );
  } else if (isImage) {
    content = (
      <MessageImgStyle
        alt="attachment"
        src={message?.video_comment?.thumbnail}
        onClick={() => onOpenLightbox(message?.video_comment)}
      />
    );
  }

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {/* {!isMe && (
          <Avatar
            alt={senderDetails.full_name}
            src={senderDetails.avatar}
            sx={{ width: 32, height: 32, mr: 2 }}
          />
        )} */}
        {!isMe ? <UserAvatar user={senderDetails} size={30} /> : null}

        <div style={{ marginLeft: 2 }}>
          <InfoStyle variant="caption" sx={{ ...(isMe && { justifyContent: 'flex-end' }) }}>
            {!isMe && `${firstName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message.created_at), {
              addSuffix: true,
            })}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && !isDoc ? { color: 'grey.800', bgcolor: 'primary.lighter' } : {}),
              ...(isImage ? { p: 0 } : {}),
            }}
          >
            {content}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
