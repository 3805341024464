import { FC } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import grey from '@mui/material/colors/grey';

// App
import FileThumbnail from 'components/File/Thumbnail';
import { FileType } from 'types/index';

const CARD_MARGIN = 50;

const useStyles = makeStyles(() => ({
  publicContent: {
    height: 'auto',
    width: '100%',
    padding: '0px !important',
  },
}));

interface ImageComponentProps {
  video: FileType;
  style: any;
  path: string;
}

const ImageComponent: FC<ImageComponentProps> = ({ video, style, path }) => {
  const height = style.height || 350;

  let renderShared;
  if (video?.parent?.id) {
    renderShared = ` • by @${video?.parent?.created_by?.username}`;
  }
  return (
    <Card key={video.id} style={style}>
      <CardContent style={{ padding: 0, height: height + CARD_MARGIN }}>
        <FileThumbnail
          path={path || `/video/${video.id}`}
          thumbnail_uri={video.thumbnail}
          file_type={video.media_type}
          height={height - CARD_MARGIN}
          created_by={video.created_by || {}}
          title={video.title}
        />
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: grey[700] }} display="block">
              <b>{video.title}</b>
            </Typography>
            <Typography variant="caption" style={{ color: grey[700] }} display="block">
              {video.created_at_humanize} • {video.videoCommentCount || 0} comments •{' '}
              {video.request_count || 0} requests{renderShared} •{' '}
              {video.dynamic_link ? 'Public' : 'Private'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ImageComponent;

interface ImagePublicComponentProps {
  video: FileType;
  style: any;
  path: string;
}

export const ImagePublicComponent: FC<ImagePublicComponentProps> = ({ video, style, path }) => {
  const height = style.height || 350;
  const classes = useStyles();

  return (
    <Card key={video.id}>
      <CardContent className={classes.publicContent}>
        <FileThumbnail
          path={path || `/video/${video.id}`}
          thumbnail_uri={video.thumbnail}
          file_type={video.media_type}
          height={height - CARD_MARGIN}
          created_by={video.created_by || {}}
          title={video.title}
          isPublic
        />
      </CardContent>
    </Card>
  );
};
