import { useState, FC } from 'react';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import { notification } from 'store/notification';
import { UserAuthType, RateListItemType, SelectedPlaceType } from 'types/index';
import { userAuth as userAuthApi, instructor as instructorApi } from 'store/index';
import { buttonPrimary, buttonDanger, buttonSecondary } from 'styles/classnames';
import { RenderHelpIcon } from 'components/Help/index';
import { log } from 'utils/index';
import { useDispatch, useSelector, useBusiness } from 'hooks';
import { ContentCard } from 'layouts/dashboard/Content';
import Autocomplete from 'components/Common/AutoComplete';

interface IRateState {
  rate?: number | null | string;
  rateList: RateListItemType[];
  lessonRateList: RateListItemType[];
  selectedPlaceList: SelectedPlaceType[];
}

interface RateDurationItemProps {
  type: 'video' | 'lesson';
  item: RateListItemType;
  setRateItem: (item: RateListItemType) => void;
  removeRateItem: (item: RateListItemType) => void;
}

const RateDurationItem: FC<RateDurationItemProps> = ({
  type,
  item,
  setRateItem,
  removeRateItem,
}) => {
  const [error] = useState({ rate: undefined });
  const DURATIONS =
    type === 'lesson' ? [30, 45, 60] : [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  const options = DURATIONS.map(dur => (
    <MenuItem key={dur} value={`${dur}`}>
      {dur} minutes
    </MenuItem>
  ));

  return (
    <Grid item xs={12}>
      <Box>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5}>
            <Box className="tw-align-center tw-flex tw-justify-center tw-justify-evenly">
              <TextField
                id="input-add-video-rate"
                label={
                  error?.rate
                    ? error?.rate
                    : type === 'video'
                      ? 'Video call rate ($)'
                      : 'Lesson rate ($)'
                }
                error={error?.rate}
                variant="outlined"
                onChange={ev => setRateItem({ ...item, rate: ev.target.value })}
                value={item.rate}
                placeholder={
                  type === 'video' ? 'Enter rate for video call' : 'Enter rate for lesson'
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <FormControl className="tw-w-full" variant="outlined">
              <InputLabel id="select-duration-label">
                {type === 'video' ? 'Duration of call' : 'Duration of lesson'}
              </InputLabel>
              <Select
                labelId="select-duration-label"
                id="select-duration"
                value={(item.duration || '').toString()}
                onChange={(ev: any) => {
                  setRateItem({ ...item, duration: ev.target.value });
                }}
              >
                {options}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <Box className="tw-flex tw-justify-end">
              <Button
                className={classnames(buttonDanger, 'tw-mr-4 tw-w-24 tw-p-2 tw-text-base')}
                onClick={() => {
                  removeRateItem(item);
                }}
              >
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

interface RateProps {
  isOnboarding?: boolean;
}

interface RateStateType {
  state: IRateState;
  setState: (state: IRateState) => void;
}

const QuestionRate = ({ state, setState }: RateStateType) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" style={{ marginBottom: 20 }} display="block">
        How much do you charge per question ($)?
        <RenderHelpIcon text="How much you charge to give feedback on your followers questions. (Can be a video, audio or text response)" />
      </Typography>

      <TextField
        id="input-add-rate"
        label="$ Rate per question"
        variant="outlined"
        onChange={ev => setState({ ...state, rate: ev.target.value })}
        value={state.rate}
        placeholder="$ Rate per question"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        type="number"
      />
    </Grid>
  );
};

const VideoCallRateListComponent = ({ state, setState }: RateStateType) => {
  return (
    <>
      <Grid item xs={10} className="tw-mt-8">
        <Typography variant="h6" display="block">
          How much do you charge for a video call ($)?
          {/* <RenderHelpIcon text="How much you charge for a video call with your audience." /> */}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2} className="tw-mt-8">
        <Box className="tw-flex tw-justify-end">
          <Button
            className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-base')}
            onClick={() => {
              const rateList = [
                ...state.rateList,
                {
                  duration: null,
                  rate: null,
                  uid: nanoid(),
                },
              ];

              setState({ ...state, rateList });
            }}
          >
            + Add
          </Button>
        </Box>
      </Grid>

      {state.rateList.map(row => (
        <Grid item xs={12} key={row.uid} className="tw-mt-3">
          <Box className={classnames('tw-align-start tw-flex tw-justify-start')}>
            <RateDurationItem
              type="video"
              item={row}
              setRateItem={(item: RateListItemType) => {
                const rateList = [...state.rateList].map(x => {
                  if (x.uid === item.uid) return item;
                  return x;
                });
                setState({ ...state, rateList });
              }}
              removeRateItem={(item: RateListItemType) => {
                const rateList = [...state.rateList].filter(x => x.uid !== item.uid);
                setState({ ...state, rateList });
              }}
            />
          </Box>
        </Grid>
      ))}
    </>
  );
};

const LessonRateListComponent = ({ state, setState }: RateStateType) => {
  return (
    <>
      <Grid item xs={10} className="tw-mt-8">
        <Typography variant="h6" display="block">
          How much do you charge for a lesson ($)?
          {/* <RenderHelpIcon text="How much you charge for a video call with your audience." /> */}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2} className="tw-mt-8">
        <Box className="tw-flex tw-justify-end">
          <Button
            className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-base')}
            onClick={() => {
              const lessonRateList = [
                ...state.lessonRateList,
                {
                  duration: null,
                  rate: null,
                  uid: nanoid(),
                },
              ];

              setState({ ...state, lessonRateList });
            }}
          >
            + Add
          </Button>
        </Box>
      </Grid>

      {state.lessonRateList.map(row => (
        <Grid item xs={12} key={row.uid} className="tw-mt-3">
          <Box className={classnames('tw-align-start tw-flex tw-justify-start')}>
            <RateDurationItem
              type="lesson"
              item={row}
              setRateItem={(item: RateListItemType) => {
                const lessonRateList = [...state.lessonRateList].map(x => {
                  if (x.uid === item.uid) return item;
                  return x;
                });
                setState({ ...state, lessonRateList });
              }}
              removeRateItem={(item: RateListItemType) => {
                const lessonRateList = [...state.lessonRateList].filter(x => x.uid !== item.uid);
                setState({ ...state, lessonRateList });
              }}
            />
          </Box>
        </Grid>
      ))}

      <Grid item xs={10} className="tw-mt-8">
        <Typography variant="h6" display="block">
          Where do you teach your lessons?
          {/* <RenderHelpIcon text="How much you charge for a video call with your audience." /> */}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2} className="tw-mt-8">
        <Box className="tw-flex tw-justify-end">
          <Button
            className={classnames(buttonPrimary, 'tw-mr-4 tw-w-24 tw-text-base')}
            onClick={() => {
              const selectedPlaceList = [
                ...state.selectedPlaceList,
                {
                  uid: nanoid(),
                  place: null,
                },
              ];

              setState({ ...state, selectedPlaceList });
            }}
          >
            + Add
          </Button>
        </Box>
      </Grid>

      {state.selectedPlaceList.map(place => (
        <Grid container item key={place.uid} className="tw-mt-3">
          <Grid item xs={8}>
            <Autocomplete
              selectedPlace={place?.place || null}
              setSelectedPlace={(value: google.maps.places.PlaceResult | null) => {
                const selectedPlaceList = [...state.selectedPlaceList].map(x => {
                  if (x.uid === place.uid) {
                    x.place = value;
                  }
                  return x;
                });
                setState({ ...state, selectedPlaceList });
              }}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Box className="tw-flex tw-justify-end">
              <Button
                className={classnames(buttonDanger, 'tw-mr-4 tw-w-24 tw-p-2 tw-text-base')}
                onClick={() => {
                  const selectedPlaceList = [...state.selectedPlaceList].filter(
                    x => x.uid !== place.uid,
                  );
                  setState({ ...state, selectedPlaceList });
                }}
              >
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export const Rate: FC<RateProps> = ({ isOnboarding }) => {
  const dispatch = useDispatch();
  const { business } = useBusiness();

  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);
  const instructorLoading: boolean = useSelector(
    (state: any) => state[instructorApi.APP_NAME].loading,
  );

  const [state, setState] = useState<IRateState>({
    rate: user?.instructor?.rate,
    rateList: user?.instructor?.rate_list || [
      {
        duration: null,
        rate: null,
        uid: nanoid(),
      },
    ],
    lessonRateList: user?.instructor?.lesson_rate_list || [
      {
        duration: null,
        rate: null,
        uid: nanoid(),
      },
    ],
    selectedPlaceList: user?.instructor?.lesson_location_list || [
      {
        uid: nanoid(),
        place: null,
      },
    ],
  });

  const callback = (resp: any) => {
    if (resp.id) {
      dispatch(notification('The rate was updated', 'success'));
      dispatch(userAuthApi.getDetailRequest('user'));
    } else if (resp.error) {
      const msg = (resp.data && resp.data.error) || resp.error;
      dispatch(notification(msg, 'error'));
    }
  };

  const apiPatchRequest = async (instructorId: number) => {
    try {
      const resp = await dispatch(
        instructorApi.patchRequest(
          {
            rate: state.rate,
            rate_list: state.rateList.filter(x => x.duration && x.rate),
            lesson_rate_list: state.lessonRateList.filter(x => x.duration && x.rate),
            lesson_location_list: state.selectedPlaceList.filter(x => !!x.place),
          },
          instructorId,
        ),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  const apiPostRequest = async () => {
    try {
      const resp = await dispatch(
        instructorApi.postRequest({
          instructor_id: user.id,
          rate: state.rate,
          rate_list: state.rateList.filter(x => x.duration && x.rate),
          lesson_rate_list: state.lessonRateList.filter(x => x.duration && x.rate),
          lesson_location_list: state.selectedPlaceList.filter(x => !!x.place),
        }),
      );
      callback(resp);
    } catch (err) {
      log.exception(err);
    }
  };

  if (
    business?.financial_config?.business_set_rate ||
    business?.financial_config?.disable_billing
  ) {
    return (
      <ContentCard>
        <Grid container spacing={2} className="tw-mt-4">
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ marginBottom: 20 }}
              display="block"
              className="tw-text-xl tw-font-normal"
            >
              The rate has been disabled or set at the business level and only an admin can change
              it.
            </Typography>
          </Grid>
        </Grid>
      </ContentCard>
    );
  }

  return (
    <Grid container spacing={2} className="tw-mt-4">
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{ marginBottom: 20 }}
          display="block"
          className="tw-text-2xl tw-font-medium"
        >
          Manage Rate
          {instructorLoading ? <CircularProgress size={18} style={{ marginLeft: 7 }} /> : null}
        </Typography>
      </Grid>

      <QuestionRate state={state} setState={setState} />

      <VideoCallRateListComponent state={state} setState={setState} />

      <LessonRateListComponent state={state} setState={setState} />

      <Grid item xs={12} className="tw-align-start tw-flex tw-justify-start tw-mt-8">
        <Button
          variant="outlined"
          className={classnames(
            buttonPrimary,
            'tw-text-lg tw-py-2 tw-w-full md:tw-w-[250px] tw-mr-1',
          )}
          onClick={() => {
            if (user?.instructor?.id) {
              apiPatchRequest(user.instructor.id);
            } else {
              apiPostRequest();
            }
          }}
        >
          Save
        </Button>

        {isOnboarding ? (
          <Button
            variant="outlined"
            className={classnames(
              buttonSecondary,
              'tw-text-lg tw-py-2 tw-w-full md:tw-w-[250px] tw-ml-1',
            )}
            component={Link}
            to="/onboard-2"
          >
            Next
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default function RateContainer({ isOnboarding }: RateProps) {
  return (
    <ContentCard>
      <Rate isOnboarding={isOnboarding} />
    </ContentCard>
  );
}
