import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

// Material UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// App
import ItemSummary from 'components/Feedback/ItemSummary';
import ProfileSummaryComponent from 'components/User/ProfileSummary';
import { VideoListLoading } from 'components/Loading/index';
import { feedbackRequest as feedbackRequestApi } from 'store/index';
import { card } from 'styles/classnames';
import { RequestType } from 'types';
import { useDispatch, useSelector } from 'hooks';

interface RequestListProps {
  list: RequestType[];
  initialized: boolean;
  loadMore: VoidFunction;
  hasMore: boolean;
}

const useStyles = makeStyles(() => ({
  paperContainer: {
    minHeight: '40vh',
  },
}));

// TODO: Add sample requests and responses to give the user an idea of what to expect
function RequestList({ list, initialized, loadMore, hasMore }: RequestListProps): JSX.Element {
  if (!initialized) {
    return <VideoListLoading lg={4} md={4} />;
  } else if (list.length === 0) {
    return (
      <Grid container justifyContent="center" style={{ marginBottom: 40 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="tw">
          <div className={card}>
            <Typography variant="h6" align="center">
              You do not have any requests for feedback.
            </Typography>
            <Typography variant="subtitle1" align="center" className="tw-mt-2">
              Share your profile link to begin giving feedback
            </Typography>
          </div>
        </Grid>

        <ProfileSummaryComponent />
      </Grid>
    );
  } else {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0} style={{ marginTop: 20 }}>
            Loading ...
          </div>
        }
        className="tw-w-full"
      >
        <Grid
          container
          justifyContent={list.length > 2 ? 'center' : 'flex-start'}
          spacing={2}
          id="request-grid"
        >
          {list.map(item => (
            <ItemSummary key={item.id} item={item} height={300} lg={4} />
          ))}
        </Grid>
      </InfiniteScroll>
    );
  }
}

export default function FeedbackListPage(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const list = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].list);
  const initialized = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].initialized);
  const nextUrl = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].url);

  useEffect(() => {
    dispatch(feedbackRequestApi.reset());
    dispatch(feedbackRequestApi.setGetParams({ limit: 25 }));
    dispatch(feedbackRequestApi.getRequest());
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.paperContainer}>
        <Grid item xs={12} className="justify-center tw-mb-8">
          <Typography variant="h5" display="inline">
            Requests
          </Typography>
        </Grid>

        <RequestList
          list={list}
          initialized={initialized}
          hasMore={!!nextUrl}
          loadMore={() => {
            dispatch(feedbackRequestApi.getRequest(nextUrl, true));
          }}
        />
      </Grid>
    </Container>
  );
}
