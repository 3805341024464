import { useContext } from 'react';
import { AppContext, AppContextProps } from 'context/AppContext';
// ----------------------------------------------------------------------

export default function useAppContext(): AppContextProps {
  const context = useContext(AppContext);

  if (!context) throw new Error('App context must be use inside AppProvider');

  return context;
}
