// material
import { useEffect, useState } from 'react';
import { Grid, Container, Typography, Card, CardHeader } from '@mui/material';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useBusiness } from 'hooks';
import classnames from 'classnames';
import Highlight from 'react-highlight';
import 'highlight.js/styles/monokai.css';

export default function Integrations(): JSX.Element {
  const { business } = useBusiness();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  const iframeLink = `
    <div style="position:relative;overflow:hidden;width:100%;padding-top:56.25%;">
      <iframe src="${business?.embed_url}" style="border:0;position:absolute;top:0;left:0;bottom:0;right:0;width:100%;height:100%;"></iframe>
    </div>
  `;
  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Embed Code
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      Copy the following code snippet and add it to your website{' '}
                      {copied ? '(Copied)' : null}
                    </Grid>
                    <Grid item xs={3} className="tw-flex tw-justify-end">
                      <CopyToClipboard text={iframeLink} onCopy={() => setCopied(true)}>
                        <Button
                          color="primary"
                          className="tw-text-blue-700"
                          startIcon={<FileCopyIcon fontSize="medium" />}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </Grid>
                  </Grid>
                }
                className="tw-text-left"
              />
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  minHeight: 100,
                }}
              >
                {business?.embed_url ? (
                  <Highlight className={classnames('monokai', 'tw-whitespace-pre-wrap')}>
                    {iframeLink}
                  </Highlight>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
