const dark = 'tw-text-white';
const border = 'tw-border tw-rounded-lg tw-border-solid ';
export const primaryColor = `tw-bg-blue-600 tw-border-blue-600 hover:tw-bg-blue-700`;
export const buttonPrimary = `${primaryColor} ${dark} ${border}`;
export const buttonSecondary = `tw-bg-white tw-text-blue-600 tw-border-blue-600 hover:tw-bg-gray-100 ${border}`;
export const buttonSuccess = `tw-bg-green-600 tw-border-green-600 hover:tw-bg-green-700 ${dark} ${border}`;
export const buttonDanger = `tw-bg-red-600 tw-border-red-600 hover:tw-bg-red-700 ${dark} ${border}`;
export const card = `tw-relative tw-bg-white tw-py-6 tw-px-6 tw-rounded-3xl tw-full tw-my-4 tw-shadow`;
export const buttonMuted = `tw-bg-gray-500 tw-border-gray-400 hover:tw-bg-gray-400 ${dark} ${border}`;
export const anchorCls = `tw-text-blue-600 hover:tw-text-blue-700 visited:tw-text-purple-600`;
export const anchorMuted = `tw-text-gray-500 hover:tw-text-gray-600 visited:tw-text-gray-600`;
export const eventbriteOrange = `tw-bg-[#ff8000]`;
export const eventbriteButton = `${eventbriteOrange} tw-border-[#ff8000] hover:tw-bg-amber-500 hover:tw-border-amber-500 tw-text-white tw-border tw-rounded-lg tw-border-solid`;

// Below is not used yet.
export const input = `tw-w-full tw-appearance-none tw-border tw-border-gray-secondary-400/60 tw-bg-gray-secondary-50 tw-py-2.5 tw-px-4 tw-text-slate-800 tw-placeholder-slate-500/75 tw-outline-none tw-duration-150 hover:tw-bg-vanilla focus:tw-border-gray-secondary-200 focus:tw-bg-vanilla focus:tw-outline-none focus:tw-ring-gray-secondary-200`;
export const inputError = `tw-border-red-600 tw-ring-red-600`;
export const inputSuccess = `tw-border-green-600 tw-ring-green-600`;
export const inputDisabled = `tw-bg-gray-300 tw-text-gray-500 tw-cursor-not-allowed`;
export const inputLabel = `tw-text-sm tw-text-gray-500 tw-font-semibold tw-mb-1 tw-block`;
export const inputGroup = `tw-flex tw-flex-col tw-mb-4`;
