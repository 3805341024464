import { FC } from 'react';

// Material ui
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// App
import Schedule from 'containers/Call/Schedule';

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    minHeight: '40vh',
  },
  headerTitle: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

const CallContainer: FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.paperContainer}>
        <Grid item xs={12} className={classes.headerTitle}>
          <Typography variant="h5" display="inline">
            Scheduled Video Calls
          </Typography>
        </Grid>

        <Schedule />
      </Grid>
    </Container>
  );
};

export default CallContainer;
