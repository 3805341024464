import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

const baseStyles =
  'tw-duration-150 tw-ease-in-out tw-inline-flex tw-items-center tw-justify-center tw-font-medium tw-group';
const styles = {
  solid: '',
  ghost: 'tw-border',
  secondary: '',
  contained: '',
};

const sizeStyles = {
  sm: 'tw-w-32 tw-py-2 tw-text-base tw-font-medium',
  md: 'tw-w-48 tw-px-5 tw-py-2.5 tw-text-base',
  lg: 'tw-w-72 tw-px-5 tw-py-2.5 xl:tw-px-7 xl:tw-py-4 tw-text-base xl:tw-text-lg',
  full: 'tw-w-full tw-py-3 tw-text-base',
};

const colors = {
  solid: {
    dark: 'tw-bg-slate-700 tw-text-white hover:tw-bg-slate-900',
    light: '',
    danger: 'tw-bg-red-600 tw-border-red-600 hover:tw-bg-red-700 tw-text-white tw-border-solid',
    primary: '',
    success:
      'tw-bg-green-600 tw-border-green-600 hover:tw-bg-green-700 tw-text-white tw-border-solid',
  },

  ghost: {
    dark: 'tw-text-slate-800 tw-border-slate-800 hover:tw-bg-slate-800 hover:tw-text-white',
    light:
      'tw-border-slate-300 tw-text-white tw-hover:tw-border-white hover:tw-bg-white hover:tw-text-slate-900',
    danger: '',
    primary: '',
    success: '',
  },

  secondary: {
    dark: 'tw-text-slate-900 tw-bg-gray-secondary-100 hover:tw-bg-gray-secondary-200/70',
    light: '',
    danger: '',
    primary: '',
    success: '',
  },

  contained: {
    dark: '',
    light: '',
    danger: '',
    primary: '',
    success: '',
  },
};

interface ButtonProps extends ButtonBaseProps {
  variant?: 'solid' | 'ghost' | 'secondary' | 'contained';
  size?: 'sm' | 'md' | 'lg' | 'full';
  className?: string;
  color?: 'dark' | 'light' | 'danger' | 'primary' | 'success';
  href?: string;
  children: ReactNode;
}

export default function Button({
  variant = 'solid',
  size = 'lg',
  color = 'dark',
  className,
  href,
  children,
  ...props
}: ButtonProps) {
  className = clsx(
    baseStyles,
    styles[variant],
    sizeStyles[size],
    colors[variant][color],
    className,
  );
  return href ? (
    <Link to={href} className={className}>
      {children}
    </Link>
  ) : (
    <ButtonBase className={className} {...props}>
      {children}
    </ButtonBase>
  );
}
